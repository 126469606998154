import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard }                from '../auth-guard.service';

import { SLMainComponent } from '../sl-main.component';

import { ProfileComponent }      from './profile.component';

const profileRoute: Routes = [
    { path: 'profile',
        component: SLMainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ProfileComponent }

        ]
    },
    { path: '', redirectTo: '/profile', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forChild(profileRoute) ],
    exports: [ RouterModule ]
})
export class ProfileRoutingModule {}