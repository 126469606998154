<div class="program-station">

    <div class="overlay" *ngIf="displayReprint || displayLBRInput || displayModifyOrder || displayAuthScreen || displayNoCheckInScreen || displayCreateOrder || displayUserNotRegisteredScreen || displayClearListScreen || showInstallList || displayProgError || displayProgDelay || displayUserSearch">

        <div class="select-install-popup" *ngIf="showInstallList">
            <div class="close-x" (click)="closeInstallSearch()"></div>
            <div class="user-search-head">
                <h2>Select Install</h2>
            </div>
            <div class="select-install">
                <div class="heading-row">
                    <p class="lbsn" style="width: 580px;">{{ASSOCIATION}}</p><p class="owner">{{TSAID}}</p><p class="assoc">{{STATUS}}</p>
                </div>
                <ol>
                    <li (click)="selectInstall(install)" *ngFor="let install of installs; index as i" [attr.data-index]="i">
                        <p class="lb">{{install.assocname}} - {{install.title}}</p> <p class="lbown">{{install.trainingscheduleid}}</p><p class="assoc">{{COMPLETE}}</p>
                    </li>
                </ol>
                <div *ngIf="displayNoInstallsFound" class="no-users">
                    {{NO_INSTALLS_FOUND}}
                </div>
            </div>
        </div>

        <div class="auth-popup" *ngIf="displayAuthScreen">
            <div class="close-x" (click)="closeAuth()"></div>
            <div class="auth-message">{{authMessage}}</div>
            <form id="auth-form">
                <input id="authUser" name="usernameBox" placeholder="{{USERNAME}}" [(ngModel)]="username" autocomplete="off" />
                <input type="password" id="passwordBox" name="passwordBox" placeholder="{{PASSWORD}}" [(ngModel)]="password" autocomplete="new-password" />
                <button class="auth-btn" (click)="authMod(authType)">{{AUTHORIZE}}</button>
            </form>
        </div>

        <div class="not-valid-popup" *ngIf="displayProgError">
            <div class="close-x" (click)="closeProgError()"></div>
            <div class="valid-message">{{this.programmingErrorMessage}}</div>
        </div>

        <div class="not-valid-popup" *ngIf="displayProgDelay">
            <div class="valid-message">{{PROG_DISPLAY}}</div>
        </div>

        <div class="not-valid-popup" *ngIf="displayReprint">
            <div class="valid-message">{{REPRINT_MESSAGE}}</div>

            <div class="add-item finish-buttons">
                <button class="modify-order" (click)="reprintOrder()" [disabled]='disablePrintButton' ng-class="{'diabled-class': disablePrintButton }">{{REPRINT_ORDER}}</button>
                <button class="create-transfers" (click)="reprintFinish()">{{FINISH_ORDER}}</button>
            </div>
        </div>

        <div class="not-valid-popup" *ngIf="displayNoCheckInScreen">
            <div class="close-x" (click)="closeCheckIn()"></div>
            <div class="valid-message" *ngIf="notValidAgent">{{AGENT_STATUS}}</div>
            <div class="valid-message" *ngIf="notValidCard">{{CARD_STATUS}}</div>
            <div class="valid-message" *ngIf="queueNoUser">{{PROG_EXIST}}</div>
            <div class="valid-message" *ngIf="queueUser">{{PROG_IN_PROGRESS}} {{this.selectedUserNewOrder.StationUser}}</div>
            <div class="valid-message" *ngIf="noAcctNum">{{this.selectedUserNewOrder.FirstName}} {{this.selectedUserNewOrder.LastName}} {{NO_ACCT_NUMBER}}</div>
            <div class="valid-message" *ngIf="unknownError">Unknown error occured, please try again.</div>
        </div>

        <div class="not-reguser-popup" *ngIf="displayUserNotRegisteredScreen">
            <div class="close-x" (click)="closeRegEmailScreen()"></div>
            <div class="email-message">{{EMAIL_MESSAGING}}</div>
            <div class="email-address"><span style="font-weight: bold;">{{EMAIL}}</span>: {{this.selectedUserNewOrder.EmailAddress}}</div>
            <div class="resend-box">
                <button class="resend" (click)="resendEmail()">{{RESEND_EMAIL}}</button>
            </div>
        </div>

        <div class="popup-box" *ngIf="displayUserSearch">
            <div class="close-x" (click)="closeSearch()"></div>
            <div class="user-search-head">
                <h2>{{CREATE_ORDER_USER_SEARCH}}</h2>
            </div>

            <div class="search-box">
                <!--<p class="heading">User Information</p>-->
                <div class="search-row">
                    <input id="accountNumberNewOrder" [(ngModel)]="firstNameSearchValueNewOrder" class="search-input"
                           [disabled]='disableFirstNameNewOrder'
                           placeholder="{{FIRST_NAME}}"
                           (focus)="firstNameGetFocus()"
                           (blur)="firstNameLoseFocus()"
                           autocomplete="off"
                           (keyup.enter)="keyUpEnterUserFN($event)"
                           value="{{selectedUser?.FirstName}}" />

                    <input [(ngModel)]="lastNameSearchValueNewOrder" class="search-input"
                           [disabled]='disableLastNameNewOrder'
                           placeholder="{{LAST_NAME}}"
                           (focus)="lastNameGetFocus()"
                           (blur)="lastNameLoseFocus()"
                           autocomplete="off"
                           (keyup.enter)="keyUpEnterUserLN($event)"
                           value="{{selectedUser?.LastName}}" />

                    <input [(ngModel)]="accountNumberSearchValueNewOrder" class="search-input" style="text-transform: uppercase;"
                           [disabled]='disableAccountNumberNewOrder'
                           placeholder="{{ACCOUNT_NUMBER}}"
                           (focus)="accountNumberGetFocus()"
                           (blur)="accountNumberLoseFocus()"
                           autocomplete="off"
                           (keyup.enter)="keyUpEnterAccountNumber($event)"
                           value="{{selectedUser?.CardSN}}" />

                    <div class="search-button-container">
                        <button type="button" (click)="searchUsersNewOrder()" class="search-button"></button>
                        <button type="button" (click)="clearSearchNewOrder()" class="clear-search-button"></button>
                    </div>
                </div>

            </div>

            <div class="user-list">
                <div class="heading-row">
                    <p class="lbsn">{{NAME}}</p><p class="owner">{{ACCOUNT_NUMBER}}</p><p class="assoc">{{COMPANY}}</p><p class="assoc">Programming Queue ID</p><p class="assoc">Station User</p>
                </div>
                <ol>
                    <li (click)="selectUserNewOrder(user)" *ngFor="let user of usersNewOrder; index as i" [attr.data-index]="i">
                        <p class="lb">{{user.FirstName}} {{user.LastName}}</p>
                        <p class="lbown">{{user.CardSN}}</p>
                        <p class="assoc">{{user.CompanyName}}</p>
                        <p class="assoc">{{user.ProgrammingQueueID}}</p>
                        <p class="assoc">{{user.StationUser}}</p>
                    </li>
                </ol>
                <div *ngIf="displayNoUserFoundNewOrder" class="no-users">
                    {{NO_USERS_FOUND}}
                </div>
            </div>
        </div>

        <div class="clear-list-popup" *ngIf="displayClearListScreen">
            <div class="close-x" (click)="closeClearListScreen()"></div>
            <div class="clear-list-message">{{CLEAR_LIST_MSG}}</div>
            <button class="yes-clear" (click)="clear()">{{YES}}</button>
            <button class="no-clear" (click)="closeClearListScreen()">{{NO}}</button>
        </div>

        <div class="lbr-popup" *ngIf="displayLBRInput">
            <div class="close-x" (click)="closeLBR()"></div>
            <h2>{{selectedUser?.FirstName}} {{selectedUser?.LastName}} ({{selectedUser?.CardSN}})</h2>

            <div class="add-lbr">
                <div class="add-text">{{LOCKBOXES_TO_RECEIVE}}: </div>
                <div><input id="lbrInput" type="number" class="add" #lbr min="1"></div>
                <div (click)="addLBR(lbr.value); lbr.value=''" class="add-image"></div>
            </div>
        </div>

        <div class="remove-popup" *ngIf="displayModifyOrder">
            <div class="close-x" (click)="closeModifyOrder()"></div>
            <div class="modify-head"><h2>{{MODIFY_ORDER}}</h2></div>

            <div class="add-lbr">
                <div class="add-text">{{LOCKBOXES_TO_RECEIVE}}: </div>
                <div><input id="lbrInput" type="number" class="add" #lbr min="1" value="{{selectedUser.BoxesOwed}}"></div>
            </div>

            <div class="remove-lbr" *ngIf="displayLBRemove">
                <div class="remove-message">Please remove {{removeNotes}} lockboxes</div>
                <div class="add-text">{{LOCKBOXES_TO_REMOVE}}: </div>
                <div><input id="lbRemoveInput" type="number" #lb min="1" (keyup.enter)="removeLB(lb.value); lb.value=''" placeholder="{{SCAN_LOCKBOXES}}"></div>
            </div>

            <div class="notes-lbr">
                <div class="add-text">{{NOTES}}</div>
                <div><textarea class='text-area' rows="4" cols="50" #notes
                               (keyup)="charCount(notesTextBox)"
                               [(ngModel)]="notesTextBox" maxlength="250">{{selectedUser.Notes}}</textarea></div>
                <div class="charLeft"><span [style.color]="notesColor">{{characterleft}}</span> of {{maxlength}}</div>
            </div>

            <div class="mod-order-message">{{modifyOrderNotes}}</div>

            <button class="modify-order-button" (click)="saveModifiedOrder(lbr.value, notes.value)">{{MODIFY_ORDER}}</button>

            <div class="load-wrapp mo" *ngIf="showAjaxLoaderAddLBR">
                <div class="load-3">
                    <div class="line linecolor1"></div>
                    <div class="line linecolor2"></div>
                    <div class="line linecolor3"></div>
                </div>
            </div>
            <div class="complete" *ngIf="showCompleteAddLBR"></div>

        </div>


        <div class="create-new-order-popup" *ngIf="displayCreateOrder">
            <div class="close-x" (click)="closeCreateOrder()"></div>
            <div class="modify-head"><h2>{{CREATE_ORDER}}</h2></div>

            <div class="name">{{selectedUserNewOrder.FirstName}} {{selectedUserNewOrder.LastName}}</div>
            <div class="company">{{selectedUserNewOrder.CompanyName}}</div>

            <div class="add-lbr">
                <div class="add-text">{{LOCKBOXES_TO_RECEIVE}}: </div>
                <div><input id="lbrInput" type="number" class="add" #lbr min="1" value="1"></div>
            </div>

            <div class="notes-lbr">
                <div class="add-text">{{NOTES}}</div>
                <div><textarea class='text-area' rows="4" cols="50" #notes
                               (keyup)="charCount(notesTextBox)"
                               [(ngModel)]="notesTextBox" maxlength="250">{{selectedUserNewOrder.Notes}}</textarea></div>
                <div class="charLeft"><span [style.color]="notesColor">{{characterleft}}</span> of {{maxlength}}</div>
            </div>

            <div class="mod-order-message">{{modifyOrderNotes}}</div>

            <button class="modify-order-button" [disabled]='disableCreateOrder' (click)="saveCreatedOrder(lbr.value, notes.value)">{{CREATE_ORDER}}</button>

            <div class="load-wrapp mo" *ngIf="showAjaxLoaderAddLBR">
                <div class="load-3">
                    <div class="line linecolor1"></div>
                    <div class="line linecolor2"></div>
                    <div class="line linecolor3"></div>
                </div>
            </div>
            <div class="complete" *ngIf="showCompleteAddLBR"></div>

        </div>

    </div>

    <div class="current-install" *ngIf="currentInstallAssoc">{{currentInstallAssoc}} ({{currentInstallTSAID}})</div>

    <div class="search-box">
        <!--<p class="heading">User Information</p>-->
        <div class="search-row">
            <input [(ngModel)]="firstNameSearchValue" class="search-input"
                   [disabled]='disableFirstName'
                   placeholder="{{FIRST_NAME}}"
                   (focus)="firstNameGetFocus()"
                   (blur)="firstNameLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="searchQueue()"
                   value="{{selectedUser?.FirstName}}" />

            <input #firstNameSearchBox [(ngModel)]="lastNameSearchValue" class="search-input"
                   [disabled]='disableLastName'
                   placeholder="{{LAST_NAME}}"
                   (focus)="lastNameGetFocus()"
                   (blur)="lastNameLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="searchQueue()"
                   value="{{selectedUser?.LastName}}" />

            <input id="accountNumber" [(ngModel)]="accountNumberSearchValue" class="search-input" style="text-transform: uppercase;"
                   [disabled]='disableAccountNumber'
                   placeholder="{{ACCOUNT_NUMBER}}"
                   (focus)="accountNumberGetFocus()"
                   (blur)="accountNumberLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="searchQueue()"
                   value="{{selectedUser?.CardSN}}" />

            <div class="search-button-container">
                <button type="button" (click)="searchQueue()" class="search-button" *ngIf="userSelected"></button>
                <button type="button" (click)="clearSearch()" class="clear-search-button"></button>
                <button type="button" (click)="selectSortPref()" class="sort-users" *ngIf="!checkinSortPreference && displayQueue"> Sort By Check-In Date</button>
                <button type="button" (click)="selectSortPref()" class="sort-users" *ngIf="checkinSortPreference && displayQueue"> Sort By Lockbox Count</button>
                <button type="button" (click)="createOrder()" class="create-order" *ngIf="userSelected">{{CREATE_ORDER}}</button>
                <!--<div class="load-wrapp" *ngIf="showAjaxLoader"
                    <div class="load-3">
                        <div class="line linecolor1"></div>
                        <div class="line linecolor2"></div>
                        <div class="line linecolor3"></div>
                    </div>
                </div>-->
            </div>

        </div>

    </div>

    <div class="prog-queue" *ngIf="displayQueue">

        <div class="message" [style.color]="messageColor">{{message}}</div>

        <div *ngIf="displayNoUserFound" class="no-users">{{NO_USERS_FOUND}}</div>

        <ol>
            <li (click)="selectUser(user)" *ngFor="let user of users; index as i" [attr.data-index]="i" class="user-click">
                <div class="card">
                    <div class="head">
                        <p class="name">{{user.FirstName}} {{user.LastName}}</p>
                    </div>
                    <div class="body">
                        <p class="card">{{user.Company}}</p>
                        <p class="lockbox-head">Boxes Owed</p>
                        <p class="lockbox" *ngIf="user.BoxesOwed">{{user.BoxesOwed}}</p>
                        <p class="lockbox" *ngIf="!user.BoxesOwed">0</p>
                        <p class="date">{{user.UTCModified}}</p>
                    </div>
                </div>
            </li>
        </ol>

    </div>

    <div class="right-box" *ngIf="!displayQueue">
        <div class="list">
            <div class="list-box">
                <p class="heading-text">{{SCANNED_LOCKBOXES}}</p>
                <ol>
                    <li *ngFor="let failedlb of failedLockboxes">
                        <span style="color: #992121; font-weight: bold;">{{failedlb}}</span>
                    </li>
                    <li *ngFor="let assignedlb of assignedLockboxes">
                        <span style="color: #58DA5A; font-weight: bold;">{{assignedlb}}</span>
                    </li>
                    <li *ngFor="let lockbox of lockboxes">
                        <span>{{lockbox}}</span>
                        <div class="delete" (click)="delete(lockbox)"><img class="svg-action" src="images/ic_delete_black_24px.svg" /></div>
                    </li>
                </ol>
                <button class="clear-list" (click)="showClearListScreen()" [disabled]='disableClearList' ng-class="{'diabled-class': disableClearList }">{{CLEAR_LIST}}</button>
            </div>

            <div class="info-box">
                <div class="user-info">
                    <!--<p class="name">{{selectedUser?.FirstName}} {{selectedUser?.LastName}}</p>
                    <p class="acctnum">{{selectedUser?.AccountNumber}}</p>-->
                    <div class="lb-box">
                        <div class="receive">
                            <div class="lb-box-head">{{RECEIVE}}</div>
                            <div *ngIf="!selectedUser.BoxesOwed">0</div>
                            <div *ngIf="selectedUser">{{selectedUser?.BoxesOwed}}</div>
                        </div>
                        <div class="scanned">
                            <div class="lb-box-head">{{SCANNED}}</div>
                            <div [style.color]="scannedLBColor">{{scannedLBCount}}</div>
                        </div>
                    </div>
                </div>

                <div class="add-item">
                    <div><input id="lbInput" type="text" class="add" #lockbox placeholder="{{ENTER_SCAN}}" (keyup.enter)="add(lockbox.value); lockbox.value=''" [disabled]='disableAddLockbox' ng-class="{'diabled-class': disableAddLockbox }"></div>
                    <button class="add-box" (click)="add(lockbox.value); lockbox.value=''" [disabled]='disableAddLockbox' ng-class="{'diabled-class': disableAddLockbox }">{{ADD_LOCKBOX}}</button>
                </div>

                <div class="add-item finish-buttons">
                    <button class="modify-order" (click)="modifyOrder()" [disabled]='disableModifyOrder' ng-class="{'diabled-class': disableModifyOrder }">{{MODIFY_ORDER}}</button>
                    <button class="create-transfers" (click)="createTransfers()" [disabled]='disableFinishOrder' ng-class="{'diabled-class': disableFinishOrder }">{{FINISH_ORDER}}</button>
                </div>
                <p class="msg-title">Messages</p>
                <div class="message-box">{{programmingMessage}}</div>
            </div>

        </div>
    </div>
</div>
