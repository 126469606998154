import { InMemoryDbService } from 'angular-in-memory-web-api';
export class InMemoryDataService implements InMemoryDbService {
  createDb() {

    /******************************************************************************
     * Data for login and forgot password screens
     * *****************************************************************************
     * */
    let login = [
      {sid:"C83D37F4466FD72031F273624A4D9CBA",
        id:"US1",
        lastname:"Haines",
        firstname:"Corey",
        username:"chaines",
        password: "Test12",
        eulafilename:"us1.jpg",
        photourl:"nophoto",
        eula:`<b>Test the end user license agreement page.</b> AngularJS (commonly referred to as "Angular" or "Angular.js") is a complete JavaScript-based open-source front-end web application framework mainly maintained by Google and by a community of individuals and corporations to address many of the challenges encountered in developing single-page applications. The JavaScript components complement Apache Cordova, the framework used for developing cross-platform mobile apps. It aims to simplify both the development and the testing of such applications by providing a framework for property-side model–view–controller (MVC) and model–view–viewmodel (MVVM) architectures, along with components commonly used in rich Internet applications.

        The AngularJS framework works by first reading the HTML page, which has embedded into it additional custom tag attributes. Angular interprets those attributes as directives to bind input or output parts of the page to a model that is represented by standard JavaScript variables. The values of those JavaScript variables can be manually set within the code, or retrieved from static or dynamic JSON resources.

          According to JavaScript analytics service Libscore, AngularJS is used on the websites of Wolfram Alpha, NBC, Walgreens, Intel, Sprint, ABC News, and approximately 12,000 other sites out of 1 million tested in October 2016.[3]

      AngularJS is the frontend part of the MEAN stack, consisting of MongoDB database, Express.js web application server framework, Angular.js itself, and Node.js runtime environment.`,
        permissions:[{"Permission":"SysAdmin","PermissionID":"AS2","PermissionDescription":"Corey Haines"}],
        ResponseCode:200,
        ResponseText:"Success"
      }
    ];

    let checkinlockbox = [
      {
        lbsn: "1034949",
        owner: "Corey Haines",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      },
      {
        lbsn: "1115679",
        owner: "Huckles Haines",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      },
      {
        lbsn: "00792223",
        owner: "Savanna Haines",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      },
      {
        lbsn: "1045125",
        owner: "Nova Haines",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      }
    ];
    let lockboxOwner = [
      {
        lbsn: "123",
        owner: "",
        association: "",
        AssocID: ""
      },
      {
        lbsn: "111",
        owner: "Huckles Haines",
        UserID: "US03",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      },
      {
        lbsn: "00792223",
        owner: "Savanna Haines",
        UserID: "US02",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      },
      {
        lbsn: "1034967",
        owner: "Nova Haines",
        UserID: "US03",
        association: "SentriLock LLC.",
        AssocID: "AS2"
      }
    ];

    let authCheck = [
      {
        username: "chaines",
        password: "Test12",
        permission:"admin"
      },
      {
        username: "notadmin",
        password: "Test12",
        permission:"checkin"
      }

    ];

    let users = [
      {
          UserID: "US01",
          FirstName: "Nova",
          LastName: "Haines",
          CardSN: "P101",
          Association: "SentriLock LLC.",
          AssocID: "AS2",
          ClassDate: "05/05",
          ClassTime: "11:00",
          LBR: "10",
          Notes:""
      },
      {
          UserID: "US02",
          FirstName: "Savanna",
          LastName: "Haines",
          CardSN: "P102",
          Association: "SentriLock LLC.",
          AssocID: "AS2",
          ClassDate: "05/07",
          ClassTime: "3:30",
          LBR: "",
          Notes:""
      },
      {
          UserID: "US03",
          FirstName: "Huckles",
          LastName: "Haines",
          CardSN: "P103",
          Association: "SentriLock LLC.",
          AssocID: "AS2",
          ClassDate: "11/15",
          ClassTime: "2:15",
          LBR: "1",
          Notes:""
      },
      {
          UserID: "US04",
          FirstName: "Dogbert",
          LastName: "Dilbert",
          CardSN: "P097422717",
          Association: "SentriLock LLC.",
          AssocID: "AS2",
          ClassDate: "11/15",
          ClassTime: "2:15",
          LBR: "3",
          Notes:""
      }
    ];

    return {
      // login and forgot password
      //login, //Commented to use server instead of in memory data
      // Lockboxes
      //checkinlockbox,
      //lockboxOwner,
      // User Management
      //users,
      //authCheck
    };
  }
}
