import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';





import { Router, ActivatedRoute, Params } from '@angular/router';

import { SentriLockHttpService } from '../sl-http.service';
import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';

@Injectable()
export class ProfileService
{

    message: string;

    email: string;
    mobile: string;
    username: string;
    password: string;

    private headers = new Headers({'Content-Type': 'application/json'});

    isRegAuthorized: boolean = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        this.slGlobalService.saveCallStack("ProfileService:constructor");
    }

    //Check if value of a variable is undefined
    public isUndefined(value) {return typeof value === 'undefined';}

    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("ProfileService:handleError");

        //this.router.navigate(['/error']);

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

    private handleRegError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("ProfileService:handleRegError");

        var errorMessage;

        errorMessage = error._body;

        return Promise.reject(errorMessage);
    }

}