<form #userForm="ngForm" novalidate>

    <div class="add-user-form">
        <div class="wrapper">
            <div class="heading">{{FIRST_NAME}}</div>
            <input type="text" #firstName="ngModel"
                   name="firstName" placeholder="{{FIRST_NAME}}" [(ngModel)]="myUser.FirstName"
                   required autocomplete="off" />
        </div>

        <div class="wrapper">
            <div class="heading">{{LAST_NAME}}</div>
            <input type="text" #lastName="ngModel"
                   name="lastName" placeholder="{{LAST_NAME}}" [(ngModel)]="myUser.LastName"
                   required autocomplete="off" />
        </div>

        <div class="wrapper">
            <div class="heading">{{USERNAME}}</div>
            <input type="text" #username="ngModel"
                   name="username" placeholder="{{USERNAME}}" [(ngModel)]="myUser.Username"
                   required autocomplete="new-password" />
        </div>

        <div class="wrapper">
            <div class="heading">{{TYPE}}</div>

            <select class="type" #typeSelect="ngModel" id="type" name="type"
                    [(ngModel)]="myUser.type" required>
                <option value="" disabled>{{SELECT_USER_TYPE}}</option>
                <option value="CheckIn">{{CHECKIN}}</option>
                <option value="Programming">{{PROGRAMMING}}</option>
                <option value="AssocAdmin">{{ASSOC_ADMIN}}</option>
                <option value="InstallAdmin">{{INSTALL_ADMIN}}</option>
                <option value="SysAdmin">{{SYS_ADMIN}}</option>
            </select>
        </div>

        <div class="wrapper">
            <div class="heading">{{PASSWORD}}</div>
            <input type="password" #password="ngModel"
                   name="password" placeholder="{{PASSWORD}}" [(ngModel)]="myUser.Password"
                   required autocomplete="new-password" />
        </div>
        <div class="wrapper">
            <div class="heading">{{CONFIRM_PASSWORD}}</div>
            <input type="password" #password="ngModel"
                   name="confirmpassword" placeholder="{{CONFIRM_PASSWORD}}" [(ngModel)]="myUser.ConfirmPassword"
                   required autocomplete="new-password" />
        </div>

        <div class="edit-message" [style.color]="messageColor">{{message}}</div>

        <div class="save">
            <button type="submit" [disabled]='!userForm.form.valid' ng-class="{'diabled-class': !userForm.form.valid }"
                    (click)="saveUser()" class="btn btn-default" class="saveButton">{{SAVE}}</button>
        </div>

    </div>
</form>