import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {

    constructor(private defaultUrlSerializer: DefaultUrlSerializer) { }

    /*
    * This function will decode the URL before we parse it.
    * */
    parse(url: string): UrlTree {
        var decodedUrl = decodeURIComponent(url);
        return this.defaultUrlSerializer.parse(decodedUrl);
    }

    /*
    * This function will just perform the default behavior
    * */
    serialize(tree: UrlTree): string {
        return this.defaultUrlSerializer.serialize(tree);
    }
}