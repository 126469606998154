
import { interval as observableInterval, Observable, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

//Angular Material Progress bar
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { Install } from '../install';
import { InstallData } from '../installdata';

// import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
    selector: 'admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css'],
    providers: [AuthService]
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

    // the tsa id for the install
    @Input() tsaid: string;
    @Input() tsaname: string;

    private refreshDashboardSub: Subscription;

    // this is to hold messages that need to get displayed to the user
    message: string = "";

    //Set the color to black
    messageColor: string = '#000';

    installMessage: string = "";
    installAssoc: string = "";
    installTSAID: string = "";

    showAjaxLoader: boolean = false;

    allowResetLockboxes: boolean = false;

    allowResetUsers: boolean = false;

    stopFlag: boolean = false;

    showInstallList: boolean = true;
    displayNoInstallsFound: boolean = false;

    isSysAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;

    dataProcessState: boolean = false;

    lastSyncDate: string = "";

    dataStart: boolean = false;

    installs;
    selectedInstall: Install = new Install;

    //Install Settings
    InstallType: string = "";
    ReturnsAccepted: string = "";
    LockboxExRatio: string = "";
    SendRegInvite: string = "";
    SendRegInviteDate: string = "";
    KeyAgreement: string = "";
    AgentBilling: string = "";
    acceptKeyReturns: string = "";
    acceptCradleReturns: string = "";
    acceptLockboxReturns: string = "";
    printLabels: string = "";

    currentInstallKeyReturn: string = "";
    currentInstallCradleReturn: string = "";
    currentInstallLBReturn: string = "";
    currentInstallLBRatio: string = "";

    //Data Progress chart
    AgentData = 0;
    AccountLockboxes = 0;
    AssociationData = 0;
    CardData = 0;
    CompanyData = 0;
    LBAuthData = 0;
    LBMFGData = 0;
    LockboxData = 0;
    RegionData = 0;
    UserData = 0;
    UserPermissionsData = 0;

    processError: string = "";

    //Tabs to display
    displayAddUser: boolean = false;
    displayProgQueue: boolean = false;
    displayTestPrint: boolean = false;
    displaySettings: boolean = true;

    //Seconds to poll queue
    installDataPollRate = 10;


    //Assigned chart
    dataSet: boolean = false;
    assigned = 0;
    remaining = 0;
    total = 0;

    //Assigned chart
    checkinDataSet: boolean = false;
    currentCheckins = 0;
    remainingCheckins = 0;
    totalCheckins = 0;

    checkinsDate = 0;
    checkins = 0;
    progqueue = 0;

    checkinDate;

    constructor(public fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        private commonService: CommonService,
        private authService: AuthService,
        private validatorService: ValidatorService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:constructor");

    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:ngOnInit");

        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        this.checkinDate = month + '/' + day + '/' + year;


        // get lb assigned amount
        this.lbAssigned();
        this.checkinsDateReport("1");
        this.checkinsReport("0");
        this.progQueueReport();

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();

        // Navigate to programming/checkin station for non-admin's
        if (this.slGlobalService.isProgrammingStation()) {
            this.router.navigate(['/programming']);
        } else if (this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        }
        if (this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }


        // get the translations
        this.authService.getTranslations('admin', "ADMIN_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

        //Refresh report data every minute
        this.refreshDashboardSub = observableInterval(60000).subscribe(x => {

            // get lb assigned amount
            this.lbAssigned();
            this.checkinsDateReport("1");
            this.checkinsReport("0");
            this.progQueueReport();

        });
    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:ngOnDestroy");

        this.refreshDashboardSub.unsubscribe();

    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    NO_INSTALLS_FOUND: string = "";             // "No Installs Found"
    ASSOCIATION: string = "";             // "Association"
    TSAID: string = "";             // "TSAID"
    STATUS: string = "";             // "Status"
    UNABLE_RETRIEVE: string = "";             // "Unable to retrieve data"
    INSTALL_SETTINGS: string = "";             // "Install Settings"
    PROCESSED_DATA: string = "";             // "Processed Data"
    AGENT_BILLING: string = "";             // "Agent Billing"
    KEY_AGREEMENT: string = "";             // "Key Agreement"
    INSTALL_TYPE: string = "";             // "Key Agreement"
    RETURNS_ACCEPTED: string = "";             // "Key Agreement"
    RETURNS_LB: string = "";             // "Key Agreement"
    RETURNS_KEY: string = "";             // "Key Agreement"
    RETURNS_CRADLE: string = "";             // "Key Agreement"
    LOCKBOX_EXCHANGE_RATIO: string = "";             // "Key Agreement"
    SEND_REG_INVITE: string = "";             // "Key Agreement"
    START_PROCESSING: string = "";             // "Start Processing"
    STOP_PROCESSING: string = "";             // "Stop Processing"
    LAST_SYNC: string = "";             // "Last Sync"
    NO_DATA: string = "";             // "No Data"
    NO: string = "";             // "No"
    YES: string = "";             // "Yes"

    AGENT: string = "";             // "Agent"
    PROGRAMMING_LIST: string = "";             // "PROGRAMMING_LIST"
    CARD: string = "";             // "Card"
    COMPANY: string = "";             // "Company"
    LBMFG: string = "";             // "LBMFG"
    LBAUTH: string = "";             // "LBMFG"
    LOCKBOX: string = "";             // "Lockbox"
    REGION: string = "";             // "Region"
    USER: string = "";             // "User"
    USER_PERMISSIONS: string = "";             // "User Permissions"
    INSTALL_DATA_PROGRESS: string = "";             // "Install Data Progress"

    TOTAL_LB_ASSIGNED: string = "";
    ASSIGNED_TITLE: string = "";
    REMAINING_TITLE: string = "";
    PROG_REMAINING: string = "";
    TOTAL: string = "";
    CHECK_INS: string = "";
    CC_TITLE: string = "";
    RC_TITLE: string = "";
    CURRENT: string = "";

    loadTranslations() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:loadTranslations");

        // the call was successful so we should be able to get the translation data
        var adminTranslationData = this.slGlobalService.getTranslations("ADMIN_COMPONENT");

        if (adminTranslationData) {
            this.NO_INSTALLS_FOUND = adminTranslationData.noinstallsfound;
            this.ASSOCIATION = adminTranslationData.association;
            this.TSAID = adminTranslationData.tsaid;
            this.STATUS = adminTranslationData.status;
            this.UNABLE_RETRIEVE = adminTranslationData.unabletoretrieve;
            this.INSTALL_SETTINGS = adminTranslationData.installsettings;
            this.PROCESSED_DATA = adminTranslationData.processeddata;
            this.AGENT_BILLING = adminTranslationData.agentbilling;
            this.KEY_AGREEMENT = adminTranslationData.keyagreement;
            this.INSTALL_TYPE = adminTranslationData.installtype;
            this.RETURNS_ACCEPTED = adminTranslationData.returnsaccepted;
            this.LOCKBOX_EXCHANGE_RATIO = adminTranslationData.lbexchangeratio;
            this.SEND_REG_INVITE = adminTranslationData.sendreginvite;
            this.START_PROCESSING = adminTranslationData.startprocessing;
            this.STOP_PROCESSING = adminTranslationData.stopprocessing;
            this.LAST_SYNC = adminTranslationData.lastsync;
            this.NO_DATA = adminTranslationData.nodata;
            this.NO = adminTranslationData.no;
            this.YES = adminTranslationData.yes;
            this.RETURNS_LB = adminTranslationData.lbreturn;
            this.RETURNS_KEY = adminTranslationData.keyreturn;
            this.RETURNS_CRADLE = adminTranslationData.cradlereturn;

            this.AGENT = adminTranslationData.agent;
            this.PROGRAMMING_LIST = adminTranslationData.programminglist;
            this.CARD = adminTranslationData.card;
            this.COMPANY = adminTranslationData.company;
            this.LBMFG = adminTranslationData.lbmfg;
            this.LBAUTH = adminTranslationData.lbauth;
            this.LOCKBOX = adminTranslationData.lockbox;
            this.REGION = adminTranslationData.region;
            this.USER = adminTranslationData.user;
            this.USER_PERMISSIONS = adminTranslationData.userpermissions;
            this.INSTALL_DATA_PROGRESS = adminTranslationData.installdataprocess;

            this.TOTAL_LB_ASSIGNED = adminTranslationData.totallbassigned;
            this.ASSIGNED_TITLE = adminTranslationData.lbassigned;
            this.REMAINING_TITLE = adminTranslationData.remaining;
            this.PROG_REMAINING = adminTranslationData.progremaining;
            this.CHECK_INS = adminTranslationData.checkins;
            this.TOTAL = adminTranslationData.total;
            this.CC_TITLE = adminTranslationData.currentcheckins;
            this.RC_TITLE = adminTranslationData.remainingcheckins;
            this.CURRENT = adminTranslationData.current;

        }

        this.InstallType = this.NO_DATA;
        this.ReturnsAccepted = this.NO_DATA;
        this.LockboxExRatio = this.NO_DATA;
        this.SendRegInvite = this.NO_DATA;
        this.SendRegInviteDate = this.NO_DATA;
        this.KeyAgreement = this.NO_DATA;
        this.AgentBilling = this.NO_DATA;
        this.acceptKeyReturns = this.NO_DATA;
        this.acceptCradleReturns = this.NO_DATA;
        this.acceptLockboxReturns = this.NO_DATA;

    }

    //Display correct menu information
    selectMenu(action) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:selectMenu");

        if (action == 'adduser') {
            this.displaySettings = false;
            this.displayProgQueue = false;
            this.displayTestPrint = false;
            this.displayAddUser = true;
        } else if (action == 'settings') {
            this.displayAddUser = false;
            this.displayProgQueue = false;
            this.displayTestPrint = false;
            this.displaySettings = true;
        } else if (action == 'programming') {
            this.displayAddUser = false;
            this.displaySettings = false;
            this.displayTestPrint = false;
            this.displayProgQueue = true;
        } else if (action == 'print') {
            this.displayAddUser = false;
            this.displaySettings = false;
            this.displayProgQueue = false;
            this.displayTestPrint = true;
        }

    }

    lbAssigned() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:lbAssigned");

        this.adminService
            .getLBAssigned(this.selectedInstall.trainingscheduleid)
            .then(this.setlbAssigned.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag
    setlbAssigned(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setlbAssigned");

        this.remaining = jsonResponse.Remaining;
        this.assigned = jsonResponse.Assigned;
        this.total = jsonResponse.Total;

        this.dataSet = true;
    }

    checkinsDateReport(date) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:lbAssigned");

        this.adminService
            .getCheckins(this.selectedInstall.trainingscheduleid, date)
            .then(this.setCheckinsDateReport.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag
    setCheckinsDateReport(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setlbAssigned");

        this.currentCheckins = jsonResponse.checkins;
        this.totalCheckins = jsonResponse.classregistration;

        if (this.totalCheckins > this.currentCheckins) {
            this.remainingCheckins = this.totalCheckins - this.currentCheckins;
        } else {
            this.remainingCheckins = 0;
            this.totalCheckins = this.currentCheckins;
        }

        this.checkinDataSet = true;
    }

    checkinsReport(date) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:lbAssigned");

        this.adminService
            .getCheckins(this.selectedInstall.trainingscheduleid, date)
            .then(this.setCheckinsReport.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag
    setCheckinsReport(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setCheckinsReport");

        this.checkins = jsonResponse.checkins;
    }

    progQueueReport() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:progQueueReport");

        this.adminService
            .getProgrammingQueues(this.selectedInstall.trainingscheduleid)
            .then(this.setProgQueueReport.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag
    setProgQueueReport(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setprogQueueReport");

        this.progqueue = jsonResponse.queues;
    }


    //Get the current installs that have data
    getCurrentInstalls() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:getCurrentInstalls");

        this.commonService
            .searchInstalls("0")
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setCurrentInstallList");

        this.installMessage = "";

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = installsArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if (installs.length == 1) {
            this.selectInstall(installsArray[0]);

            this.showInstallList = false;

            //Get the settings and data
            this.getInstallSettings();
            this.getInstallData();


        } else {

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    //Get the current installs that have data
    getInstallData() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:getInstallData");

        //If install is selected lets re-check the data progress every 10 seconds
        if (this.selectedInstall.trainingscheduleid && this.stopFlag != true) {

            this.adminService
                .searchInstallData(this.selectedInstall.trainingscheduleid)
                .then(this.setCurrentInstallData.bind(this))
                .catch(this.displayError.bind(this));

        } else {
            //Do not run
        }
    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallData(installData): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData.data;

        var tsaid = this.selectedInstall.trainingscheduleid;

        if (installData.allowreset == "1") {
            this.allowResetLockboxes = true;
        }

        if (InstallDataProgress[tsaid].utclastsync) {
            this.lastSyncDate = this.slGlobalService.getDateFromString(InstallDataProgress[tsaid].utclastsync);
        }

        this.AgentData = InstallDataProgress[tsaid].Agent.percentcomplete;
        this.AccountLockboxes = InstallDataProgress[tsaid].AccountLockboxes.percentcomplete;
        this.AssociationData = InstallDataProgress[tsaid].Association.percentcomplete;
        this.CardData = InstallDataProgress[tsaid].Card.percentcomplete;
        this.CompanyData = InstallDataProgress[tsaid].Company.percentcomplete;
        this.LBAuthData = InstallDataProgress[tsaid].LBAuth.percentcomplete;
        this.LBMFGData = InstallDataProgress[tsaid].LBMFG.percentcomplete;
        this.LockboxData = InstallDataProgress[tsaid].Lockbox.percentcomplete;
        this.RegionData = InstallDataProgress[tsaid].Region.percentcomplete;
        this.UserData = InstallDataProgress[tsaid].User.percentcomplete;
        this.UserPermissionsData = InstallDataProgress[tsaid].UserPermission.percentcomplete;

        //Check current state of data processing
        //this.checkDataProcessState();

    }

    //Get the current installs that have data
    getInstallSettings() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:getInstallSettings");

        if (this.selectedInstall.trainingscheduleid) {
            this.commonService
                .searchInstallSettings(this.selectedInstall.trainingscheduleid)
                .then(this.setInstallSettings.bind(this))
                .catch(this.displayError.bind(this));
        }

    }

    // Set the complete flag from change lockboxes to receive
    setInstallSettings(installData): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:setInstallSettings");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {

            if (setting.Name == "AgentBilling") {

                if (setting.Value == '1') {
                    this.AgentBilling = this.YES;
                } else {
                    this.AgentBilling = this.NO;
                }
            }
            if (setting.Name == "ConversionType") {
                this.InstallType = setting.Value;
            }
            if (setting.Name == "KeyAgreement") {
                if (setting.Value == '1') {
                    this.KeyAgreement = this.YES;
                } else {
                    this.KeyAgreement = this.NO;
                }
            }
            if (setting.Name == "LBReturnRatio") {
                this.LockboxExRatio = setting.Value + " to 1";
            }
            if (setting.Name == "SendRegistrationInviteStartDate") {
                this.SendRegInviteDate = setting.Value;
            }
            if (setting.Name == "AcceptLockboxReturns") {
                if (setting.Value == "1") {
                    this.acceptLockboxReturns = this.YES;
                } else {
                    this.acceptLockboxReturns = this.NO;
                }
                this.currentInstallLBReturn = setting.Value;
            }
            if (setting.Name == "AcceptCradleReturns") {
                if (setting.Value == "1") {
                    this.acceptCradleReturns = this.YES;
                } else {
                    this.acceptCradleReturns = this.NO;
                }
                this.currentInstallCradleReturn = setting.Value;
            }
            if (setting.Name == "AcceptKeyReturns") {
                if (setting.Value == "1") {
                    this.acceptKeyReturns = this.YES;
                } else {
                    this.acceptKeyReturns = this.NO;
                }
                this.currentInstallKeyReturn = setting.Value;
            }
            if (setting.Name == "PrintLabels") {
                if (setting.Value == "1") {
                    this.printLabels = this.YES;
                } else {
                    this.printLabels = this.NO;
                }
            }
        }

    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:selectInstall");

        this.selectedInstall = install;

        this.installAssoc = install.assocname + ' - ' + this.selectedInstall.title;
        this.installTSAID = install.trainingscheduleid;

        if (install.allowuserreset == 1) {
            this.allowResetUsers = true;
        }

        this.showInstallList = false;

        //Get the settings and data
        this.getInstallSettings();
        this.getInstallData();
    }

    //Close the user search dialog
    closeInstallSearch() {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:closeInstallSearch");

        this.displayAddUser = true;
        this.displaySettings = false;
    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminDashboardComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }

}