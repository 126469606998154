<div class="guage-box">
    <h2>{{TOTAL_LB_ASSIGNED}}</h2>
    <div class="guage-block" >
        <mwl-gauge *ngIf="dataSet"
                class="two"
                [max]=total
                [dialStartAngle]="180"
                [dialEndAngle]="0"
                [value]=assigned
                [animated]="false">
        </mwl-gauge>
    </div>
    <div class="dash-total">{{total}}</div>
    <div class="dashboard-value">
        <div class="current">
            <div class="current-title">{{ASSIGNED_TITLE}}</div>
            <div class="current-value" style="padding: 4px 0px 0px 0px;">{{assigned}}</div>
        </div>
        <div class="total">
            <div class="total-title">{{REMAINING_TITLE}}</div>
            <div class="total-title" style="padding: 4px 0px 0px 0px;">{{remaining}}</div>
        </div>
    </div>
</div>

<div class="guage-box green">
    <h2>{{PROG_REMAINING}}</h2>
    <div class="checkin-total">{{progqueue}}</div>
</div>

<div class="guage-box purple">
    <h2>{{CHECK_INS}} {{checkinDate}}</h2>
    <div class="guage-block" >
    <mwl-gauge *ngIf="checkinDataSet"
               class="two"
               [max]=totalCheckins
               [dialStartAngle]="180"
               [dialEndAngle]="0"
               [value]=currentCheckins
               [animated]="false">
    </mwl-gauge>
</div>
    <div class="dash-total purple">{{totalCheckins}}</div>
    <div class="dashboard-value">
        <div class="current">
            <div class="current-title">{{CURRENT}}</div>
            <div class="current-value" style="padding: 4px 0px 0px 0px;">{{currentCheckins}}</div>
        </div>
        <div class="total">
            <div class="total-title">{{REMAINING_TITLE}}</div>
            <div class="total-title" style="padding: 4px 0px 0px 0px;">{{remainingCheckins}}</div>
        </div>
    </div>
</div>

<div class="guage-box orange">
    <h2>{{TOTAL}} {{CHECK_INS}}</h2>
    <div class="checkin-total">{{checkins}}</div>
</div>

