<div class="settings">
    <p class="title head">{{INSTALL_SETTINGS}}</p>
    <p class="title">{{INSTALL_TYPE}}:</p><p class="value">{{InstallType}}</p>
    <p class="title">{{RETURNS_KEY}}:</p><p class="value">{{acceptKeyReturns}}</p>
    <p class="title">{{RETURNS_CRADLE}}:</p><p class="value">{{acceptCradleReturns}}</p>
    <p class="title">{{RETURNS_SENTRICARD}}:</p><p class="value">{{acceptSentriCardReturns}}</p>
    <p class="title">{{RETURNS_LB}}:</p><p class="value">{{acceptLockboxReturns}}</p>
    <p class="title">{{LOCKBOX_EXCHANGE_RATIO}}:</p><p class="value">{{LockboxExRatio}}</p>
    <p class="title">{{SEND_REG_INVITE}}:</p><p class="value">{{SendRegInviteDate}}</p>
    <p class="title">{{KEY_AGREEMENT}}:</p><p class="value">{{KeyAgreement}}</p>
    <p class="title">{{SUPRA_LOCKBOX_OWNER}}:</p><p class="value">{{SupraLockbox}}</p>
    <p class="title">{{AGENT_BILLING}}:</p><p class="value">{{AgentBilling}}</p>
</div>