import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { Observable, Subscription } from 'rxjs';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

//Angular Material Progress bar
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { Install } from '../install';
import { InstallData } from '../installdata';

// import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
    selector: 'admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.css'],
    providers: [AuthService]
})
export class AdminSettingsComponent implements OnInit, OnDestroy {

    // the tsa id for the install
    @Input() tsaid: string;
    @Input() tsaname: string;

    private dataProgressSub: Subscription;

    // this is to hold messages that need to get displayed to the user
    message: string = "";

    //Set the color to black
    messageColor: string = '#000';

    installMessage: string = "";
    installAssoc: string = "";
    installTSAID: string = "";

    showAjaxLoader: boolean = false;

    allowResetLockboxes: boolean = false;

    allowResetUsers: boolean = false;

    stopFlag: boolean = false;

    showInstallList: boolean = true;
    displayNoInstallsFound: boolean = false;

    isSysAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;

    dataProcessState: boolean = false;

    lastSyncDate: string = "";

    dataStart: boolean = false;

    installs;
    selectedInstall: Install = new Install;

    //Install Settings
    InstallType: string = "";
    ReturnsAccepted: string = "";
    LockboxExRatio: string = "";
    SendRegInvite: string = "";
    SendRegInviteDate: string = "";
    KeyAgreement: string = "";
    SupraLockbox: string = "";
    AgentBilling: string = "";
    acceptKeyReturns: string = "";
    acceptCradleReturns: string = "";
    acceptSentriCardReturns: string = "";
    acceptLockboxReturns: string = "";
    printLabels: string = "";

    currentInstallKeyReturn: string = "";
    currentInstallCradleReturn: string = "";
    currentInstallSentriCardReturn: string = "";
    currentInstallLBReturn: string = "";
    currentInstallLBRatio: string = "";

    //Data Progress chart
    AccountLockboxes = 0;

    constructor(public fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        private commonService: CommonService,
        private authService: AuthService,
        private validatorService: ValidatorService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminComponent:constructor");

    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminComponent:ngOnInit");

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();

        // Navigate to programming/checkin station for non-admin's
        if (this.slGlobalService.isProgrammingStation()) {
            this.router.navigate(['/programming']);
        } else if (this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        }
        if (this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }

        this.getInstallSettings();

        // get the translations
        this.authService.getTranslations('admin', "ADMIN_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("AdminComponent:ngOnDestroy");

    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("AdminComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("AdminComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    NO_INSTALLS_FOUND: string = "";             // "No Installs Found"
    ASSOCIATION: string = "";             // "Association"
    TSAID: string = "";             // "TSAID"
    STATUS: string = "";             // "Status"
    UNABLE_RETRIEVE: string = "";             // "Unable to retrieve data"
    INSTALL_SETTINGS: string = "";             // "Install Settings"
    PROCESSED_DATA: string = "";             // "Processed Data"
    AGENT_BILLING: string = "";             // "Agent Billing"
    KEY_AGREEMENT: string = "";             // "Key Agreement"
    INSTALL_TYPE: string = "";             // "Key Agreement"
    RETURNS_ACCEPTED: string = "";             // "Key Agreement"
    RETURNS_LB: string = "";             // "Key Agreement"
    RETURNS_KEY: string = "";             // "Key Agreement"
    RETURNS_CRADLE: string = "";             // "Key Agreement"
    RETURNS_SENTRICARD: string = "";             // "Key Agreement"
    LOCKBOX_EXCHANGE_RATIO: string = "";             // "Key Agreement"
    SEND_REG_INVITE: string = "";             // "Key Agreement"
    START_PROCESSING: string = "";             // "Start Processing"
    STOP_PROCESSING: string = "";             // "Stop Processing"
    LAST_SYNC: string = "";             // "Last Sync"
    NO_DATA: string = "";             // "No Data"
    NO: string = "";             // "No"
    YES: string = "";             // "Yes"

    AGENT: string = "";             // "Agent"
    PROGRAMMING_LIST: string = "";             // "PROGRAMMING_LIST"
    CARD: string = "";             // "Card"
    COMPANY: string = "";             // "Company"
    LBMFG: string = "";             // "LBMFG"
    LBAUTH: string = "";             // "LBMFG"
    LOCKBOX: string = "";             // "Lockbox"
    REGION: string = "";             // "Region"
    USER: string = "";             // "User"
    USER_PERMISSIONS: string = "";             // "User Permissions"
    INSTALL_DATA_PROGRESS: string = "";             // "Install Data Progress"
    SUPRA_LOCKBOX_OWNER: string = "";             // "Install Data Progress"

    loadTranslations() {

        this.slGlobalService.saveCallStack("AdminComponent:loadTranslations");

        // the call was successful so we should be able to get the translation data
        var adminTranslationData = this.slGlobalService.getTranslations("ADMIN_COMPONENT");

        if (adminTranslationData) {
            this.NO_INSTALLS_FOUND = adminTranslationData.noinstallsfound;
            this.ASSOCIATION = adminTranslationData.association;
            this.TSAID = adminTranslationData.tsaid;
            this.STATUS = adminTranslationData.status;
            this.UNABLE_RETRIEVE = adminTranslationData.unabletoretrieve;
            this.INSTALL_SETTINGS = adminTranslationData.installsettings;
            this.PROCESSED_DATA = adminTranslationData.processeddata;
            this.AGENT_BILLING = adminTranslationData.agentbilling;
            this.KEY_AGREEMENT = adminTranslationData.keyagreement;
            this.SUPRA_LOCKBOX_OWNER = adminTranslationData.supralb;
            this.INSTALL_TYPE = adminTranslationData.installtype;
            this.RETURNS_ACCEPTED = adminTranslationData.returnsaccepted;
            this.LOCKBOX_EXCHANGE_RATIO = adminTranslationData.lbexchangeratio;
            this.SEND_REG_INVITE = adminTranslationData.sendreginvite;
            this.START_PROCESSING = adminTranslationData.startprocessing;
            this.STOP_PROCESSING = adminTranslationData.stopprocessing;
            this.LAST_SYNC = adminTranslationData.lastsync;
            this.NO_DATA = adminTranslationData.nodata;
            this.NO = adminTranslationData.no;
            this.YES = adminTranslationData.yes;
            this.RETURNS_LB = adminTranslationData.lbreturn;
            this.RETURNS_KEY = adminTranslationData.keyreturn;
            this.RETURNS_CRADLE = adminTranslationData.cradlereturn;
            this.RETURNS_SENTRICARD = adminTranslationData.sentricardreturn;

            this.AGENT = adminTranslationData.agent;
            this.PROGRAMMING_LIST = adminTranslationData.programminglist;
            this.CARD = adminTranslationData.card;
            this.COMPANY = adminTranslationData.company;
            this.LBMFG = adminTranslationData.lbmfg;
            this.LBAUTH = adminTranslationData.lbauth;
            this.LOCKBOX = adminTranslationData.lockbox;
            this.REGION = adminTranslationData.region;
            this.USER = adminTranslationData.user;
            this.USER_PERMISSIONS = adminTranslationData.userpermissions;
            this.INSTALL_DATA_PROGRESS = adminTranslationData.installdataprocess;

        }

        this.InstallType = this.NO_DATA;
        this.ReturnsAccepted = this.NO_DATA;
        this.LockboxExRatio = this.NO_DATA;
        this.SendRegInvite = this.NO_DATA;
        this.SendRegInviteDate = this.NO_DATA;
        this.KeyAgreement = this.NO_DATA;
        this.SupraLockbox = this.NO_DATA;
        this.AgentBilling = this.NO_DATA;
        this.acceptKeyReturns = this.NO_DATA;
        this.acceptCradleReturns = this.NO_DATA;
        this.acceptSentriCardReturns = this.NO_DATA;
        this.acceptLockboxReturns = this.NO_DATA;

    }





    //Get the current installs that have data
    getInstallSettings() {

        this.slGlobalService.saveCallStack("AdminComponent:getInstallSettings");

        if (this.tsaid) {
            this.commonService
                .searchInstallSettings(this.tsaid)
                .then(this.setInstallSettings.bind(this))
                .catch(this.displayError.bind(this));
        } else {
            //do not check settings
        }

    }

    // Set the complete flag from change lockboxes to receive
    setInstallSettings(installData): void {

        this.slGlobalService.saveCallStack("AdminComponent:setInstallSettings");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {

            if (setting.Name == "AgentBilling") {

                if (setting.Value == '1') {
                    this.AgentBilling = this.YES;
                } else {
                    this.AgentBilling = this.NO;
                }
            }
            if (setting.Name == "ConversionType") {
                this.InstallType = setting.Value;
            }
            if (setting.Name == "KeyAgreement") {
                if (setting.Value == '1') {
                    this.KeyAgreement = this.YES;
                } else {
                    this.KeyAgreement = this.NO;
                }
            }
            if (setting.Name == "CheckSupraLBOwnership") {
                if (setting.Value == '1') {
                    this.SupraLockbox = this.YES;
                } else {
                    this.SupraLockbox = this.NO;
                }
            }
            if (setting.Name == "LBReturnRatio") {
                this.LockboxExRatio = setting.Value + " to 1";
            }
            if (setting.Name == "SendRegistrationInviteStartDate") {
                this.SendRegInviteDate = setting.Value;
            }
            if (setting.Name == "AcceptLockboxReturns") {
                if (setting.Value == "1") {
                    this.acceptLockboxReturns = this.YES;
                } else {
                    this.acceptLockboxReturns = this.NO;
                }
                this.currentInstallLBReturn = setting.Value;
            }
            if (setting.Name == "AcceptCradleReturns") {
                if (setting.Value == "1") {
                    this.acceptCradleReturns = this.YES;
                } else {
                    this.acceptCradleReturns = this.NO;
                }
                this.currentInstallCradleReturn = setting.Value;
            }
            if (setting.Name == "AcceptSentriCardReturns") {
                if (setting.Value == "1") {
                    this.acceptSentriCardReturns = this.YES;
                } else {
                    this.acceptSentriCardReturns = this.NO;
                }
                this.currentInstallSentriCardReturn = setting.Value;
            }
            if (setting.Name == "AcceptKeyReturns") {
                if (setting.Value == "1") {
                    this.acceptKeyReturns = this.YES;
                } else {
                    this.acceptKeyReturns = this.NO;
                }
                this.currentInstallKeyReturn = setting.Value;
            }
            if (setting.Name == "PrintLabels") {
                if (setting.Value == "1") {
                    this.printLabels = this.YES;
                } else {
                    this.printLabels = this.NO;
                }
            }
        }

    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }

}