export class User {
    UserID: string;
    AgentID: string;
    FirstName: string;
    LastName: string;
    CardSN: string;
    Association: string;
    AssocID: string;
    ClassDate: string;
    ClassTime: string;
    LBR: number;
    
    /** LBXChange count in the AccountLockboxes table */
    BoxesOwed: number; 
    Notes: string;
    Company: string;
    CompanyName: string;
    UTCModified: string;
    Password: string;
    Username: string;
    type: string;
    ConfirmPassword: string;
    BoxesDisputed: string;
    EmailAddress: string;
    ProgrammingQueueID: string;
    StationUser: string;
    Proxy: string;
    LBLimitCount: number;

    /** The amount of lockboxes that this agent has already received from programming */
    ReceivedLBCount: number; 
}