import { Component,Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ProfileService } from './profile.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: [ './profile.component.css'],
    providers: [AuthService]
})
export class ProfileComponent implements OnInit {

    //isAuthorizedToRegister check if user is authorized to see programming form
    isAuthorizedToRegister: boolean = false;

    //As you type format phone number
    formattedMobile: string = "";

    // Controls whether to display reg form or success text
    registrationSuccessBool: boolean = false;

    // If programming is already complete display message and app store/play store image links
    registrationCompleteBool: boolean = false;

    showPasswordBool: boolean = true;
    passwordType: string = "password";

    showConfirmPasswordBool: boolean = true;
    confirmPasswordType: string = "password";

    hasCard: boolean = false;

    // the ID is our internal ID for the user registering
    user: string = "";
    checksum: string = "";

    // we need to know the logged in users permission
    userFirstName: string = "";
    userLastName: string = "";
    userEmail: string = "";
    userPermission: string = "";

    // Registration form data to post
    regUserID: string = "";
    email: string = "";
    mobile: string = "";
    password: string = "";
    pin: string = "";
    confirmPin: string = "";

    mobileHint: string = "";

    subscription: Subscription;
    formControl: FormControl;
    registrationForm: FormGroup;

    // this is to hold messages that need to get displayed to the user
    message: string = "";

    // this tracks whether there is an error that needs to be displayed
    hasError: boolean = false;

    asYouType: any;
    format: any;
    parse: any;

    // language constants from USER
    PRE_REG_ERROR: string = "";              // "Unable to complete preregistration."
    MOBILE_INCORRECT: string = "";           // "Mobile number is incorrect!"
    SK_ID_INCORRECT: string = "";            // "Email Address is incorrect!"
    INVALID_PASSWORD: string = "";           // "Invalid password please follow password rules!"
    INVALID_PIN: string = "";                // "Invalid pin please follow pin rules!"
    REG_ERROR: string = "";                  // "Error completing programming please try again in a moment."
    WELCOME: string = "";           // "Welcome to SentriKey"
    PAGE_FOR: string = "";           // "This is the programming page for"
    PLEASE_CLOSE: string = "";           // "If this is not you, please close this page and contact SentriKey Technical Support at 01684 219090."
    USER_REG: string = "";           // "User Registration"
    MOBILE_REQUIRED: string = "";           // "Mobile is required."
    SK_ID_REQUIRED: string = "";           // "Email Address is required."
    PASSWORD_RULES: string = "";           // "Password Rules"
    PASSWORD_NOTE: string = "";           // "Password must be at least 6 characters, containing 1 upper case letter, 1 lower case letter, and 1 number."
    PASSWORD_REQUIRED: string = "";           // "Password is required."
    PASSWORD_NO_NUMBER: string = "";           // "Password does not contain a number."
    PASSWORD_NO_UPPER: string = "";           // "Password does not contain a upper case character."
    PASSWORD_NO_LOWER: string = "";           // "Password does not contain a lower case character."
    PASSWORD_MIN_LENGTH: string = "";           // "Password does not contain at least 6 characters."
    PASSWORD_MATCH: string = "";           // "Passwords do not match."
    PIN_RULES: string = "";           // "Pin Rules"
    MUST_BE_NUMBER: string = "";           // "PIN must be a number between 1000 and 999999"
    BEGIN_ZERO: string = "";           // "PIN cannot begin with a zero"
    REPEATING_VALUES: string = "";           // "PIN cannot contain repeating values"
    SEQUENTIAL_VALUES: string = "";           // "PIN cannot contain sequential ascending or descending values"
    PIN_REQUIRED: string = "";           // "PIN is required."
    PIN_MIN_LENGTH: string = "";           // "PIN isn't long enough."
    PIN_MAX_LENGTH: string = "";           // "PIN cannot be more than six numbers."
    PIN_SEQUENTIAL: string = "";           // "PIN cannot be sequential."
    PIN_SPACES: string = "";           // "PIN cannot contain spaces."
    PIN_REPEATING: string = "";           // "PIN cannot contain repeating numbers."
    PIN_NUMERIC: string = "";           // "PIN must be numeric."
    PIN_ZERO: string = "";           // "PIN cannot start with zero."
    PIN_MATCH: string = "";           // "PINs do not match."
    REGISTER_ACCOUNT: string = "";           // "Register Account"
    NOT_AUTHORIZED: string = "";           // "You are not authorized to view this page. Please navigate to the login screen by clicking"
    HERE: string = "";                   // "here"
    MOBILE: string = "";                 // "Mobile"
    MOBILE_HINT: string = "(***) ***-****";                 // "Mobile"
    EMAIL_ADDRESS: string = "";          // "Email Address"
    PASSWORD: string = "";               // "Password"
    CONFIRM_PASSWORD: string = "";       // "Confirm Password"
    PIN: string = "";                    // "PIN"
    CONFIRM_PIN: string = "";            // "Confirm PIN"
    PIN_NOTE: string = "";  //"Your PIN must be 4 to 6 digits and can’t start with a zero. Also, the PIN can’t have repeating digits, nor can they have sequential ascending or decending values."

    constructor(public fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private slGlobalService: SentriLockGlobalService,
                private authService: AuthService,
                private validatorService: ValidatorService,
                private profileService: ProfileService) {

        this.slGlobalService.saveCallStack("ProfileComponent:constructor");

    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("ProfileComponent:ngOnInit");

        // get the translations
        this.authService.getTranslations('registration', "REGISTRATION_COMPONENT")
        //.then(properties => {this.rows = properties;});
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("ProfileComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("ProfileComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    loadTranslations() {

        this.slGlobalService.saveCallStack("ProfileComponent:loadTranslations");

        // the call was successful so we should be able to get the translation data
        var translationData = this.slGlobalService.getTranslations("REGISTRATION_COMPONENT");

        if (translationData) {

            this.PRE_REG_ERROR = translationData.preregerror;
            this.MOBILE_INCORRECT = translationData.mobileincorrect;
            this.SK_ID_INCORRECT = translationData.skidincorrect;
            this.INVALID_PASSWORD = translationData.invalidpassword;
            this.INVALID_PIN = translationData.invalidpin;
            this.REG_ERROR = translationData.regerror;
            this.WELCOME = translationData.welcome;
            this.PAGE_FOR = translationData.pagefor;
            this.PLEASE_CLOSE = translationData.pleaseclose;
            this.USER_REG = translationData.userreg;
            this.MOBILE_REQUIRED = translationData.mobilerequired;
            this.SK_ID_REQUIRED = translationData.skidrequired;
            this.PASSWORD_RULES = translationData.passwordrules;
            this.PASSWORD_NOTE = translationData.passwordnote;
            this.PASSWORD_REQUIRED = translationData.passwordrequired;
            this.PASSWORD_NO_NUMBER = translationData.passwordnonumber;
            this.PASSWORD_NO_UPPER = translationData.passwordnoupper;
            this.PASSWORD_NO_LOWER = translationData.passwordnolower;
            this.PASSWORD_MIN_LENGTH = translationData.passwordminlength;
            this.PASSWORD_MATCH = translationData.passwordmatch;
            this.PIN_RULES = translationData.pinrules;
            this.MUST_BE_NUMBER = translationData.mustbenumber;
            this.BEGIN_ZERO = translationData.beginzero;
            this.REPEATING_VALUES = translationData.repeatingvalues;
            this.SEQUENTIAL_VALUES = translationData.sequentialvalues;
            this.PIN_REQUIRED = translationData.pinrequired;
            this.PIN_MIN_LENGTH = translationData.pinminlength;
            this.PIN_MAX_LENGTH = translationData.pinmaxlength;
            this.PIN_SEQUENTIAL = translationData.pinsequential;
            this.PIN_SPACES = translationData.pinspaces;
            this.PIN_REPEATING = translationData.pinrepeating;
            this.PIN_NUMERIC = translationData.pinnumeric;
            this.PIN_ZERO = translationData.pinzero;
            this.PIN_MATCH = translationData.pinmatch;
            this.REGISTER_ACCOUNT = translationData.registeraccount;
            this.NOT_AUTHORIZED = translationData.notauthorized;
            this.HERE = translationData.here;
            this.MOBILE = translationData.mobile;
            this.EMAIL_ADDRESS = translationData.emailaddress;
            this.PASSWORD = translationData.password;
            this.CONFIRM_PASSWORD = translationData.confirmpassword;
            this.PIN = translationData.pin;
            this.CONFIRM_PIN = translationData.confirmpin;
            this.PIN_NOTE = translationData.pinnote;
        }
    }

    // this function gets called when the Logout button is clicked and logs the user out
    logout(): void {

        this.slGlobalService.saveCallStack("ProfileComponent:logout");

        // TODO: make a server call to let the server know the user has logged out
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("ProfileComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }

}