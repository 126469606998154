
import {interval as observableInterval,  Observable ,  Subscription ,  Subject } from 'rxjs';
import { Component,Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ProgrammingService } from './programming.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

import { SearchFields } from '../search-fields';

import { User } from '../user';
import { Install } from '../install';
import { Carton } from './carton';
import { Lockbox } from '../lockbox';
import { PrintingService } from '../printing/printing.service';

@Component({
    selector: 'programming',
    templateUrl: './programming.component.html',
    styleUrls: [ './programming.component.css'],
    providers: [AuthService,{ provide: 'Window', useValue: window }, PrintingService]
})
export class ProgrammingComponent implements OnInit, OnDestroy {

    private updateQueueSub: Subscription;

    username: string;
    password: string;

    authType: string = "";

    //Set the color to black
    messageColor: string = '#000';
    message: string = '';

    lbListColor: string = "";

    authMessage: string = "";

    programmingMessage: string = "";
    installMessage: string = "";

    modifyOrderNotes: string = "";
    removeNotes: number = 0;

    showAjaxLoader: boolean = false;

    showProgrammingStation: boolean = false;
    showInstallList: boolean = false;

    //Modify Order popup
    showAjaxLoaderAddLBR: boolean = false;
    showCompleteAddLBR: boolean = false;

    showAjaxLoaderRMLB: boolean = false;
    showCompleteRMLB: boolean = false;

    showAjaxLoaderSaveNotes: boolean = false;
    showCompleteSaveNotes: boolean = false;

    displayLBRInput: boolean = false;
    displayModifyOrder: boolean = false;
    displayUserNotRegisteredScreen: boolean = false;
    displayAuthScreen: boolean = false;
    displayClearListScreen: boolean = false;
    displayLBRemove: boolean = false;
    displayProgError: boolean = false;
    displayProgDelay: boolean = false;
    displayNoCheckInScreen: boolean = false;
    displayCreateOrder: boolean = false;
    displayReprint: boolean = false;

    displayUserSearch: boolean = false;
    displayNoUserFound: boolean = false;
    displayNoUserFoundNewOrder: boolean = false;

    disableCreateOrder: boolean = false;
    disablePrintButton: boolean = false;

    notValidAgent: boolean = false;
    notValidCard: boolean = false;
    queueNoUser: boolean = false;
    queueUser: boolean = false;
    noAcctNum: boolean = false;
    unknownError: boolean = false;

    displayNoInstallsFound: boolean = false;

    checkinSortPreference: boolean = true;

    displayQueue: boolean = true;
    stopQueue: boolean = false;

    scannedLBColor = '#AF2626';

    notesColor = '#505050';

    scannedLBCount = 0;
    lastScannedLB = 0;
    lastScannedCarton = 0;

    previousLBR = 0;

    assignedCount = 0;
    failedLockboxes = [];
    assignedLockboxes = [];

    lockboxes = [];

    users;
    usersNewOrder;

    dateSortedUsers;

    installs;

    currentInstallAssoc: string = "";
    currentInstallTSAID: string = "";
    currentPrintLabel: string = "1";

    selectedInstall: Install = new Install;

    selectedUser: User = new User;
    selectedUserNewOrder: User = new User;

    searchKey: string = 'programmingComponentSearch';

    //Disable buttons until they are able to be clicked
    disableFinishOrder: boolean = true;
    disableModifyOrder: boolean = true;
    disableAddLockbox: boolean = true;
    disableClearList: boolean = true;

    maxlength = 250;
    characterleft = this.maxlength;
    notesTextBox: string = "";

    // first name search box
    firstNameSearchValue: string = "";
    firstNameHasFocus: boolean = false;
    lastSearchedUserFN: string = "";

    // last name search box
    lastNameSearchValue: string = "";
    lastNameHasFocus: boolean = false;
    lastSearchedUserLN: string = "";

    // account number search box
    accountNumberSearchValue: string = "";
    accountNumberHasFocus: boolean = false;
    lastSearchedAccountNumber: string = "";

    // first name search box New Order
    firstNameSearchValueNewOrder: string = "";
    firstNameHasFocusNewOrder: boolean = false;
    lastSearchedUserFNNewOrder: string = "";

    // last name search box New Order
    lastNameSearchValueNewOrder: string = "";
    lastNameHasFocusNewOrder: boolean = false;
    lastSearchedUserLNNewOrder: string = "";

    // account number search box New Order
    accountNumberSearchValueNewOrder: string = "";
    accountNumberHasFocusNewOrder: boolean = false;
    lastSearchedAccountNumberNewOrder: string = "";

    isSysAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;

    queueSort: string = "";

    userSelected: boolean = true;

    //Disable search fields when user is selected
    disableFirstName: boolean = false;
    disableLastName: boolean = false;
    disableAccountNumber: boolean = false;

    disableFirstNameNewOrder: boolean = false;
    disableLastNameNewOrder: boolean = false;
    disableAccountNumberNewOrder: boolean = false;

    // Seconds to poll queue
    progqPollRate = 10;

    proxy: boolean = false;
    programmingErrorMessage: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private slGlobalService: SentriLockGlobalService,
                private commonService: CommonService,
                private authService: AuthService,
                private validatorService: ValidatorService,
                private programmingService: ProgrammingService,
                private printingService: PrintingService,
                @Inject('Window') private window: Window) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:constructor");
    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:ngOnInit");

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();

        if(this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        }

        if(this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }

        // Speed to poll the queue default is 10 seconds (10 * 1000ms)
        if(this.slGlobalService.getProgQPollRate() !="" || this.slGlobalService.getProgQPollRate() !='0') {
            this.progqPollRate = parseInt(this.slGlobalService.getProgQPollRate(),10) * 1000;
        } else {
            this.progqPollRate = this.progqPollRate * 1000;
        }

        this.queueSort = this.slGlobalService.getQueueSort();

        if(!this.queueSort) {
            this.slGlobalService.setQueueSort("date");
            this.queueSort = "date";
        }

        if(this.queueSort == 'lockbox') {
            this.checkinSortPreference = false;
        }

        if(this.showProgrammingStation) {
            //Set the cursor focus position to the account number text box
            setTimeout(()=>{document.getElementById("accountNumber").focus()}, 100);
        }

        if(this.slGlobalService.getCurrentInstallAssoc() || this.slGlobalService.getCurrentInstall()) {

            this.currentInstallTSAID = this.slGlobalService.getCurrentInstall();
            this.currentInstallAssoc = this.slGlobalService.getCurrentInstallAssoc();

            //Get the install settings
            this.getInstallSettings();

            this.showInstallList = false;

            this.searchUsers();
        } else {
            this.getCurrentInstalls();
        }

        //Reload the users every second
        this.updateQueueSub = observableInterval(this.progqPollRate).subscribe(x => {
            this.checkUserSearch();
        });

        this.slGlobalService.saveCallStack("ProgrammingComponent:ngOnInit");

        // get the translations
        this.authService.getTranslations('programming', "PROGRAMMING_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:ngOnDestroy");

        this.updateQueueSub.unsubscribe();

        if(this.selectedUser) {
            //Release the user back into the queue
            this.programmingService
                .releaseUser(this.selectedUser.CardSN)
                .then(this.setReleaseUser.bind(this))
                .catch(this.displayError.bind(this));
        }
    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    // language constants from COMMON
    FIRST_NAME: string = "";                   // "First Name"
    LAST_NAME: string = "";                    // "Last Name"
    ACCOUNT_NUMBER: string = "";             // "Account Number"
    ENTER_SCAN: string = "";             // "Enter or Scan LBSN"
    UNABLE_RETRIEVE: string = "";             // "Enter or Scan LBSN"
    AUTHORIZE: string = "";             // "Authorize"
    USER_SEARCH: string = "";             // "User Search"
    CREATE_ORDER_USER_SEARCH: string = "";             // "User Search"
    CREATE_ORDER: string = "";             // "User Search"
    NAME: string = "";             // "Name"
    ASSOCIATION: string = "";             // "Association"
    NO_USERS_FOUND: string = "";             // "No Users Found"
    NO_INSTALLS_FOUND: string = "";             // "No Installs Found"
    LOCKBOXES_TO_RECEIVE: string = "";             // "Number of Lockboxes to receive"
    MODIFY_ORDER: string = "";             // "Modfiy Order"
    LOCKBOXES_TO_REMOVE: string = "";             // "Lockboxes to remove"
    SCAN_LOCKBOXES: string = "";             // "Scan Lockboxes"
    NOTES: string = "";             // "Notes"
    SCANNED_LOCKBOXES: string = "";             // "Scanned Lockboxes"
    CLEAR_LIST: string = "";             // "Clear List"
    RECEIVE: string = "";             // "Receive"
    SCANNED: string = "";             // "Scanned"
    ADD_LOCKBOX: string = "";             // "Add Lockbox"
    FINISH_ORDER: string = "";             // "Finish Order"
    USERNAME: string = "";             // "Username"
    PASSWORD: string = "";             // "Password"
    REMOVE: string = "";               // "You must remove"
    DESCREASE: string = "";               // " lockboxes in order to decrease the Lockbox amount to receive."
    UNABLE_TRANSLATE: string = "";        // "Unable to get translation data"
    INVALID_USERPASS: string = "";        // "Unable to get translation data"
    SELECT_USER: string = "";        // "Unable to get translation data"
    NO_BOXES: string = "";
    NOT_ASSIGNED: string = "";
    COMPLETE: string = "";             // "Finish Order"
    STATUS: string = "";             // "Finish Order"
    CLEAR_LIST_MSG: string = "";             // "Finish Order"
    YES: string = "";             // "Finish Order"
    NO: string = "";             // "Finish Order"
    TSAID: string = "";             // "Finish Order"
    PLEASE_REMOVE: string = "";             // "Finish Order"
    BEFORE_REMOVE: string = "";             // "Finish Order"
    LOCKBOX: string = "";             // "Finish Order"
    LOCKBOXES: string = "";             // "Finish Order"
    PROG_ERROR: string = "";             // "Finish Order"
    PROG_DISPLAY: string = "";             // "Finish Order"
    COMPANY: string = "";
    PROG_EXIST: string = "";
    PROG_IN_PROGRESS: string = "";
    NO_ACCT_NUMBER: string = "";
    AGENT_STATUS: string = "";
    CARD_STATUS: string = "";
    RESEND_EMAIL: string = "";
    EMAIL: string = "";
    EMAIL_MESSAGING: string = "";
    REPRINT_MESSAGE: string = "";
    REPRINT_ORDER: string = "";

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:loadTranslations");

        // get the common translation data
        var programmingTranslationData = this.slGlobalService.getTranslations('PROGRAMMING_COMPONENT');

        if (programmingTranslationData) {

            this.FIRST_NAME = programmingTranslationData.firstname;
            this.LAST_NAME = programmingTranslationData.lastname;
            this.ACCOUNT_NUMBER = programmingTranslationData.accountnumber;
            this.ENTER_SCAN = programmingTranslationData.enterscan;
            this.UNABLE_RETRIEVE = programmingTranslationData.unableretrieve;
            this.AUTHORIZE = programmingTranslationData.authorize;
            this.USER_SEARCH = programmingTranslationData.usersearch;
            this.NAME = programmingTranslationData.name;
            this.ASSOCIATION = programmingTranslationData.association;
            this.NO_USERS_FOUND = programmingTranslationData.nousersfound;
            this.NO_INSTALLS_FOUND = programmingTranslationData.noinstallsfound;
            this.LOCKBOXES_TO_RECEIVE = programmingTranslationData.lockboxestoreceive;
            this.MODIFY_ORDER = programmingTranslationData.modifyorder;
            this.LOCKBOXES_TO_REMOVE = programmingTranslationData.lockboxestoremove;
            this.SCAN_LOCKBOXES = programmingTranslationData.scanlockboxes;
            this.NOTES = programmingTranslationData.notes;
            this.SCANNED_LOCKBOXES = programmingTranslationData.scannedlockboxes;
            this.CLEAR_LIST = programmingTranslationData.clearlist;
            this.RECEIVE = programmingTranslationData.toreceive;
            this.SCANNED = programmingTranslationData.scanned;
            this.ADD_LOCKBOX = programmingTranslationData.addlockbox;
            this.FINISH_ORDER = programmingTranslationData.finishorder;
            this.USERNAME = programmingTranslationData.username;
            this.PASSWORD = programmingTranslationData.password;
            this.REMOVE = programmingTranslationData.youmustremove;
            this.DESCREASE = programmingTranslationData.decreaselockboxes;
            this.UNABLE_TRANSLATE = programmingTranslationData.unabletranslation;
            this.INVALID_USERPASS = programmingTranslationData.invaliduserpass;
            this.NO_BOXES = programmingTranslationData.noboxesadded;
            this.SELECT_USER = programmingTranslationData.selectuser;
            this.NOT_ASSIGNED = programmingTranslationData.notassigned;
            this.COMPLETE = programmingTranslationData.complete;
            this.STATUS = programmingTranslationData.status;
            this.CLEAR_LIST_MSG = programmingTranslationData.clearlistmsg;
            this.YES = programmingTranslationData.yes;
            this.NO = programmingTranslationData.no;
            this.TSAID = programmingTranslationData.tsaid;
            this.PLEASE_REMOVE = programmingTranslationData.pleaseremove;
            this.BEFORE_REMOVE = programmingTranslationData.beforereceive;
            this.LOCKBOX = programmingTranslationData.lockbox;
            this.LOCKBOXES = programmingTranslationData.lockboxes;
            this.PROG_ERROR = programmingTranslationData.progerror;
            this.PROG_DISPLAY = programmingTranslationData.progdisplay;
            this.COMPANY = programmingTranslationData.company;
            this.CREATE_ORDER_USER_SEARCH = programmingTranslationData.createorderusersearch;
            this.CREATE_ORDER = programmingTranslationData.createorder;
            this.PROG_EXIST = programmingTranslationData.progexist;
            this.PROG_IN_PROGRESS = programmingTranslationData.proginprogress;
            this.NO_ACCT_NUMBER = programmingTranslationData.noaccountnumber;
            this.REPRINT_ORDER = programmingTranslationData.reprintorder;
            this.REPRINT_MESSAGE = programmingTranslationData.reprintordermessage;
        }

        var checkinTranslationData = this.slGlobalService.getTranslations('CHECKIN_COMPONENT');

        if (checkinTranslationData) {
            this.AGENT_STATUS = checkinTranslationData.agentstatus;
            this.CARD_STATUS = checkinTranslationData.cardstatus;
            this.RESEND_EMAIL = checkinTranslationData.resendemail;
            this.EMAIL = checkinTranslationData.email;
            this.EMAIL_MESSAGING = checkinTranslationData.emailmessage;
        }
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserFN(event): void {

        this.slGlobalService.saveCallStack("CheckinComponent:keyUpEnterUserFN");

        if (event.keyCode == 13) {
            this.lastSearchedUserFN = this.firstNameSearchValue;
            this.searchUsersNewOrder();
        }
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserLN(event): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:keyUpEnterUserLN");

        this.lastSearchedUserLN = this.lastNameSearchValue;
        this.searchUsersNewOrder();
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterAccountNumber(event): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:keyUpEnterAccountNumber");

        if (event.keyCode == 13) {
            this.lastSearchedAccountNumber = this.accountNumberSearchValue;
            this.searchUsersNewOrder();
        }

    }


    firstNameGetFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:firstNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the First Name field while it has focus
        this.firstNameHasFocus = true;
    }

    firstNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:firstNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the First Name field after we've
        // lost focus
        this.firstNameHasFocus = false;
    }


    lastNameGetFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:lastNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Last Name field while it has focus
        this.lastNameHasFocus = true;
    }

    lastNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:lastNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Last Name field after we've
        // lost focus
        this.lastNameHasFocus = false;
    }


    accountNumberGetFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:accountNumberGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Company field while it has focus
        this.accountNumberHasFocus = true;
    }

    accountNumberLoseFocus(): void {

        this.slGlobalService.saveCallStack("ProgramminComponent:accountNumberLostFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Company field after we've
        // lost focus
        this.accountNumberHasFocus = false;
    }

    getInstallSettings() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:getInstallSettings");

        this.commonService
            .searchInstallSettings(this.selectedInstall.trainingscheduleid)
            .then(this.setInstallSettings.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setInstallSettings(installData): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {

            if(setting.Name == "ConversionType") {
                this.slGlobalService.setCurrentInstallConversionType(setting.Value);
            }
            if(setting.Name == "LBReturnRatio") {
                this.slGlobalService.setCurrentInstallLBRatio(setting.Value);
            }
            if(setting.Name == "AcceptLockboxReturns") {
                this.slGlobalService.setCurrentInstallLBReturn(setting.Value);
            }
            if(setting.Name == "AcceptCradleReturns") {
                this.slGlobalService.setCurrentInstallCradleReturn(setting.Value);
            }
            if(setting.Name == "AcceptKeyReturns") {
                this.slGlobalService.setCurrentInstallKeyReturn(setting.Value);
            }
            if(setting.Name == "AcceptSentriCardReturns") {
                this.slGlobalService.setCurrentInstallCardReturn(setting.Value);
            }
            if(setting.Name == "PrintLabels") {
                this.slGlobalService.setPrintLabels(setting.Value);
                this.currentPrintLabel = setting.Value;
            }
        }
    }

    /*
     *Clear the search values
     */
    clearUserSearch() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:clearSearch");

        this.userSelected = true;

        this.displayProgDelay = false;

        this.firstNameSearchValue = "";
        this.lastNameSearchValue = "";
        this.accountNumberSearchValue = "";
    }

    searchQueue() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:searchQueue");

        this.stopQueue = true;

        this.searchUsers();

    }

    // runs the search when the search button is clicked
    searchUsers(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:searchUsers");

        this.lockboxes = [];
        this.assignedLockboxes = [];
        this.failedLockboxes = [];

        this.programmingMessage = "";

        this.selectedUser = new User;

        this.showAjaxLoader = true;

        if(this.stopQueue) {

            if(this.firstNameSearchValue !='') {
                this.filterByString(this.firstNameSearchValue.toLowerCase(), 'FirstName');
            }
            if(this.lastNameSearchValue !='') {
                this.filterByString(this.lastNameSearchValue.toLowerCase(), 'LastName');
            }
            if(this.accountNumberSearchValue !='') {

                //Trim space around string, remove spaces, remove dashes
                var replacedString = this.accountNumberSearchValue.trim().replace(/-|\s/g,'');

                this.filterByString(replacedString.toLowerCase(), 'CardSN');
            }

        } else {
            this.programmingService
                .searchUsers(this.firstNameSearchValue, this.lastNameSearchValue, this.accountNumberSearchValue)
                .then(this.setUserData.bind(this))
                .catch(this.displayError.bind(this));
        }
    }

    filterByString(string, from) {

        if(from == "FirstName") {
            this.users = this.users.filter(e => e.FirstName.toLowerCase().includes(string));
        }
        if(from == "LastName") {
            this.users = this.users.filter(e => e.LastName.toLowerCase().includes(string));
        }
        if(from == "CardSN") {
            this.users = this.users.filter(e => e.CardSN.toLowerCase().includes(string));
        }

        if (!this.users || (this.users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFound = true;
        }

        //If only one user select them
        if(this.users.length == 1) {
            this.selectUser(this.users[0]);
        }
    }

    checkUserSearch(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:checkUserSearch");

        if(this.displayQueue && !this.stopQueue && !this.displayNoInstallsFound) {
            this.searchUsers();
        }
    }

    // runs the search when the search button is clicked
    searchUsersNewOrder(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:searchUsers");

        this.lockboxes = [];
        this.assignedLockboxes = [];
        this.failedLockboxes = [];

        this.programmingMessage = "";

        this.selectedUser = new User;

        this.showAjaxLoader = true;

        this.programmingService
            .searchUsersNewOrder(this.firstNameSearchValueNewOrder, this.lastNameSearchValueNewOrder, this.accountNumberSearchValueNewOrder)
            .then(this.setUserNewOrderData.bind(this))
            .catch(this.displayError.bind(this));

    }

    //Select user from list for new order
    selectUserNewOrder(user) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectUser");

        this.userSelected = false;

        if(user.ProgrammingQueueID && !user.StationUser) {
            this.displayNoCheckInScreen = true;
            this.queueNoUser = true;
            this.userSelected = true;
        } else if(user.ProgrammingQueueID && user.StationUser) {
            this.displayNoCheckInScreen = true;
            this.queueUser = true;
            this.userSelected = true;
        } else {
            this.getUserStatus(user.CardSN);
        }

        this.selectedUserNewOrder = user;

        this.usersNewOrder = [];

        //hide the user search
        this.displayUserSearch = false;


    }

    getUserStatus(cardsn: string) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:getUserStatus");

        this.programmingService
            .getUserStatus(cardsn)
            .then(this.setUserStatus.bind(this))
            .catch(this.userStatusError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setUserStatus(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setUserStatus");

        if(jsonResponse.data.registered == false) {
            this.displayUserNotRegisteredScreen = true;
        } else if(jsonResponse.data.agentstatus != "Valid") {
            this.notValidAgent = true;
            this.displayNoCheckInScreen = true;
        } else if(jsonResponse.data.cardstatus != "Valid") {
            this.notValidCard = true;
            this.displayNoCheckInScreen = true;
        } else {
            this.displayCreateOrder = true;
        }
    }

    reprintOrder() {
        this.disablePrintButton = true;
        this.printOrder();
    }

    reprintFinish() {
        // Clear the search values
        this.clearUserSearch();

        // Clear the selected user
        this.selectedUser = new User;

        // Clear the lockbox list
        this.lockboxes = [];
        this.failedLockboxes = [];
        this.assignedLockboxes = []
        this.scannedLBCount = 0;

        // Disable clear list button
        this.disableClearList = false;

        //Enable search fields
        this.disableFirstName = !this.disableFirstName;
        this.disableLastName = !this.disableLastName;
        this.disableAccountNumber = !this.disableAccountNumber;

        //display the queue after finish
        this.displayReprint = false;
        this.stopQueue = false;
        this.displayQueue = true;

        this.searchUsers();

        // Timeout 1/2 second to allow print preview to go away
        setTimeout(()=>{document.getElementById('accountNumber').focus()}, 500);
    }

    // clears the user search
    clearSearch(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:clearSearch");

        this.programmingMessage = "";

        this.clearUserSearch();

        //Enable search fields
        if(!this.displayQueue) {
            this.disableFirstName = !this.disableFirstName;
            this.disableLastName = !this.disableLastName;
            this.disableAccountNumber = !this.disableAccountNumber;
        }

        //Show add lockbox and modify order ability once the user is selected
        this.disableAddLockbox = true;
        this.disableModifyOrder = true;

        //Release the user back into the queue
        this.programmingService
            .releaseUser(this.selectedUser.CardSN)
            .then(this.setReleaseUser.bind(this))
            .catch(this.displayError.bind(this));

        this.selectedUser = new User;
        this.scannedLBCount = 0;
        this.lockboxes = [];
        this.assignedLockboxes = [];
        this.failedLockboxes = [];

        //Display the queue and start it back up
        this.displayQueue = true;
        this.stopQueue = false;
        this.searchUsers();

        //Set the cursor focus position to the account number text box
        setTimeout(()=>{document.getElementById("accountNumber").focus()}, 100);
    }

    // clears the user search
    clearSearchNewOrder(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:clearSearch");

        this.notValidAgent = false;
        this.notValidCard = false;
        this.queueNoUser = false;
        this.queueUser = false;
        this.noAcctNum = false;
        this.unknownError = false;

        this.displayNoUserFoundNewOrder = false;
        this.disableCreateOrder = false;
        this.showCompleteAddLBR = false;

        this.authType = "";
        this.selectedUserNewOrder = new User;
        this.usersNewOrder = [];

        this.firstNameSearchValueNewOrder = "";
        this.lastNameSearchValueNewOrder = "";
        this.accountNumberSearchValueNewOrder = "";

        this.authMessage = "";
        this.modifyOrderNotes = "";

        //Set the cursor focus position to the account number text box
        setTimeout(()=>{document.getElementById("accountNumber").focus()}, 100);
    }

    setReleaseUser(users): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setReleaseUser");

        this.searchUsers();

    }

    // clears the user search
    selectSortPref(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectSortPref");

        //Sort the users array
        if(this.checkinSortPreference == true) {

            this.slGlobalService.setQueueSort("lockbox");
            this.queueSort = this.slGlobalService.getQueueSort();

            //Sort By Lockbox count
            this.users.sort(function (a, b) {
                return b.BoxesOwed - a.BoxesOwed;
            });

            this.checkinSortPreference = false;
        } else {

            this.slGlobalService.setQueueSort("date");
            this.queueSort = this.slGlobalService.getQueueSort();

            //Set users to the date sorted users
            this.users = this.dateSortedUsers;

            this.checkinSortPreference = true;
        }

    }

    setUserData(users): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setUserData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFound = true;
        }
        else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoUserFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFound = false;
        }

        //if(users.length == 1) {

        //    this.selectUser(userArray[0]);

        //} else {
            this.displayQueue = true;

            this.users = userArray;

            //make the check in time the modified time
            for (let user of this.users) {
                user.UTCModified = this.slGlobalService.getLocaleDateFromString(user.UTCModified);
            }

            if(this.queueSort == 'lockbox') {

                //Sort By Lockbox count
                this.users.sort(function (a, b) {
                    return b.BoxesOwed - a.BoxesOwed;
                });

                this.checkinSortPreference = false;
            }

            //Leave the user array date sorted for switching
            this.dateSortedUsers = this.users;

            this.selectedUser = new User;
        //}

    }

    setUserNewOrderData(users): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setUserData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFoundNewOrder = true;
        }
        else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoUserFoundNewOrder = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFoundNewOrder = false;
        }

        this.usersNewOrder = userArray;
        this.selectedUser = new User;

    }

    userStatusError(errorMessage: any): void {
        this.slGlobalService.saveCallStack("ProgrammingComponent:displayError");

        if(errorMessage == "Missing CardSN") {
            this.noAcctNum = true;
            this.displayNoCheckInScreen = true;
        } else {
            this.unknownError = true;
            this.displayNoCheckInScreen = true;
        }

        this.userSelected = true;
    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }

    charCount(note) {

        this.characterleft = this.maxlength - note.length;

        if(this.characterleft < 0) {
            this.notesColor = "#CB2C2C";
        } else {
            this.notesColor = "#505050";
        }

    }

    /**
     * Prints the programming order.
     * @returns 
     */
    printOrder() {

        this.printingService.printOrder(this.selectedUser, this.scannedLBCount)

        this.slGlobalService.saveCallStack("ProgrammingComponent:printOrder");

        //Send to create transfers
        this.disablePrintButton = false;
        return false;
    }

    authMod(type: string) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:authMod");

        if(type == "ModifyOrder") {
            this.programmingService
                .authCheck(this.username, this.password)
                .then(this.setAuthMod.bind(this))
                .catch(this.handleAuthError.bind(this));
        } else if(type == "CreateOrder") {
            this.programmingService
                .authCheck(this.username, this.password)
                .then(this.setCreateOrder.bind(this))
                .catch(this.handleAuthError.bind(this));
        }


    }

    setAuthMod(jsonResponse: any): void {

        this.username = "";
        this.password = "";

        if (jsonResponse.permissions[0].Permission == "SysAdmin") {
            this.displayAuthScreen = false;
            this.displayModifyOrder = true;
        }
        else {
            this.authMessage = this.INVALID_USERPASS;
        }

    }

    setCreateOrder(jsonResponse: any): void {

        this.username = "";
        this.password = "";

        if (jsonResponse.permissions[0].Permission == "SysAdmin") {
            this.displayAuthScreen = false;
            this.displayUserSearch = true;

            //Set the cursor focus position to the account number text box
            setTimeout(()=>{document.getElementById("accountNumberNewOrder").focus()}, 100);
        }
        else {
            this.authMessage = this.INVALID_USERPASS;
        }

    }

    /*
     * this functions sets the search fields using the passed in SearchFields object
     * */
    setSearchFields(searchFields: SearchFields) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setSearchFields");

        this.firstNameSearchValue = searchFields.userFirstName;
        this.lastNameSearchValue = searchFields.userLastName;
        this.accountNumberSearchValue = searchFields.accountNumber;
    }

    //Select user from list
    selectUser(user) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectUser");

        //Stop the queue and hide the queue
        this.stopQueue = true;
        this.displayQueue = false;

        //Kill the interface functionality until we get a server response
        this.displayProgDelay = true;

        this.selectedUser = user;

        if(this.selectedUser.Proxy == null) {
            this.selectedUser.Proxy = 'None';
        }

        this.userSelected = false;

        this.notesTextBox = this.selectedUser.Notes;

        //Show add lockbox ability once the user is selected
        this.disableAddLockbox = false;
        this.disableModifyOrder = false;

        //Disable search fields
        this.disableFirstName = true;
        this.disableLastName = true;
        this.disableAccountNumber = true;

        //Call the server to let it know that we selected a user
        this.programmingService
            .selectUser(this.selectedUser.CardSN)
            .then(this.setUserSelect.bind(this))
            .catch(this.displayError.bind(this));

    }

    setUserSelect(jsonResponse): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setUserSelect");

        if(jsonResponse == '204') {
            this.displayProgDelay = false;
            this.displayProgError = true;

            if(this.selectedUser) {
                //Release the user back into the queue
                this.programmingService
                    .releaseUser(this.selectedUser.CardSN)
                    .then(this.setReleaseUser.bind(this))
                    .catch(this.displayError.bind(this));
            }
        } else {
            //Display the interface
            this.displayProgDelay = false;

            //If no LBR make programming station put in LBR
            if((this.selectedUser.BoxesOwed == 0 || !this.selectedUser.BoxesOwed) && this.selectedUser) {
                this.displayLBRInput = true;

                //Set the cursor focus position to the lockbox text box
                setTimeout(()=>{document.getElementById("lbrInput").focus()}, 100);
            } else {
                //Set the cursor focus position to the lockbox text box
                setTimeout(()=>{document.getElementById("lbInput").focus()}, 100);
            }
        }

    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectUser");

        this.slGlobalService.setCurrentInstall(install.trainingscheduleid);
        this.slGlobalService.setCurrentInstallAssoc(install.assocname + ' - ' + install.title);

        this.currentInstallAssoc = install.assocname + ' - ' + install.title;
        this.currentInstallTSAID = install.trainingscheduleid;

        this.getInstallSettings();

        this.showInstallList = false;

        this.searchUsers();
        this.displayQueue = true;

    }

    getCurrentInstalls() {

        this.programmingService
            .searchInstalls()
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = installsArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if(installs.length == 1) {
            this.selectedInstall = installsArray[0];

            this.slGlobalService.setCurrentInstall(this.selectedInstall.trainingscheduleid);
            this.slGlobalService.setCurrentInstallAssoc(this.selectedInstall.assocname + ' - ' + this.selectedInstall.title);

            this.currentInstallAssoc = this.selectedInstall.assocname + ' - ' + this.selectedInstall.title;
            this.currentInstallTSAID = this.selectedInstall.trainingscheduleid;

            this.getInstallSettings();

            this.showInstallList = false;

            this.searchUsers();
            this.displayQueue = true;
        } else {
            this.showInstallList = true;

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    // Add "Lockboxes to Receive (LBR)" to selected user
    addLBR(lbr) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:addLBR");

        lbr = lbr.trim();
        if (!lbr) { return; }

        this.showCompleteAddLBR = false;
        this.showAjaxLoaderAddLBR = true;

        var removeCount = this.scannedLBCount - lbr;

        // If previousLBR is 0, lets set the current user's LBR
        if(this.previousLBR = 0) {
            this.previousLBR = this.selectedUser.BoxesOwed;
        }

        if(this.scannedLBCount <= lbr) {
            // Dont lest user save values less than 1
            if(lbr > 0) {

                //Set LBR, close dialog
                this.displayLBRInput = false;
                this.previousLBR = 0;

                this.programmingService
                    .saveLBR(lbr,"", this.selectedUser.CardSN)
                    .then(this.setSaveLBRComplete.bind(this))
                    .catch(this.handleError.bind(this));

                //Set the cursor focus position to the lockbox text box
                setTimeout(()=>{document.getElementById("lbInput").focus()}, 100);
            }
        } else {

            alert("You must remove " + removeCount + " lockboxes in order to decrease the Lockbox amount to receive.");

            setTimeout(()=>{document.getElementById("lbRemoveInput").focus()}, 100);
        }
    }

    // Set the complete flag from change lockboxes to receive
    setSaveLBRComplete(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setSaveLBRComplete");

        //Show the updated count
        this.selectedUser.BoxesOwed = jsonResponse.data.boxcount;

        this.showAjaxLoaderAddLBR = false;
        setTimeout(() => this.showCompleteAddLBR = true, 100);
    }

    resendEmail() {

        this.slGlobalService.saveCallStack("CheckinComponent:resendEmail");

        this.programmingService
            .resendEmail(this.selectedUserNewOrder.CardSN)
            .then(this.setResendEmail.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setResendEmail(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setResendEmail");

        this.clearSearchNewOrder();
        this.displayUserNotRegisteredScreen = false;

        this.userSelected = true;
    }

    // Modal dialogs
    // Close the clear list screen
    closeRegEmailScreen() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeRegEmailScreen");

        this.displayUserNotRegisteredScreen = false;

        this.clearSearchNewOrder();

        this.userSelected = true;
    }

    //Close the user search dialog
    closeInstallSearch() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeInstallSearch");

        this.programmingMessage = "";

        //Don't allow user to close the install search and stay on this page
        this.router.navigate(['/profile']);
    }

    //Close the "Lockboxes to receive" dialog and clear the selected user
    closeLBR() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeLBR");

        this.programmingMessage = "";

        this.username = "";
        this.password = "";

        this.displayLBRInput = false;

        this.selectedUser = new User;

        this.searchUsers();
        this.displayQueue = true;
    }

    //Close the auth screen
    closeAuth() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeAuth");

        this.programmingMessage = "";

        this.displayAuthScreen = false;

        this.username = "";
        this.password = "";

        this.authType = "";
        this.authMessage = "";
    }

    //Close the auth screen
    closeProgError() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeProgError");

        this.displayProgError = false;
        this.displayProgDelay = false;

        this.searchUsers();

        //Release the user back into the queue
        this.programmingService
            .releaseUser(this.selectedUser.CardSN)
            .then(this.setReleaseUser.bind(this))
            .catch(this.displayError.bind(this));

        // Clear the search values
        this.clearUserSearch();

        // Clear the selected user
        this.selectedUser = new User;

        // Clear the lockbox list
        this.lockboxes = [];
        this.failedLockboxes = [];
        this.assignedLockboxes = []
        this.scannedLBCount = 0;

        // Disable clear list button
        this.disableClearList = false;

        //Enable search fields
        this.disableFirstName = !this.disableFirstName;
        this.disableLastName = !this.disableLastName;
        this.disableAccountNumber = !this.disableAccountNumber;

        //display the queue after finish
        this.stopQueue = false;
        this.displayQueue = true;

        // Timeout 1/2 second to allow print preview to go away
        setTimeout(()=>{document.getElementById('accountNumber').focus()}, 500);

    }

    //Close the clear list screen
    closeClearListScreen() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeClearListScreen");

        this.displayClearListScreen = false;
    }

    //Close the user search dialog
    closeSearch() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeSearch");

        this.displayUserSearch = false;

        this.notValidAgent = false;
        this.notValidCard = false;
        this.queueNoUser = false;
        this.queueUser = false;
        this.noAcctNum = false;

        this.authType = "";
        this.selectedUserNewOrder = new User;
        this.usersNewOrder = [];

        this.firstNameSearchValueNewOrder = "";
        this.lastNameSearchValueNewOrder = "";
        this.accountNumberSearchValueNewOrder = "";
    }

    //Close the "Modify Order" dialog and clear the selected user
    closeModifyOrder() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeModifyOrder");

        this.programmingMessage = "";
        this.modifyOrderNotes = "";

        if(this.scannedLBCount > this.selectedUser.BoxesOwed) {

            this.selectedUser.BoxesOwed = this.previousLBR;
            this.previousLBR = 0;

        } else {
            this.displayModifyOrder = false;
        }

    }

    //Close the "Modify Order" dialog and clear the selected user
    closeCreateOrder() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeCreateOrder");

        this.displayCreateOrder = false;

        this.clearSearchNewOrder();

        this.userSelected = true;

    }

    // Modal dialogs
    // Close the clear list screen
    closeCheckIn() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:closeCheckIn");

        this.displayNoCheckInScreen = false;
        this.notValidAgent = false;
        this.notValidCard = false;
        this.queueNoUser = false;
        this.queueUser = false;
        this.noAcctNum = false;

        this.authType = "";
        this.selectedUserNewOrder = new User;
        this.usersNewOrder = [];

        this.firstNameSearchValueNewOrder = "";
        this.lastNameSearchValueNewOrder = "";
        this.accountNumberSearchValueNewOrder = "";

        this.authMessage = "";

        this.clearSearchNewOrder();

        this.userSelected = true;
    }

    //ModifyOrder
    saveModifiedOrder(lbr, notes) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:saveModifiedOrder");

        lbr = lbr.trim();
        if (!lbr) { return; }

        this.showCompleteAddLBR = false;

        // If previousLBR is 0, lets set the current user's LB
        if(this.previousLBR = 0) {
            this.previousLBR = this.selectedUser.BoxesOwed;
        }

        if(this.scannedLBCount <= lbr) {
            // Dont lest user save values less than 1
            //Set LBR, close dialog
            this.displayLBRInput = false;
            this.previousLBR = 0;
            this.showAjaxLoaderAddLBR = true;

            this.programmingService
                .saveLBR(lbr, notes, this.selectedUser.CardSN)
                .then(this.setModifiedOrderComplete.bind(this))
                .catch(this.handleError.bind(this));

            //Set the cursor focus position to the lockbox text box
            setTimeout(()=>{document.getElementById("lbInput").focus()}, 100);
        } else if(this.scannedLBCount > lbr) {
            var removeNum = this.scannedLBCount - lbr;

            if(removeNum < 2) {
                this.modifyOrderNotes = this.PLEASE_REMOVE + " " + removeNum + " " + this.LOCKBOX + " " + this.BEFORE_REMOVE;
            } else {
                this.modifyOrderNotes = this.PLEASE_REMOVE + " " + removeNum + " " + this.LOCKBOXES + " " + this.BEFORE_REMOVE;
            }

        }

    }

    // Set the complete flag from change lockboxes to receive
    setModifiedOrderComplete(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setModifiedOrderComplete");

        this.modifyOrderNotes = "";

        if(jsonResponse.data.boxcount == 0) {
            this.displayModifyOrder = false;
            this.clearSearch();
        } else {
            //Show the updated count
            this.selectedUser.BoxesOwed = jsonResponse.data.boxcount;
            this.selectedUser.Notes = jsonResponse.data.notes;

            //If the modified order boxes owed matches the scanned count we need to enable the finish order button
            if(this.selectedUser.BoxesOwed == this.scannedLBCount) {
                this.disableFinishOrder = false;
            } else {
                this.disableFinishOrder = true;
            }

            this.showAjaxLoaderAddLBR = false;

            setTimeout(() => this.showCompleteAddLBR = true, 100);

            //Hide the modify order popup window
            setTimeout(() => this.displayModifyOrder = false, 600);
        }
    }

    //ModifyOrder
    saveCreatedOrder(lbr, notes) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:saveModifiedOrder");
        
        this.disableCreateOrder = true;

        lbr = lbr.trim();
        if (!lbr) { return; }

        this.showCompleteAddLBR = false;

        if(lbr > 0) {

            this.selectedUserNewOrder.BoxesOwed = lbr;

            this.programmingService
                .createOrder(lbr, notes, this.selectedUserNewOrder.CardSN)
                .then(this.setCreatedOrderComplete.bind(this))
                .catch(this.handleError.bind(this));
        } else {
            this.disableCreateOrder = false;
            this.modifyOrderNotes = "Lockbox order must be greater than zero";
        }
    }

    // Set the complete flag from change lockboxes to receive
    setCreatedOrderComplete(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setModifiedOrderComplete");

        this.showCompleteAddLBR = true;

        //Set the cursor focus position to the account number text box
        setTimeout(()=>{this.displayCreateOrder = false; this.selectUser(this.selectedUserNewOrder); this.clearSearchNewOrder();}, 2000);

    }

    //Add "Lockboxes to Receive (LBR)" to selected user
    removeLB(lb) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:removeLB");

        this.programmingMessage = "";

        this.showCompleteRMLB = false;
        this.showAjaxLoaderRMLB = true;

        lb = lb.trim();
        if (!lb) { return; }

        this.delete(lb);
    }

    //Add lockboxes to the list
    add(lbsn) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:add");

        this.programmingMessage = "";

        lbsn = lbsn.trim();
        if (!lbsn) { return; }

        var carton;

        //if the scanned LBSN has a '-' or 'SLC' then it is a carton.
        if (lbsn.indexOf('-') > -1 || lbsn.indexOf('SLC') > -1) {
            carton = lbsn;
            //Remove everything that isn't 'SLC', a digit , '.' or '-'
            carton = carton.replace(/[^SLC\d.-]/g, '');
        } else {
            lbsn = lbsn.replace(/^[0\.]+/, '');
            lbsn = lbsn.replace(/[^0-9.]/g,'');
        }

        this.lastScannedLB = lbsn;

        if(this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined && this.selectedUser.CardSN != undefined) {

            if(this.scannedLBCount != this.selectedUser.BoxesOwed) {

                if(lbsn.length >= 7) {
                    console.log("getting carton data")
                    //Get the carton data
                    if(carton) {

                        if(carton != '-') {
                            this.getCartonData(carton);
                            this.lastScannedCarton = carton;
                        }

                    } else {
                        if(this.lastScannedLB != 0 && lbsn > 0 && lbsn.indexOf(".") ==  -1 && lbsn.length < 13) {

                            if(this.lockboxes.length == 0) {

                                if (this.lockboxes.indexOf(this.lastScannedLB) > -1
                                    || this.failedLockboxes.indexOf(this.lastScannedLB) > -1
                                    || this.assignedLockboxes.indexOf(this.lastScannedLB) > -1) {
                                    //DO Nothing
                                    //alert("Lockbox already in the list.");
                                } else {
                                    this.lockboxes.push(lbsn);

                                    this.disableClearList = false;

                                    //Update Scanned Lockboxes count
                                    this.scannedLBCount = this.lockboxes.length + this.assignedLockboxes.length;
                                }
                            } else {
                                var nope = "";

                                for (const lb of this.lockboxes) {

                                    if (this.lockboxes.indexOf(this.lastScannedLB) > -1
                                        || this.failedLockboxes.indexOf(this.lastScannedLB) > -1
                                        || this.assignedLockboxes.indexOf(this.lastScannedLB) > -1
                                        || lb.includes(this.lastScannedLB)) {
                                        //DO Nothing
                                        nope = "yes";
                                    }

                                }

                                if(nope !="yes") {
                                    this.lockboxes.push(lbsn);

                                    this.disableClearList = false;

                                    //Update Scanned Lockboxes count
                                    this.scannedLBCount = this.lockboxes.length + this.assignedLockboxes.length;
                                }
                            }


                        }
                    }
                } else {

                }

            } else {
                this.programmingMessage = this.NO_BOXES;
            }

            if(this.scannedLBCount == this.selectedUser.BoxesOwed) {
                this.scannedLBColor = "#368640";

                //Enable the finish order button once the scanned number matches the receive number
                this.disableFinishOrder = false;
            } else {
                this.scannedLBColor = "#AF2626";

                //Enable the finish order button once the scanned number matches the receive number
                this.disableFinishOrder = true;
            }
            this.lastScannedLB = 0;

        } else {
            //No user selected
            this.programmingMessage = this.SELECT_USER;

            this.lastScannedLB = 0;

            //Set the cursor focus position to the lockbox text box
            setTimeout(()=>{document.getElementById("accountNumber").focus()}, 100);
        }


    }

    //Get the carton data
    getCartonData(cartonid) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:getCartonData");

        this.programmingService
            .searchCartonData(cartonid)
            .then(this.setCartonData.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the carton data for the carton id that was passed
    setCartonData(cartonData): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:setCartonData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var cartonList = cartonData;

        //If the carton scan puts the count higher than the owed don't allow it
        if(this.selectedUser.BoxesOwed < (this.scannedLBCount + cartonList.length)) {
            this.programmingMessage = this.NO_BOXES;
        } else {
            for (const lb of cartonList) {

                if (this.lockboxes.indexOf(lb) > -1 || this.failedLockboxes.indexOf(lb) > -1 || this.assignedLockboxes.indexOf(lb) > -1 ) {
                    //DO Nothing
                    //alert("Lockbox already in the list.");
                } else {
                    if (this.lockboxes.indexOf(this.lastScannedCarton + ' ' + lb) > -1
                        || this.failedLockboxes.indexOf(this.lastScannedCarton + ' ' + lb) > -1
                        || this.assignedLockboxes.indexOf(this.lastScannedCarton + ' ' + lb) > -1) {
                        //Do Nothing the box is already in the list
                    } else {
                        this.lockboxes.push(this.lastScannedCarton + ' ' + lb);

                        this.disableClearList = false;

                        //Update Scanned Lockboxes count
                        this.scannedLBCount = this.lockboxes.length + this.assignedLockboxes.length;
                    }
                }
            }
        }

        if(this.scannedLBCount == this.selectedUser.BoxesOwed) {
            this.scannedLBColor = "#368640";

            //Enable the finish order button once the scanned number matches the receive number
            this.disableFinishOrder = false;
        } else {
            this.scannedLBColor = "#AF2626";

            //Enable the finish order button once the scanned number matches the receive number
            this.disableFinishOrder = true;
        }
        this.lastScannedLB = 0;

    }

    //removeFailedLBs lockboxes from the list
    removeFailedLBs(lbsn) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:removeFailedLBs");

        const index: number = this.lockboxes.indexOf(lbsn);
        if (index !== -1) {
            //alert("Lockbox " + lbsn +" has been removed");
            this.lockboxes.splice(index, 1);
        }
    }

    //Delete lockboxes from the list
    delete(lbsn) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:delete");

        if(/\s/.test(lbsn)) {

            lbsn = lbsn.split(/\s(.+)/)[0];

            const filtered = this.lockboxes.filter((carton) => {
                return !carton.includes(lbsn) && !carton.includes(lbsn);
            });

            this.lockboxes = filtered;

            this.showAjaxLoaderRMLB = false;
            this.showCompleteRMLB = true;

            //Update Scanned Lockboxes count
            this.scannedLBCount = this.lockboxes.length;

        } else {

            const index: number = this.lockboxes.indexOf(lbsn);
            if (index !== -1) {
                //alert("Lockbox " + lbsn +" has been removed");
                this.lockboxes.splice(index, 1);

                this.showAjaxLoaderRMLB = false;
                this.showCompleteRMLB = true;

                //Update Scanned Lockboxes count
                this.scannedLBCount = this.lockboxes.length;
            }

        }

        if(this.scannedLBCount == this.selectedUser.BoxesOwed) {
            this.scannedLBColor = "#368640";
        } else {
            this.scannedLBColor = "#AF2626";
        }

        if(this.scannedLBCount == 0) {
            this.disableClearList = true;
        }

        //Disable the finish order button
        if(this.lockboxes.length != this.selectedUser.BoxesOwed) {
            this.disableFinishOrder = true;
        }
    }

    //Show the dialog that allows you to clear the lockbox list
    showClearListScreen() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:showClearListScreen");

        this.programmingMessage = "";

        this.displayClearListScreen = true;
    }

    //Clear entire lockbox list
    clear() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:clear");

        this.displayClearListScreen = false;

        this.lockboxes = [];

        this.disableClearList = true;

        this.disableFinishOrder = true;

        //Update Scanned Lockboxes count
        this.scannedLBCount = 0;

        //Reset the scanned lb color
        this.scannedLBColor = "#AF2626";

    }

    modifyOrder() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:modifyOrder");

        this.programmingMessage = "";

        this.showCompleteAddLBR = false;
        this.showAjaxLoaderAddLBR = false;
        this.authMessage = "";

        this.username = "";
        this.password = "";

        if(this.selectedUser.FirstName == undefined && this.selectedUser.LastName == undefined && this.selectedUser.CardSN == undefined) {
            //this.programmingMessage = "You must select a user before you can modify the order.";
        } else {
            this.authType = "ModifyOrder";
            this.authCheck();
        }

    }

    createOrder() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:createOrder");

        this.authType = "CreateOrder";
        this.authCheck();

    }

    authCheck() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:authCheck");

        //Display the auth screen
        this.displayAuthScreen = true;

        //Set the cursor focus position to the lockbox text box
        setTimeout(()=>{document.getElementById("authUser").focus()}, 100);

    }


    createTransfers() {

        this.slGlobalService.saveCallStack("ProgrammingComponent:createTransfers");

        this.disableFinishOrder = true;

        this.programmingMessage = "";

        if(this.lockboxes === undefined || this.lockboxes.length == 0) {
            //this.programmingMessage = "Lockbox list is empty";
        } else {

            if(this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined && this.selectedUser.CardSN != undefined) {

                // Finish Order create transfers to the agent
                this.programmingService
                    .finishOrder(this.selectedUser.CardSN, this.lockboxes)
                    .then(this.processFinishOrder.bind(this))
                    .catch(this.programmingOrderError.bind(this));

            } 

        }

    }

    /**
     * Sets and displays the programming order dialog.
     * @param errorMessage 
     */
    programmingOrderError(errorMessage: string): void {
        this.slGlobalService.saveCallStack("SearchComponent:lbOwnerCheckError");
        this.programmingErrorMessage =  errorMessage;
        this.displayProgError = true;
    }

    /**
     * Process the jsonResponse from finishing the order.
     * @param jsonResponse 
     */
    processFinishOrder(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:processFinishOrder");

        // Remove failed boxes, place them in failed boxes array
        if (jsonResponse.failedlbsns) {

            this.programmingMessage = this.NOT_ASSIGNED + jsonResponse.failedlbsns;

            for (let failedLb of jsonResponse.failedlbsns) {
                this.failedLockboxes.push(failedLb);
                this.removeFailedLBs(failedLb);

                this.scannedLBCount = this.scannedLBCount - 1;
                this.scannedLBColor = "#AF2626";
            }
        }

        // Remove assigned boxes, place them in completed boxes array
        if (this.lockboxes) {

            for (let lb in this.lockboxes) {

                this.assignedLockboxes.push(this.lockboxes[lb]);
            }

            this.lockboxes = [];

            //Disable the clear list button
            this.disableClearList = true;

        }

        // If the number of assigned boxes matches the number the user should receive
        if (this.assignedLockboxes.length == this.selectedUser.BoxesOwed) {

            //Are we printing labels for this install, 1 = yes
            if (this.currentPrintLabel == "1") {

                // Print the labels
                this.printOrder();

                //Check if label printed correctly
                this.displayReprint = true;

            } else {
                // Clear the search values
                this.clearUserSearch();

                // Clear the selected user
                this.selectedUser = new User;

                // Clear the lockbox list
                this.lockboxes = [];
                this.failedLockboxes = [];
                this.assignedLockboxes = []
                this.scannedLBCount = 0;

                // Disable clear list button
                this.disableClearList = false;

                //Enable search fields
                this.disableFirstName = !this.disableFirstName;
                this.disableLastName = !this.disableLastName;
                this.disableAccountNumber = !this.disableAccountNumber;

                //display the queue after finish
                this.stopQueue = false;
                this.displayQueue = true;

                this.searchUsers();

                // Timeout 1/2 second to allow print preview to go away
                setTimeout(() => { document.getElementById('accountNumber').focus() }, 500);
            }

        }
    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = this.UNABLE_TRANSLATE;
        }
    }

    handleAuthError(): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:handleAuthError");

        this.authMessage = this.INVALID_USERPASS;
    }

}