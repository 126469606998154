import { Component,Input, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {Observable,  Subject ,  Subscription } from 'rxjs';

import { SearchService } from './search.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { AuthService } from '../auth.service';

import { SearchFields } from '../search-fields';

import { User } from '../user';
import { Install } from "../install";
import { UserStatus } from "../userstatus";

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: [ './search.component.css'],
    providers: [AuthService,{ provide: 'Window', useValue: window }]
})
export class SearchComponent implements OnInit {

    // this is to hold messages that need to get displayed to the user
    message: string = "";
    installMessage: string = "";

    //Set the color to black
    messageColor: string = '#000';

    errorMessage: string = "";

    selectedUser: User = new User;

    displayUserSearch: boolean = false;
    displayUserNotRegisteredScreen: boolean = false;
    displayNoCheckInScreen: boolean = false;

    displayNoUserFound: boolean = false;

    firstName: string = "";
    lastName: string = "";

    lastScannedLB: string = "";

    showAjaxLoader: boolean = false;

    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;
    isSysAdmin: boolean = false;

    notValidAgent: boolean = false;
    notValidCard: boolean = false;

    currentInstallAssoc: string = "";
    currentInstallTSAID: string = "";
    showInstallList: boolean = false;
    displayNoInstallsFound: boolean = false;
    displayNoDataFound: boolean = false;
    displayLBNoDataFound: boolean = false;

    users;

    userStatus;
    lbsAssigned;
    programmingOrders;

    name: string = "";

    installs;

    selectedInstall: Install = new Install;

    // first name search box
    firstNameSearchValue: string = "";
    firstNameHasFocus: boolean = false;
    lastSearchedUserFN: string = "";

    // last name search box
    lastNameSearchValue: string = "";
    lastNameHasFocus: boolean = false;
    lastSearchedUserLN: string = "";

    userSelected: boolean = true;

    // account number search box
    accountNumberSearchValue: string = "";
    accountNumberHasFocus: boolean = false;
    lastSearchedAccountNumber: string = "";

    //Disable search fields when user is selected
    disableFirstName: boolean = false;
    disableLastName: boolean = false;
    disableAccountNumber: boolean = false;

    /**Controls the popup box for Check Ownership */
    displayCheckOwnershipPopup: boolean = false;
    /** Message to be displayed in the check ownership popup */
    checkOwnershipPopupMessage: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private slGlobalService: SentriLockGlobalService,
                private commonService: CommonService,
                private authService: AuthService,
                private searchService: SearchService,
                @Inject('Window') private window: Window) {

        this.slGlobalService.saveCallStack("SearchComponent:constructor");
    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("SearchComponent:ngOnInit");

        // get the translations
        this.authService.getTranslations('search', "SEARCH_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();
        this.isSysAdmin = this.slGlobalService.isSysAdmin();

        if(this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }
        if(this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        }

        if(this.slGlobalService.getCurrentInstallAssoc() || this.slGlobalService.getCurrentInstall()) {

            this.currentInstallTSAID = this.slGlobalService.getCurrentInstall();
            this.currentInstallAssoc = this.slGlobalService.getCurrentInstallAssoc();

            //Get the install settings
            this.getInstallSettings();

            this.showInstallList = false;

            setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
        } else {
            this.getCurrentInstalls();
        }
    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("SearchComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("SearchComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    // language constants from COMMON
    FIRST_NAME: string = "";                   // "First Name"
    LAST_NAME: string = "";                    // "Last Name"
    ACCOUNT_NUMBER: string = "";             // "Account Number"
    ENTER_SCAN: string = "";             // "Enter or Scan LBSN"
    UNABLE_RETRIEVE: string = "";             // "Enter or Scan LBSN"
    CHECK_OWNERSHIP: string = "";             // "Check Ownership"
    SEARCH_AGENT: string = "";             // "Check Ownership"
    ENTER_AGENT: string = "";             // "Check Ownership"
    NO_USERS_FOUND: string = "";             // "No Users Found"
    NO_INSTALLS_FOUND: string = "";             // "No Users Found"
    STATUS: string = "";             // "No Users Found"
    COMPLETE: string = "";             // "No Users Found"
    ORDER_STATUS: string = "";             // "No Users Found"
    CHECKIN_TIME: string = "";             // "No Users Found"
    TSAID: string = "";             // "No Users Found"
    ASSOCIATION: string = "";             // "No Users Found"
    AGENT_STATUS: string = "";             // "Finish Order"
    CARD_STATUS: string = "";             // "Finish Order"
    EMAIL_MESSAGING: string = "";             // "Finish Order"
    USER_SEARCH: string = "";             // "Finish Order"
    NAME: string = "";             // "Finish Order"
    COMPANY: string = "";             // "Finish Order"
    NO_ORDER_DATA: string = "";             // "Finish Order"

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("SearchComponent:loadTranslations");

        // get the common translation data
        var searchTranslationData = this.slGlobalService.getTranslations('SEARCH_COMPONENT');
        var checkinTranslationData = this.slGlobalService.getTranslations('CHECKIN_COMPONENT');

        if (searchTranslationData) {

            this.FIRST_NAME = searchTranslationData.firstname;
            this.LAST_NAME = searchTranslationData.lastname;
            this.ACCOUNT_NUMBER = searchTranslationData.accountnumber;
            this.ENTER_SCAN = searchTranslationData.enterscan;
            this.UNABLE_RETRIEVE = searchTranslationData.unableretrieve;
            this.CHECK_OWNERSHIP = searchTranslationData.checkownership;
            this.SEARCH_AGENT = searchTranslationData.searchagent;
            this.ENTER_AGENT = searchTranslationData.entagentname;
            this.NO_USERS_FOUND = searchTranslationData.nousersfound;
            this.NO_INSTALLS_FOUND = searchTranslationData.nousersfound;
            this.STATUS = searchTranslationData.status;
            this.CHECKIN_TIME = searchTranslationData.checkintime;
            this.ORDER_STATUS = searchTranslationData.orderstatus;
            this.NO_ORDER_DATA = searchTranslationData.noorderdata;

            this.COMPLETE = checkinTranslationData.complete;
            this.TSAID = checkinTranslationData.tsaid;
            this.ASSOCIATION = checkinTranslationData.association;
            this.AGENT_STATUS = checkinTranslationData.agentstatus;
            this.CARD_STATUS = checkinTranslationData.cardstatus;
            this.EMAIL_MESSAGING = checkinTranslationData.emailmessage;
            this.COMPANY = checkinTranslationData.company;
            this.USER_SEARCH = checkinTranslationData.usersearch;

        }
    }

    firstNameGetFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:firstNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the First Name field while it has focus
        this.firstNameHasFocus = true;
    }

    firstNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:firstNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the First Name field after we've
        // lost focus
        this.firstNameHasFocus = false;
    }


    lastNameGetFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:lastNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Last Name field while it has focus
        this.lastNameHasFocus = true;
    }

    lastNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:lastNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Last Name field after we've
        // lost focus
        this.lastNameHasFocus = false;
    }


    accountNumberGetFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:accountNumberGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Company field while it has focus
        this.accountNumberHasFocus = true;
    }

    accountNumberLoseFocus(): void {

        this.slGlobalService.saveCallStack("SearchComponent:accountNumberLostFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Company field after we've
        // lost focus
        this.accountNumberHasFocus = false;
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserFN(event): void {

        this.slGlobalService.saveCallStack("SearchComponent:keyUpEnterUserFN");

        if (event.keyCode == 13) {
            this.lastSearchedUserFN = this.firstNameSearchValue;
            this.searchUsers();
        }
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserLN(event): void {

        this.slGlobalService.saveCallStack("SearchComponent:keyUpEnterUserLN");

        this.lastSearchedUserLN = this.lastNameSearchValue;
        this.searchUsers();
    }

    getInstallSettings() {

        this.slGlobalService.saveCallStack("SearchComponent:getInstallSettings");

        this.commonService
            .searchInstallSettings(this.selectedInstall.trainingscheduleid)
            .then(this.setInstallSettings.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setInstallSettings(installData): void {

        this.slGlobalService.saveCallStack("SearchComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {

            if(setting.Name == "ConversionType") {
                this.slGlobalService.setCurrentInstallConversionType(setting.Value);
            }
            if(setting.Name == "LBReturnRatio") {
                this.slGlobalService.setCurrentInstallLBRatio(setting.Value);
            }
            if(setting.Name == "AcceptLockboxReturns") {
                this.slGlobalService.setCurrentInstallLBReturn(setting.Value);
            }
            if(setting.Name == "AcceptCradleReturns") {
                this.slGlobalService.setCurrentInstallCradleReturn(setting.Value);
            }
            if(setting.Name == "AcceptKeyReturns") {
                this.slGlobalService.setCurrentInstallKeyReturn(setting.Value);
            }
            if(setting.Name == "PrintLabels") {
                this.slGlobalService.setPrintLabels(setting.Value);
            }
        }
    }

    activityReport() {

        this.slGlobalService.saveCallStack("SearchComponent:activityReport");

        this.searchService
            .activityReport(this.selectedInstall.trainingscheduleid, this.selectedUser.AgentID, this.selectedUser.CardSN)
            .then(this.setActivityReport.bind(this))
            .catch(this.displayError.bind(this));

    }

    setActivityReport(userStatus): void {

        this.slGlobalService.saveCallStack("SearchComponent:setActivityReport");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userStatusArray = userStatus.data;

        //make the check in time the modified time
        for (let row of userStatus.data) {
            row.CheckInTime = this.slGlobalService.getLocaleDateFromString(row.CheckInTime);
        }

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userStatusArray.length;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!userStatus.data || (userStatus.data.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoDataFound = true;
        }
        else if (userStatus.data && (userStatus.data.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoDataFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoDataFound = false;
        }

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!userStatus.lbassigned || (userStatus.lbassigned.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayLBNoDataFound = true;
        }
        else if (userStatus.lbassigned && (userStatus.lbassigned.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayLBNoDataFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayLBNoDataFound = false;
        }

        this.userStatus = userStatusArray;
        this.lbsAssigned = userStatus.lbassigned;
        this.programmingOrders = userStatus.programming;
    }

    getCurrentInstalls() {

        this.slGlobalService.saveCallStack("SearchComponent:getCurrentInstalls");

        this.commonService
            .searchInstalls("1")
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("SearchComponent:setCurrentInstallList");

        this.installMessage = "";

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = installsArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if(installs.length == 1) {
            this.selectedInstall = installsArray[0];

            this.slGlobalService.setCurrentInstall(this.selectedInstall.trainingscheduleid);
            this.slGlobalService.setCurrentInstallAssoc(this.selectedInstall.assocname + ' - ' + this.selectedInstall.title);

            this.currentInstallAssoc = this.selectedInstall.assocname + ' - ' + this.selectedInstall.title;
            this.currentInstallTSAID = this.selectedInstall.trainingscheduleid;

            this.getInstallSettings();

            this.showInstallList = false;

            setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
            
        } else {
            this.showInstallList = true;

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("SearchComponent:selectInstall");

        this.slGlobalService.setCurrentInstall(install.trainingscheduleid);
        this.slGlobalService.setCurrentInstallAssoc(install.assocname + ' - ' + install.title);

        this.currentInstallAssoc = install.assocname + ' - ' + install.title;
        this.currentInstallTSAID = install.trainingscheduleid;

        this.getInstallSettings();

        this.showInstallList = false;

        setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterAccountNumber(event): void {

        this.slGlobalService.saveCallStack("SearchComponent:keyUpEnterAccountNumber");

        if (event.keyCode == 13) {
            this.lastSearchedAccountNumber = this.accountNumberSearchValue;
            this.searchUsers();
        }

    }

    /**Searches by LBSN for the lockbox owner. */
    checkOwner(lbsn): void {

        this.slGlobalService.saveCallStack("SearchComponent:checkOwner");

        this.lastScannedLB = lbsn;

        this.searchService
            .lbOwnerCheck(lbsn)
            .then(this.lbOwnerCheck.bind(this))
            .catch(this.lbOwnerCheckError.bind(this));

    }

    /**
     * Displays lbowner check popup and ownership message.
     * @param jsonResponse 
     */
    lbOwnerCheck(jsonResponse: any): void {
        
        this.slGlobalService.saveCallStack("SearchComponent:lbOwnerCheck");

        if(jsonResponse.status == "Unowned") {
            this.checkOwnershipPopupMessage = "Lockbox: " + this.lastScannedLB + " is unowned and ready to be assigned.";
        } else {
            this.checkOwnershipPopupMessage = "Lockbox: " + this.lastScannedLB + " is owned by " + jsonResponse.firstname + " " + jsonResponse.lastname + " (" + jsonResponse.cardsn + ")";
        }

        this.displayCheckOwnershipPopup = true;
    }

    /**
     * Displays error message in the lbowner Check Ownership popup
     * @param errorMessage 
     */
    lbOwnerCheckError(errorMessage: string): void {
        this.slGlobalService.saveCallStack("SearchComponent:lbOwnerCheckError");
        this.checkOwnershipPopupMessage =  errorMessage;
        this.displayCheckOwnershipPopup = true;
    }

    /*
     *Clear the search values
     */
    clearUserSearch() {

        this.slGlobalService.saveCallStack("SearchComponent:clearSearch");

        this.firstNameSearchValue = "";
        this.lastNameSearchValue = "";
        this.accountNumberSearchValue = "";

        this.userStatus = [];
        this.displayNoDataFound = false;
    }

    clearMessages(): void {

        this.slGlobalService.saveCallStack("SearchComponent:clearMessages");

        this.errorMessage = "";
    }

    // Modal dialogs
    // Close the clear list screen
    closeRegEmailScreen() {

        this.slGlobalService.saveCallStack("SearchComponent:closeRegEmailScreen");

        this.displayUserNotRegisteredScreen = false;

        this.clearSearch();
    }

    // Modal dialogs
    // Close the clear list screen
    closeCheckIn() {

        this.slGlobalService.saveCallStack("SearchComponent:closeCheckIn");

        this.displayNoCheckInScreen = false;
        this.notValidAgent = false;
        this.notValidCard = false;

        this.clearSearch();
    }

    //Close the user search dialog
    closeSearch() {

        this.slGlobalService.saveCallStack("SearchComponent:closeSearch");

        this.displayUserSearch = false;
    }

        //Close the user search dialog
    closeCheckOwnershipPopup() {

        this.slGlobalService.saveCallStack("SearchComponent:closeCheckOwnershipPopup");

        this.displayCheckOwnershipPopup = false;
    }

    // clears the user search
    clearSearch(): void {

        this.slGlobalService.saveCallStack("SearchComponent:clearSearch");

        this.clearMessages();

        this.userSelected = true;

        this.clearUserSearch();

        this.disableFirstName = !this.disableFirstName;
        this.disableLastName = !this.disableLastName;
        this.disableAccountNumber = !this.disableAccountNumber;


        this.selectedUser = new User;

        //Set the cursor focus position to the account number text box
        setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
    }

    // runs the search when the search button is clicked
    searchUsers(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:searchUsers");

        this.clearMessages();

        this.selectedUser = new User;

        this.showAjaxLoader = true;

        //Trim space around string, remove spaces, remove dashes
        var replacedString = this.accountNumberSearchValue.trim().replace(/-|\s/g,'');

        this.searchService
            .searchUsers(this.firstNameSearchValue, this.lastNameSearchValue, replacedString)
            .then(this.setUserData.bind(this))
            .catch(this.displayError.bind(this));


    }

    setUserData(users): void {

        this.slGlobalService.saveCallStack("SearchComponent:setUserTableData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userArray.length;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFound = true;
        }
        else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoUserFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFound = false;
        }

        this.displayUserSearch = true;

        this.users = userArray;

    }

    //Select user from list
    selectUser(user) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectUser");

        this.userSelected = false;

        this.getUserStatus(user.CardSN);

        this.selectedUser = user;

        //Disable search fields
        this.disableFirstName = true;
        this.disableLastName = true;
        this.disableAccountNumber = true;

        //hide the user search
        this.displayUserSearch = false;
    }

    getUserStatus(cardsn: string) {

        this.searchService
            .getUserStatus(cardsn)
            .then(this.setUserStatus.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setUserStatus(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("SearchComponent:setUserStatus");

        this.activityReport();

        if(jsonResponse.data.registered == false) {
            this.displayUserNotRegisteredScreen = true;
        } else if(jsonResponse.data.agentstatus != "Valid") {
            this.notValidAgent = true;
            this.displayNoCheckInScreen = true;
        } else if(jsonResponse.data.cardstatus != "Valid") {
            this.notValidCard = true;
            this.displayNoCheckInScreen = true;
        } else {
            //this.activityReport();
        }
    }

    //Close the user search dialog
    closeInstallSearch() {

        //Don't allow user to close the install search and stay on this page
        this.router.navigate(['/profile']);
    }

    resendEmail() {

        this.searchService
            .resendEmail(this.selectedUser.CardSN)
            .then(this.setResendEmail.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setResendEmail(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("SearchComponent:setUserStatus");

        this.clearSearch();
        this.displayUserNotRegisteredScreen = false;

    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:displayError");
    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("ProgrammingComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }


}