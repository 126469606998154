import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { CommonModule }   from '@angular/common';
import { HttpModule } from '@angular/http';

import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
//import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// Login components
import { LoginRoutingModule }   from './login-routing.module';
import { LoginComponent }       from './login.component';
import { LoginService }  from './login.service';

// we need to change the URL serializer so we can handle URL's that have been encoded
import { UrlSerializer, DefaultUrlSerializer } from '@angular/router';
import { CustomUrlSerializer }     from './custom-url-serializer';

import { AppRoutingModule }     from './app-routing.module';

// Imports for loading & configuring the in-memory web api
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService }  from './in-memory-data.service';

// main app component
import { AppComponent } from './app.component';
import { AuthGuard } from './auth-guard.service';
import { AppService } from './app.service';
import { SentriLockErrorHandler } from './sl-error.handler';
import { SLMainComponent } from './sl-main.component';

// global HTTP service override
import { SentriLockHttpService }  from './sl-http.service';
import { SentriLockGlobalService }  from './sl-global.service';
import { DefaultTranslationService }  from './default-translation.service';
import { CommonService }  from './common.service';

import { ProgrammingModule } from './programming/programming.module';
import { ProgrammingService } from './programming/programming.service';

import { AdminModule } from './admin/admin.module';
import { AdminService } from './admin/admin.service';

import { AssociationModule } from './association/association.module';
import { AssociationService } from './association/association.service';

import { ProfileModule } from './profile/profile.module';
import { ProfileService } from './profile/profile.service';

import { CheckinModule } from './checkin/checkin.module';
import { CheckinService } from './checkin/checkin.service';

import { SearchModule } from './search/search.module';
import { SearchService } from './search/search.service';

// Custom Error Screens
import { ErrorModule } from './error/error.module';
import { ErrorService } from './error/error.service';

//Validation Service
import { ValidatorService }  from './validator.service';

//Typeahead Service
import { TypeaheadService }  from './typeahead.service';

import { ReactiveFormsModule } from '@angular/forms';

import './rxjs-extensions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * NgModule that includes all Material modules that are required to serve
 * the Plunker.
 */
@NgModule({
    exports: [
        MatProgressBarModule
    ]
})
export class MaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SLMainComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginRoutingModule,
    MaterialModule,
    FormsModule,
    HttpModule,
      HttpClientModule,
    TypeaheadModule.forRoot(),
      InMemoryWebApiModule.forRoot(InMemoryDataService, {dataEncapsulation: true, passThruUnknownUrl: true }),
      ReactiveFormsModule,
      ProgrammingModule,
      ProfileModule,
      CheckinModule,
      SearchModule,
      AdminModule,
      AssociationModule,
      ErrorModule,
      AppRoutingModule,
      MatFormFieldModule,
      MatInputModule                                      // the AppRoutingModule needs to be last to support wildcard routes
  ],
  providers: [ValidatorService,
    AppService,
    AuthGuard,
    LoginService,
    SentriLockHttpService,
    SentriLockGlobalService,
    DefaultTranslationService,
    CommonService,
    DefaultUrlSerializer,
    ProgrammingService,
    ProfileService,
    CheckinService,
    SearchService,
    AdminService,
    AssociationService,
    ErrorService,
    TypeaheadService,
    {provide: UrlSerializer, useClass: CustomUrlSerializer},    // the UrlSerializer and ErrorHandler need to be last
    {provide: ErrorHandler, useClass: SentriLockErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
