import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from './admin.service';
import { Subscription } from 'rxjs';
import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';
//Form Validation
import { FormBuilder } from '@angular/forms';
import { Install } from '../install';
import { AngularCsv } from 'angular-csv-ext';

@Component({
    selector: 'admin-report',
    templateUrl: './admin-reports.component.html',
    styleUrls: ['./admin-reports.component.css'],
    providers: [AuthService]
})
export class AdminReportComponent implements OnInit {

    // the tsa id for the install
    @Input() tsaid: string;
    @Input() tsaname: string;


    // this is to hold messages that need to get displayed to the user
    message: string = "";
    //Set the color to black
    messageColor: string = '#000';
    installMessage: string = "";
    displayNoInstallsFound: boolean = false;
    showAjaxLoader: boolean = false;
    UNABLE_RETRIEVE: string = "";             // "Unable to retrieve data"


    constructor(public fb: FormBuilder,
        private slGlobalService: SentriLockGlobalService,
        private authService: AuthService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminComponent:constructor");

    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminComponent:ngOnInit");

        // get the translations

        var adminTranslationData = this.slGlobalService.getTranslations("ADMIN_COMPONENT");

        if (adminTranslationData) {
            this.UNABLE_RETRIEVE = adminTranslationData.unabletoretrieve;
        }
    }


    /**
     * Fetches report data for Activity Report.
     */
    activityReport() {

        this.slGlobalService.saveCallStack("AdminComponent:activityReport");

        var d = new Date(); // or whatever date you have
        var tzName = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();

        var headers = ["Username", "Last Name", "First Name", "AgentID", "CompanyID",
            "Office Number", "Office Name", "CheckInTime", "CheckInID", "ItemID", "ReturnType",
            "Quarantined", "QuarantineCleared", "Proxy"];

        var fileName = "Activity Report " + this.tsaid;

        this.adminService
            .activityReport(this.tsaid, tzName)
            .then(this.setReportData.bind(this, fileName, headers))
            .catch(this.displayError.bind(this));
    }

    /**
     * Fetches report data for Summary Report.
     */
    summaryReport() {
        this.slGlobalService.saveCallStack("AdminComponent:summaryReport");

        var headers = ["Sign Ins", "Key Returns", "Cradle Returns", "Lockbox Returns", "Lockboxes Denied"];
        var fileName = "Summary Report " + this.tsaid;

        this.adminService
            .summaryReport(this.tsaid)
            .then(this.setReportData.bind(this, fileName, headers))
            .catch(this.displayError.bind(this));
    }

    /**
     * Fetches report data for lbQuarantineReport
     */
    lbQuarantineReport() {
        this.slGlobalService.saveCallStack("AdminComponent:lbQuarantineReport");

        var headers = ["Name", "LBSN", "Status", "Created"];
        var fileName = "Lockbox Quarantine Report " + this.tsaid;

        this.adminService
            .lbQuarantineReport(this.tsaid)
            .then(this.setReportData.bind(this, fileName, headers))
            .catch(this.displayError.bind(this));
    }

    /**
     * Fetches report data for FullProgrammingListReport
     */
    fullProgrammingListReport() {
        this.slGlobalService.saveCallStack("AdminComponent:fullProgrammingListReport");

        var headers = ["AgentID", "First Name", "Last Name", "Lockbox Count", "CardSN", "External ID"];
        var fileName = "Full Programming List Report " + this.tsaid;
        this.adminService
            .fullProgrammingListReport(this.tsaid)
            .then(this.setReportData.bind(this, fileName, headers))
            .catch(this.displayError.bind(this));
    }

    /**
     * Fetches report data for FullLimitListReport
     */
    fullLimitListReport() {
        this.slGlobalService.saveCallStack("AdminComponent:fullLimitListReport");

        var headers = ["AgentID", "First Name", "Last Name", "Lockbox Limit Count", "CardSN", "External ID"];
        var fileName = "Full Limit List Report " + this.tsaid;
        this.adminService
            .fullLimitListReport(this.tsaid)
            .then(this.setReportData.bind(this, fileName, headers))
            .catch(this.displayError.bind(this));
    }

    /**
     * Displays user erroe message
     * @param errorMessage 
     */
    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }

    /**
     * Handles erroe message
     * 
     * @param errorMessage 
     */
    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }

    /**
     * Creates and sets the report data for the file download.
     * @param fileName 
     * @param headers 
     * @param jsonResponse 
     */
    private setReportData(fileName, headers, jsonResponse: any): void {
        this.slGlobalService.saveCallStack("AdminComponent:setFullProgrammingListReport");

        for (let row of jsonResponse.data) {
            row.UTCCreated = this.slGlobalService.getLocaleDateFromString(row.UTCCreated);
        }

        var d = new Date();
        var day = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();

        var today = month + "/" + day + "/" + year;

        let title = this.tsaid + "\r\n\n" +
            "Date: " + today + "\r\n\n";


        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useBom: true,
            noDownload: false,
            headers: headers
        };

        new AngularCsv(jsonResponse.data, fileName, options);
    }
}