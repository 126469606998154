<div class="full-page">
    <div class="mobile-screen">
        <div class="header">
            <h1>{{LOGIN_BUTTON}}</h1>
        </div>

        <div class="logo">
            <div class="logo-box">{{KEWE}}</div>
        </div>

        <form id="login-form">
            <p class="login-error">{{message}}</p>
            <input name="usernameBox" placeholder="{{EMAIL_ADDRESS}}" [(ngModel)]="username" autocomplete="username" autofocus />
            <input type="{{passwordType}}" placeholder="{{PASSWORD}}" id="passwordBox" name="passwordBox" [(ngModel)]="password" autocomplete="current-password" />
            <button class="login-btn" (click)="login()">{{LOGIN_BUTTON}}</button>
        </form>

    </div>
</div>