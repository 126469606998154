import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';




import { SentriLockHttpService } from './sl-http.service';
import { SentriLockGlobalService } from './sl-global.service';

import { Lockbox } from './lockbox';

import { User } from './user';
import { Install } from './install';
import { InstallData } from './installdata';

@Injectable()
export class CommonService
{

  private headers = new Headers({'Content-Type': 'application/json'});

  constructor(public slHttpService: SentriLockHttpService,
              public slGlobalService: SentriLockGlobalService) {

	  this.slGlobalService.saveCallStack("CommonService:constructor");
  }

    searchInstalls(dataComplete: string): Promise<any> {

        this.slGlobalService.saveCallStack("CommonService:searchUsers");

        // Send completed install as "1" so we we only get installs back that have data
        var paramObject = {
            completedonly: dataComplete
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("CommonService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

  searchInstallSettings(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("CommonService:searchInstalls");

		var paramObject = {
			tsaid: tsaid
		};

		return this.slHttpService
			.getInMemPostServer(`kewe/admin/installsettings`, paramObject)
			.toPromise()
			.then(this.processInstallSettings.bind(this, paramObject))
			.catch(this.handleError.bind(this));

    }

    private processInstallSettings(origParamObject, response): InstallData[] {

        this.slGlobalService.saveCallStack("CommonService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CommonService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as InstallData[];
    }

	private handleError(error: any): Promise<any> {

		this.slGlobalService.saveCallStack("CommonService:handleError");

		var genericErrorMsg = "";

		var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

		if (translationData) {
			genericErrorMsg = translationData.genericerror;
		}

		var errorMessage;

		try {
			if (error && error.message) {
				errorMessage = error.message;
			}
			else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
				errorMessage = error.json().ResponseText;
			}
			else if (error && error.statusText) {
				errorMessage = error.statusText;
			}
			else {
				errorMessage = genericErrorMsg;
			}

			// make sure the error message isn't a JSON parse error message, if it use the generic error message
			if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
				errorMessage = genericErrorMsg;
			}
		}
		catch (err) {
			errorMessage = genericErrorMsg;
		}

		return Promise.reject(errorMessage);
	}


}
