import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AdminRoutingModule } from './admin-routing.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AdminComponent } from './admin.component';
import { AdminService } from './admin.service';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { GaugeModule } from 'angular-gauge';

// components for Admin screens
import { AdminUserComponent } from './admin-user.component';
import { AdminProgComponent } from './admin-prog.component';
import { AdminPrintComponent } from './admin-print.component';
import { AdminSettingsComponent } from './admin-settings.component';
import { AdminDataComponent } from './admin-data.component';
import { AdminReportComponent } from './admin-reports.component';
import { AdminDashboardComponent } from './admin-dashboard.component';

//import './rxjs-extensions';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        //InMemoryWebApiModule.forRoot(InMemoryDataService),
        AdminRoutingModule,
        BsDropdownModule,
        MatProgressBarModule,
        GaugeModule.forRoot()
    ],
    declarations: [
        AdminComponent,
        AdminUserComponent,
        AdminProgComponent,
        AdminPrintComponent,
        AdminSettingsComponent,
        AdminDataComponent,
        AdminReportComponent,
        AdminDashboardComponent
    ]
})
export class AdminModule { }
