import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ProfileRoutingModule } from './profile-routing.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';

//import './rxjs-extensions';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        //InMemoryWebApiModule.forRoot(InMemoryDataService),
        ProfileRoutingModule,
        BsDropdownModule
    ],
    declarations: [
        ProfileComponent
    ]
})
export class ProfileModule { }
