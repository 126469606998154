import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';

import { Observable } from 'rxjs';






import { Router, ActivatedRoute, Params } from '@angular/router';

import { SentriLockHttpService } from '../sl-http.service';
import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';
import { TypeaheadService } from '../typeahead.service';

import { Lockbox } from '../lockbox';

import { User } from '../user';
import { Install } from '../install';
import { InstallData } from '../installdata';

@Injectable()
export class CheckinService  extends TypeaheadService
{

    message: string;

    email: string;
    mobile: string;
    username: string;
    password: string;

    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private route: ActivatedRoute,
                private router: Router,
                public slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        super(slHttpService, slGlobalService);

        this.slGlobalService.saveCallStack("CheckinService:constructor");
    }

    //Check if value of a variable is undefined
    public isUndefined(value) {return typeof value === 'undefined';}


    public addLockbox(lbsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:addLockbox");
        // we're making a real server call
        var searchField = '';
        var searchValue = '';

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            searchfield: searchField,
            searchvalue: searchValue,
            searchcomparator: 'EQ',
            orderby: 'LBSN',
            ordervector: 'ASC',
            limit: 1/*,
                 fieldlist: "FirstName,LastName,IDN,PropertyStatus"*/
        };


        paramObject["lbsn"] = lbsn;

        return this.slHttpService
            .getInMemPostServer(`kewe/lb/getlb`, paramObject)
            .toPromise()
            .then(this.processLB.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    searchUsers(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchUsers");


        // we're making a real server call
        var searchField = '';
        var searchValue = '';

        if (firstName && (firstName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "FirstName";
            searchValue = searchValue + "%" + firstName + "%";
        }

        if (lastName && (lastName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "LastName";
            searchValue = searchValue + "%" + lastName + "%";
        }

        if (accountNumber && (accountNumber != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "CardSN";
            searchValue = searchValue + "%" + accountNumber + "%";
        }

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            searchfield: searchField,
            searchvalue: searchValue,
            searchcomparator: 'LI',
            orderby: 'LastName',
            ordervector: 'ASC',
            limit: 100/*,
                 fieldlist: "FirstName,LastName,IDN,PatientStatus"*/
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/getagents`, paramObject)
            .toPromise()
            .then(this.processUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processUsers(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("CheckinService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") &&  response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    searchInstallSettings(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchInstalls");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installsettings`, paramObject)
            .toPromise()
            .then(this.processInstallSettings.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processInstallSettings(origParamObject, response): InstallData[] {

        this.slGlobalService.saveCallStack("CheckinService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as InstallData[];
    }

    /**
     * Gets the agent status
     * @param cardsn 
     * @returns 
     */
    getAgentStatus(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:getAgentStatus");
        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/agentstatus`, paramObject)
            .toPromise()
            .then(this.processAgentStatus.bind(this, paramObject))
            .catch(this.userStatusError.bind(this));
    }

    /**
     * Process the response from the server, sends any exceptions to the server to be logged. 
     * @param origParamObject 
     * @param response 
     * @returns 
     */
    private processAgentStatus(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processAgentStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    resendEmail(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchInstalls");

        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/resendregistrationemail`, paramObject)
            .toPromise()
            .then(this.processResendEmail.bind(this, paramObject))
            .catch(this.handleError.bind(this));


    }

    private processResendEmail(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processUserStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    private processLB(origParamObject, response): void {

        this.slGlobalService.saveCallStack("CheckinService:processUsers");

        var lbResponse;

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") &&  response.json() && response.json().data) {
                lbResponse = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processLB");
            // rethrow the exception
            throw exception;
        }

        //If the box is not found in the station data for the install
        // create object for it with no confirmed owner
        if(lbResponse.length == 0) {

            if(confirm("Lockbox " + origParamObject.lbsn + " was not found in lockbox ownership data, do you still want to accept this lockbox?")) {
                lbResponse.push({
                    lbsn: origParamObject.lbsn,
                    owner: "Not found",
                    association: "Not found"
                });
            } else {
                //Do nothing
            }

        }

        return lbResponse;
    }

    searchInstalls(): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchInstalls");

        // Send completed install as "1" so we we only get installs back that have data
        var paramObject = {
            completedonly: 1
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("CheckinService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

    /**
     * API call to finish the check in.
     * @param cardsn 
     * @param suprakey 
     * @param cradle 
     * @param lbsn 
     * @param quarantine 
     * @param sentricard 
     * @param checkinproxy 
     * @param agentLimitLBCount 
     * @param profileOnly 
     * @returns 
     */
    finishCheckin(cardsn: string, suprakey: string, cradle: string, lbsn: any[], quarantine, sentricard: string, checkinproxy: string, agentLimitLBCount, profileonly ): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:finishCheckin");
        var paramObject = {
            cardsn: cardsn,
            suprakey: suprakey,
            cradle: cradle,
            lbsnlist: lbsn,
            quarantine: quarantine,
            sentricard: sentricard,
            checkinproxy: checkinproxy,
            profileonly: profileonly
        };

        if (agentLimitLBCount !== null) {
            paramObject[agentLimitLBCount] = agentLimitLBCount;
        }

        return this.slHttpService
            .getInMemPostServer(`kewe/account/returns`, paramObject)
            .toPromise()
            .then(this.processFinishCheckinResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processFinishCheckinResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processFinishCheckinResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processFinishCheckinResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    lockboxOwnerCheck(cardsn: string, lbsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:lockboxOwnerCheck");

        // we're making a real server call
        // TODO: for now we are just using a single search parameter, once the server supports multiple we need to use all of them
        var paramObject = {
            cardsn: cardsn,
            lbsn: lbsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/lockbox/lbownercheck`, paramObject)
            .toPromise()
            .then(this.processLockboxOwnerCheck.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processLockboxOwnerCheck(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processLockboxOwnerCheck");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processFinishOrderResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

    private userStatusError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            } else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }

            //No valid card send to assoc
            if(error.json().ResponseText.includes('Invalid card sn')) {
                errorMessage = "Invalid card sn";
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

}