import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { ErrorRoutingModule } from './error-routing.module';


// components for Error screens
import { ErrorComponent } from './error.component';

//import './rxjs-extensions';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        //InMemoryWebApiModule.forRoot(InMemoryDataService),
        ErrorRoutingModule
    ],
    declarations: [
        ErrorComponent
    ]
})
export class ErrorModule { }
