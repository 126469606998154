import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';




import { Error } from './error';

import { SentriLockHttpService } from '../sl-http.service';
import { SentriLockGlobalService } from '../sl-global.service';
import { TypeaheadService } from '../typeahead.service';

@Injectable()
export class ErrorService extends TypeaheadService
{

    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(public slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        super(slHttpService, slGlobalService);
    }



}