<div class="current-install" *ngIf="currentInstallAssoc">{{currentInstallAssoc}} ({{currentInstallTSAID}})</div>

<div class="error-message" [style.color]="messageColor">{{errorMessage}}</div>

<div class="overlay" *ngIf="displayUserSearch || showInstallList || displayUserNotRegisteredScreen || displayNoCheckInScreen || displayCheckOwnershipPopup">

    <div class="select-install-popup" *ngIf="showInstallList">
        <div class="close-x" (click)="closeInstallSearch()"></div>
        <div class="user-search-head">
            <h2>Select Install</h2>
        </div>
        <div class="select-install">
            <div class="heading-row">
                <p class="lbsn" style="width: 580px;">{{ASSOCIATION}}</p><p class="owner">{{TSAID}}</p><p class="assoc">{{STATUS}}</p>
            </div>
            <ol>
                <li (click)="selectInstall(install)" *ngFor="let install of installs; index as i" [attr.data-index]="i">
                    <p class="lb">{{install.assocname}} - {{install.title}}</p> <p class="lbown">{{install.trainingscheduleid}}</p><p class="assoc">{{COMPLETE}}</p>
                </li>
            </ol>
            <div *ngIf="displayNoInstallsFound" class="no-users">
                {{NO_INSTALLS_FOUND}}
            </div>
        </div>
    </div>

    <div class="not-valid-popup" *ngIf="displayNoCheckInScreen">
        <div class="close-x" (click)="closeCheckIn()"></div>
        <div class="valid-message" *ngIf="notValidAgent">{{AGENT_STATUS}}</div>
        <div class="valid-message" *ngIf="notValidCard">{{CARD_STATUS}}</div>
    </div>

    <div class="not-reguser-popup" *ngIf="displayUserNotRegisteredScreen">
        <div class="close-x" (click)="closeRegEmailScreen()"></div>
        <div class="email-message">{{EMAIL_MESSAGING}}</div>
        <div class="email-address"><span style="font-weight: bold;">Email</span>: {{this.selectedUser.EmailAddress}}</div>
        <div class="resend-box">
            <button class="resend" (click)="resendEmail()">Resend Email</button>
        </div>
    </div>

    <div class="popup-box" *ngIf="displayUserSearch">
        <div class="close-x" (click)="closeSearch()"></div>
        <div class="user-search-head">
            <h2>{{USER_SEARCH}}</h2>
        </div>
        <div class="user-list">
            <div class="heading-row">
                <p class="lbsn">{{NAME}}</p><p class="owner">{{ACCOUNT_NUMBER}}</p><p class="assoc">{{COMPANY}}</p>
            </div>
            <ol>
                <li (click)="selectUser(user)" *ngFor="let user of users; index as i" [attr.data-index]="i">
                    <p class="lb">{{user.FirstName}} {{user.LastName}}</p> <p class="lbown">{{user.CardSN}}</p><p class="assoc">{{user.CompanyName}}</p>
                </li>
            </ol>
            <div *ngIf="displayNoUserFound" class="no-users">
                {{NO_USERS_FOUND}}
            </div>
        </div>
    </div>

    <div class="popup-box" *ngIf="displayCheckOwnershipPopup">
        <div class="close-x" (click)="closeCheckOwnershipPopup()"></div>
        <div class="valid-message" >{{this.checkOwnershipPopupMessage}}</div>
    </div>

</div>

<div class="add-item">
    <div><input id="lbInput" type="number" class="add" #lockbox placeholder="{{ENTER_SCAN}}" (keyup.enter)="checkOwner(lockbox.value); lockbox.value=''" max="8"></div>
    <div class="add-box" (click)="checkOwner(lockbox.value); lockbox.value=''">{{CHECK_OWNERSHIP}}</div>
</div>

<div class="add-item" *ngIf="isSysAdmin">
    <div class="search-box">
        <!--<p class="heading">User Information</p>-->
        <div class="search-row">
            <input id="firstName" [(ngModel)]="firstNameSearchValue" class="search-input"
                   [disabled]='disableFirstName'
                   placeholder="{{FIRST_NAME}}"
                   (focus)="firstNameGetFocus()"
                   (blur)="firstNameLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="keyUpEnterUserFN($event)"
                   value="{{selectedUser?.FirstName}}" />

            <input #firstNameSearchBox [(ngModel)]="lastNameSearchValue" class="search-input"
                   [disabled]='disableLastName'
                   placeholder="{{LAST_NAME}}"
                   (focus)="lastNameGetFocus()"
                   (blur)="lastNameLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="keyUpEnterUserLN($event)"
                   value="{{selectedUser?.LastName}}" />

            <input id="accountNumber" [(ngModel)]="accountNumberSearchValue" class="search-input" style="text-transform: uppercase;"
                   [disabled]='disableAccountNumber'
                   placeholder="{{ACCOUNT_NUMBER}}"
                   (focus)="accountNumberGetFocus()"
                   (blur)="accountNumberLoseFocus()"
                   autocomplete="off"
                   (keyup.enter)="keyUpEnterAccountNumber($event)"
                   value="{{selectedUser?.CardSN}}" />

            <div class="search-button-container">
                <button type="button" (click)="searchUsers()" class="search-button" *ngIf="userSelected"></button>
                <button type="button" (click)="clearSearch()" class="clear-search-button"></button>
            </div>

        </div>

    </div>

    <div class="user-list">
        <div class="heading-row">
            <p class="lbsn">{{CHECKIN_TIME}}</p><p class="owner">Kewe User</p>
        </div>
        <ol>
            <li *ngFor="let user of userStatus; index as i" [attr.data-index]="i">
                <p class="lb">{{user.CheckInTime}}</p> <p class="lbown">{{user.UserName}}</p>
            </li>
        </ol>
        <div *ngIf="displayNoDataFound" class="no-users">
            {{NO_ORDER_DATA}}
        </div>
    </div>

    <div class="user-list">
        <div class="heading-row">
            <!--<p class="lbsn">{{CHECKIN_TIME}}</p><p class="owner">{{ORDER_STATUS}}</p>-->
            <p class="lbsn">Lockboxes Assigned</p><p class="owner">Date</p><p class="owner">Kewe User</p>
        </div>
        <ol>
            <li *ngFor="let user of lbsAssigned; index as i" [attr.data-index]="i">
                <p class="lb">{{user.LBCount}}</p> <p class="lbown">{{user.LBAssignedTime}}</p><p class="lbown">{{user.UserName}}</p>
            </li>
        </ol>
        <div *ngIf="displayNoDataFound" class="no-users">
            {{NO_ORDER_DATA}}
        </div>
    </div>

    <div class="user-list">
        <div class="heading-row">
            <!--<p class="lbsn">{{CHECKIN_TIME}}</p><p class="owner">{{ORDER_STATUS}}</p>-->
            <p class="lbsn">Programming Order LB Count</p><p class="owner" style="width: 200px;">In Progress</p><p class="owner">Kewe User</p>
        </div>
        <ol>
            <li *ngFor="let user of programmingOrders; index as i" [attr.data-index]="i">
                <p class="lb">{{user.BoxesOwed}}</p>
                <p class="lbown" *ngIf="user.InProgress==1" style="width: 200px;">Yes</p>
                <p class="lbown" *ngIf="user.InProgress==0" style="width: 200px;">No</p>
                <p class="lbown" *ngIf="user.InProgress==1" style="width: 260px;">{{user.username}}</p>
                <p class="lbown" *ngIf="user.InProgress==0" style="width: 260px;"></p>
            </li>
        </ol>
        <div *ngIf="displayNoDataFound" class="no-users">
            {{NO_ORDER_DATA}}
        </div>
    </div>
</div>