<div class="settings">
    <p class="title head">{{INSTALL_DATA_PROGRESS}}</p>
    <p class="title">{{AGENT}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="AgentData"></mat-progress-bar> {{AgentData}}%</p>
    <p class="title">{{ASSOCIATION}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="AssociationData"></mat-progress-bar> {{AssociationData}}%</p>
    <p class="title">{{CARD}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="CardData"></mat-progress-bar> {{CardData}}%</p>
    <p class="title">{{COMPANY}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="CompanyData"></mat-progress-bar> {{CompanyData}}%</p>
    <p class="title">{{LBAUTH}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="LBAuthData"></mat-progress-bar> {{LBAuthData}}%</p>
    <p class="title">{{LBMFG}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="LBMFGData"></mat-progress-bar> {{LBMFGData}}%</p>
    <p class="title">{{LOCKBOX}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="LockboxData"></mat-progress-bar> {{LockboxData}}%</p>
    <p class="title">Supra LB Ownership:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="SupraLBOwner"></mat-progress-bar> {{SupraLBOwner}}%</p>
    <p class="title">{{PROGRAMMING_LIST}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="AccountLockboxes"></mat-progress-bar> {{AccountLockboxes}}%</p>
    <p class="title">{{REGION}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="RegionData"></mat-progress-bar> {{RegionData}}%</p>
    <p class="title">{{USER}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="UserData"></mat-progress-bar> {{UserData}}%</p>
    <p class="title">{{USER_PERMISSIONS}}:</p><p class="value"><mat-progress-bar class="app-progress-bar" mode="determinate" [value]="UserPermissionsData"></mat-progress-bar> {{UserPermissionsData}}%</p>
</div>

<div class="buttons">
    <div class="last-sync">{{LAST_SYNC}}: {{lastSyncDate}}</div>
    <div class="last-sync">{{processError}}</div>
    <button class="start-data" *ngIf="!dataProcessState" (click)="startProcessing()">{{START_PROCESSING}}</button>
    <button class="stop-data" *ngIf="dataProcessState" (click)="stopProcessing()">{{STOP_PROCESSING}}</button>
</div>

<div class="reset-message" *ngIf="lbReset">Lockboxes have been reset.</div>
<div class="reset" *ngIf="allowResetLockboxes" (click)="resetLockboxes()">Reset Lockboxes</div>

<div class="reset-message" *ngIf="userReset">Unactivated users have been reset.</div>
<div class="reset" *ngIf="allowResetUsers" (click)="resetUsers()">Reset Unactivated Users</div>