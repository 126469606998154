import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';





import { Router, ActivatedRoute, Params } from '@angular/router';

import { SentriLockHttpService } from '../sl-http.service';
import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';

import { Install } from '../programming/install';
import { InstallData } from '../installdata';
import {User} from '../programming/user';
import { DisputedLockbox } from '../interfaces/DisputedLockbox';

@Injectable()
export class AssociationService
{

    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private route: ActivatedRoute,
                private router: Router,
                private slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        this.slGlobalService.saveCallStack("AssociationService:constructor");
    }

    searchInstalls(): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:searchInstalls");
        var paramObject = {
            completedonly: 1
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("AssociationService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

    searchInstallSettings(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:searchInstalls");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installsettings`, paramObject)
            .toPromise()
            .then(this.processInstallSettings.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    searchUsers(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:searchUsers");

        // we're making a real server call
        var searchField = '';
        var searchValue = '';

        if (firstName && (firstName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "FirstName";
            searchValue = searchValue + "%" + firstName + "%";
        }

        if (lastName && (lastName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "LastName";
            searchValue = searchValue + "%" + lastName + "%";
        }

        if (accountNumber && (accountNumber != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "CardSN";
            searchValue = searchValue + "%" + accountNumber + "%";
        }

        var paramObject = {
            searchfield: searchField,
            searchvalue: searchValue,
            searchcomparator: 'LI',
            orderby: 'LastName',
            /*lowercasekeys: 0, */
            ordervector: 'ASC',
            limit: 500
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/getassocholdqueue`, paramObject)
            .toPromise()
            .then(this.processUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processUsers(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("AssociationService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    selectUser(accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:searchUsers");

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            cardsn: accountNumber
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/getquarantinelist`, paramObject)
            .toPromise()
            .then(this.processSelectUser.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processSelectUser(origParamObject, response): DisputedLockbox[] {

        this.slGlobalService.saveCallStack("AssociationService:processSelectUser");

        var lockboxArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                lockboxArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processSelectUser");
            // rethrow the exception
            throw exception;
        }

        return lockboxArray as DisputedLockbox[];
    }

    private processInstallSettings(origParamObject, response): InstallData[] {

        this.slGlobalService.saveCallStack("AssociationService:processInstallSettings");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as InstallData[];
    }

    finishCheckin(cardsn: string, lbsn: any[]): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:finishCheckin");

        var paramObject = {
            cardsn: cardsn,
            quarantineidlist: lbsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/clearquarantine`, paramObject)
            .toPromise()
            .then(this.processFinishCheckinResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processFinishCheckinResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AssociationService:processFinishCheckinResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processFinishCheckinResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    releaseQueue(progqid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:releaseQueue");

        var paramObject = {
            progqid: progqid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/releaseassochold`, paramObject)
            .toPromise()
            .then(this.processReleaseQueueResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processReleaseQueueResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AssociationService:processReleaseQueueResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processFinishCheckinResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }


    saveLBR(lbr, cardsn: string, sendreceipt: number): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:saveLBR");

        // we're making a real server call
        var paramObject = {
            modifyorder: 1,
            lockboxcount: lbr,
            cardsn: cardsn,
            sendreceipt: sendreceipt
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/accountlockboxes`, paramObject)
            .toPromise()
            .then(this.processSaveLBRResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processSaveLBRResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AssociationService:processSaveLBRResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processSaveLBRResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }


    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("AssociationService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

}