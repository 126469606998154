import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard }                from '../auth-guard.service';

import { SLMainComponent } from '../sl-main.component';

import { ProgrammingComponent }      from './programming.component';

const programmingRoute: Routes = [
    { path: 'programming',
        component: SLMainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ProgrammingComponent }

        ]
    },
    { path: '', redirectTo: '/programming', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forChild(programmingRoute) ],
    exports: [ RouterModule ]
})
export class ProgrammingRoutingModule {}