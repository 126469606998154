
import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AssociationService } from './association.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import {Install} from "../install";
import {User} from "../user";
import { DisputedLockbox as DisputedLockbox } from '../interfaces/DisputedLockbox';

@Component({
    selector: 'association',
    templateUrl: './association.component.html',
    styleUrls: [ './association.component.css'],
    providers: [AuthService]
})
export class AssociationComponent implements OnInit, OnDestroy {

    private updateQueueSub: Subscription;

    //Set the color to black
    messageColor: string = '#000';
    message: string = '';
    installMessage: string = '';

    disableFinishOrder: boolean = true;

    isSysAdmin: boolean = false;
    isAssocAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;

    displayNoUserFound: boolean = false;

    displayQueue: boolean = true;
    stopQueue: boolean = false;

    userSelected: boolean = true;

    showInstallList: boolean = false;
    displayNoInstallsFound: boolean = false;

    displayCompleteMessage: boolean = false;

    installs;

    users;

    currentProgList: string = "";

    hasLimitList: boolean;
    agentLimitLBCount: number = 0;

    //install settings
    currentInstallKeyReturn: string = "";
    currentInstallCradleReturn: string = "";
    currentInstallLBReturn: string = "";

    lbsOwed = 0;

    scannedLBCount = 0;
    lastScannedLB = 0;

    previousLBR = 0;

    disputedLockboxes = new Array<DisputedLockbox>();
    assignedLockboxes = new Array<DisputedLockbox>();

    assignedCount = 0;
    failedLockboxes = [];

    lbQIDLockboxes = [];

    currentInstallAssoc: string = "";
    currentInstallTSAID: string = "";

    selectedInstall: Install = new Install;

    selectedUser: User = new User;

    // Seconds to poll queue
    assocqPollRate = 10;

    displaySimpleCheckIn: boolean = false;
    proxy: boolean = false;

    showModifyOrder: boolean = false;

    constructor(public fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private slGlobalService: SentriLockGlobalService,
                private commonService: CommonService,
                private authService: AuthService,
                private validatorService: ValidatorService,
                private associationService: AssociationService) {

        this.slGlobalService.saveCallStack("AssociationComponent:constructor");

    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AssociationComponent:ngOnInit");

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();

        // Navigate to programming/checkin station for non-admin's
        if(this.slGlobalService.isProgrammingStation()) {
            this.router.navigate(['/programming']);
        } else if(this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        }

        // Speed to poll the queue default is 10 seconds (10 * 1000ms)
        if(this.slGlobalService.getAssocHoldPollRate() !="" || this.slGlobalService.getAssocHoldPollRate() !='0') {
            this.assocqPollRate = parseInt(this.slGlobalService.getAssocHoldPollRate(),10) * 1000;
        } else {
            this.assocqPollRate = this.assocqPollRate * 1000;
        }


        if(this.slGlobalService.getCurrentInstallAssoc() || this.slGlobalService.getCurrentInstall()) {

            this.currentInstallTSAID = this.slGlobalService.getCurrentInstall();
            this.currentInstallAssoc = this.slGlobalService.getCurrentInstallAssoc();

            //Get the install settings
            this.getInstallSettings();

            this.showInstallList = false;

            this.searchHoldQueue();
        } else {
            this.getCurrentInstalls();
        }

        //Reload the users every second
        this.updateQueueSub = observableInterval(10000).subscribe(x => {
            this.checkHoldQueue();
        });

        // get the translations
        this.authService.getTranslations('association', 'ASSOCIATION_COMPONENT')
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("AssociationComponent:ngOnDestroy");

        this.updateQueueSub.unsubscribe();
    }

    checkHoldQueue(): void {

        this.slGlobalService.saveCallStack("AssociationComponent:checkHoldQueue");

        if(this.displayQueue && !this.stopQueue && !this.displayNoInstallsFound) {
            this.searchHoldQueue();
        }
    }


    getCurrentInstalls() {

        this.slGlobalService.saveCallStack("AssociationComponent:getCurrentInstalls");

        this.commonService
            .searchInstalls("1")
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change disputedLockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("AssociationComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = installsArray.length;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if(installs.length == 1) {
            this.selectedInstall = installsArray[0];

            this.slGlobalService.setCurrentInstall(this.selectedInstall.trainingscheduleid);
            this.slGlobalService.setCurrentInstallAssoc(this.selectedInstall.assocname + ' - ' + this.selectedInstall.title);

            this.currentInstallAssoc = this.selectedInstall.assocname  + ' - ' + this.selectedInstall.title;
            this.currentInstallTSAID = this.selectedInstall.trainingscheduleid;

            this.showInstallList = false;

            //Get the install settings
            this.getInstallSettings();

            this.searchHoldQueue();
            //this.displayQueue = true;

        } else {
            this.showInstallList = true;

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    // runs the search when the search button is clicked
    searchHoldQueue(): void {

        this.slGlobalService.saveCallStack("AssociationComponent:searchHoldQueue");

        this.associationService
            .searchUsers("", "", "")
            .then(this.setUserData.bind(this))
            .catch(this.displayError.bind(this));
    }


    setUserData(users): void {

        this.slGlobalService.saveCallStack("AssociationComponent:setUserData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userArray.length;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFound = true;
        }
        else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoUserFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFound = false;
        }

        this.displayQueue = true;

        this.users = userArray;

        //make the check in time the modified time
        for (let user of this.users) {
            user.UTCModified = this.slGlobalService.getLocaleDateFromString(user.UTCModified);
        }
    }

    /**
     * Selects the user from the list of agents with disputed lockboxes.
     * @param user 
     */
    selectUser(user) {

        this.slGlobalService.saveCallStack("AssociationComponent:selectUser");

        this.selectedUser = user;

        if(this.selectedUser.Proxy != null) {
            this.proxy = true;
        }

        this.userSelected = false;

        this.displayQueue = false;

        this.lbsOwed = this.selectedUser.BoxesOwed;
        this.agentLimitLBCount = this.getSelectedUserLBLimitCount(this.selectedUser, this.hasLimitList);

        //Call the server to let it know that we selected a user
        this.associationService
            .selectUser(this.selectedUser.CardSN)
            .then(this.setDisputedLockboxes.bind(this))
            .catch(this.displayError.bind(this));



    }

    /**
     * Takes the raw disputedlockbox array and makes it user friendly.  Sets the disputedlockbox array to be used by the UI.
     * @param disputedLockboxes array of disputed lockboxes from the server
     */
    setDisputedLockboxes(disputedLockboxes: DisputedLockbox[]): void {

        this.slGlobalService.saveCallStack("AssociationComponent:setUserSelect");

        disputedLockboxes.forEach(disputedLockbox => {
            if(disputedLockbox.Name === null){
                disputedLockbox.Name = this.NO_OWNER;
            }
        });

        if(disputedLockboxes.length === 0) {
            this.displaySimpleCheckIn = true;
            this.disableFinishOrder = false;
        }

        this.disputedLockboxes = disputedLockboxes;
    }

    getInstallSettings() {

        this.slGlobalService.saveCallStack("AssociationComponent:getInstallSettings");

        this.commonService
            .searchInstallSettings(this.selectedInstall.trainingscheduleid)
            .then(this.setInstallSettings.bind(this))
            .catch(this.displayError.bind(this));

    }
    

    // Set the complete flag from change disputedLockboxes to receive
    setInstallSettings(installData): void {

        this.slGlobalService.saveCallStack("AssociationComponent:setCurrentInstallList");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {

            if(setting.Name == "ConversionType") {
                this.slGlobalService.setCurrentInstallConversionType(setting.Value);
            }
            if(setting.Name == "LBReturnRatio") {
                this.slGlobalService.setCurrentInstallLBRatio(setting.Value);
            }
            if(setting.Name == "AcceptLockboxReturns") {
                this.slGlobalService.setCurrentInstallLBReturn(setting.Value);
                this.currentInstallLBReturn = setting.Value;
            }
            if(setting.Name == "AcceptCradleReturns") {
                this.slGlobalService.setCurrentInstallCradleReturn(setting.Value);
                this.currentInstallCradleReturn = setting.Value;
            }
            if(setting.Name == "AcceptKeyReturns") {
                this.slGlobalService.setCurrentInstallKeyReturn(setting.Value);
                this.currentInstallKeyReturn = setting.Value;
            }
            if(setting.Name == "AcceptSentriCardReturns") {
                this.slGlobalService.setCurrentInstallCardReturn(setting.Value);
            }
            if(setting.Name == "PrintLabels") {
                this.slGlobalService.setPrintLabels(setting.Value);
            }
            if(setting.Name == "ProgrammingList") {
                this.slGlobalService.setProgList(setting.Value);
                this.currentProgList = setting.Value;
            }
            if(setting.Name == "LimitList") {
                this.slGlobalService.setLimitList(setting.Value);
                this.hasLimitList = Boolean(Number(setting.Value));
            }
        }

    }

    /**
     * Accepts the disputed lockbox and adds is to the assignedLockboxes array
     * @param lockbox 
     */
    acceptDisputedLockbox(lockbox: DisputedLockbox) {

        this.slGlobalService.saveCallStack("AssociationComponent:acceptDisputedLockbox");

        if (this.assignedLockboxes.findIndex(x => x.LBSN === lockbox.LBSN) == -1) {
            this.assignedLockboxes.push(lockbox);
            this.lbQIDLockboxes.push(lockbox.LBQuarantineID);

            this.removeLockboxFromQuarantine(lockbox);

            if(this.currentProgList != "1") {
                //Update boxes owed by one
                this.selectedUser.BoxesOwed++;
            }
        } 
    }

    /**
     * Removes the lockbox from the quaratine list
     * @param lockbox 
     */
    removeLockboxFromQuarantine(lockbox: DisputedLockbox) {

        this.slGlobalService.saveCallStack("AssociationComponent:delete");

        var index = this.disputedLockboxes.findIndex(x => x.LBQuarantineID === lockbox.LBQuarantineID);

        if (index !== -1){
            this.disputedLockboxes.splice(index, 1);
        } 

        if(this.disputedLockboxes.length == 0) {
            this.disableFinishOrder = false;
        }

    }

    cancelCheckin() {

        this.slGlobalService.saveCallStack("AssociationComponent:cancelCheckin");

        this.searchHoldQueue();

        this.displayQueue = true;

        if(this.displaySimpleCheckIn) {
            this.disableFinishOrder = false;
        } else {
            this.disableFinishOrder = true;
        }

        this.disputedLockboxes = [];
        this.assignedLockboxes = [];
        this.lbQIDLockboxes = [];
    }

    finishCheckin() {

        this.slGlobalService.saveCallStack("AssociationComponent:finishCheckin");

        if(this.selectedUser.CardSN != undefined) {

            if(this.displaySimpleCheckIn) {

                this.disableFinishOrder = false;

                //Update the account lockbox record
                this.associationService
                    .saveLBR(this.lbsOwed, this.selectedUser.CardSN, 1)
                    .then(this.setModifiedOrderComplete.bind(this))
                    .catch(this.handleError.bind(this));
            } else {
                // Finish Order create transfers to the agent
                this.associationService
                    .finishCheckin(this.selectedUser.CardSN, this.lbQIDLockboxes)
                    .then(this.processFinishCheckIn.bind(this))
                    .catch(this.handleError.bind(this));
            }

        } else {

        }

    }

    processFinishCheckIn(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AssociationComponent:processFinishCheckIn");

        this.disputedLockboxes = [];
        this.assignedLockboxes = [];
        this.lbQIDLockboxes = [];

        this.selectUser(this.selectedUser);
    }

    processFinishCheckInModifyOrder(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AssociationComponent:processFinishCheckIn");

        this.disputedLockboxes = [];
        this.assignedLockboxes = [];
        this.lbQIDLockboxes = [];

        this.disputedLockboxes = [];
        this.assignedLockboxes = [];
        this.lbQIDLockboxes = [];

        this.proxy = false;

        this.lbsOwed = 0;

        this.searchHoldQueue();

        this.displayQueue = true;

        if(this.displaySimpleCheckIn) {
            this.disableFinishOrder = false;
        } else {
            this.disableFinishOrder = true;
        }

        this.displayCompleteMessage = false;
    }

    // Set the complete flag from change disputedLockboxes to receive
    setModifiedOrderComplete(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("AssociationComponent:setModifiedOrderComplete");

        this.displayCompleteMessage = true;

        this.disableFinishOrder = true;

        if(this.lbsOwed == 0) {

            //Set the cursor focus position to the account number text box
            setTimeout(()=>{
                this.disputedLockboxes = [];
                this.assignedLockboxes = [];
                this.lbQIDLockboxes = [];

                this.lbsOwed = 0;

                this.proxy = false;

                this.searchHoldQueue();

                this.displayQueue = true;

                if(this.displaySimpleCheckIn) {
                    this.disableFinishOrder = false;
                } else {
                    this.disableFinishOrder = true;
                }

                this.displayCompleteMessage = false;
            }, 2000);

        } else {
            //Set the cursor focus position to the account number text box
            setTimeout(()=>{
                // Release the queue
                this.associationService
                    .releaseQueue(this.selectedUser.ProgrammingQueueID)
                    .then(this.processFinishCheckInModifyOrder.bind(this))
                    .catch(this.handleError.bind(this));
            }, 2000);
        }

    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("AssociationComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("AssociationComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    ASSOCIATION: string = "";
    TSAID: string = "";
    STATUS: string = "";
    NO_INSTALLS_FOUND: string = "";
    COMPLETE: string = "";
    NO_USERS_FOUND: string = "";
    FINISH_CHECK: string = "";
    CANCEL: string = "";
    LIST_LOCKBOXES: string = "";
    DISPUTED: string = "";
    MODIFY_ORDER: string = "";
    BOXESOWED: string = "";
    ORDER_COMPLETE: string = "";
    RECEIVE: string = "";
    PROXY: string = "";
    NO_OWNER: string = "";

    loadTranslations() {

        this.slGlobalService.saveCallStack("AssociationComponent:loadTranslations");

        // the call was successful so we should be able to get the translation data
        var associationTranslationData = this.slGlobalService.getTranslations("ASSOCIATION_COMPONENT");
        var checkinTranslationData = this.slGlobalService.getTranslations("CHECKIN_COMPONENT");

        if (associationTranslationData) {
            this.ASSOCIATION = associationTranslationData.association;
            this.NO_INSTALLS_FOUND = associationTranslationData.noinstallsfound;
            this.NO_USERS_FOUND = associationTranslationData.nousersfound;
            this.FINISH_CHECK = associationTranslationData.finishcheckin;
            this.CANCEL = associationTranslationData.cancel;
            this.LIST_LOCKBOXES = associationTranslationData.lockbox;
            this.DISPUTED = associationTranslationData.disputedboxes;
            this.BOXESOWED = associationTranslationData.boxesowed;
            this.MODIFY_ORDER = associationTranslationData.modifyorder;
            this.ORDER_COMPLETE = associationTranslationData.ordercomplete;
            this.RECEIVE = associationTranslationData.receive;
            this.NO_OWNER = associationTranslationData.noowner;

            this.TSAID = checkinTranslationData.tsaid;
            this.PROXY = checkinTranslationData.proxy;

        }
    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("AssociationComponent:selectInstall");

        this.slGlobalService.setCurrentInstall(install.trainingscheduleid);
        this.slGlobalService.setCurrentInstallAssoc(install.assocname + ' - ' + install.title);

        this.currentInstallAssoc = install.assocname + ' - ' + install.title;
        this.currentInstallTSAID = install.trainingscheduleid;

        this.showInstallList = false;

        //Get the install settings
        this.getInstallSettings();

        this.searchHoldQueue();
        //this.displayQueue = true;

    }

    //Close the user search dialog
    closeInstallSearch() {

        this.slGlobalService.saveCallStack("AssociationComponent:closeInstallSearch");

        //Don't allow user to close the install search and stay on this page
        this.router.navigate(['/profile']);
    }


    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AssociationComponent:displayError");

    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AssociationComponent:handleError");

    }

        /**
     * Gets the LBLimit count of the selected user.
     * 
     * If there isn't a limit list being used for this install we want to return null for the LBLimit count.  
     * @param selectedUser 
     * @param hasLimitList 
     * @returns 
     */
         getSelectedUserLBLimitCount(selectedUser, hasLimitList){
            var lBLimitCount;
    
            if(!hasLimitList){
                lBLimitCount = null;
            } else if(!selectedUser.LBLimitCount || selectedUser.LBLimitCount == 0 ) {
                //Changes a null value into 0 also fixes weirdness with data coming back from the server as a string, changing "0" to 0.
                lBLimitCount = 0;
            } else{
                lBLimitCount = selectedUser.LBLimitCount
            }
    
            return lBLimitCount;
    
        }

}