import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { SearchRoutingModule } from './search-routing.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { SearchComponent } from './search.component';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

//import './rxjs-extensions';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SearchRoutingModule,
        BsDropdownModule,
        TypeaheadModule
    ],
    declarations: [
        SearchComponent
    ]
})
export class SearchModule { }
