import { Component,Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';
import { PrintingService } from '../printing/printing.service';

@Component({
    selector: 'adminprint',
    templateUrl: './admin-print.component.html',
    styleUrls: [ './admin-print.component.css'],
    providers: [AuthService, PrintingService]
})
export class AdminPrintComponent implements OnInit {
    // the ID is our internal ID for the property
    @Input() id: string;

    // language constants from USER
    USER_SAVE_SUCCESS: string = "";              // "User data saved successfully"
    USER_SAVE_ERROR: string = "";              // "Unable to save user data"
    GO_BACK: string = "";                       // "Back to user list"

    TEST_PRINT: string = "";
    LABEL_HEAD: string = "";
    SL_TEST_FIRST: string = "";
    SL_TEST_LAST: string = "";
    SL_TEST_COMPANY: string = "";
    PRINT_BETWEEN: string = "";
    MAX_LABEL_COUNT: number = 30;

    labelCount = 1;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        private printingService: PrintingService) {

        this.slGlobalService.saveCallStack("AdminPrintComponent:constructor");

    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminPrintComponent:ngOnInit");

        // load the translations
        this.loadTranslations();

    }

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("AdminPrintComponent:loadTranslations");

        // get the user translation data
        var adminTranslationData = this.slGlobalService.getTranslations('ADMIN_COMPONENT');

        if (adminTranslationData) {

            this.USER_SAVE_SUCCESS = adminTranslationData.usersavesuccess;
            this.USER_SAVE_ERROR = adminTranslationData.usersaveerror;
            this.GO_BACK = adminTranslationData.goback;

            this.TEST_PRINT = adminTranslationData.testprint;
            this.LABEL_HEAD = adminTranslationData.labelhead;
            this.SL_TEST_FIRST = adminTranslationData.sltestfirst;
            this.SL_TEST_LAST = adminTranslationData.sltestlast;
            this.SL_TEST_COMPANY = adminTranslationData.sltestcompany;
            this.PRINT_BETWEEN = adminTranslationData.printbetween;
        }

    }

    /**
     * Calls the printing service to create a test print.
     */
    testPrint() {
        if(this.labelCount > this.MAX_LABEL_COUNT){
            this.labelCount = this.MAX_LABEL_COUNT;
        }
        this.printingService.printTestOrder(this.labelCount);
    }
}