
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';






import { SentriLockGlobalService } from './sl-global.service';
import { SentriLockHttpService } from './sl-http.service';

@Injectable()
export class TypeaheadService
{

    NO_MATCHES: string = "";                    // "No Matches"

    constructor(public slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) { }

    public getTypeAheadWithParam(url: string, searchfield : string, searchcomparator: string, searchvalue: string, limit: string, paramObject: any): Observable<Object[]> {


        paramObject['searchfield'] = (searchfield ? searchfield : '');
        paramObject['searchcomparator'] = (searchcomparator ? searchcomparator.trim() : '');
        paramObject['searchvalue'] = (searchvalue ? '%' + searchvalue + '%' : '');
        paramObject['limit'] = (limit ? limit.trim() : '');
        paramObject['orderby'] = (searchfield ? searchfield.trim() : '');
        paramObject['ordervector'] = 'DESC';

        if (paramObject['searchcomparator'] && (paramObject['searchcomparator'].toLowerCase() == 'li')) {

            // this is a like search so we need to update the "searchvalue" to start and end with "%" characters
            /*var searchValueArray = paramObject['searchvalue'];


             if (searchValueArray && (searchValueArray.length > 0)) {

             // have to add percent signs to to the serach values and save in a new array
             var newSearchValueArray = new Array();

             for (var i=0; i < searchValueArray.length; i++) {

             var newSearchValue = searchValueArray[i];

             if (newSearchValue) {
             newSearchValue = '%' + newSearchValue.trim() + '%';
             }

             newSearchValueArray.push(newSearchValue);
             }

             paramObject['searchvalue'] = newSearchValueArray;
             }*/
        }

        return this.getTypeAheadUrlParam(url, paramObject, searchfield, searchvalue);
    }

    public getTypeAheadSelfService(url: string, searchfield : string, searchcomparator: string, searchvalue: string, limit: string): Observable<Object[]> {

        var paramObject = {};

        return this.getTypeAheadWithParam(url, searchfield, searchcomparator, searchvalue, limit, paramObject);
    }

    private getTypeAheadUrlParam(url: string, paramObject: Object, filterField: string, searchValue: string): Observable<Object[]> {
            return this.slHttpService
                .getInMemPostServer(`kewe/${url}`, paramObject).pipe(
                // ...and calling .json() on the response to return data
                map(this.processTypeAheadResponse.bind(this, filterField, paramObject, searchValue)),
                catchError((err) => this.handleTypeaheadError(err)),);
    }


    private processTypeAheadResponse(filterField: string, origParamObject: any, searchValue: string,  response: any): Object[] {

        var responseData = new Array();

        try {
            //If response is 204 we dont have any data.
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (response.status == 200) && (typeof response.json === "function") && response.json() && response.json().data) {

                // get the response data so it can be displayed in the list of options, if we have a filter field we need to use that to filter the results
                // based on data in that field so only unique results are returned
                var resultsToFilter = response.json().data;

                if (filterField && filterField != "") {

                    if (resultsToFilter && (resultsToFilter.length > 0)) {

                        var alreadyMatched = new Array();
                        var uniqueResults = new Array();

                        // We want to include the value that the user typed as the first choice in the typeahead box so that if
                        // they just hit the enter key to try and search we don't change what they had typed.  This is the same
                        // thing that Google does on their search page.
                        if (searchValue && (searchValue != "")) {

                            var userEnteredValue = {};
                            userEnteredValue[filterField] = searchValue;
                            uniqueResults.push(userEnteredValue);
                            alreadyMatched.push(searchValue);
                        }

                        // look through the results and makesure the values in the "filterField" are unique
                        for (var i = 0; i < resultsToFilter.length; i++) {

                            // if we have the filter field and haven't already found a result with that value we will add it to
                            // the array of unique results and add the value of the field to the already matched array so we don't
                            // add that same value again
                            if (resultsToFilter[i] && resultsToFilter[i][filterField] && (alreadyMatched.indexOf(resultsToFilter[i][filterField]) == -1)) {

                                uniqueResults.push(resultsToFilter[i]);
                                alreadyMatched.push(resultsToFilter[i][filterField]);
                            }
                            else if ( resultsToFilter[i] && resultsToFilter[i][filterField] && searchValue && (searchValue != "") && uniqueResults
                                && uniqueResults[0] && uniqueResults[0][filterField] && (searchValue == resultsToFilter[i][filterField])
                                && (uniqueResults[0][filterField] == searchValue) ) {

                                // if the search value exactly matches one of the results then we want to replace the search value
                                // record with the REAL record
                                uniqueResults[0] = resultsToFilter[i];
                            }
                        }

                        responseData = uniqueResults;

                    }
                    else {
                        responseData = resultsToFilter;
                    }
                }
                else {
                    // no filter field so return all the results
                    responseData = resultsToFilter;
                }
            } else {

                var noMatches = "No Matches";

                var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

                if (translationData) {
                    noMatches = translationData.nomatches;
                }

                if (!noMatches || (noMatches == null)) {
                    // be sure no matches isn't null or undefined
                    noMatches = "No Matches";
                }

                responseData = new Array();
                responseData.push(noMatches);
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "TypeaheadService:processTypeAheadResponse");
            // rethrow the exception
            throw exception;
        }

        return responseData;
    }

    public isNoMatches(valueToCheck: string): boolean {

        if (!this.NO_MATCHES || (this.NO_MATCHES == "")) {
            this.NO_MATCHES = "No Matches";

            var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

            if (translationData) {
                this.NO_MATCHES = translationData.nomatches;
            }

            if (!this.NO_MATCHES || (this.NO_MATCHES == null)) {
                // be sure no matches isn't null or undefined
                this.NO_MATCHES = "No Matches";
            }
        }

        if (this.NO_MATCHES == valueToCheck) {
            return true;
        }
        else {
            return false;
        }
    }

    private handleTypeaheadError(error: any) {

        var unableRetrieveMsg = "";
        var noMatches = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            unableRetrieveMsg = translationData.typeaheaderror;
            noMatches = translationData.nomatches;
        }

        var errorMessage = unableRetrieveMsg;

        if (error && error.message && (error.message != '')) {
            errorMessage = error.message;
        }

        console.error(errorMessage); // log the error to the console
        //return Observable.throw(errorMessage);

        var responseData = new Array();
        responseData.push(noMatches);
        return responseData;
    }


    /*
     * I don't know why but this function is needed or we get errors on all of the pages that have typeahead
     * controls even though it isn't used anywhere
     */

    public getTypeAhead(url: string, paramName: string, serviceProviderId: string): Observable<{}> {

        var typeaheadErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            typeaheadErrorMsg = translationData.typeaheaderror;
        }

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .get(`sks/${url}/?${paramName}=${serviceProviderId}`).pipe(
            // ...and calling .json() on the response to return data
            map(this.processTypeAheadResponse.bind(this)),
            //...errors if any
            catchError((error:any) => observableThrowError(error.json().error ||typeaheadErrorMsg)),);

    }

}