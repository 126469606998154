import { Injectable } from '@angular/core';
import { Headers, Http, Request, Response, RequestOptionsArgs } from '@angular/http';
import { Router }      from '@angular/router';
import { Title }     from '@angular/platform-browser';

import { DefaultTranslationService } from './default-translation.service';

import { SearchFields } from './search-fields';

// import 'rxjs/add/operator/toPromise';

import * as moment from 'moment-timezone';

// use Google Analytics
declare let ga: Function;

/* This class is intended to be used to provide some services that are needed through out the application such as:
  * 1. getting the session
  * 2. provide a different hostname and domain from the one the application is running on for development
  * 3. getting some global variables*/
@Injectable()
export class SentriLockGlobalService
{
    //private loggedIn: boolean = false;
    //private sessionId: string = "";
    //private sendErrorsToServer: boolean = true;
    //private overrideHostname: string = "";
    //private translationData: Object;

    //Languages requested for SentriKey
    private supportedLanguages: string[] = [
        'en',       //English
        'dirka',    //Dirka
        'ar',   	//Arabic
        'bn',	    //Bengali & Silheti
        'tl',	    //Filipino
        'fr',	    //French
        'de',	    //German
        'gu',	    //Gujerati
        'hi',	    //Hindi
        'it',	    //Italian
        'pl',	    //Polish
        'pa',	    //Punjabi
        'es',	    //Spanish
        'tr',	    //Turkish
        'ur',	    //Urdu
        'cy'	    //Welsh
    ];

    // the array of permissions and constants for different permissions.  The permissions are all listed in this wiki page:
    // http://wisg.sentrilock.com/develwiki/index.php/SentriKey_Applications_Overview
    //public permissions: string[];
    //private SYSTEM_ADMIN: string = "SYSADMIN";
    public SYSTEM_ADMIN: string = "SysAdmin";
    public INSTALL_ADMIN: string = "InstallAdmin";
    public ASSOC_ADMIN: string = "AssocAdmin";
    public PROGRAMMING_STATION: string = "Programming";
    public CHECKIN_STATION: string = "CheckIn";

    // these constants are for the displayable name of each role
    private SYSTEM_ADMIN_NAME: string = "System Admin";
    private INSTALL_ADMIN_NAME: string = "Install Admin";
    private PROGRAMMING_STATION_NAME: string = "Programming Station";
    private CHECKIN_STATION_NAME: string = "Check In Station";


    // store some information about the logged in user
    //private loggedInUserFirstName: string;
    //private loggedInUserLastName: string;
    //private loggedInUserID: string;
    //private loggedInUserSPID: string;
    //private loggedInUserLAID: string;
    public loggedInUserPhoto: string;

    //private allowPropertyCodes: boolean = true;
    //private displayPropertyNames: boolean = true;

    // this holds the LA or SP ID of the logged in user
    //private laOrSpId: string = '';

    // The LA or SP name that the user belongs to
    //private permissionDescription: string = '';

    // these are the services that can be selected in the system
    //private servicesArray: any[];

    // this is used to show some controls in the event the logged in user is a System Admin and has put themselves in
    // the context of another user
    //private loggedInUserIsSysAdmin: boolean;

    private LOCAL: string = "en";
    private MOMENT_LOCAL: string = "us";
    private MOMENT_DATE_FORMAT: string = "MM/DD/YYYY";
    private MOMENT_DATE_TIME_FORMAT: string = "MM/DD/YYYY, h:mm a";
    private MOMENT_MYSQL_DATE_FORMAT: string = "YYYY-MM-DD HH:mm:ss";

    constructor(private http: Http, public router: Router,
                public defaultTranslationService: DefaultTranslationService,
                public titleService: Title) { }

    public isLoggedIn(): boolean {

        var loggedIn = false;

        if (sessionStorage.getItem("loggedIn")) {
            var loggedInStr = sessionStorage.getItem("loggedIn");

            if (loggedInStr === "true") {
                loggedIn = true;
            }
            else {
                loggedIn = false;
            }
        }

        return loggedIn;
    }

    /*
    * This function updates the last cache refresh and sets it to now.
    * */
    public updateLastCacheRefresh(): number {

        var currentDateTime = new Date();
        var currentDateTimeMilliseconds = currentDateTime.getTime();

        sessionStorage.setItem('lastCacheRefresh', currentDateTimeMilliseconds.toString());

        return currentDateTimeMilliseconds;
    }

    /*
     * This function gets the last time the cache was refreshed.  If there isn't a cache refresh in session storage it sets it to be now.
     * */
    public getLastCacheRefresh(): number {

        var lastCacheRefresh;

        if (sessionStorage.getItem('lastCacheRefresh')) {

            // sessionStorage does have the last time cache was refreshed
            var lastCacheRefreshStr = sessionStorage.getItem('lastCacheRefresh');

            lastCacheRefresh = Number(lastCacheRefreshStr);
        }
        else {

            // session storage doesn't have the last time cache was refreshed, so put the current time there
            lastCacheRefresh = this.updateLastCacheRefresh();
        }

        return lastCacheRefresh;
    }
    
    /*
     * This function tells if the cache is expired (if it's more than 2 minutes old).
     * */
    public isCacheExpired(): boolean {
        

        var currentDateTime = new Date();
        
        var lastCacheRefresh = this.getLastCacheRefresh();
        var cacheExpirationTime = lastCacheRefresh + 2 * 60 * 1000;
        
        var cacheExpired = false;
        if (currentDateTime.getTime() > cacheExpirationTime) {
            
            // it's been more than 2 minutes since the cache was refreshed so it needs to be done again
            cacheExpired = true;
        }
        
        return cacheExpired;
    }

    /*
     * This function will reload the page if the cache is expired and update the time it was last refreshed.  The cache is expired if it's more than 2 hours old.
     * */
    public reloadCacheIfExpired(): void {

        if (this.isCacheExpired()) {

            // the cache is expired so update the cache date and reload the cache
            this.updateLastCacheRefresh();

            // The forceGet parameter is set to "true" so we force a reload from the server;
            window.location.reload();
        }
    }

    public clearSessionVariables(): void {

        // we need to get the last cache refresh so we can restore it
        var lastCacheRefreshNumber = this.getLastCacheRefresh();

        sessionStorage.clear();

        // now resport the last cache time
        sessionStorage.setItem('lastCacheRefresh', lastCacheRefreshNumber.toString());
    }

    public setLoggedIn(loggedIn: boolean): void {

        if (loggedIn) {
            sessionStorage.setItem('loggedIn', "true");

            // if the user is logged in be sure the Title doesn't say they are still on the login page
            this.setTitle("SentriLock® | Key Exchange Web");
        }
        else {
            sessionStorage.setItem('loggedIn', "false");
    
            // set the tile back to the login page title
            this.setTitle("SentriLock® | Key Exchange Web");
        }
    }

    public setSessionId(sessionId: string ): void {

        if ((sessionId != undefined) && (sessionId != null)) {

            sessionStorage.setItem('sessionId', sessionId);

        }
    }

    public getSessionId(): string {

        var sessionId = "";

        if (sessionStorage.getItem("sessionId")) {
            sessionId = sessionStorage.getItem("sessionId");
        }

        return sessionId;
    }

    public setProgQPollRate(progqpollingrate: string ): void {

        if ((progqpollingrate != undefined) && (progqpollingrate != null)) {

            sessionStorage.setItem('progqpollingrate', progqpollingrate);

        }
    }

    public getProgQPollRate(): string {

        var progqpollingrate = "";

        if (sessionStorage.getItem("progqpollingrate")) {
            progqpollingrate = sessionStorage.getItem("progqpollingrate");
        }

        return progqpollingrate;
    }

    public setInstallDataPollRate(installdatapollingrate: string ): void {

        if ((installdatapollingrate != undefined) && (installdatapollingrate != null)) {

            sessionStorage.setItem('installdatapollingrate', installdatapollingrate);

        }
    }

    public getInstallDataPollRate(): string {

        var installdatapollingrate = "";

        if (sessionStorage.getItem("installdatapollingrate")) {
            installdatapollingrate = sessionStorage.getItem("installdatapollingrate");
        }

        return installdatapollingrate;
    }

    public setAssocHoldPollRate(assocqpollingrate: string ): void {

        if ((assocqpollingrate != undefined) && (assocqpollingrate != null)) {

            sessionStorage.setItem('assocqueuepollingrate', assocqpollingrate);

        }
    }

    public getAssocHoldPollRate(): string {

        var assocqpollingrate = "";

        if (sessionStorage.getItem("assocqueuepollingrate")) {
            assocqpollingrate = sessionStorage.getItem("assocqueuepollingrate");
        }

        return assocqpollingrate;
    }

    public setDBHost(dbhost: string ): void {

        if ((dbhost != undefined) && (dbhost != null)) {

            sessionStorage.setItem('dbhost', dbhost);

        }
    }

    public getDBHost(): string {

        var dbhost = "";

        if (sessionStorage.getItem("dbhost")) {
            dbhost = sessionStorage.getItem("dbhost");
        }

        return dbhost;
    }

    // determine if the passed in language is one of the supported languages or not
    public isLanguageSupported(language: string): boolean {

        var languageSupported = false;

        if (this.supportedLanguages.indexOf(language) > -1) {

            languageSupported = true;
        }

        return languageSupported;
    }

    /*
    * This function will take a passed in language (or null or empty string) and return a suppored language.
    * For example "en-us" becomes just "en".
    *
    * If no lonaugage is passed in (null or empty string) we will try to look it up from the browser.
    *
    * If we can't match the language to a supported language we will default to English "en".
    * */
    public getSupportedLanguage(language: string): string {

        if (!language || (language == "")) {

            try {
                // a language wasn't passed in so we need to look up the language
                if (localStorage.getItem('userLanguage')) {
                    language = localStorage.getItem('userLanguage');
                }
                else if (sessionStorage.getItem('userLanguage')) {
                    language = sessionStorage.getItem('userLanguage');
                }
                else if ((<any>navigator).language) {
                    language = (<any>navigator).language;
                }
                else if ((<any>navigator).userLanguage) {
                    language = (<any>navigator).userLanguage;
                }
                else {
                    language = "en";
                }
            }
            catch (exception) {

                // there was an error when we were trying to lookup the language so default it to English
                language = "en";
            }
        }

        // tells if we found the language in the list of supported languages
        var found = false;

        // loop through all the supported languages and match up the language with a supported one
        for (var i = 0; i < this.supportedLanguages.length; i++) {

            if (language.toLowerCase().includes(this.supportedLanguages[i])) {

                found = true;
                language = this.supportedLanguages[i];
            }
        }

        if (!found) {

            // the language isn't one of the supported ones so set it to English "en"
            language = "en";
        }

        try {
            // Local Storage stays around like a cookie does.  We wil save the language in Local Storage  so that we can default to it in the future.
            localStorage.setItem('userLanguage', language);
        }
        catch (exception) {

            // in private browsing mode or incognito mode you can't use localStorage so we will just save it in session storage
            sessionStorage.setItem('userLanguage', language);
        }

        return language;
    }

    public saveUserLanguage(language: string): void {

        if (language && (language != "")  && this.isLanguageSupported(language)) {

            try {
                // Local Storage stays around like a cookie does.  We wil save the language in Local Storage  so that we can default to it in the future.
                localStorage.setItem('userLanguage', language);
            }
            catch (exception) {

                // in private browsing mode or incognito mode you can't use localStorage so we will just save it in session storage
                sessionStorage.setItem('userLanguage', language);
            }
        }
    }

    public getUserLanguage(): string {

        var language = localStorage.getItem('userLanguage');

        if (!language || (language == '')) {

            // if the language wasn't in local storage check session storage
            var language = sessionStorage.getItem('userLanguage');

            if (!language || (language == '')) {

                // default to English if we don't have a language set
                language = 'en';
            }
        }

        return language;
    }

    public setSendErrorsToServer(sendErrorsToServer: boolean): void {

        if ((sendErrorsToServer != undefined) && (sendErrorsToServer != null)) {

            if (sendErrorsToServer) {
                sessionStorage.setItem('sendErrorsToServer', "true");
            }
            else {
                sessionStorage.setItem('sendErrorsToServer', "false");
            }

        }
    }

    public getSendErrorsToServer(): boolean {

        var sendErrorsToServer = true;

        if (sessionStorage.getItem("sendErrorsToServer")) {
            var sendErrorsToServerStr = sessionStorage.getItem("sendErrorsToServer");

            if (sendErrorsToServerStr === "true") {
                sendErrorsToServer = true;
            }
            else {
                sendErrorsToServer = false;
            }
        }

        return sendErrorsToServer;
    }

    public saveLoggdInUserFirstName(loggedInUserFirstName: string ): void {

        sessionStorage.setItem('loggedInUserFirstName', loggedInUserFirstName);
    }

    public getLoggdInUserFirstName(): string {

        return sessionStorage.getItem("loggedInUserFirstName");
    }

    public saveLoggdInUserLastName(loggedInUserLastName: string ): void {

        sessionStorage.setItem('loggedInUserLastName', loggedInUserLastName);
    }

    public getLoggdInUserLastName(): string {

        return sessionStorage.getItem("loggedInUserLastName");
    }

    public saveLoggdInUserID(loggedInUserID: string ): void {

        sessionStorage.setItem('loggedInUserID', loggedInUserID);
    }

    public getLoggdInUserID(): string {

        return sessionStorage.getItem("loggedInUserID");
    }

    public saveLoggdInUserPhoto(loggedInUserPhoto: string ): void {

        sessionStorage.setItem('loggedInUserPhoto', loggedInUserPhoto);
    }

    public getLoggdInUserPhoto(): string {

        this.loggedInUserPhoto = sessionStorage.getItem("loggedInUserPhoto");

        return this.loggedInUserPhoto;
    }

    public saveLoggdInUserCompID(loggedInUserSPID: string ): void {

        sessionStorage.setItem('loggedInUserSPID', loggedInUserSPID);
    }

    public getLoggdInUserSPID(): string {

        return sessionStorage.getItem("loggedInUserSPID");
    }

    public saveLoggdInUserPMID(loggedInUserLAID: string ): void {

        sessionStorage.setItem('loggedInUserLAID', loggedInUserLAID);
    }

    public getLoggdInUserLAID(): string {

        return sessionStorage.getItem("loggedInUserLAID");
    }

    public saveServicesArray(servicesArray: any[] ): void {

        if (servicesArray) {
            sessionStorage.setItem('servicesArray', JSON.stringify(servicesArray));
        }
        else {
            sessionStorage.setItem('servicesArray', JSON.stringify([]));
        }
    }

    public getServicesArray(): any[] {

        if (sessionStorage.getItem("servicesArray")) {
            return JSON.parse(sessionStorage.getItem("servicesArray"));
        }
        else {
            return [];
        }
    }

    /*
     * This takes a translation for a specific page and saves it in the session storage
     * */
    public saveTranslation(translationData: any, translationKey: string ): void {

        if (!translationData) {
            translationData = {};
        }

        var mergedTranslation = this.mergeTranslations(translationKey, translationData[translationKey]);
        sessionStorage.setItem(translationKey, JSON.stringify(mergedTranslation));
    }

    /*
    * This function merges translations with the default translations and returns the merged set
    * */
    public mergeTranslations(translationKey: string, translationData: any): any {

        var defaultTranslation = this.defaultTranslationService.getDefaultTranslation(translationKey);

        // clone the object so we don't overwrite the default values
        if (defaultTranslation) {
            defaultTranslation = JSON.parse(JSON.stringify(defaultTranslation));
        }

        // see if we have translation data from the server to merge
        if (translationData  && defaultTranslation) {

            for (var attributeName in translationData) {

                if (translationData[attributeName]) {

                    defaultTranslation[attributeName] = translationData[attributeName];
                }
            }
        }

        // if there were no default translations found then use whatever we got from the server
        if (!defaultTranslation) {
            defaultTranslation = translationData;
        }

        return defaultTranslation;
    }

    /*
    * This takes a full set of all translations for all the pages and saves them in the session storage, it starts with the
    * default translations and then overwrites the default values with the data that comes from the server
    * */
    public saveTranslations(translationData: any ): void {

        if (!translationData) {
            translationData = {};
        }


        var loginTranslations = this.mergeTranslations('LOGIN_COMPONENT', translationData.LOGIN_COMPONENT);
        sessionStorage.setItem('LOGIN_COMPONENT', JSON.stringify(loginTranslations));

        var forgotPasswordTranslations = this.mergeTranslations('FORGOTPASSWORD_COMPONENT', translationData.FORGOTPASSWORD_COMPONENT);
        sessionStorage.setItem('FORGOTPASSWORD_COMPONENT', JSON.stringify(forgotPasswordTranslations));

        var resetPasswordTranslations = this.mergeTranslations('RESETPASSWORD_COMPONENT', translationData.RESETPASSWORD_COMPONENT);
        sessionStorage.setItem('RESETPASSWORD_COMPONENT', JSON.stringify(resetPasswordTranslations));

        var commonTranslations = this.mergeTranslations('COMMON_COMPONENT', translationData.COMMON_COMPONENT);
        sessionStorage.setItem('COMMON_COMPONENT', JSON.stringify(commonTranslations));

        var tableTranslations = this.mergeTranslations('TABLE_COMPONENT', translationData.TABLE_COMPONENT);
        sessionStorage.setItem('TABLE_COMPONENT', JSON.stringify(tableTranslations));

        var mainTranslations = this.mergeTranslations('MAIN_COMPONENT', translationData.MAIN_COMPONENT);
        sessionStorage.setItem('MAIN_COMPONENT', JSON.stringify(mainTranslations));

        var checkinTranslations = this.mergeTranslations('CHECKIN_COMPONENT', translationData.CHECKIN_COMPONENT);
        sessionStorage.setItem('CHECKIN_COMPONENT', JSON.stringify(checkinTranslations));

        var programmingTranslations = this.mergeTranslations('PROGRAMMING_COMPONENT', translationData.PROGRAMMING_COMPONENT);
        sessionStorage.setItem('PROGRAMMING_COMPONENT', JSON.stringify(programmingTranslations));

        var reportTranslations = this.mergeTranslations('ADMIN_COMPONENT', translationData.ADMIN_COMPONENT);
        sessionStorage.setItem('ADMIN_COMPONENT', JSON.stringify(reportTranslations));

        var associationTranslations = this.mergeTranslations('ASSOCIATION_COMPONENT', translationData.ASSOCIATION_COMPONENT);
        sessionStorage.setItem('ASSOCIATION_COMPONENT', JSON.stringify(associationTranslations));

    }

    /**
     * Gets the translations from the sessionStorage by the translationKey.
     * Sends default translation if the that translationKey is not found in the session storage.
     * @param translationKey 
     * @returns 
     */
    public getTranslations(translationKey: string): any {

        if (sessionStorage.getItem(translationKey)) {
            return JSON.parse(sessionStorage.getItem(translationKey));
        }
        else {
            var defaultTranslation = this.defaultTranslationService.getDefaultTranslation(translationKey);
            return defaultTranslation;
        }
    }

    private getTimezone(): string {
        return moment.tz.guess();
    }

    public getLocal(): string {
        return this.LOCAL;
    }

    // if an override URL was passed in this function will save it so it can be used later.  For security you
    // can only set the override URL if the hostname is "localhost".  The override URL should be in the format
    // sa-jesse-web.sentrikey.com
    public saveOverrideUrl(): void {

        if (window && window.location && ((window.location.hostname == "localhost") || /^[0-9.]*$/.test(window.location.hostname))) {
            // this is the localhost so see if there is an override hostname provided after the # symbol
            if (window.location.hash) {
                sessionStorage.setItem('overrideHostname', window.location.hash.substr(1));
            }
        }
        else {
            // be sure the override hostname is cleared out
            sessionStorage.setItem('overrideHostname', "");
        }
    }

    public setOverrideHostname(overrideHostname: string): void {

        if (overrideHostname) {
            // set the override hostname to the value we passed in
            sessionStorage.setItem('overrideHostname', overrideHostname);
        }
        else {
            // there isn't an override hostname so set it to blank
            sessionStorage.setItem('overrideHostname', "");
        }

    }

    public getOverrideHostname(): string {

       return sessionStorage.getItem("overrideHostname");
        //return "kewe-steve.sentrilock.com"
        //return "kewe-corey-web.sentrilock.com"
        //return "kewetest.sentrilock.com";
        //return "kewe.sentrilock.com";
        //return "kewedevel.sentrilock.com";
        //return "kewe-ryan-web.sentrilock.com";
    }

    public setQueueSort(queueSort: string): void {

        sessionStorage.setItem('queueSort', "");

        if (queueSort) {
            // set the current install to the value we passed in
            sessionStorage.setItem('queueSort', queueSort);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('queueSort', "");
        }

    }

    public getQueueSort(): string {

        return sessionStorage.getItem("queueSort");
    }

    public setCurrentInstall(currentInstall: string): void {

        sessionStorage.setItem('currentInstall', "");

        if (currentInstall) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstall', currentInstall);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstall', "");
        }

    }

    public getCurrentInstall(): string {

        return sessionStorage.getItem("currentInstall");
    }

    public setCurrentInstallAssoc(currentInstallAssoc: string): void {

        sessionStorage.setItem('currentInstallAssoc', "");

        if (currentInstallAssoc) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallAssoc', currentInstallAssoc);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallAssoc', "");
        }

    }

    public getCurrentInstallAssoc(): string {

        return sessionStorage.getItem("currentInstallAssoc");
    }

    public setCurrentInstallConversionType(currentInstallConversionType: string): void {

        sessionStorage.setItem('currentInstallConversionType', "");

        if (currentInstallConversionType) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallConversionType', currentInstallConversionType);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallConversionType', "");
        }

    }

    public getPrintLabels(): string {

        return sessionStorage.getItem("printLabels");
    }

    public setPrintLabels(printLabels: string): void {

        sessionStorage.setItem('printLabels', "");

        if (printLabels) {
            // set the current install to the value we passed in
            sessionStorage.setItem('printLabels', printLabels);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('printLabels', "");
        }

    }

    public getProgList(): string {

        return sessionStorage.getItem("ProgrammingList");
    }

    public setProgList(programmingList: string): void {

        sessionStorage.setItem('ProgrammingList', "");

        if (programmingList) {
            // set the current install to the value we passed in
            sessionStorage.setItem('ProgrammingList', programmingList);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('ProgrammingList', "");
        }

    }

    public setLimitList(limitList: string): void {

        sessionStorage.setItem('LimitList', "");

        if (limitList) {
            // set the current install to the value we passed in
            sessionStorage.setItem('LimitList', limitList);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('LimitList', "");
        }

    }

    public getCurrentInstallConversionType(): string {

        return sessionStorage.getItem('currentInstallConversionType');
    }

    public setCurrentInstallKeyReturn(currentInstallKeyReturn: string): void {

        sessionStorage.setItem('currentInstallKeyReturn', "");

        if (currentInstallKeyReturn) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallKeyReturn', currentInstallKeyReturn);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallKeyReturn', "");
        }

    }

    public getCurrentInstallKeyReturn(): string {

        return sessionStorage.getItem('currentInstallKeyReturn');
    }

    public setCurrentInstallCradleReturn(currentInstallCradleReturn: string): void {

        sessionStorage.setItem('currentInstallCradleReturn', "");

        if (currentInstallCradleReturn) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallCradleReturn', currentInstallCradleReturn);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallCradleReturn', "");
        }

    }

    public getCurrentInstallCradleReturn(): string {

        return sessionStorage.getItem('currentInstallCradleReturn');
    }

    public setSupraLBOwnerCheck(supraLBOwnerCheck: string): void {

        sessionStorage.setItem('supraLBOwnerCheck', "");

        if (supraLBOwnerCheck) {
            // set the current install to the value we passed in
            sessionStorage.setItem('supraLBOwnerCheck', supraLBOwnerCheck);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('supraLBOwnerCheck', "");
        }

    }

    public getCurrentSupraLBOwnerCheck(): string {

        return sessionStorage.getItem('supraLBOwnerCheck');
    }

    public setCurrentInstallLBReturn(currentInstallLBReturn: string): void {

        sessionStorage.setItem('currentInstallLBReturn', "");

        if (currentInstallLBReturn) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallLBReturn', currentInstallLBReturn);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallLBReturn', "");
        }

    }

    public getCurrentInstallLBReturn(): string {

        return sessionStorage.getItem('currentInstallLBReturn');
    }

    public setCurrentInstallCardReturn(currentInstallCardReturn: string): void {

        sessionStorage.setItem('currentInstallCardReturn', "");

        if (currentInstallCardReturn) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallCardReturn', currentInstallCardReturn);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallCardReturn', "");
        }

    }

    public getCurrentInstallCardReturn(): string {

        return sessionStorage.getItem('currentInstallCardReturn');
    }

    public setCurrentInstallLBRatio(currentInstallLBRatio: string): void {

        sessionStorage.setItem('currentInstallLBRatio', '');

        if (currentInstallLBRatio) {
            // set the current install to the value we passed in
            sessionStorage.setItem('currentInstallLBRatio', currentInstallLBRatio);
        }
        else {
            // there isn't an current install so set it to blank
            sessionStorage.setItem('currentInstallLBRatio', '');
        }

    }

    public getCurrentInstallLBRatio(): string {

        return sessionStorage.getItem('currentInstallLBRatio');
    }

    /*This function is to support the Ghostlab automated testing tool*/
    public getGhostLabHostname(): string {

        var hostname = "";

        if (window && window.location && window.location.href) {

            if (/^[0-9.]*$/.test(window.location.hostname)) {
                // find out if "http:" or "https:" appears twice in the URL
                var firstIndexOfHTTP = window.location.href.indexOf("http:");
                var lastIndexOfHTTP = window.location.href.lastIndexOf("http:");
                var firstIndexOfHTTPS = window.location.href.indexOf("https:");
                var lastIndexOfHTTPS = window.location.href.lastIndexOf("https:");

                var firstIndex = -1;

                if (firstIndexOfHTTP > -1) {
                    firstIndex = firstIndexOfHTTP;
                }
                else if (firstIndexOfHTTPS > -1) {
                    firstIndex = firstIndexOfHTTPS;
                }

                var lastIndex = -1;

                if ( (lastIndexOfHTTP > -1) && (firstIndex > -1) && (lastIndexOfHTTP != firstIndex) ) {
                    lastIndex = lastIndexOfHTTP;
                }
                else if ( (lastIndexOfHTTPS > -1) && (firstIndex > -1) && (lastIndexOfHTTPS != firstIndex) ) {
                    lastIndex = lastIndexOfHTTPS;
                }


                if ( (firstIndex > -1) && (lastIndex > -1) && (firstIndex != lastIndex) ) {

                    // there are multiple URL's so we need to get the host name out of the second one
                    var secondHREF = window.location.href.substring(lastIndex, window.location.href.length);

                    // remove the http:// or https://
                    var secondHostnameIndex = secondHREF.indexOf("://") + 3;
                    secondHREF = secondHREF.substring(secondHostnameIndex, secondHREF.length);

                    // remove anything past the hostname (if there is anything)
                    var slashIndex = secondHREF.indexOf("/");

                    if (slashIndex > -1) {

                        hostname = secondHREF.substring(0, slashIndex);
                    }
                }
            }
        }

        return hostname;
    }

    /*
    * The URL that serves the angular files begin with "sa-" while the URL for the API calls
    * begins with "saapi-".  This function is used for getting the URL for the API calls so
    * need to replace "sa-" with "saapi-".
    * */
    public getHostnameWithProtocol(): string {

        var hostnameWithProtocol = "";

        // if we have an override URL we want to use that instead of the URL in the browser
        var hostname = this.getOverrideHostname();
        var protocol = "https:";

        //http for local testing with windows host entries to php-* machine
        //var protocol = "http:";

        if (!hostname || (hostname == "")) {

            // try to get the hostname for Ghost Lab (automated testing tool)
            if (/^[0-9.]*$/.test(window.location.hostname)) {
                hostname = this.getGhostLabHostname();
            }

            if (!hostname || (hostname == "")) {
                // there was no override hostname so use the hostname from the URL
                if (window && window.location && window.location.hostname) {

                    hostname = window.location.hostname;
                }

                if (window && window.location && window.location.protocol) {

                    protocol = window.location.protocol;
                }
            }
        }

        if (hostname) {

            // The URL where you make the API calls to will contain "api" right before the first "." or first "-",
            // whichever appears earlier in the URL.
            var indexOfDot = hostname.indexOf(".");
            var indexOfDash = hostname.indexOf("-");

            if ((indexOfDash != -1) && (indexOfDash < indexOfDot)) {
                // the dash "-" is first
                hostnameWithProtocol = protocol + "//" + hostname.replace("-", "api-") + "/";
                //hostnameWithProtocol = "http:" + "//" + hostname.replace("-", "api-") + "/";
            }
            else {
                // either there is no "-" or the "." is first
                hostnameWithProtocol = protocol + "//" + hostname.replace(".", "api.") + "/";
                //hostnameWithProtocol = "http:" + "//" + hostname.replace(".", "api.") + "/";
            }

        }

        return hostnameWithProtocol;
    }

    /*
    * This function sets the users permissions that we got from the server at login.
    * */
    public setPermissions(permissions: any[]): void {

        if (permissions && (permissions.length > 0)) {
            var permissionArray = [permissions[0].Permission];
            sessionStorage.setItem('permissions', JSON.stringify(permissionArray));
        }
        else {
            sessionStorage.setItem('permissions', JSON.stringify([]));
        }
    }

    /*
    * This function returns the users permissions that we got from the server at login.
    * */
    public getPermissions(): string[] {

        if (sessionStorage.getItem("permissions")) {
            return JSON.parse(sessionStorage.getItem("permissions"));
        }
        else {
            return [];
        }
    }

    /*
    * This function sets the ID of the local authority or service provider the logged in user is associated with.
    * */
    public setPermissionDescription(permissions: any[]): void {

        sessionStorage.setItem('permissionDescription', "");

        if (permissions) {
            if (permissions.length > 0) {

                sessionStorage.setItem('permissionDescription', permissions[0].PermissionDescription);
            }
        }
    }

    /*
     * This function returns the ID of the local authority or service provider the logged in user is associated with.
     * */
    public getPermissionDescription(): string {

        return sessionStorage.getItem("permissionDescription");
    }

    /* This function returns the name of the highest role the user has. */
    public getUserRole(): string {

        if (this.isSysAdmin()) {
            return this.SYSTEM_ADMIN_NAME;
        }
        else if (this.isInstallAdmin()) {
            return this.INSTALL_ADMIN_NAME;
        }
        else if (this.isProgrammingStation()) {
            return this.PROGRAMMING_STATION_NAME;
        }
        else if (this.isCheckinStation()) {
            return this.CHECKIN_STATION_NAME;
        }

    }


    /*
     * This function sets search parameters for the specified search key so the search can be restored when the
     * user returns to the page.
     * */
    public setSearchParameters(searchKey: string, searchFields: SearchFields): void {

        if (searchFields) {
            sessionStorage.setItem(searchKey, JSON.stringify(searchFields));
        }
        else {
            sessionStorage.setItem(searchKey, JSON.stringify(new SearchFields()));
        }
    }

    /*
     * This function returns the search parameters for the specified search key so the search can be restored when the
     * user returns to the page.
     * */
    public getSearchParameters(searchKey: string): SearchFields {

        if (sessionStorage.getItem(searchKey)) {
            return JSON.parse(sessionStorage.getItem(searchKey));
        }
        else {
            return new SearchFields();
        }
    }

    /*
    * Get the most recent function calls that have been made as an array.
    * Only the 5  most recent are saved.
    * */
    public getCallStackAsArray(): string[] {

        var callStack;

        if (sessionStorage.getItem("callStack")) {
            callStack = JSON.parse(sessionStorage.getItem("callStack"));
        }
        else {
            callStack = [];
        }

        return callStack as string[];
    }

    /*
     * Get the most recent function calls that have been made as a string.
     * Only the 5  most recent are saved.
     * */
    public getCallStackAsString(): string {

        var callStack;

        if (sessionStorage.getItem("callStack")) {
            callStack = sessionStorage.getItem("callStack");
        }
        else {
            callStack = "";
        }

        return callStack as string;
    }

    /*
     * Save the most recent function call that has been made.
     * Only the 5  most recent are saved.  If there are already more than 5 the oldest will be removed.
     * */
    public saveCallStack(functionName: string): void {

        var callStack = this.getCallStackAsArray();

        // get the current call stack or create a new one
        /*if (sessionStorage.getItem("callStack")) {
            callStack = JSON.parse(sessionStorage.getItem("callStack"));
        }
        else {
            callStack = new Array();
        }*/


        // put the newest function call at the top of the stack
        callStack.push(functionName);

        if (callStack.length > 5) {
            // there are more than 5 function calls listed in this stack so remove the oldest one
            callStack.shift();
        }

        // save the callStack
        sessionStorage.setItem("callStack", JSON.stringify(callStack));
    }

    /*
    * This function will refresh the page if it hasn't been refreshed in the last 15 minutes.
    * */
    /*public refreshPageIfNeeded() {

        var lastRefreshTime = this.getLastRefreshTime();

        var currentTime = new Date().getTime();

        if ((currentTime - lastRefreshTime) > 900000) {

            // we have NOT refreshed the page in the last 15 minutes so we will do that now, remembering to update the
            // last refresh time
            this.saveLastRefreshTime();

            // The forceGet parameter is set to "true" so we force a reload from the server;
            window.location.reload(true);
        }
    }

    private getLastRefreshTime() {

        var lastRefreshTime = 0;

        if (localStorage.getItem("lastRefreshTime")) {
            lastRefreshTime = parseInt(localStorage.getItem("lastRefreshTime"));
        }

        return lastRefreshTime;
    }

    private saveLastRefreshTime() {

        // save now as the last refresh time
        var lastRefreshTime = new Date().getTime();
        localStorage.setItem("lastRefreshTime", lastRefreshTime.toString());
    }*/

    /*
    * This function tells if the user is a system admin.
    * */
    public isSysAdmin(): boolean {

        var isSysAdmin = false;

        if (this.getPermissions()) {

            var permission = JSON.stringify(this.getPermissions());

            isSysAdmin = (permission.indexOf(this.SYSTEM_ADMIN) > -1);
        }

        return isSysAdmin;
    }

    /*
    * This function tells if the user is a system admin.
    * */
    public isInstallAdmin(): boolean {

        var isInstallAdmin = false;

        if (this.getPermissions()) {

            var permission = JSON.stringify(this.getPermissions());

            isInstallAdmin = (permission.indexOf(this.INSTALL_ADMIN) > -1);
        }

        return isInstallAdmin;
    }

    /*
    * This function tells if the user is a assoc admin.
    * */
    public isAssocAdmin(): boolean {

        var isAssocAdmin = false;

        if (this.getPermissions()) {

            var permission = JSON.stringify(this.getPermissions());

            isAssocAdmin = (permission.indexOf(this.ASSOC_ADMIN) > -1);
        }

        return isAssocAdmin;
    }

    /*
    * This function tells if the user is a local authority admin.
    * */
    public isProgrammingStation(): boolean {

        var isProgrammingStation = false;

        if (this.getPermissions()) {

            var permission = JSON.stringify(this.getPermissions());

            isProgrammingStation = (permission.indexOf(this.PROGRAMMING_STATION) > -1);
        }

        return isProgrammingStation;
    }

    /*
    * This function tells if the user is a service provider admin
    * */
    public isCheckinStation(): boolean {

        var isCheckinStation = false;

        if (this.getPermissions()) {

            var permission = JSON.stringify(this.getPermissions());

            isCheckinStation = (permission.indexOf(this.CHECKIN_STATION) > -1);
        }

        return isCheckinStation;
    }

    public getLoggdInUsersType(): string {

        var userType = '';
        var permissions = this.getPermissions();

        if (permissions && (permissions.length > 0)) {

            userType = (permissions[0] as any).Permission;
        }

        return userType;
    }

    public getRedirectUrl(): string {
        return sessionStorage.getItem('redirectUrl');
    }

    public setRedirectUrl(redirectUrl: string): void {

        if (redirectUrl && (redirectUrl != "") && (redirectUrl != "null") && (redirectUrl != "undefined")) {
            // be sure to decode the URL before we save it so that it can be used for navigation in the app
            var decodedUrl = decodeURIComponent(redirectUrl);
            sessionStorage.setItem('redirectUrl', redirectUrl);
        }
    }

    public hasRedirectUrl(): boolean {

        var hasRedirectUrl = false;

        var redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl && (redirectUrl != "")) {
            hasRedirectUrl = true;
        }

        return hasRedirectUrl as boolean;
    }

    /*
     * This function is so the login screen can check to see if there is a EULA so it knows if it needs to route to the EULA screen
     * */
    public hasEULA(): boolean {

        var hasEULA = false;

        var eula = sessionStorage.getItem('eula');

        if (eula && (eula != "")) {
            hasEULA = true;
        }

        return hasEULA;
    }

    /*
     * This function is so the EULA screen can clear out the EULA after the user accepts.
     * */
    public setEULA(eula: string): void {
        sessionStorage.setItem('eula', eula);
    }

    /*
     * This function lets the EULA screen access the EULA text to display.
     */
    public getEULA(): string {
        return sessionStorage.getItem('eula');
    }


    /*
     * This function is for user pass/pin screen, if a user has v-card display pin change form
     * */
    public hasCard(): boolean {

        var hasCard = false;

        var card = sessionStorage.getItem('hascard');

        if (card) {
            hasCard = true;
        }

        return hasCard;
    }

    /*
     * This function is for user pass/pin screen, if a user has v-card display pin change form
     * */
    public setHasCard(card: string): void {
        sessionStorage.setItem('hascard', card);
    }
    
    public setTitle( newTitle: string) {
        
        this.titleService.setTitle( newTitle );
    }

    /* This function formats the passed in date/time string into a date string in the format of the desired Local (en-gb).
    * The format of the passed in string should be the standard JavaScript date format 2016-12-17T16:22:00.0Z*/
    public getLocaleDateFromString(dateStringToFormat: string): string {

        if (!dateStringToFormat  || (dateStringToFormat == "")) {
            // we don't have a date to format so return an empty string
            return "";
        }

        // Some browsers (Safari and Chrome on iOS) don't support the ISO data string so we will use Moment.js to
        // create the date instead.
        //var dateToFormat = new Date(dateStringToFormat);
        var dateToFormat = moment.utc(dateStringToFormat).toDate();

        return this.getLocaleDate(dateToFormat);
    }

    public getLocaleDate(dateToFormat: Date): string {

        var formattedDate = "";

        if (dateToFormat) {

            // we have to use moment.js to convert the date and time into Englands local timezone because FireFox doesn't support
            // the IANA time zones there were defined but made optional in ECMA-402
            //var ukDateTime = dateToFormat.toLocaleString(this.getLocal(), {timeZone: this.getTimezone()});
            formattedDate = moment(dateToFormat).tz(this.getTimezone()).format(this.MOMENT_DATE_TIME_FORMAT);
        }

        if (formattedDate == "Invalid date") {
            // the date isn't valid so set it to an empty string
            formattedDate = "";
        }

        return formattedDate;
    }


    /* This function formats the passed in date/time string into a date string in the format of the desired Local (en-gb).
     * The format of the passed in string should be the standard JavaScript date format 2016-12-17T16:22:00.0Z
     * It does NOT do a timezone adjustment.*/
    public getDateFromString(dateStringToFormat: string): string {

        if (!dateStringToFormat  || (dateStringToFormat == "")) {
            // we don't have a date to format so return an empty string
            return "";
        }

        // Some browsers (Safari and Chrome on iOS) don't support the ISO data string so we will use Moment.js to
        // create the date instead.
        //var dateToFormat = new Date(dateStringToFormat);
        var dateToFormat = moment(dateStringToFormat).toDate();

        return this.getDateString(dateToFormat);
    }

    public getDateString(dateToFormat: Date): string {

        var formattedDate = "";

        if (dateToFormat) {

            // we have to use moment.js to convert the date
            formattedDate = moment(dateToFormat).format(this.MOMENT_DATE_FORMAT);
        }

        if (formattedDate == "Invalid date") {
            // the date isn't valid so set it to an empty string
            formattedDate = "";
        }

        return formattedDate;
    }

    public getDateTimeString(dateToFormat: Date): string {

        var formattedDate = "";

        if (dateToFormat) {

            // we have to use moment.js to convert the date
            formattedDate = moment(dateToFormat).format(this.MOMENT_DATE_TIME_FORMAT);
        }

        if (formattedDate == "Invalid date") {
            // the date isn't valid so set it to an empty string
            formattedDate = "";
        }

        return formattedDate;
    }


    /* This function formats the passed in date/time string into a date/time string in the format of the desired Local (en-gb).
     * The format of the passed in string should be the standard JavaScript date format 2016-12-17T16:22:00.0Z*/
    public getLocaleDateTimeFromString(dateStringToFormat: string): string {

        if (!dateStringToFormat  || (dateStringToFormat == "")) {
            // we don't have a date to format so return an empty string
            return "";
        }

        // Some browsers (Safari and Chrome on iOS) don't support the ISO data string so we will use Moment.js to
        // create the date instead.
        //dateToFormat = new Date(dateStringToFormat);
        var dateToFormat = moment(dateStringToFormat).toDate();

        return this.getLocaleDateTime(dateToFormat);
    }

    public getLocaleDateTime(dateToFormat: Date): string {

        var formattedDateTime = "";

        if (dateToFormat) {

            // we have to use moment.js to convert the date and time into Englands local timezone because FireFox doesn't support
            // the IANA time zones there were defined but made optional in ECMA-402
            //var ukDateTime = dateToFormat.toLocaleString(this.getLocal(), {timeZone: this.getTimezone()});
            formattedDateTime = moment(dateToFormat).tz(this.getTimezone()).utc().locale(this.MOMENT_LOCAL).format(this.MOMENT_DATE_TIME_FORMAT);
        }

        return formattedDateTime;
    }

    public setUKTime(localDate: Date, hour: number, minute: number, second: number): Date {

        var ukStartDate = localDate;

        if (localDate) {
            var ukMoment = moment(localDate).tz(this.getTimezone());
            ukMoment.hour(hour);
            ukMoment.minute(minute);
            ukMoment.second(second);

            ukStartDate = ukMoment.toDate();
        }

        return ukStartDate;
    }

    /*
    * The date that is created will be converted from the UK's timezone used in the app to UTC used on the server.
    * */
    public getMysqlDate(dateToFormat: Date): string {

        var formattedDate = "";

        if (dateToFormat) {

            // we have to use moment.js to convert the date and time into Englands local timezone because FireFox doesn't support
            // the IANA time zones there were defined but made optional in ECMA-402
            //var ukDateTime = dateToFormat.toLocaleString(this.getLocal(), {timeZone: this.getTimezone()});
            formattedDate = moment(dateToFormat).tz(this.getTimezone()).utc().format(this.MOMENT_MYSQL_DATE_FORMAT);
        }

        return formattedDate;
    }

    /*
     * The date that is created will be converted to the MySQL format.  The date and time will NOT be altered or converted to
      * a different timezone or UTC.
     * */
    public getMysqlDateWithoutUTCConversion(dateToFormat: Date): string {

        var formattedDate = "";

        if (dateToFormat) {

            // change the date into the MySQL date format but do NOT alter the date or convert it to a different timezone or UTC.
            formattedDate = moment(dateToFormat).format(this.MOMENT_MYSQL_DATE_FORMAT);
        }

        return formattedDate;
    }

    /*
    * This function allows us to navigate to an angular page using the passed in string which can contain optional parameters.
    * The string can be in the form "/property/list" or "/property/detail;mode=edit;id=1".  If there are parameters it will
    * extract them and turn them into an object that can be passed to the Angular router.*/
    public navigateWithOptionalParams(routingString: string): void {

        var routingArray = decodeURIComponent(routingString).split(";");

        if (routingArray.length == 1) {
            // There aren't any optional parameters so we can just navigate directly to the passed in routing string
            this.router.navigate([routingString]);
        }
        else {
            // we have optional parameters so we need to turn them into an object
            var routingObject = new Object();

            // the first index in the array "0" is the path, the items after that are the optional parameters
            for (var i = 1; i < routingArray.length; i++) {
                var keyValueArray = routingArray[i].split("=");
                routingObject[keyValueArray[0]] = keyValueArray[1];
            }

            // navigate using the path (array index 0) and Object of optional parameters.
            this.router.navigate([routingArray[0], routingObject]);
        }
    }


    /*
    * This function sends page views to Google Analytics
    * */
    public sendPageViewToGA(pageName: string): void {

        //ga('set', 'page', pageName);
        //ga('send', 'pageview');
    }

}

