<div class="tabs {{hideText}}">
    <div class="item-image {{hideText}}">
        <div class="hamburger" *ngIf="!showTextBool" (click)="showHideText()"></div>
        <div class="arrow"  *ngIf="showTextBool" (click)="showHideText()"></div>
    </div>
    <div class="settings-icon item {{settingsClass}} {{hideText}}" (click)="selectMenu('settings')"><span class="{{hideText}}">Settings</span></div>
    <div class="data-icon item {{installDataClass}} {{hideText}}" (click)="selectMenu('installdata')"><span class="{{hideText}}">Install Data</span></div>
    <div class="dashboard-icon item {{dashboardClass}} {{hideText}}" (click)="selectMenu('dashboard')"><span class="{{hideText}}">Dashboard</span></div>
    <div class="report-icon item {{reportsClass}} {{hideText}}" (click)="selectMenu('reports')"><span class="{{hideText}}">Reports</span></div>
    <div class="queue-icon item {{lockedQueueClass}} {{hideText}}" (click)="selectMenu('programming')"><span class="{{hideText}}">Locked Programming Queue</span></div>
    <div class="print-icon item {{testPrintClass}} {{hideText}}" (click)="selectMenu('print')"><span class="{{hideText}}">Print Test Label</span></div>
    <div class="user-icon item {{addUserClass}} {{hideText}}" (click)="selectMenu('adduser')" *ngIf="!isInstallAdmin"><span class="{{hideText}}">Add User</span></div>
</div>

<div class="right-box">

    <div class="overlay" *ngIf="showInstallList">

        <div class="select-install-popup" *ngIf="showInstallList">
            <!--<div class="close-x" (click)="closeInstallSearch()"></div>-->
            <div class="user-search-head">
                <h2>Select Install</h2>
            </div>
            <div class="select-install">
                <div class="heading-row">
                    <p class="lbsn" style="width: 580px;">{{ASSOCIATION}}</p><p class="owner">{{TSAID}}</p>
                </div>
                <ol>
                    <li (click)="selectInstall(install)" *ngFor="let install of installs; index as i" [attr.data-index]="i">
                        <p class="lb">{{install.assocname}} - {{install.title}}</p> <p class="lbown">{{install.trainingscheduleid}}</p>
                    </li>
                </ol>
                <div *ngIf="displayNoInstallsFound" class="no-users">
                    {{NO_INSTALLS_FOUND}}
                </div>
            </div>
        </div>
    </div>

    <div class="current-install" *ngIf="installAssoc">{{installAssoc}} ({{installTSAID}})</div>

    <div class="prog-queue" *ngIf="displayProgQueue">
        <adminprog [tsaid]="installTSAID" [tsaname]="installAssoc"></adminprog>
    </div>

    <div class="user-box" *ngIf="displayAddUser && !isInstallAdmin">
        <adminuser></adminuser>
    </div>

    <div class="user-box" *ngIf="displayTestPrint">
        <adminprint></adminprint>
    </div>

    <div class="user-box" *ngIf="displayReports">
        <admin-report [tsaid]="installTSAID" [tsaname]="installAssoc"></admin-report>
    </div>

    <div class="user-box" *ngIf="displayDashboard">
        <admin-dashboard [tsaid]="installTSAID" [tsaname]="installAssoc"></admin-dashboard>
    </div>

    <div class="user-box" *ngIf="displayData">
        <admin-data [tsaid]="installTSAID" [tsaname]="installAssoc" [allowResetUsers]="allowResetUsers"></admin-data>
    </div>
    <div class="user-box" *ngIf="displaySettings">
        <admin-settings [tsaid]="installTSAID" [tsaname]="installAssoc"></admin-settings>
    </div>
</div>