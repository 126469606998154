
import { from as observableFrom, throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Headers, Http, Request, Response, RequestOptionsArgs, URLSearchParams, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';





import { SentriLockGlobalService } from './sl-global.service';
import { CustomQueryEncoderHelper } from './custom-query-encoder-helper';

/* This class is intended to be used to make HTTP calls throughout the SentriKey application.  It will allow us to
 * do the following:
  * 1. automatically append the sessionId to each request
  * 2. prepend a different hostname and domain from the one the application is running on for development
  * 3. be able to make global error handling if desired*/
@Injectable()
export class SentriLockHttpService {
    constructor(private router: Router, private http: Http, private slGlobalService: SentriLockGlobalService) { }

    public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.request(url, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public getInMem(url: string): Observable<Response> {

        return this.http.get(url);
    }

    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {

        var fullUrl = this.slGlobalService.getHostnameWithProtocol() + url;

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.get(fullUrl, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.post(url, body, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public put(url: string, body: any, options?: any): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.put(url, body, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public delete(url: string, options?: RequestOptionsArgs): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.delete(url, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.patch(url, body, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {

        // set the Content-Type if it isn't already set
        options = this.setHeaderOptions(options);

        var response = this.http.head(url, options);

        var reponsePromise = response.toPromise();
        reponsePromise.then(this.checkForRedirect.bind(this))
            .catch(this.handleError.bind(this));

        return observableFrom(reponsePromise) as Observable<Response>;
    }

    /*
    * This function sets the header option for content type if there isn't already a header set*/
    private setHeaderOptions(options?: RequestOptionsArgs): RequestOptionsArgs {

        if (options) {

            // we have options now see if we already have headers set
            if (!options.headers) {

                options.headers = new Headers();
            }

            // now see specifically if we have a Content-Type header set
            if (!options.headers.has('Content-Type')) {
                options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
            }
        }
        else {

            // there are no options set at all
            var headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            options = { headers: headers };
        }

        return options;
    }

    /*
    * This function makes a GET if we are using the in memory API and makes a POST if we're using the real server
    * */
    public getInMemPostServer(url: string, paramObject: Object, options?: any): Observable<Response> {

        var response;

        // be sure to add in the session ID if we have one
        var sessionId = this.slGlobalService.getSessionId();

        if (sessionId && (sessionId != null) && (sessionId != "")) {

            paramObject["SID"] = sessionId;
        } else {

            if (this.slGlobalService.isLoggedIn()) {
                // we need to clear out the current session data
                this.slGlobalService.clearSessionVariables();

                // we don't have a redirect location so default to "login"
                this.router.navigate(['/login']);
            }

        }

        //If there is no tsa id append the global value
        if (!paramObject["tsaid"]) {
            // be sure to add in the tsa id if we have one
            var tsaid = this.slGlobalService.getCurrentInstall();

            if (tsaid && (tsaid != null) && (tsaid != "")) {

                paramObject["tsaid"] = tsaid;
            }
        }


        // add in the language that should be used for any translations the server makes
        var language = this.slGlobalService.getUserLanguage();

        if (language && (language != null) && (language != "")) {

            paramObject["language"] = language;
        }

        var paramString = this.formatParameters(paramObject, false);
        var fullUrl = this.slGlobalService.getHostnameWithProtocol() + url;
        response = this.post(fullUrl, paramString, options);


        return response;
    }

    /*
     * This function will send error information to the server so it can be logged
     * */
    public sendErrorToServer(originalParamObject: Object, error: any, response: any, callingFunction: string): void {


        var stackTrace = "";
        var errorMessage = "";

        // include the error from the error message
        if (error && error.message) {
            errorMessage = "errorMessage = " + error.message + " --";
        }

        var loggedInUserId = this.slGlobalService.getLoggdInUserID();

        if (loggedInUserId) {
            errorMessage = errorMessage + " UserID = " + loggedInUserId + " --";
        }

        if (callingFunction && (callingFunction != "")) {
            errorMessage = errorMessage + " CallingFunction = " + callingFunction + " --";
        }

        if (response) {

            if (response._body) {
                errorMessage = errorMessage + " ResponseBody = " + response._body + " --";
            }
            else {
                errorMessage = errorMessage + " Missing Response Body" + " --";
            }
        }

        // include the original request parameters
        if (originalParamObject) {

            // make sure we don't send the users password if it's in the list of parameters
            if (originalParamObject['password']) {
                originalParamObject['password'] = "";
            }

            errorMessage = errorMessage + " origParams = " + this.formatParameters(originalParamObject, true);
        }

        if (response) {

            errorMessage = errorMessage + " response = " + response.toString();

            if (response._body) {

                errorMessage = errorMessage + " responseBody = " + response._body;
            }
        }

        // add in our own internal callstack
        stackTrace = stackTrace + this.slGlobalService.getCallStackAsString();

        // add in the call stack from the error
        if (error && error.stack) {
            stackTrace = stackTrace + " -- " + error.stack;
        }

        //we want to send back the user agent string so we know what browser the error happened in
        var userAgent = "";

        if (window && window.navigator && window.navigator.userAgent) {
            userAgent = window.navigator.userAgent;
        }

        var paramObject = {
            errormessage: errorMessage,
            stacktrace: stackTrace,
            useragent: userAgent
        };

        this.getInMemPostServer(`kewe/app/exception`, paramObject)
            .toPromise()
            .then(this.processErrorLogging.bind(this))
            .catch(this.handleErrorWhenLoggingError.bind(this));

    }

    /*
    * This function checks to see if there is a redirect and if there is it performs the redirect.
    * */
    private checkForRedirect(response): Observable<Response> {


        // if this is a redirect instruct Angular to perform the redirect
        if (response && response.headers) {

            var redirect = response.headers.get('redirect');

            if ((redirect === true) || (redirect === 'true')) {

                // we are supposed to do a redirect see if we have a redirect location, the default is "login"
                var redirectLocation = response.headers.get('redirectlocation');

                if (redirectLocation && (redirectLocation !== '')) {

                    if (redirectLocation.toLowerCase().includes("login")) {
                        // we need to clear out the current session data
                        this.slGlobalService.clearSessionVariables();
                    }

                    //this.router.navigate(['/' + redirectLocation]);
                }
                else {
                    // we need to clear out the current session data
                    this.slGlobalService.clearSessionVariables();

                    // we don't have a redirect location so default to "login"
                    //this.router.navigate(['/error']);
                }

                // We need to let the caller know this is a redirect so we don't send any errors that may result to the
                // server.  The redirects don't have a body so we will probably get a JSON parse error.
                response.isRedirect = true;
            }
        }

        return observableFrom(response) as Observable<Response>;

        //return origResponse;
    }

    /*
    * Generic error handler.  Let code further up handle the error
    * */
    private handleError(response): Observable<Response> {

        return observableThrowError(response);
    }

    /*
     * We don't need to do anything with the response from logging an error
     * */
    private processErrorLogging(response): void {

        // Do Nothing
    }

    /*
     * If there is an error while we're logging an error we will just ignore it
     * */
    private handleErrorWhenLoggingError(response): void {

        // Do Nothing
    }

    /**
     * This function will format the parameter object into a string.  If we're making a GET with the in memory api,
     * or using any request with the real server then it uses a URI string.  Otherwise it uses a JSON string
     * 
     * @param paramObject 
     * @param isHttpGet 
     * @returns 
     */
    formatParameters(paramObject: Object, isHttpGet: boolean): string {

        var paramString = "";

        // we will return a URI string
        paramString = this.objectToUriString(paramObject);

        return paramString;
    }

    /*
    * This function takes an Object and turns it into a URI encoded string*/
    objectToUriString(paramObject: Object): string {

        let searchParams = new URLSearchParams('', new CustomQueryEncoderHelper());

        // we will get the keys of all of the properties of the parameters object, then loop through those keys to
        // get the values and build up the parameters string
        var keyArray = Object.keys(paramObject);

        for (var i = 0; i < keyArray.length; i++) {

            var key = keyArray[i];

            if ((paramObject[key] !== undefined) && (paramObject[key] !== null) && (paramObject[key] !== "")) {

                // there IS an actual value for this parameter so we want to include it in the parameters string.
                // If it's an object or Array we need to turn it into a JSON string
                if ((paramObject[key].constructor === Object) || (paramObject[key].constructor === Array)) {
                    searchParams.set(key, JSON.stringify(paramObject[key]));
                }
                else {
                    searchParams.set(key, paramObject[key]);
                }

            }
        }

        return searchParams.toString();
    }

    /* This function takes an Object and turns it into a string of parameters. It reads the "keys" and "values" and
     * builds the string from that. Any values that are null, undefined, or empty will be left out. */
    objectToParametersString(paramObject: Object): string {

        var paramString = "";

        // we will get the keys of all of the properties of the parameters object, then loop through those keys to
        // get the values and build up the parameters string
        var keyArray = Object.keys(paramObject);

        for (var i = 0; i < keyArray.length; i++) {

            var key = keyArray[i];

            if (paramObject[key] && (paramObject[key] != "")) {

                // there IS an actual value for this parameter so we want to include it in the parameters string
                if (paramString && (paramString != "")) {

                    paramString = paramString + "&";
                }

                paramString = paramString + key + "=" + paramObject[key];
            }
        }

        return paramString as string;
    }

    /*
    * This function takes a response and returns the JSON data.  It allows us to work with data from a real server and
    * from Google's in memory API which put the JSON in different locations.
    *
    * If the function can't find the JSON data it will return and object with a generic error message
    * */
    public getJsonFromResponse(response: Response): any {


        var jsonData = undefined;

        // A redirect response doesn't have a body so trying to get the JSON would result in an error.
        if (response && !(<any>response).isRedirect && (typeof response.json === "function") && response.json() && response.json().data && response.json().data[0]) {

            // The response is coming from Google's in memory API
            jsonData = response.json().data[0];
        }
        else if (response && !(<any>response).isRedirect && (typeof response.json === "function") && response.json()) {

            // the response is from the real server
            jsonData = response.json();
        }
        else {

            // the JSON data is missing so we will just return an object with a generic error message
            jsonData = {
                ResponseText: 'An error occurred.',
                message: 'An error occurred.',
                ok: false
            };
        }

        return jsonData;
    }

}

