<div class="prog-queue">

    <div class="message" [style.color]="messageColor">{{message}}</div>

    <div *ngIf="displayNoUserFound" class="no-users">{{NO_USERS_FOUND}}</div>

    <ol>
        <li (click)="unlockUser(user)" *ngFor="let user of users; index as i" [attr.data-index]="i" class="user-click">
            <div class="card">
                <div class="head">
                    <p class="name">{{user.FirstName}} {{user.LastName}}</p>
                </div>
                <div class="body">
                    <p class="card">{{user.Company}}</p>
                    <p class="lockbox-head">Boxes Owed</p>
                    <p class="lockbox" *ngIf="user.BoxesOwed">{{user.BoxesOwed}}</p>
                    <p class="lockbox" *ngIf="!user.BoxesOwed">0</p>
                    <p class="date">{{user.UTCModified}}</p>
                </div>
            </div>
        </li>
    </ol>

</div>