import { Component,Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { User } from '../user';
import { Subscription } from 'rxjs';

@Component({
    selector: 'adminuser',
    templateUrl: './admin-user.component.html',
    styleUrls: [ './admin-user.component.css'],
    providers: [AuthService]
})
export class AdminUserComponent implements OnInit {
// the ID is our internal ID for the property
    @Input() id: string;

    format: any;
    parse: any;

    // this is to hold messages that need to get displayed to the user
    message: string = "";

    myUser: User = new User();
    lookedupName: string = "";

    // these variables control when the message about the LA or Company that was entered being invalid are shown
    showInvalidPMMessage: boolean = false;
    showInvalidCompanyMessage: boolean = false;

    subscription: Subscription;

    // Registration form data to post
    sentriKeyId: string = "";
    FirstName: string = "";
    LastName: string = "";
    mobile: string = "";
    entityid: string = "";
    UserID: string = "";
    type: any[];
    countryCode: string = "";
    username: string = "";
    password: string = "";
    confirmpassword: string = "";

    // holds the country that gets selected from the list
    selectedCountry: any;

    isValidMobile: boolean = false;

    completeRegistrationCheck: string = "";

    pmName: string = "";
    companyName: string = "";

    completeReg: boolean = false;

    //Set the color to black
    messageColor: string = '#000';

    // we need to know the logged in users permission
    CompanyorLAID: string = "";

    isSysAdmin: boolean = false;

    isPMAdmin: boolean = false;
    isPMStaff: boolean = false;
    //isLAUser: boolean = false;
    isPMInstaller: boolean = false;

    isCompanyAdmin: boolean = false;
    isCompanyStaff: boolean = false;
    //isCompanyUser: boolean = false;
    isCompanyInstaller: boolean = false;

    // Sys Admin select where they are adding users to
    selectPMCompany: string = "PM";
    showPMCompany: boolean = true;


    // language constants from COMMON
    TYPEAHEAD_ERROR: string = "";              // "An error occurred, no results to display"
    FIRST_NAME: string = "";                   // "First Name"
    LAST_NAME: string = "";                    // "Last Name"
    PROPERTY_MANAGER: string = "";              // "Property Manager"
    COMPANY: string = "";             // "Company"
    SAVE: string = "";                         // "Save"


    // language constants from USER
    USER_SAVE_SUCCESS: string = "";              // "User data saved successfully"
    USER_SAVE_ERROR: string = "";              // "Unable to save user data"
    GO_BACK: string = "";                       // "Back to user list"
    ADD_USER_INSTRUCTIONS: string = "";        // "Use the form below to authorize a new user. Press the 'Save' button when you are finished."
    EMAIL_ADDRESS: string = "";                // "Email Address"
    MOBILE_NO: string = "";                   // "Mobile No."
    USER_TYPE: string = "";                   // "User Type"
    SELECT_USER_TYPE: string = "";             // "Select User Type"
    SYS_ADMIN: string = "";                   // "System Administrator"
    INSTALL_ADMIN: string = "";                   // "System Administrator"
    PM_ADMIN: string = "";                    // "Property Manager Admin"
    PM_STAFF: string = "";                   // "Property Manager Staff"
    PM_INSTALLER: string = "";                // "Property Manager Installer"
    COMPANY_ADMIN: string = "";                    // "Company Admin"
    COMPANY_STAFF: string = "";                    // "Company Staff"
    COMPANY_INSTALLER: string = "";                // "Company Installer"
    ACTIVATE_NOW: string = "";                // "Activate User Now"
    COMPLETE_NOW: string = "";                // "Complete registration immediately, instead of sending email."
    AUTHORIZE_USER: string = "";              // "Authorize New User"
    TYPE: string = "";                        // "Type"
    PM_NOT_VALID: string = "";                // "No property manager matches the entered property manager name. Please enter the full name of the property manager or begin typing the property manager name and select the proper name from the list of matching names."
    COMPANY_NOT_VALID: string = "";                // "No service provider matches the entered service provider name. Please enter the full name of the service provider or begin typing the service provider name and select the proper name from the list of matching names."
    EMAIL_BLANK: string = "";                        // "Email Address cannot be blank."
    FIRST_NAME_BLANK: string = "";                   // "First name cannot be blank."
    LAST_NAME_BLANK: string = "";                    // "Last name cannot be blank."
    MOBILE_BLANK: string = "";                       // "Mobile cannot be blank."
    USER_TYPE_BLANK: string = "";                    // "User type cannot be blank."
    TYPE_BLANK: string = "";                         // "Type cannot be blank."
    ALL_REQUIRED_FIELDS: string = "";                // "All required fields must be filled in for the save button to be enabled."
    REQUIRED_FIELD: string = "";                     // "Required Field"
    PROPERTY_MANAGER_BLANK: string = "";              // "Property Manager cannot be blank."
    SERVICE_PROVIDER_BLANK: string = "";             // "Company cannot be blank."
    MUST_BE_EMAIL: string = "";                      // "Must be a valid email address."
    MUST_BE_MOBILE: string = "";                      // "Must be a valid mobile."
    PASSWORD: string = "";
    CONFIRM_PASSWORD: string = "";
    PASSWORD_MATCH: string = "";
    USERNAME: string = "";
    CHECKIN: string = "";
    PROGRAMMING: string = "";
    ASSOC_ADMIN: string = "";

    constructor(
        public fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        public  validatorService: ValidatorService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminUserComponent:constructor");


    }

    handleTypeaheadError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:handleTypeaheadError");

        // for the type ahead error we'll just display no results
        this.messageColor = '#BF2B3F';
        this.message = this.TYPEAHEAD_ERROR;
    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:ngOnInit");

        // load the translations
        this.loadTranslations();

        //Set default type to placeholder text
        this.myUser.type = "";
    }

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:loadTranslations");

        // get the user translation data
        var adminTranslationData = this.slGlobalService.getTranslations('ADMIN_COMPONENT');

        if (adminTranslationData) {

            this.USER_SAVE_SUCCESS = adminTranslationData.usersavesuccess;
            this.USER_SAVE_ERROR = adminTranslationData.usersaveerror;
            this.GO_BACK = adminTranslationData.goback;
            this.ADD_USER_INSTRUCTIONS = adminTranslationData.adduser;
            this.EMAIL_ADDRESS = adminTranslationData.emailaddress;
            this.MOBILE_NO = adminTranslationData.mobileno;
            this.USER_TYPE = adminTranslationData.usertype;
            this.SELECT_USER_TYPE = adminTranslationData.selectusertype;
            this.SYS_ADMIN = adminTranslationData.sysadmin;
            this.INSTALL_ADMIN = adminTranslationData.installadmin;
            this.PM_ADMIN = adminTranslationData.pmadmin;
            this.PM_STAFF = adminTranslationData.pmstaff;
            this.PM_INSTALLER = adminTranslationData.pminstaller;
            this.COMPANY_ADMIN = adminTranslationData.coadmin;
            this.COMPANY_STAFF = adminTranslationData.costaff;
            this.COMPANY_INSTALLER = adminTranslationData.coinstaller;
            this.ACTIVATE_NOW = adminTranslationData.activatenow;
            this.COMPLETE_NOW = adminTranslationData.completenow;
            this.AUTHORIZE_USER = adminTranslationData.authorizeuser;
            this.TYPE = adminTranslationData.type;
            this.EMAIL_BLANK = adminTranslationData.emailblank;
            this.FIRST_NAME_BLANK = adminTranslationData.firstnameblank;
            this.LAST_NAME_BLANK = adminTranslationData.lastnameblank;
            this.MOBILE_BLANK = adminTranslationData.mobileblank;
            this.USER_TYPE_BLANK = adminTranslationData.usertypeblank;
            this.TYPE_BLANK = adminTranslationData.typeblank;
            this.ALL_REQUIRED_FIELDS = adminTranslationData.allrequiredfields;
            this.REQUIRED_FIELD = adminTranslationData.requiredfield;
            this.PROPERTY_MANAGER_BLANK = adminTranslationData.propertymanagerblank;
            this.SERVICE_PROVIDER_BLANK = adminTranslationData.companyblank;
            this.MUST_BE_EMAIL = adminTranslationData.mustbeemail;
            this.MUST_BE_MOBILE = adminTranslationData.mustbemobile;
            this.PM_NOT_VALID = adminTranslationData.pmnotvalid;
            this.COMPANY_NOT_VALID = adminTranslationData.companynotvalid;
            this.USERNAME = adminTranslationData.username;
            this.PROGRAMMING = adminTranslationData.programming;
            this.CHECKIN = adminTranslationData.checkin;
            this.ASSOC_ADMIN = adminTranslationData.assocadmin;
            this.SYS_ADMIN = adminTranslationData.sysadmin;
            this.SAVE = adminTranslationData.adduser;
            this.FIRST_NAME = adminTranslationData.firstname;
            this.LAST_NAME = adminTranslationData.lastname;
            this.PASSWORD = adminTranslationData.password;
            this.CONFIRM_PASSWORD = adminTranslationData.confirmpassword;
            this.PASSWORD_MATCH = adminTranslationData.passwordmatch;
        }

    }


    saveUser(): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:saveUser");

        this.username = this.myUser.Username;
        this.FirstName = this.myUser.FirstName;
        this.LastName = this.myUser.LastName;
        this.type = [this.myUser.type];
        this.password = this.myUser.Password;
        this.confirmpassword = this.myUser.ConfirmPassword;

        //Only save if the passwords match
        if(this.confirmpassword != this.password) {
            this.messageColor = '#BF2B3F';
            this.message = this.PASSWORD_MATCH;
        } else {
            this.adminService
                .saveUser(this.FirstName, this.LastName, this.username, this.type, this.password, this.confirmpassword)
                .then(this.displaySaveStatusMessage.bind(this))
                .catch(this.displaySaveError.bind(this));
        }


    }

    displaySaveStatusMessage(response: any): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:displaySaveStatusMessage");

        if (response) {
            this.messageColor = '#1AA544';
            this.message = this.USER_SAVE_SUCCESS;

            //Reset Form Data after new user was created
            this.myUser.Username = "";
            this.myUser.FirstName = "";
            this.myUser.LastName = "";
            this.myUser.Password = "";
            this.myUser.ConfirmPassword = "";
            this.myUser.type = "";

        }
        else {
            this.messageColor = '#BF2B3F';
            this.message = this.USER_SAVE_ERROR;
        }
    }

    displaySaveError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminUserComponent:displaySaveError");

        if (errorMessage) {
            this.messageColor = '#BF2B3F';
            this.message = errorMessage;
        }
        else {
            this.messageColor = '#BF2B3F';
            this.message = this.USER_SAVE_ERROR;
        }
    }

}