<div class="overlay" *ngIf="displayUserSearch || displayClearListScreen || showInstallList || displayUserNotRegisteredScreen || displayHoldQueueMessageScreen || displayNoCheckInScreen || (isOverLBLimit && !sendToAssoc)">

    <div class="select-install-popup" *ngIf="showInstallList">
        <div class="close-x" (click)="closeInstallSearch()"></div>
        <div class="user-search-head">
            <h2>{{SELECT_INSTALL}}</h2>
        </div>
        <div class="select-install">
            <div class="heading-row">
                <p class="lbsn" style="width: 580px;">{{ASSOCIATION}}</p><p class="owner">{{TSAID}}</p><p class="assoc">{{STATUS}}</p>
            </div>
            <ol>
                <li (click)="selectInstall(install)" *ngFor="let install of installs; index as i" [attr.data-index]="i">
                    <p class="lb">{{install.assocname}} - {{install.title}}</p> <p class="lbown">{{install.trainingscheduleid}}</p><p class="assoc">{{COMPLETE}}</p>
                </li>
            </ol>
            <div *ngIf="displayNoInstallsFound" class="no-users">
                {{NO_INSTALLS_FOUND}}
            </div>
        </div>
    </div>

    <div class="clear-list-popup" *ngIf="displayClearListScreen">
        <div class="close-x" (click)="closeClearListScreen()"></div>
        <div class="clear-list-message">{{QUESTION_CLEAR_LIST}}</div>
        <button class="yes-clear" (click)="clear()">{{YES}}</button>
        <button class="no-clear" (click)="closeClearListScreen()">{{NO}}</button>
    </div>
a
    <div class="not-valid-popup" *ngIf="displayNoCheckInScreen">
        <div class="close-x" *ngIf="!notValidTurnIn && !sentriCardBlank && !chkProxyBlank" (click)="closeCheckIn()"></div>
        <div class="close-x" *ngIf="notValidTurnIn || sentriCardBlank || chkProxyBlank" (click)="closeCardCheckIn()"></div>
        <div class="valid-message" *ngIf="notValidAgent">{{AGENT_STATUS}}</div>
        <div class="valid-message" *ngIf="notValidCard">{{CARD_STATUS}}</div>
        <div class="valid-message" *ngIf="notValidTurnIn">{{CARD_RETURN_ERROR}}</div>
        <div class="valid-message" *ngIf="sentriCardBlank">{{CARD_RETURN_BLANK}}</div>
        <div class="valid-message" *ngIf="chkProxyBlank">{{CHECKIN_PROXY_BLANK}}</div>
        <div class="valid-message" *ngIf="noAcctNum">{{this.selectedUser.FirstName}} {{this.selectedUser.LastName}} {{NO_ACCT_NUMBER}}</div>
        <div class="valid-message" *ngIf="unknownError">{{UNKNOWN_ERROR}}</div>
    </div>

    <div class="not-reguser-popup" *ngIf="displayUserNotRegisteredScreen">
        <div class="close-x" (click)="closeRegEmailScreen()"></div>
        <div class="email-message">{{EMAIL_MESSAGING}}</div>
        <div class="email-address"><span style="font-weight: bold;">{{EMAIL}}</span>: {{this.selectedUser.EmailAddress}}</div>
        <div class="resend-box">
            <button class="resend" (click)="resendEmail()">{{RESEND_EMAIL}}</button>
        </div>
    </div>

    <div class="queue-popup" *ngIf="displayHoldQueueMessageScreen">
        <div class="close-x" (click)="closeHoldQueueScreen()"></div>
        <div class="email-message">{{checkinMessage}}</div>
    </div>

    <div class="popup-box" *ngIf="displayUserSearch">
        <div class="close-x" (click)="closeSearch()"></div>
        <div class="user-search-head">
            <h2>{{USER_SEARCH}}</h2>
        </div>
        <div class="user-list">
            <div class="heading-row">
                <p class="lbsn">{{NAME}}</p><p class="owner">{{ACCOUNT_NUMBER}}</p><p class="assoc">{{COMPANY}}</p><p class="externalid">{{EXTERNALID}}</p><p class="externalid">{{EMAIL}}</p>
            </div>
            <ol>
                <li (click)="selectUser(user)" *ngFor="let user of users; index as i" [attr.data-index]="i">
                    <p class="lb">{{user.FirstName}} {{user.LastName}}</p> <p class="lbown">{{user.CardSN}}</p><p class="assoc">{{user.CompanyName}}</p><p class="externalid">{{user.ExternalID}}</p><p class="externalid">{{user.EmailAddress}}</p>
                </li>
            </ol>
            <div *ngIf="displayNoUserFound" class="no-users">
                {{NO_USERS_FOUND}}
            </div>
        </div>
    </div>

    <!-- Displays only when it is over the LBLimit and the sendassoc checkbox is not checked -->
    <div class="overLbLimit-popup" *ngIf="isOverLBLimit && !sendToAssoc">
        <div class="close-x" (click)="closeOverLBLimitScreen()"></div>
        <div class="heading-row-large">
            <p>Would you like to send this to the associtaion to review?</p> 
        </div>
        <div class="overLbLimit-message">  
            <p>The amount of lockboxes to be recieved is greater than the limit set by the association.</p>
            <p>Yes, will send this order to the association to review.</p>
            <p>No, will create the order for the limit amount.</p>
        </div>
        <button class="yes-clear" (click)="finishCheckInSendToAssociation()">{{YES}}</button>
        <button class="no-clear" (click)="finishCheckin()">{{NO}}</button>
    </div>

</div>



<div class="current-install" *ngIf="currentInstallAssoc">{{currentInstallAssoc}} ({{currentInstallTSAID}})</div>

<div class="search-box">
    <!--<p class="heading">User Information</p>-->
    <div class="search-row">
        <input id="firstName" [(ngModel)]="firstNameSearchValue" class="search-input"
               [disabled]='disableFirstName'
               placeholder="{{FIRST_NAME}}"
               (focus)="firstNameGetFocus()"
               (blur)="firstNameLoseFocus()"
               autocomplete="off"
               (keyup.enter)="keyUpEnterUserFN($event)"
               value="{{selectedUser?.FirstName}}" />

        <input #firstNameSearchBox [(ngModel)]="lastNameSearchValue" class="search-input"
               [disabled]='disableLastName'
               placeholder="{{LAST_NAME}}"
               (focus)="lastNameGetFocus()"
               (blur)="lastNameLoseFocus()"
               autocomplete="off"
               (keyup.enter)="keyUpEnterUserLN($event)"
               value="{{selectedUser?.LastName}}" />

        <input id="accountNumber" [(ngModel)]="accountNumberSearchValue" class="search-input" style="text-transform: uppercase;"
               [disabled]='disableAccountNumber'
               placeholder="{{ACCOUNT_NUMBER}}"
               (focus)="accountNumberGetFocus()"
               (blur)="accountNumberLoseFocus()"
               autocomplete="off"
               (keyup.enter)="keyUpEnterAccountNumber($event)"
               value="{{selectedUser?.CardSN}}" />

        <div class="search-button-container">
            <button type="button" (click)="searchUsers()" class="search-button" *ngIf="! isUserSelected"></button>
            <button type="button" (click)="resetScreen()" class="clear-search-button"></button>
            <button type="button" (click)="finishCheckin()" class="finish-checkin" *ngIf="displaySimpleCheckIn && isUserSelected">{{FINISH_ORDER}}</button>
            <div class="load-wrapp" *ngIf="showAjaxLoader">
                <div class="load-3">
                    <div class="line linecolor1"></div>
                    <div class="line linecolor2"></div>
                    <div class="line linecolor3"></div>
                </div>
            </div>
        </div>

    </div>

</div>

<div class="error-message" [style.color]="messageColor">{{errorMessage}}</div>

<div class="right-box" *ngIf="displaySimpleCheckIn && isUserSelected">
    <div class="user-info">

        <div class="wizard proxy" style="width: 50px;">
            <div class="title" style="padding: 0px 0px 0px 0px; width: 200px;"><span class="bold-steps"></span> {{PROXY_CHECK_IN}}</div>
            <div class="chkp-input"><input [(ngModel)]="checkinProxy" #checkInProxyTextbox type="text" class="add" placeholder="{{NAME}}" [disabled]='checkinProxyBool'></div>
            <div class="checkbox" style="padding: 0px 0px 0px 0px; width: 50px;">
                <label class="control control-checkbox">
                    <input #checkInProxyCheckbox (change)="updateCHKP()" type="checkbox" name="chkProxy" />
                    <div class="control-indicator"></div>
                </label>
            </div>
        </div>


        <div class="lb-box">
            <div class="receive simple">

                <div class="checkbox">
                    <label class="control control-checkbox">
                        <input #sendassoc (change)="updateSendToAssoc()" type="checkbox" name="sendassoc" />
                        <div class="control-indicator"></div>
                    </label>
                    <div class="chk-label-assoc">{{SEND_TO_ASSOC}}</div>
                </div>

                <div class="lb-box-head">{{LB_RECEIVE}}</div>
                <div *ngIf="selectedUser?.BoxesOwed <= 0" class="bold-and-red">0</div>
                <div *ngIf="selectedUser?.BoxesOwed > 0" class="bold-and-red">{{selectedUser?.BoxesOwed}}</div>
            </div>
        </div>

    </div>

</div>

<div class="right-box" *ngIf="hideWizard">
    <div class="list">
        <div class="list-box" *ngIf="lbrStep">
            <p class="heading-text">{{SCANNED_LOCKBOXES}}</p>
            <ol>
                <li *ngFor="let failedlb of failedLockboxes">
                    <span style="color: #992121; font-weight: bold;">{{failedlb}}</span>
                    <div class="delete" (click)="deleteFailed(failedlb)"><img class="svg-action" src="images/ic_delete_black_24px.svg" /></div>
                </li>
                <li *ngFor="let assignedlb of assignedLockboxes">
                    <span style="color: #58DA5A; font-weight: bold;">{{assignedlb}}</span>
                    <div class="delete" (click)="deleteLockbox(assignedlb)"><img class="svg-action" src="images/ic_delete_black_24px.svg" /></div>
                </li>
                <li *ngFor="let lockbox of lockboxList">
                    <span>{{lockbox}}</span>
                    <div class="delete" (click)="deleteLockbox(lockbox)"><img class="svg-action" src="images/ic_delete_black_24px.svg" /></div>
                </li>
            </ol>
            <button class="clear-list" (click)="showClearListScreen()" [disabled]='disableClearList' ng-class="{'diabled-class': disableClearList }">{{CLEAR_LIST}}</button>
        </div>

        <div class="hide-info" #hideBox *ngIf="showHideDisplay && !displaySimpleCheckIn"></div>
        <div class="info-box" #infoBox>

            <div class="wizard" *ngIf="!displaySimpleCheckIn">
                <div class="title"><span class="bold-steps"></span> {{PROXY_CHECK_IN}}</div>
                <div class="chkp-input"><input [(ngModel)]="checkinProxy" #checkInProxyTextbox type="text" class="add" placeholder="{{NAME}}" [disabled]='checkinProxyBool'></div>
                <div class="checkbox" style="width: 50px;">
                    <label class="control control-checkbox">
                        <input #checkInProxyCheckbox (change)="updateCHKP()" type="checkbox" name="chkProxy" />
                        <div class="control-indicator"></div>
                    </label>
                </div>
            </div>

            <div class="wizard" *ngIf="spkStep && spInstall">
                <div class="title"><span class="bold-steps">{{STEP}} {{stepOne}}:</span> {{TURN_IN_SUPRA_KEY_SCAN}}</div>
                <div class="chk-input"><input [(ngModel)]="supraKey" #spKey type="text" class="add" placeholder="{{TURN_IN_SUPRA_KEY_SCAN}}" [disabled]='spKeyReturned'></div>
                <div class="checkbox">
                    <label class="control control-checkbox">
                        <input #key (change)="updateSPK()" type="checkbox" name="spKey" id="spkyes" />
                        <div class="control-indicator"></div>
                    </label>
                    <div class="chk-label">{{TURN_IN_SUPRA_KEY_CHKBOX}}</div>
                </div>
            </div>

            <div class="wizard" *ngIf="spcStep && spInstall">
                <div class="title"><span class="bold-steps">{{STEP}} {{stepTwo}}:</span> {{TURN_IN_SUPRA_CRADLE_SCAN}}</div>
                <div class="chk-input"><input [(ngModel)]="supraCradle" #spCradle type="text" class="add" placeholder="{{TURN_IN_SUPRA_CRADLE_SCAN}}" [disabled]='spCradleReturned'></div>
                <div class="checkbox">
                    <label class="control control-checkbox">
                        <input #cradle (change)="updateSPC()" type="checkbox" name="spCradle" id="spcyes"/>
                        <div class="control-indicator"></div>
                    </label>
                    <div class="chk-label">{{TURN_IN_SUPRA_CRADLE_CHKBOX}}</div>
                </div>
            </div>

            <div class="wizard" *ngIf="sentriCardStep">
                <div class="title"><span class="bold-steps">{{STEP}} {{stepOne}}:</span> {{TURN_IN_SENTRICARD_SCAN}}</div>
                <div class="chk-input"><input [(ngModel)]="sentriCard" #sentriCardReturn type="text" class="add" placeholder="{{TURN_IN_SENTRICARD_SCAN}}" [disabled]='sentriCardReturned' (change)="checkCard()"></div>
                <div class="checkbox">
                    <label class="control control-checkbox">
                        <input #card (change)="updateSentriCard()" type="checkbox" name="sentriCard" id="sentricardyes" />
                        <div class="control-indicator"></div>
                    </label>
                    <div class="chk-label">{{TURN_IN_SENTRICARD_CHKBOX}}</div>
                </div>
            </div>

            <div class="wizard wizard-tall" *ngIf="lbrStep">
                <div class="title"><span class="bold-steps">{{STEP}} {{stepThree}}:</span> {{BOX_TYPE_SCAN}}</div>
                <div class="checkbox">
                    <label class="control control-checkbox">
                        <input #box (change)="updateLB()" type="checkbox" name="lockbox" id="lbyes"/>
                        <div class="control-indicator"></div>
                    </label>
                    <div class="chk-label">{{BOX_TYPE_CHK}}</div>
                </div>

                <div class="add-item">
                    <div><input type="text" class="add" #lockbox placeholder="{{ENTER_SCAN}}" (keyup.enter)="addLockbox(lockbox.value); lockbox.value=''" [disabled]='disableAddLockbox'></div>
                    <button class="add-box" (click)="addLockbox(lockbox.value); lockbox.value=''" [disabled]='disableAddLockbox' ng-class="{'diabled-class': disableAddLockbox }">{{ADD_LOCKBOX}}</button>
                </div>

                <div class="user-info">
                    <div class="lb-box">
                        <div class = "row">
                            <div class ="column">
                                <div class="firstRow">
                                    <div class="lb-box-head">{{CREDIT}}</div>
                                    <div >{{creditLBCount}}</div>
                                </div>
                            </div>
                            <div class ="column">
                                <div class="firstRow">
                                    <div class="lb-box-head">{{SCANNED}} </div> 
                                    <div>{{scannedLBCount}}</div>
                                </div>
                            </div>
                        </div>
                        <div class = "row">
                            <div class ="column">
                                <div class="secondRow">
                                    <div class="lb-box-head">{{RECEIVED}}</div>
                                    <div >{{receivedLBCount}}</div>
                                </div>
                            </div>
                            <div class ="column">
                                <div class="secondRow"  *ngIf="hasLimitList">
                                    <div class="lb-box-head">{{LIMIT}}</div>
                                    <div>{{agentLimitLBCount}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="checkbox" style="margin-left: 15px;" *ngIf="!displaySimpleCheckIn">
                <label class="control control-checkbox">
                    <input #sendassoc (change)="updateSendToAssoc()" type="checkbox" name="sendassoc" />
                    <div class="control-indicator"></div>
                </label>
                <div class="chk-label-assoc">{{SEND_TO_ASSOC}}</div>
            </div>

            <div class="finish-buttons" *ngIf="!displaySimpleCheckIn">
                <button class="create-transfers" (click)="finishCheckinButtonClick()" [disabled]='disableFinishOrder' ng-class="{'diabled-class': disableFinishOrder }">{{FINISH_ORDER}}</button>
                <button *ngIf="! isProgrammingListSet()" class="finish-without-order" (click)="finishCheckinWitoutOrder()" [disabled]='disableFinishWithoutOrder' ng-class="{'diabled-class': disableFinishWithoutOrder }">{{FINISH_WITHOUT_ORDER}}</button>
            </div>

        </div>

    </div>
</div>