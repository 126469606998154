import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';

import { Subscription } from 'rxjs';

import { AuthService } from './auth.service';

import { SentriLockGlobalService } from './sl-global.service';

import { SentriLockHttpService } from './sl-http.service';

@Injectable()
export class LoginService
{
    constructor(private authService: AuthService,
                private slGlobalService: SentriLockGlobalService,
                public slHttpService: SentriLockHttpService) {

        this.slGlobalService.saveCallStack("LoginService:constructor");
    }

    message: string = "";

    // this tracks whether there is an error that needs to be displayed
    hasError: boolean = false;

    subscription: Subscription;

    processLoginResponse(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("LoginService:processLoginResponse");

        if (jsonResponse && jsonResponse.ResponseText == "Success"){

            // we need to set this users permissions in the sk-global.service so they will be available throughout the application
            this.slGlobalService.setPermissions(jsonResponse.permissions);

            // set the Name of the local authority or service provider the logged in user is associated with
            this.slGlobalService.setPermissionDescription(jsonResponse.permissions);

            // we need to set the other user information in the sk-global.service so it will be available to use later
            this.slGlobalService.saveLoggdInUserFirstName(jsonResponse.firstname);
            this.slGlobalService.saveLoggdInUserLastName(jsonResponse.lastname);
            this.slGlobalService.saveLoggdInUserID(jsonResponse.id);
            this.slGlobalService.saveLoggdInUserCompID(jsonResponse.companyId);
            this.slGlobalService.saveLoggdInUserPMID(jsonResponse.propertyManagerId);
            this.slGlobalService.saveLoggdInUserPhoto(jsonResponse.photourl);

            // Set the polling speed for the queue's and install data
            this.slGlobalService.setProgQPollRate(jsonResponse.progqpollingrate);
            this.slGlobalService.setInstallDataPollRate(jsonResponse.installdatapollingrate);
            this.slGlobalService.setAssocHoldPollRate(jsonResponse.assocqueuepollingrate);

            // TODO: save the sessionId and whether we are supposed to send javascript errors back to the server to be logged
            this.slGlobalService.setSessionId(jsonResponse.sid);
            this.slGlobalService.setSendErrorsToServer(jsonResponse.sendErrorsToServer);

            // the user is logged in
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            let navigatePath = this.authService.hasRedirectUrl() ? this.authService.getRedirectUrl() : '/profile';

            if(this.slGlobalService.isSysAdmin()) {
                navigatePath = '/admin';
            } else if(this.slGlobalService.isInstallAdmin()) {
                navigatePath = '/admin';
            } else if(this.slGlobalService.isProgrammingStation()) {
                navigatePath = '/programming';
            } else if(this.slGlobalService.isCheckinStation()) {
                navigatePath = '/checkin';
            }else if(this.slGlobalService.isAssocAdmin()) {
                navigatePath = '/association';
            }


            // navigate the user to the page they want to go to
            this.slGlobalService.navigateWithOptionalParams(navigatePath);

        }
        else {

            var unableLogIn = "";

            var translationData = this.slGlobalService.getTranslations('LOGIN_COMPONENT');

            if (translationData) {
                unableLogIn = translationData.unablelogin;
            }

            // the user isn't logged in so display the error message if there is one
            var myMessage;

            if (jsonResponse && jsonResponse.ResponseText && jsonResponse.ResponseText != "") {

                myMessage =  jsonResponse.ResponseText;
            }

            this.message = myMessage;
            this.hasError = true;
        }
    }

    public loginError(origParamObject, errorResponse: any): void {

        this.slGlobalService.saveCallStack("LoginService:loginError");

        var errorMessage;

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (errorResponse && !errorResponse.isRedirect && (typeof errorResponse.json === "function") && errorResponse.json() && errorResponse.json().ResponseText) {
                errorMessage =  errorResponse.json().ResponseText;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, errorResponse, "LoginService:loginError");
        }

        this.message = errorMessage;
        this.hasError = true;
    }

}