import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";

import { SentriLockGlobalService } from './sl-global.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'SentriLock';

  constructor(private skGlobalService: SentriLockGlobalService, public router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        skGlobalService.sendPageViewToGA(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void
  {
    // see if we're supposed to make our requests use a different server
    this.skGlobalService.saveOverrideUrl();
  }
}
