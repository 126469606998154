import { Component }   from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute }      from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';

// this is needed to process route parameters

import { Subscription } from 'rxjs';

import { AuthService } from './auth.service';

import { SentriLockGlobalService } from './sl-global.service';

import { LoginService } from './login.service';


@Component({
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.css' ]
})
export class LoginComponent implements OnInit, OnDestroy {
    message: string;
    username: string;
    password: string;

    // this tracks whether there is an error that needs to be displayed
    hasError: boolean = false;

    /* Comment out code to force a reload, the caching issues should be fixed by the new version of the AngularCLI
    fromForcedReload: boolean = false;
    subscription: Subscription;*/
    reloadTimer: any;
    

    // these are all the variables the translations for the page go in.
    EMAIL_ADDRESS: string = "";       // "Email Address"
    PASSWORD: string = "";       // "Password"
    LOGIN_BUTTON: string = "";         // "Log In"
    EMAIL_ADDRESS_NOT_BLANK: string = "";   // "Email address cannot be blank."
    PASSWORD_NOT_BLANK: string = "";   // "Password cannot be blank."
    TRY_LOG_IN: string = "";           // "Trying to log in"
    KEWE: string = "";           // "kewe"

    showPasswordBool: boolean = true;
    passwordType: string = "password";

    constructor(public authService: AuthService,
                public router: Router,
                private route: ActivatedRoute,
                private slGlobalService: SentriLockGlobalService,
                private loginService: LoginService,
                private location: Location) {

        this.slGlobalService.saveCallStack("LoginComponent:constructor");

    }

    setMessage() {
        this.slGlobalService.saveCallStack("LoginComponent:setMessage");
        this.message = '';
        this.hasError = false;
    }

    showHidePassword() {

        this.slGlobalService.saveCallStack("LoginComponent:showHidePassword");

        this.showPasswordBool = !this.showPasswordBool;

        if (this.showPasswordBool) {
            this.passwordType = "password";
        }
        else {
            this.passwordType = "text";
        }
    }

    login() {

        this.slGlobalService.saveCallStack("LoginComponent:login");

        if (!this.username)
        {
            this.message = this.EMAIL_ADDRESS_NOT_BLANK;
            this.hasError = true;
            return false;
        }

        if (!this.password)
        {
            this.message = this.PASSWORD_NOT_BLANK;
            this.hasError = true;
            return false;
        }

        this.hasError = false;

        this.message = this.TRY_LOG_IN + ' ' + this.username + ' ...';

        var paramObject = {
            username: this.username
        };

        this.authService.login(this.username, this.password)
            .then(this.loginService.processLoginResponse.bind(this))
            .catch(this.loginService.loginError.bind(this, paramObject));
    }

    logout() {

        this.slGlobalService.saveCallStack("LoginComponent:logout");

        this.authService.logout();
        this.setMessage();
    }

    getAndLoadTranslations() {

        this.slGlobalService.saveCallStack("LoginComponent:getAndLoadTranslations");

        //this.slGlobalService.getTranslationData().then(this.loadTranslations.bind(this));

        this.authService.getTranslations('login', "LOGIN_COMPONENT")
        //.then(properties => {this.rows = properties;});
            .then(this.loadTranslationsAndGetMoreTranslations.bind(this))
            .catch(this.handleTranslationError.bind(this));
    }

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("LoginComponent:loadTranslations");

        // we should be able to get the translation data
        var translationData = this.slGlobalService.getTranslations('LOGIN_COMPONENT');

        if (translationData) {

            this.EMAIL_ADDRESS = translationData.emailaddress;
            this.PASSWORD = translationData.password;
            this.LOGIN_BUTTON = translationData.signin;
            this.EMAIL_ADDRESS_NOT_BLANK = translationData.emailaddressnotblank;
            this.PASSWORD_NOT_BLANK = translationData.passwordnotblank;
            this.TRY_LOG_IN = translationData.trylogin;
            this.KEWE = translationData.kewelogo;

            // the message uses the translations so it can't be set until we have loaded the translations
            this.setMessage();
        }
    }

    loadTranslationsAndGetMoreTranslations(success): void {

        this.slGlobalService.saveCallStack("LoginComponent:loadTranslationsAndGetMoreTranslations");

        this.loadTranslations();

        // get all of the rest of the translations for the application
        this.authService.getTranslations("", "")
            .then(this.allTranslationsSuccess.bind(this))
            .catch(this.handleTranslationError.bind(this));
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("LoginComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded already
    }

    handleTranslationError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("LoginComponent:handleTranslationError");

        // there was an error but we can still load the default translations
        this.loadTranslations();

        // now set the error message
        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }

        this.hasError = true;
    }

    ngOnInit(): void
    {
        this.slGlobalService.saveCallStack("LoginComponent:ngOnInit");

        // get theURL we are supposed to navigate to (if there is one)
        var redirectURL = this.slGlobalService.getRedirectUrl();

        // get the override Hostname if there is one
        var overrideHostname = this.slGlobalService.getOverrideHostname();

        // make sure any variables from previous sessions are cleared out
        this.slGlobalService.clearSessionVariables();

        // restore the URL we're supposed to navigate to
        this.slGlobalService.setRedirectUrl(redirectURL);

        // restore the Override Hostname
        this.slGlobalService.setOverrideHostname(overrideHostname);

        // reload the page to refresh the cache if the cache is expired
        this.slGlobalService.reloadCacheIfExpired();

        // We need to schedule a reload to happen 14 hours from now if the user is still on the login page.  This will prevent
        // the user from leaving the page open overnight and it not getting reloaded if we have deployed new code over night.
        this.reloadTimer = window.setTimeout(this.reloadPage.bind(this), 51600000);
        
        this.getAndLoadTranslations();

    }

    ngOnDestroy() {

        // clear out the timer once we have left the page
        if (this.reloadTimer) {
            window.clearTimeout(this.reloadTimer);
        }
    }

    // this is the function to get executed when the timer goes off
    reloadPage(): void {

        try {
            //this.slGlobalService.refreshPageIfNeeded();
            //this.location.replaceState("login;fromForcedReload=true");
            
            // This check is in case the component didn't get destroyed for some reason when the user logged in, this
            // will keep us from accidentially reloading the application on the user once they are actively logged in
            // and using the application (it should never happen)
            if (!this.slGlobalService.isLoggedIn())
            {
                // The forceGet parameter is set to "true" so we force a reload from the server;
                window.location.reload(true);
            }
        }
        catch (exception) {
            // if we get an exception we weren't able to reload the page
        }
    }
}
