import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard }                from '../auth-guard.service';

import { SLMainComponent } from '../sl-main.component';

import { AssociationComponent }      from './association.component';

const associationRoute: Routes = [
    { path: 'association',
        component: SLMainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: AssociationComponent }

        ]
    },
    { path: '', redirectTo: '/association', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forChild(associationRoute) ],
    exports: [ RouterModule ]
})
export class AssociationRoutingModule {}