import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { Observable, Subscription } from 'rxjs';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

//Angular Material Progress bar
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { Install } from '../install';
import { InstallData } from '../installdata';

// import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
    providers: [AuthService]
})
export class AdminComponent implements OnInit, OnDestroy {

    private dataProgressSub: Subscription;

    // this is to hold messages that need to get displayed to the user
    message: string = "";

    //Set the color to black
    messageColor: string = '#000';

    installMessage: string = "";
    installAssoc: string = "";
    installTSAID: string = "";

    showAjaxLoader: boolean = false;

    allowResetLockboxes: boolean = false;

    allowResetUsers: boolean = false;

    stopFlag: boolean = false;

    showInstallList: boolean = true;
    displayNoInstallsFound: boolean = false;

    isSysAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;
    isInstallAdmin: boolean = false;

    dataProcessState: boolean = false;

    lastSyncDate: string = "";

    dataStart: boolean = false;

    installs;
    selectedInstall: Install = new Install;

    //Install Settings
    InstallType: string = "";
    ReturnsAccepted: string = "";
    LockboxExRatio: string = "";
    SendRegInvite: string = "";
    SendRegInviteDate: string = "";
    KeyAgreement: string = "";
    AgentBilling: string = "";
    acceptKeyReturns: string = "";
    acceptCradleReturns: string = "";
    acceptLockboxReturns: string = "";
    printLabels: string = "";

    currentInstallKeyReturn: string = "";
    currentInstallCradleReturn: string = "";
    currentInstallLBReturn: string = "";
    currentInstallLBRatio: string = "";

    //Data Progress chart
    AgentData = 0;
    AccountLockboxes = 0;
    AssociationData = 0;
    CardData = 0;
    CompanyData = 0;
    LBAuthData = 0;
    LBMFGData = 0;
    LockboxData = 0;
    RegionData = 0;
    UserData = 0;
    UserPermissionsData = 0;
    SupraData = 0;

    processError: string = "";

    //Tabs to display
    displayAddUser: boolean = false;
    displayProgQueue: boolean = false;
    displayTestPrint: boolean = false;
    displaySettings: boolean = false;
    displayData: boolean = false;
    displayReports: boolean = false;
    displayDashboard: boolean = false;

    //Seconds to poll queue
    installDataPollRate = 10;

    settingsClass: string = "";
    installDataClass: string = "";
    dashboardClass: string = "";
    reportsClass: string = "";
    lockedQueueClass: string = "";
    testPrintClass: string = "";
    addUserClass: string = "";

    showTextBool: boolean = true;
    hideText: string = "";

    constructor(public fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        private commonService: CommonService,
        private authService: AuthService,
        private validatorService: ValidatorService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminComponent:constructor");

    }

    clearAllClasses(): void {

        this.settingsClass = "";
        this.installDataClass = "";
        this.reportsClass = "";
        this.lockedQueueClass = "";
        this.testPrintClass = "";
        this.addUserClass = "";
        this.dashboardClass = "";

        this.displaySettings = false;
        this.displayProgQueue = false;
        this.displayTestPrint = false;
        this.displayData = false;
        this.displayReports = false;
        this.displayDashboard = false;
        this.displayAddUser = false;
    }


    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminComponent:ngOnInit");

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();
        this.isInstallAdmin = this.slGlobalService.isInstallAdmin()

        // Navigate to programming/checkin station for non-admin's
        if (this.slGlobalService.isProgrammingStation()) {
            this.router.navigate(['/programming']);
        } else if (this.slGlobalService.isCheckinStation()) {
            this.router.navigate(['/checkin']);
        } else if (this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }


        //Show the installs popup window
        this.showInstallList = true;

        // get the translations
        this.authService.getTranslations('admin', "ADMIN_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));

        if (this.slGlobalService.getCurrentInstallAssoc() || this.slGlobalService.getCurrentInstall()) {

            this.installTSAID = this.slGlobalService.getCurrentInstall();
            this.installAssoc = this.slGlobalService.getCurrentInstallAssoc();

            this.showInstallList = false;

            this.settingsClass = "active";
            this.displaySettings = true;
        } else {
            this.getCurrentInstalls();
        }


    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("AdminComponent:ngOnDestroy");

    }

    showHideText() {

        this.slGlobalService.saveCallStack("LoginComponent:showHidePassword");

        this.showTextBool = !this.showTextBool;

        if (!this.showTextBool) {
            this.hideText = "hideText";
        }
        else {
            this.hideText = "";
        }
    }

    processTranslations(success) {

        this.slGlobalService.saveCallStack("AdminComponent:processTranslations");

        if (success) {

            // the call was successful so we should be able to get the translation data
            this.loadTranslations();
        }
    }

    allTranslationsSuccess(success) {

        this.slGlobalService.saveCallStack("AdminComponent:allTranslationsSuccess");

        // nothing to do the translations are loaded
    }

    NO_INSTALLS_FOUND: string = "";             // "No Installs Found"
    ASSOCIATION: string = "";             // "Association"
    TSAID: string = "";             // "TSAID"
    STATUS: string = "";             // "Status"
    UNABLE_RETRIEVE: string = "";             // "Unable to retrieve data"
    INSTALL_SETTINGS: string = "";             // "Install Settings"
    PROCESSED_DATA: string = "";             // "Processed Data"
    AGENT_BILLING: string = "";             // "Agent Billing"
    KEY_AGREEMENT: string = "";             // "Key Agreement"
    INSTALL_TYPE: string = "";             // "Key Agreement"
    RETURNS_ACCEPTED: string = "";             // "Key Agreement"
    RETURNS_LB: string = "";             // "Key Agreement"
    RETURNS_KEY: string = "";             // "Key Agreement"
    RETURNS_CRADLE: string = "";             // "Key Agreement"
    LOCKBOX_EXCHANGE_RATIO: string = "";             // "Key Agreement"
    SEND_REG_INVITE: string = "";             // "Key Agreement"
    START_PROCESSING: string = "";             // "Start Processing"
    STOP_PROCESSING: string = "";             // "Stop Processing"
    LAST_SYNC: string = "";             // "Last Sync"
    NO_DATA: string = "";             // "No Data"
    NO: string = "";             // "No"
    YES: string = "";             // "Yes"

    AGENT: string = "";             // "Agent"
    PROGRAMMING_LIST: string = "";             // "PROGRAMMING_LIST"
    CARD: string = "";             // "Card"
    COMPANY: string = "";             // "Company"
    LBMFG: string = "";             // "LBMFG"
    LBAUTH: string = "";             // "LBMFG"
    LOCKBOX: string = "";             // "Lockbox"
    REGION: string = "";             // "Region"
    USER: string = "";             // "User"
    USER_PERMISSIONS: string = "";             // "User Permissions"
    INSTALL_DATA_PROGRESS: string = "";             // "Install Data Progress"

    loadTranslations() {

        this.slGlobalService.saveCallStack("AdminComponent:loadTranslations");

        // the call was successful so we should be able to get the translation data
        var adminTranslationData = this.slGlobalService.getTranslations("ADMIN_COMPONENT");

        if (adminTranslationData) {
            this.NO_INSTALLS_FOUND = adminTranslationData.noinstallsfound;
            this.ASSOCIATION = adminTranslationData.association;
            this.TSAID = adminTranslationData.tsaid;
            this.STATUS = adminTranslationData.status;
            this.UNABLE_RETRIEVE = adminTranslationData.unabletoretrieve;
            this.INSTALL_SETTINGS = adminTranslationData.installsettings;
            this.PROCESSED_DATA = adminTranslationData.processeddata;
            this.AGENT_BILLING = adminTranslationData.agentbilling;
            this.KEY_AGREEMENT = adminTranslationData.keyagreement;
            this.INSTALL_TYPE = adminTranslationData.installtype;
            this.RETURNS_ACCEPTED = adminTranslationData.returnsaccepted;
            this.LOCKBOX_EXCHANGE_RATIO = adminTranslationData.lbexchangeratio;
            this.SEND_REG_INVITE = adminTranslationData.sendreginvite;
            this.START_PROCESSING = adminTranslationData.startprocessing;
            this.STOP_PROCESSING = adminTranslationData.stopprocessing;
            this.LAST_SYNC = adminTranslationData.lastsync;
            this.NO_DATA = adminTranslationData.nodata;
            this.NO = adminTranslationData.no;
            this.YES = adminTranslationData.yes;
            this.RETURNS_LB = adminTranslationData.lbreturn;
            this.RETURNS_KEY = adminTranslationData.keyreturn;
            this.RETURNS_CRADLE = adminTranslationData.cradlereturn;

            this.AGENT = adminTranslationData.agent;
            this.PROGRAMMING_LIST = adminTranslationData.programminglist;
            this.CARD = adminTranslationData.card;
            this.COMPANY = adminTranslationData.company;
            this.LBMFG = adminTranslationData.lbmfg;
            this.LBAUTH = adminTranslationData.lbauth;
            this.LOCKBOX = adminTranslationData.lockbox;
            this.REGION = adminTranslationData.region;
            this.USER = adminTranslationData.user;
            this.USER_PERMISSIONS = adminTranslationData.userpermissions;
            this.INSTALL_DATA_PROGRESS = adminTranslationData.installdataprocess;

        }

        this.InstallType = this.NO_DATA;
        this.ReturnsAccepted = this.NO_DATA;
        this.LockboxExRatio = this.NO_DATA;
        this.SendRegInvite = this.NO_DATA;
        this.SendRegInviteDate = this.NO_DATA;
        this.KeyAgreement = this.NO_DATA;
        this.AgentBilling = this.NO_DATA;
        this.acceptKeyReturns = this.NO_DATA;
        this.acceptCradleReturns = this.NO_DATA;
        this.acceptLockboxReturns = this.NO_DATA;

    }

    //Display correct menu information
    selectMenu(action) {

        this.slGlobalService.saveCallStack("AdminComponent:selectMenu");

        this.clearAllClasses();

        if (action == 'adduser') {
            this.addUserClass = "active";
            this.displayAddUser = true;
        } else if (action == 'settings') {
            this.settingsClass = "active";
            this.displaySettings = true;
        } else if (action == 'programming') {
            this.lockedQueueClass = "active";
            this.displayProgQueue = true;
        } else if (action == 'print') {
            this.testPrintClass = "active";
            this.displayTestPrint = true;
        } else if (action == 'installdata') {
            this.installDataClass = "active";
            this.displayData = true;
        } else if (action == 'reports') {
            this.reportsClass = "active";
            this.displayReports = true;
        } else if (action == 'dashboard') {
            this.dashboardClass = "active";
            this.displayDashboard = true;
        }

    }

    //Get the current installs that have data
    getCurrentInstalls() {

        this.slGlobalService.saveCallStack("AdminComponent:getCurrentInstalls");

        this.commonService
            .searchInstalls("0")
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("AdminComponent:setCurrentInstallList");

        this.installMessage = "";

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = installsArray.length;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if (installs.length == 1) {
            this.selectInstall(installsArray[0]);

            this.showInstallList = false;

        } else {

            this.showInstallList = true;

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("AdminComponent:selectInstall");

        this.selectedInstall = install;

        this.slGlobalService.setCurrentInstall(install.trainingscheduleid);
        this.slGlobalService.setCurrentInstallAssoc(install.assocname + ' - ' + install.title);

        this.installAssoc = install.assocname + ' - ' + this.selectedInstall.title;
        this.installTSAID = install.trainingscheduleid;

        if (install.allowuserreset == 1) {
            this.allowResetUsers = true;
        }

        this.showInstallList = false;

        this.settingsClass = "active";
        this.displaySettings = true;

    }

    //Close the user search dialog
    closeInstallSearch() {

        this.slGlobalService.saveCallStack("AdminComponent:closeInstallSearch");

        this.displayAddUser = true;
        this.displaySettings = false;
    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminComponent:handleError");

        if (errorMessage) {
            this.message = errorMessage;
        }
        else {
            this.message = "Unable to get translation data";
        }
    }

}