import {Injectable} from '@angular/core';
import {Response, Headers} from '@angular/http';

import {Observable} from 'rxjs';

import {Router, ActivatedRoute, Params} from '@angular/router';

import {SentriLockHttpService} from '../sl-http.service';
import {SentriLockGlobalService} from '../sl-global.service';
import {AuthService} from '../auth.service';
import {TypeaheadService} from '../typeahead.service';

import {User} from '../user';
import {Install} from '../install';
import {Carton} from './carton';
import {InstallData} from "../installdata";

@Injectable()
export class ProgrammingService extends TypeaheadService {

    message: string;

    email: string;
    mobile: string;
    username: string;
    password: string;

    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private route: ActivatedRoute,
                private router: Router,
                public slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        super(slHttpService, slGlobalService);

        this.slGlobalService.saveCallStack("ProgrammingService:constructor");
    }


    searchUsers(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:searchUsers");


        // we're making a real server call
        var searchField = '';
        var searchValue = '';

        if (firstName && (firstName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "FirstName";
            searchValue = searchValue + "%" + firstName + "%";
        }

        if (lastName && (lastName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "LastName";
            searchValue = searchValue + "%" + lastName + "%";
        }

        if (accountNumber && (accountNumber != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "CardSN";
            searchValue = searchValue + "%" + accountNumber + "%";
        }

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            searchfield: searchField,
            searchvalue: searchValue,
            searchcomparator: 'LI',
            orderby: 'LastName',
            /*lowercasekeys: 0, */
            ordervector: 'ASC',
            limit: 500
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/progq`, paramObject)
            .toPromise()
            .then(this.processUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    searchUserQueue(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:searchUserQueue");


        // turn the parameters into a string, leaving out any that are undefined or empty
        var inMemParamObject = {
            FirstName: firstName,
            LastName: lastName,
            CardSN: accountNumber
        };

        var paramString = this.slHttpService.objectToParametersString(inMemParamObject);

        // this call just gets made if we are using the in-memory api
        return this.slHttpService
            .get(`api/users/?${paramString}`)
            .toPromise()
            .then(this.processUsers.bind(this, inMemParamObject))
            .catch(this.handleError.bind(this));

    }

    private processUsers(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("ProgrammingService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    searchUsersNewOrder(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:searchUsers");


            // we're making a real server call
            var searchField = '';
            var searchValue = '';

            if (firstName && (firstName != "")) {

                if (searchField && (searchField != '')) {
                    searchField = searchField + ",";
                }

                if (searchValue && (searchValue != '')) {
                    searchValue = searchValue + ",";
                }

                searchField = searchField + "FirstName";
                searchValue = searchValue + "%" + firstName + "%";
            }

            if (lastName && (lastName != "")) {

                if (searchField && (searchField != '')) {
                    searchField = searchField + ",";
                }

                if (searchValue && (searchValue != '')) {
                    searchValue = searchValue + ",";
                }

                searchField = searchField + "LastName";
                searchValue = searchValue + "%" + lastName + "%";
            }

            if (accountNumber && (accountNumber != "")) {

                if (searchField && (searchField != '')) {
                    searchField = searchField + ",";
                }

                if (searchValue && (searchValue != '')) {
                    searchValue = searchValue + ",";
                }

                searchField = searchField + "CardSN";
                searchValue = searchValue + "%" + accountNumber + "%";
            }

            // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
            var paramObject = {
                searchfield: searchField,
                searchvalue: searchValue,
                searchcomparator: 'LI',
                orderby: 'LastName',
                /*lowercasekeys: 0, */
                ordervector: 'ASC',
                limit: 500
            };

            return this.slHttpService
                .getInMemPostServer(`kewe/agent/getagents`, paramObject)
                .toPromise()
                .then(this.processUsersNewOrder.bind(this, paramObject))
                .catch(this.handleError.bind(this));
    }



    private processUsersNewOrder(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("ProgrammingService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    getUserStatus(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchInstalls");

        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/agentstatus`, paramObject)
            .toPromise()
            .then(this.processUserStatus.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processUserStatus(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processUserStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    resendEmail(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("CheckinService:searchInstalls");



        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/resendregistrationemail`, paramObject)
            .toPromise()
            .then(this.processResendEmail.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processResendEmail(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("CheckinService:processUserStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "CheckinService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }


    selectUser(accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:selectUser");

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            cardsn: accountNumber
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/progq`, paramObject)
            .toPromise()
            .then(this.processSelectUser.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processSelectUser(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processSelectUser");

        var responseCode;
        var jsonData = {};

        if (response.status == '204') {
            responseCode = response.status;
            return responseCode;
        } else {

            try {
                jsonData = this.slHttpService.getJsonFromResponse(response);
            }
            catch (exception) {

                // send the exception to the server to be logged
                this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processFinishOrderResponse");
                // rethrow the exception
                throw exception;
            }

            return jsonData;
        }
    }

    releaseUser(accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:releaseUser");

        var paramObject = {
            clearcardsn: accountNumber
        };

        
        return this.slHttpService
            .getInMemPostServer(`kewe/account/progq`, paramObject)
            .toPromise()
            .then(this.processReleaseUser.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    // TODO {RSW} Can I make a generic one to process these?
    private processReleaseUser(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("ProgrammingService:processReleaseUser");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processReleaseUser");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }


    searchInstalls(): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:searchInstalls");

        // Send completed install as "1" so we we only get installs back that have data
        var paramObject = {
            completedonly: 1
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("ProgrammingService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

    finishOrder(cardsn: string, lbsn: any[]): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:finishOrder");

        // we're making a real server call
        // TODO: for now we are just using a single search parameter, once the server supports multiple we need to use all of them
        var paramObject = {
            cardsn: cardsn,
            lbsnlist: lbsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/lockboxassignments`, paramObject)
            .toPromise()
            .then(this.processFinishOrderResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processFinishOrderResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processFinishOrderResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processFinishOrderResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    lbOwnerCheck(lbsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:lbOwnerCheck");

        // we're making a real server call
        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            lbsn: lbsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/user/lockboxOwner`, paramObject)
            .toPromise()
            .then(this.processLBOwnerResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processLBOwnerResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processLBOwnerResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processLBOwnerResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    saveLBR(lbr: string, notes: string, cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:saveLBR");

        // we're making a real server call
        var paramObject = {
            modifyorder: 1,
            lockboxcount: lbr,
            notes: notes,
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/accountlockboxes`, paramObject)
            .toPromise()
            .then(this.processSaveLBRResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processSaveLBRResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processSaveLBRResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processSaveLBRResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    createOrder(lbr: string, notes: string, cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:saveLBR");

        var paramObject = {
            neworder: 1,
            lockboxcount: lbr,
            notes: notes,
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/accountlockboxes`, paramObject)
            .toPromise()
            .then(this.processSaveCreateOrderResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processSaveCreateOrderResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processSaveLBRResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processSaveLBRResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    saveNotes(notes: string, cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:saveNotes");
        // we're making a real server call
        var paramObject = {
            notes: notes,
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/account/accountlockboxes`, paramObject)
            .toPromise()
            .then(this.processSaveNoteResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processSaveNoteResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processSaveNoteResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processSaveNoteResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    authCheck(userName: string, password: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:authCheck");
        // we're making a real server call
        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            username: userName,
            password: password,
            nosession: true
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/login/login`, paramObject)
            .toPromise()
            .then(this.processAuthResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processAuthResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("ProgrammingService:processAuthResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processAuthResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    searchCartonData(cartonid: string): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:searchCartonData");

        var paramObject = {
            cartonid: cartonid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/lockbox/getcartoncontents`, paramObject)
            .toPromise()
            .then(this.processCartonData.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processCartonData(origParamObject, response): Carton[] {

        this.slGlobalService.saveCallStack("ProgrammingService:processCartonData");

        var cartonArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                cartonArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "ProgrammingService:processCartonData");
            // rethrow the exception
            throw exception;
        }

        return cartonArray as Carton[];
    }


    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("ProgrammingService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

}