import { Component, SimpleChanges } from '@angular/core';
import { OnInit, OnChanges } from '@angular/core';
import { Router }      from '@angular/router';

import { SentriLockGlobalService } from './sl-global.service';

import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './sl-main.component.html',
  styleUrls: [ './sl-main.component.css' ]
})
export class SLMainComponent implements OnInit, OnChanges {

  title = 'Key Exchange Web';

  // language constants from MAIN
  WELCOME: string = "";            // "Welcome,"
  PROGRAMMING: string = "";            // "PROGRAMMING"
  CHECKIN: string = "";            // "CHECKIN"
  ADMIN: string = "";            // "Service Providers"
  ASSOCIATION: string = "";            // "Service Providers"
  LOCKBOXES: string = "";            // "Lockboxes"
  PASSWORD_AND_PIN: string = "";            // "Password & Pin"
  SIGN_OUT: string = "";            // "Sign Out"
  KEWE_LOWERCASE: string = "";            // "kewe"
  SEARCH: string = "";            // "Search"
  DB_HOST: string = "";            // "Search"

  checkInClass: string = "";
  adminClass: string = "";
  assocClass: string = "";
  programmingClass: string = "";
  profileClass: string = "";
  searchClass: string = "";

  //Show hide the left menu
  showLeftMenuBool: boolean = true;
  leftMenuDisplayType: string = "table";

  // the ID of the Service Provider the logged in user belongs to (used for routing the user directly to the service provider
  // if they don't have access to the Service Provider list screen)
  loggedInUsersSPID: string = "";

  // the ID of the logged in user(used for routing the user directly to their user information
  // if they don't have access to the User Management list screen)
  loggedInUsersID: string = "";

  loggedInUsersType: string = "";

  loggedInUsersPhoto: string = "";

  loggedInUsersNewPhoto: string = "";

  loggedInUsersLeftPhoto: string = "";
  loggedInUsersRightPhoto: string = "";

  loggedInUsersPermissionDescription: string = "";


  // we need to get the users name and role to display in the navigation menu
  firstName: string = "";
  lastName: string = "";
  userRole: string = "";

  isSysAdmin: boolean = false;
  isInstallAdmin: boolean = false;
  isAssocAdmin: boolean = false;
  isProgrammingStation: boolean = false;
  isCheckinStation: boolean = false;


  constructor(public authService: AuthService,
              public router: Router,
              public slGlobalService: SentriLockGlobalService) {

    this.slGlobalService.saveCallStack("SLMainComponent:constructor");

    router.events.subscribe((val) => {

      // We need to change with section is currently active so that we can highlight the proper button on the side
      // navigation.  We tried using [routerLinkActive]="['selected-link']" to do that but that doesn't work once you
      // navigate to the sub page within the section (i.e. go from the list screen to the details screen).  Then the
      // highlighting got lost.
      this.clearAllClasses();

      if (val && val.toString().includes("checkin")) {

        this.checkInClass = "active";
      }
      else if (val && val.toString().includes("programming")) {

        this.programmingClass = "active";
      }
      else if (val && val.toString().includes("admin")) {

        this.adminClass = "active";
      }
      else if (val && val.toString().includes("association")) {

        this.assocClass = "active";
      }
      else if (val && val.toString().includes("profile")) {

        this.profileClass = "active";
      }
      else if (val && val.toString().includes("search")) {

        this.searchClass = "active";
      }

    });

  }

  clearAllClasses(): void {

    this.checkInClass = "";
    this.adminClass = "";
    this.assocClass = "";
    this.programmingClass = "";
  }

  ngOnInit(): void
  {
    this.slGlobalService.saveCallStack("SLMainComponent:ngOnInit");

    // we need to see if this users permissions indicate they are a System Admin
    this.isSysAdmin = this.slGlobalService.isSysAdmin();
    this.isInstallAdmin = this.slGlobalService.isInstallAdmin();
    this.isAssocAdmin = this.slGlobalService.isAssocAdmin();
    this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
    this.isCheckinStation = this.slGlobalService.isCheckinStation();
    this.DB_HOST = this.slGlobalService.getDBHost();

      // load the translations
    this.loadTranslations();

    this.loggedInUsersPermissionDescription = this.slGlobalService.getPermissionDescription();

    // we need to know the ID of the logged in user incase we need that for making a call to get user data directly
    this.loggedInUsersID = this.slGlobalService.getLoggdInUserID();

    this.loggedInUsersType = this.slGlobalService.getLoggdInUsersType();

    //get logged in users avatar
    this.loggedInUsersPhoto = this.slGlobalService.getLoggdInUserPhoto();

    // we need to get the users name and role to display in the navigation menu
    this.firstName = this.slGlobalService.getLoggdInUserFirstName();
    this.lastName = this.slGlobalService.getLoggdInUserLastName();
    this.userRole = this.slGlobalService.getUserRole();
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.slGlobalService.saveCallStack("SLMainComponent:ngOnChanges");

    this.loggedInUsersPhoto = this.slGlobalService.getLoggdInUserPhoto();

  }

  showHideMenu() {

    this.slGlobalService.saveCallStack("SLMainComponent:showHideMenu");

    this.showLeftMenuBool = !this.showLeftMenuBool;

    if (this.showLeftMenuBool) {
        this.leftMenuDisplayType = "table";
    }
    else {
        this.leftMenuDisplayType = "none";
    }
  }

  loadTranslations(): void {

    this.slGlobalService.saveCallStack("SLMainComponent:loadTranslations");

    // get the patient translation data
    var mainTranslationData = this.slGlobalService.getTranslations('MAIN_COMPONENT');

    if (mainTranslationData) {

      this.WELCOME = mainTranslationData.welcome;
      this.PROGRAMMING = mainTranslationData.programming;
      this.CHECKIN = mainTranslationData.checkin;
      this.ADMIN = mainTranslationData.admin;
      this.ASSOCIATION = mainTranslationData.association;
      this.LOCKBOXES = mainTranslationData.lockboxes;
      this.PASSWORD_AND_PIN = mainTranslationData.passwordandpin;
      this.SIGN_OUT = mainTranslationData.signout;
      this.KEWE_LOWERCASE = mainTranslationData.kewelowercase;
      this.SEARCH = mainTranslationData.search;

    }
  }

  // this funcction gets called when the Logout button is clicked and logs the user out
  logout(): void {

    this.slGlobalService.saveCallStack("SLMainComponent:logout");

    // TODO: make a server call to let the server know the user has logged out
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
