import { Component, ViewChild, ElementRef } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// import { ModalDirective } from 'ngx-bootstrap';

import { SentriLockGlobalService } from '../sl-global.service';

import { Error } from './error';
import { ErrorService } from './error.service';

@Component({
    selector: 'my-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnDestroy {

    hostName: string = "";

    constructor(
        private errorService: ErrorService,
        private router: Router,
        private slGlobalService: SentriLockGlobalService) {

    }



    ngOnInit(): void {
        this.slGlobalService.saveCallStack("ErrorComponent:ngOnInit");

        //this.hostName = this.slGlobalService.getHostnameWithProtocol();
    }

    ngOnDestroy(): void {
        this.slGlobalService.saveCallStack("ErrorComponent:ngOnDestroy");
    }


}