
import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';
import { Component,Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AdminService } from './admin.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { ValidatorService } from '../validator.service';
import { AuthService } from '../auth.service';

//Form Validation
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { User } from '../user';

@Component({
    selector: 'adminprog',
    templateUrl: './admin-prog.component.html',
    styleUrls: [ './admin-prog.component.css'],
    providers: [AuthService]
})
export class AdminProgComponent implements OnInit, OnDestroy {

    private updateQueueSub: Subscription;

    // the tsa id for the install
    @Input() tsaid: string;
    @Input() tsaname: string;

    format: any;
    parse: any;

    //Set the color to black
    messageColor: string = '#000';
    message: string = '';

    users;

    myUser: User = new User();

    displayNoUserFound: boolean = false;
    displayNoInstallsFound: boolean = false;

    installMessage: string = "";

    // first name search box
    firstNameSearchValue: string = "";

    // last name search box
    lastNameSearchValue: string = "";

    // account number search box
    accountNumberSearchValue: string = "";

    constructor(
        public fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private slGlobalService: SentriLockGlobalService,
        public  validatorService: ValidatorService,
        private adminService: AdminService) {

        this.slGlobalService.saveCallStack("AdminProgComponent:constructor");


    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:ngOnInit");

        // load the translations
        this.loadTranslations();

        this.searchUsers();

        //Reload the users every second
        this.updateQueueSub = observableInterval(60000).subscribe(x => {
            this.searchUsers();
        });

    }

    ngOnDestroy(): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:ngOnDestroy");

        this.updateQueueSub.unsubscribe();
    }

    UNABLE_RETRIEVE: string = "";             // "Enter or Scan LBSN"
    NO_USERS_FOUND: string = "";             // "Enter or Scan LBSN"

    loadTranslations(): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:loadTranslations");

        // get the user translation data
        var adminTranslationData = this.slGlobalService.getTranslations('ADMIN_COMPONENT');

        if (adminTranslationData) {

        }

        // get the common translation data
        var programmingTranslationData = this.slGlobalService.getTranslations('PROGRAMMING_COMPONENT');

        if (programmingTranslationData) {

            this.NO_USERS_FOUND = programmingTranslationData.nousersfound;

        }

    }

    // runs the search when the search button is clicked
    searchUsers(): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:searchUsers");

        this.adminService
            .searchUsers(this.firstNameSearchValue, this.lastNameSearchValue, this.accountNumberSearchValue, this.tsaid)
            .then(this.setUserData.bind(this))
            .catch(this.displayError.bind(this));

    }

    setUserData(users): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:setUserData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;

        // add the "Edit" icon to each object in the array so it will show up in the table
        var arrayLength = userArray.length;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoUserFound = true;
        }
        else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoUserFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFound = false;
        }

        this.users = userArray;

        //make the check in time the modified time
        for (let user of this.users) {
            user.UTCModified = this.slGlobalService.getLocaleDateFromString(user.UTCModified);
        }

    }

    //Select user from list
    unlockUser(user) {

        this.slGlobalService.saveCallStack("AdminProgComponent:unlockUser");

        //Release the user back into the queue
        this.adminService
            .releaseUser(user.CardSN, this.tsaid, user.ProgrammingQueueID)
            .then(this.setUnlockUser.bind(this))
            .catch(this.displayError.bind(this));


    }

    setUnlockUser(users): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:setUnlockUser");

        this.searchUsers();

    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("AdminProgComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.installMessage = errorMessage;
        }
        else {
            this.installMessage = this.UNABLE_RETRIEVE;
        }

    }


}