/*import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';

@Injectable()
export class AuthService {
    isLoggedIn: boolean = false;

    // store the URL so we can redirect after logging in
    redirectUrl: string;

    login(): Observable<boolean> {
        return Observable.of(true).delay(1000).do(val => this.isLoggedIn = true);
    }

    logout(): void {
        this.isLoggedIn = false;
    }
}*/

import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';





import { SentriLockGlobalService } from './sl-global.service';
import { SentriLockHttpService } from './sl-http.service';


@Injectable()
export class AppService
{
    // the message returned from the server
    message: string;

    private loginUrl = 'kewe/login';  // URL to web api
    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private slHttpService: SentriLockHttpService,
                private slGlobalService: SentriLockGlobalService) {

        this.slGlobalService.saveCallStack("AppService:constructor");
    }

    deleteRecord(subUrl: string, id: string): Promise<any> {

        this.slGlobalService.saveCallStack("AppService:deleteRecord");

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService.delete(`kewe/${subUrl}/${id}`)
            .toPromise()
            .then(this.processDeleteResponse.bind(this))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleError.bind(this));
    }

    private processDeleteResponse(response): boolean {

        this.slGlobalService.saveCallStack("AppService:processDeleteResponse");

        // if we get to this function then the delete was successful
        return true;
    }

    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("AppService:handleError");
        //alert("Error in searchProperties");

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = "Unable to process request please try again in a few minutes.";
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = "Unable to process request please try again in a few minutes.";
            }
        }
        catch (err) {
            errorMessage = "Unable to process request please try again in a few minutes.";
        }

        return Promise.reject(errorMessage);
    }
/*
    // this function will send an error message and stack trace back to the server so it can be logged
    public logException(errorMessage: string, stackTrace: string): void {

        //we want to send back the user agent string so we know what browser the error happened in
        var userAgent = "";

        if (window && window.navigator && window.navigator.userAgent) {
            userAgent = window.navigator.userAgent;
        }

        var paramObject = {
            errorMessage: errorMessage,
            stackTrace: stackTrace,
            userAgent: userAgent
        };

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        this.slHttpService.getInMemPostServer(`kewe/logException`, paramObject)
            .toPromise()
            .then(this.processLogExceptionResponse.bind(this))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleLogExceptionError.bind(this));
    }

    private processLogExceptionResponse(response): void {

        // the exception was successfully logged on the server, there is nothing else for us to do
    }

    private handleLogExceptionError(error: any): void {

        // if we got an exception while trying to log the excpetion there is nothing we can do just ignore it
    }
*/
}

