import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard }                from '../auth-guard.service';

import { SLMainComponent } from '../sl-main.component';

import { SearchComponent }      from './search.component';

const searchRoute: Routes = [
    { path: 'search',
        component: SLMainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: SearchComponent }

        ]
    },
    { path: '', redirectTo: '/search', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forChild(searchRoute) ],
    exports: [ RouterModule ]
})
export class SearchRoutingModule {}