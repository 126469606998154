import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { SentriLockGlobalService } from './sl-global.service';

export class ValidatorService
{
    constructor() { }

    private EMAIL_ADDRESS_REGEX: RegExp = new RegExp(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i);
    private NO_SPECIAL_CHAR_REGEX: RegExp = new RegExp(/^[a-zA-Z0-9- ]*$/);
    private HAS_UPPER_CASE_REGEX: RegExp = new RegExp(/[A-Z]/);
    private HAS_LOWER_CASE_REGEX: RegExp = new RegExp(/[a-z]/);
    private HAS_NUMBER_REGEX: RegExp = new RegExp(/\d/);
    private IS_NUMERIC_REGEX: RegExp = new RegExp(/^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/);
    private HAS_WHITE_SPACE_REGEX: RegExp = new RegExp(/\s/);

    // SINGLE FIELD VALIDATORS
    emailValidator(control: FormControl): {[key: string]: any} {
        var emailRegexp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (control.value && !emailRegexp.test(control.value)) {
            return { invalidEmail: true };
        }
    }

    public isEmailAddressValid(emailAddress: string): boolean {
        return this.EMAIL_ADDRESS_REGEX.test(emailAddress);
    }

    //Check if field has a special, error message if special character
    specialCharacterValidator(control: FormControl): {[key: string]: any} {
        var specRegexp = /^[a-zA-Z0-9- ]*$/;

        if (control.value && !specRegexp.test(control.value)) {
            return { specialcharacter: true };
        }
    }

    public hasSpecialCharacter(stringToTest: string): boolean {
        return !this.NO_SPECIAL_CHAR_REGEX.test(stringToTest);
    }

    //Verify string has an upper case character
    upperCaseValidator(control: FormControl): {[key: string]: any} {

        var isUpperCase = /[A-Z]/;

        if (control.value && !isUpperCase.test(control.value)) {
            return { nouppercase: true };
        }
    }

    public hasUpperCaseCharacter(stringToTest: string): boolean {
        return this.HAS_UPPER_CASE_REGEX.test(stringToTest);
    }

    //Verify string has a lower case character
    lowerCaseValidator(control: FormControl): {[key: string]: any} {

        var isLowerCase = /[a-z]/;

        if (control.value && !isLowerCase.test(control.value)) {
            return { nolowercase: true };
        }
    }

    public hasLowerCaseCharacter(stringToTest: string): boolean {
        return this.HAS_LOWER_CASE_REGEX.test(stringToTest);
    }

    //Verify string has an upper case character
    hasNumberValidator(control: FormControl): {[key: string]: any} {

        var hasNumericChar = /\d/;

        if (control.value && !hasNumericChar.test(control.value)) {
            return { nonumber: true };
        }
    }

    public hasNumber(stringToTest: string): boolean {
        return this.HAS_NUMBER_REGEX.test(stringToTest);
    }

    //Check if string is numeric, first make sure there are no spaces
    isNumericValidator(control: FormControl): {[key: string]: any} {

        var isSpaces = /\s/;
        var isNumeric = /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/;

        if(control.value && isSpaces.test(control.value)) {
            return { isspaces: true };
        } else {
            if (control.value && !isNumeric.test(control.value)) {
                return { isnotnumeric: true };
            }
        }


    }

    public isNumeric(stringToTest: string): boolean {
        return this.IS_NUMERIC_REGEX.test(stringToTest);
    }

    public hasWhiteSpace(stringToTest: string): boolean {
        return this.HAS_WHITE_SPACE_REGEX.test(stringToTest);
    }

    // This function checks to see if a the passed in password is valid.  If it is then an empty string is returned.
    // If it isn't an error message is returned.
    public validatePassword(password: string, skGlobalService: SentriLockGlobalService): string {

        var errorMsg = "";

        var translationData = skGlobalService.getTranslations('USER_COMPONENT');

        var passwordErrorMessage = "";

        if (translationData) {
            if (!password || (password == "")) {
                passwordErrorMessage = translationData.passwordrequired;
            }
            else if (!this.hasLowerCaseCharacter(password)) {
                passwordErrorMessage = translationData.passwordnolower;
            }
            else if (!this.hasUpperCaseCharacter(password)) {
                passwordErrorMessage = translationData.passwordnoupper;
            }
            else if (!this.hasNumber(password)) {
                passwordErrorMessage = translationData.passwordnonumber;
            }
            else if (password.length < 6) {
                passwordErrorMessage = translationData.passwordminlength;
            }
        }

        return passwordErrorMessage;
    }

    // Check if Pin start with zero
    zeroPin(control: FormControl): {[key: string]: any} {

        if (String(control.value).charAt(0) == '0' ) {
            return { isZero: true };
        }

    }

    // Check if Pin start with zero
    sequentialPin(control: FormControl): {[key: string]: any} {

        var sequentialPin1234 = ["1234","2345","3456","4567","5678","6789","7890","12345","23456","34567","45678","56789","67890","123456","234567","345678","456789","567890"];
        var sequentialPin4321 = ["9876","8765","7654","6543","5432","4321","3210","98765","87654","76543","65432","54321","43210","987654","876543","765432","654321","543210"];

        if(sequentialPin1234.indexOf(control.value) > -1 || sequentialPin4321.indexOf(control.value) > -1) {
            return {seqPin1234: true};
        }
    }

    // Check if Pin start with zero
    repeatingPin(control: FormControl): {[key: string]: any} {

        var sequentialPin4 = ["1111","2222","3333","4444","5555","6666","7777","8888","9999"];
        var sequentialPin5 = ["11111","22222","33333","44444","55555","66666","77777","88888","99999"];
        var sequentialPin6 = ["111111","222222","333333","444444","555555","666666","777777","888888","999999"];

        if(sequentialPin4.indexOf(control.value) > -1 || sequentialPin5.indexOf(control.value) > -1 || sequentialPin6.indexOf(control.value) > -1) {
            return {repeatingPin: true};
        }
    }


    // FORM GROUP VALIDATORS
    matchingPasswordPin(passwordKey: string, confirmPasswordKey: string, pinKey: string, confirmPinKey: string) {
        return (group: FormGroup): {[key: string]: any} => {
            let password = group.controls[passwordKey];
            let confirmPassword = group.controls[confirmPasswordKey];

            let pin = group.controls[pinKey];
            let confirmPin = group.controls[confirmPinKey];

            if (pin.value !== confirmPin.value && password.value !== confirmPassword.value) {
                return { mismatchedPassPin: true };
            } else if (password.value !== confirmPassword.value && pin.value == confirmPin.value) {
                return { mismatchedPasswords: true };
            } else if (pin.value !== confirmPin.value && password.value == confirmPassword.value) {
                return { mismatchedPin: true };
            }

        }
    }

    matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
        return (group: FormGroup): {[key: string]: any} => {
            let password = group.controls[passwordKey];
            let confirmPassword = group.controls[confirmPasswordKey];

            if (password.value !== confirmPassword.value) {
                return {
                    mismatchedPasswords: true
                };
            }
        }
    }

     matchingPins(pinKey: string, confirmPinKey: string) {
         return (group: FormGroup): {[key: string]: any} => {
            let pin = group.controls[pinKey];
            let confirmPin = group.controls[confirmPinKey];

             if (pin.value !== confirmPin.value) {
                return {
                    mismatchedPin: true
                };
             }

         }
     }

}