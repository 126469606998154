// /app/sk-error.handler.ts
import { ErrorHandler, Injectable } from '@angular/core';
import { Headers, Http, URLSearchParams } from '@angular/http';

import { CustomQueryEncoderHelper } from './custom-query-encoder-helper';



@Injectable()
export class SentriLockErrorHandler extends ErrorHandler {

    constructor(private http: Http) {

        super();
    }

    handleError(error:any) {

        // since we can't access our Angular services from the error handler (because it creates a circular dependency) so we have
        // to check the sessionStorage directly
        var logErrors = true;
        var sid = "";

        if (sessionStorage.getItem("sendErrorsToServer")) {
            var sendErrorsToServerStr = sessionStorage.getItem("sendErrorsToServer");

            if (sendErrorsToServerStr === "true") {
                logErrors = true;
            }
            else {
                logErrors = false;
            }
        }

        if (sessionStorage.getItem("sessionId")) {

            sid = sessionStorage.getItem("sessionId");
        }


        // get the stack trace and error message, then make the server call so we can log that data
        if (error && logErrors) {

            var stackTrace = "";
            var errorMessage = "";

            if (error.message) {
                errorMessage = "Error Message: " + error.message;
            }

            var loggedInUserId = sessionStorage.getItem("loggedInUserID");

            if (loggedInUserId) {
                errorMessage = errorMessage + " UserID = " + loggedInUserId;
            }

            // add in our own internal callstack
            if (sessionStorage.getItem("callStack")) {
                stackTrace = stackTrace + sessionStorage.getItem("callStack");
            }

            // add in the call stack from the error
            if (error.stack) {
                stackTrace = stackTrace + " -- " + error.stack;
            }


            //we want to send back the user agent string so we know what browser the error happened in
            var userAgent = "";

            if (window && window.navigator && window.navigator.userAgent) {
                userAgent = window.navigator.userAgent;
            }

            var paramObject = {
                errormessage: errorMessage,
                stacktrace: stackTrace,
                useragent: userAgent,
                sid: sid
            };

            var paramString = this.objectToUriString(paramObject);

            // set the HTTP headers
            var headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
            this.http.post(this.getHostnameWithProtocol() + `kewe/app/exception`, paramString, {headers: headers})
                .toPromise()
                .then(this.processLogExceptionResponse.bind(this))
                //.then(response => this.isLoggedIn = response.json().data.valid)
                .catch(this.handleLogExceptionError.bind(this));
        }

        // now call the base class function to write the data to the console
        super.handleError(error);
    }

    private processLogExceptionResponse(response): void {

        // the exception was successfully logged on the server, there is nothing else for us to do
    }

    private handleLogExceptionError(error: any): void {

        // if we got an exception while trying to log the excpetion there is nothing we can do just ignore it
    }

    /*
     * The URL that serves the angular files begin with "sa-" while the URL for the API calls
     * begins with "saapi-".  This function is used for getting the URL for the API calls so
     * need to replace "sa-" with "saapi-".
     * */
    public getHostnameWithProtocol(): string {

        var hostnameWithProtocol = "";

        // if we have an override URL we want to use that instead of the URL in the browser
        var hostname = "";
        var protocol = "https:";

        if (!hostname || (hostname == "")) {

            // there was no override hostname so use the hostname from the URL
            if (window && window.location && window.location.hostname) {

                hostname = window.location.hostname
            }

            if (window && window.location && window.location.protocol) {

                protocol = window.location.protocol
            }
        }

        if (hostname) {

            // The URL where you make the API calls to will contain "api" right before the first "." or first "-",
            // whichever appears earlier in the URL.
            var indexOfDot = hostname.indexOf(".");
            var indexOfDash = hostname.indexOf("-");

            if ((indexOfDash != -1) && (indexOfDash < indexOfDot)) {
                // the dash "-" is first
                hostnameWithProtocol = protocol + "//" + hostname.replace("-", "api-") + "/";
                //hostnameWithProtocol = "https:" + "//" + hostname + "/";
                //hostnameWithProtocol = "http:" + "//" + hostname + "/";
            }
            else {
                // either there is no "-" or the "." is first
                hostnameWithProtocol = protocol + "//" + hostname.replace(".", "api.") + "/";
                //hostnameWithProtocol = "https:" + "//" + hostname + "/";
                //hostnameWithProtocol = "http:" + "//" + hostname + "/";
            }

        }

        return hostnameWithProtocol;
    }

    /*
     * This function takes an Object and turns it into a URI encoded string*/
    objectToUriString(paramObject: Object): string {

        let searchParams = new URLSearchParams('', new CustomQueryEncoderHelper());

        // we will get the keys of all of the properties of the parameters object, then loop through those keys to
        // get the values and build up the parameters string
        var keyArray = Object.keys(paramObject);

        for (var i = 0; i < keyArray.length; i++) {

            var key = keyArray[i];

            if ((paramObject[key] !== undefined) && (paramObject[key] !== null) && (paramObject[key] !== "")) {

                // there IS an actual value for this parameter so we want to include it in the parameters string.
                // If it's an object or Array we need to turn it into a JSON string
                if ((paramObject[key].constructor === Object) || (paramObject[key].constructor === Array)) {
                    searchParams.set(key, JSON.stringify(paramObject[key]));
                }
                else {
                    searchParams.set(key, paramObject[key]);
                }

            }
        }

        return searchParams.toString();
    }
}