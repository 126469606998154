import { Injectable }       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
}                           from '@angular/router';
import { AuthService }      from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        // we need to verify that the user is logged in AND that they don't have an End User License Agreement that they
        // need to sign
        if (this.authService.isLoggedIn()) {
            return true;
        }

        // Store the attempted URL for redirecting
        this.authService.setRedirectUrl(url);

        // The user isn't logged in yet so navigate to the login page
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['/login']);
        }

        return false;
    }
}
