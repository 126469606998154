export class SearchFields {
    propertyFirstName: string = "";
    propertyLastName: string = "";
    propertyIDN: string = "";
    propertyId: string = "";

    propertyName: string = "";
    address: string = "";
    town: string = "";
    postalCode: string = "";

    userStatus: string = "";
    companyName: string = "";
    companyIDN: string = "";
    companyId: string = "";
    service: string = "";
    lockboxSN: string = "";
    propertyManagerName: string = "";
    propertyManagerId: string = "";
    startDate: Date = new Date(new Date().setDate(new Date().getDate()-30));  // initialize the start date to be 30 days in the past
    endDate: Date = new Date();
    reportType: string = "";
  
    accessType: string = "";
    includedCompleted: boolean = false;
    includeHidden: boolean = false;
    includeCancelled: boolean = false;
    includeExpired: boolean = false;

    userFirstName: string = "";
    userLastName: string = "";
    association: string = "";
    accountNumber: string = "";
}