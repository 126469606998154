<div class="top-bar">
    <div class="menu">
        <div class="logo">
            <div class="small-logo">{{KEWE_LOWERCASE}}</div>
        </div>

        <div class="version">v1.21.0 &nbsp;&nbsp;&nbsp;{{DB_HOST}}</div>

        <div class="menu-items">
            <ul>
                <a href="#" [routerLink]="['/checkin']" class="{{checkInClass}}" >
                    <li *ngIf="isSysAdmin || isInstallAdmin || isCheckinStation" class="{{checkInClass}} text">
                        {{CHECKIN}}
                    </li>
                </a>
                <a href="#" [routerLink]="['/programming']" class="{{programmingClass}}" >
                    <li *ngIf="isSysAdmin || isInstallAdmin || isProgrammingStation" class="{{programmingClass}} text">
                        {{PROGRAMMING}}
                    </li>
                </a>
                <a href="#" [routerLink]="['/search']" class="{{searchClass}}" >
                    <li *ngIf="isSysAdmin || isInstallAdmin || isProgrammingStation" class="{{searchClass}} text">
                        {{SEARCH}}
                    </li>
                </a>
                <a *ngIf="isAssocAdmin || isSysAdmin || isInstallAdmin" href="#" [routerLink]="['/association']" class="{{assocClass}}" >
                    <li class="{{assocClass}} text ">
                        {{ASSOCIATION}}
                    </li>
                </a>
                <a *ngIf="isSysAdmin || isInstallAdmin" href="#" [routerLink]="['/admin']" class="{{adminClass}}" >
                    <li class="{{adminClass}} text ">
                        {{ADMIN}}
                    </li>
                </a>

            </ul>

        </div>

        <div class="profile-img">
            <ul>
                <a href="#" [routerLink]="['/profile']" class="{{profileClass}}" >
                    <li class="{{profileClass}} img">
                        <img class="profile" src="images/profile-side-v2.svg" >
                    </li>
                </a>
            </ul>
        </div>

    </div>
</div>

<div class="main-content">
    <router-outlet></router-outlet>
</div>