import {Injectable} from '@angular/core';
import {Response, Headers} from '@angular/http';

import {Observable} from 'rxjs';

import {Router, ActivatedRoute, Params} from '@angular/router';

import {SentriLockHttpService} from '../sl-http.service';
import {SentriLockGlobalService} from '../sl-global.service';
import {AuthService} from '../auth.service';
import {TypeaheadService} from '../typeahead.service';
import { User } from "../user";
import { Install } from "../install";
import { UserStatus } from "../userstatus";

@Injectable()
export class SearchService extends TypeaheadService {

    message: string;

    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private route: ActivatedRoute,
                private router: Router,
                public slHttpService: SentriLockHttpService,
                public slGlobalService: SentriLockGlobalService) {

        super(slHttpService, slGlobalService);

        this.slGlobalService.saveCallStack("SearchService:constructor");
    }

    searchUsers(firstName: string, lastName: string, accountNumber: string): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:searchUsers");

        // we're making a real server call
        var searchField = '';
        var searchValue = '';

        if (firstName && (firstName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "FirstName";
            searchValue = searchValue + "%" + firstName + "%";
        }

        if (lastName && (lastName != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "LastName";
            searchValue = searchValue + "%" + lastName + "%";
        }

        if (accountNumber && (accountNumber != "")) {

            if (searchField && (searchField != '')) {
                searchField = searchField + ",";
            }

            if (searchValue && (searchValue != '')) {
                searchValue = searchValue + ",";
            }

            searchField = searchField + "CardSN";
            searchValue = searchValue + "%" + accountNumber + "%";
        }

        // TODO: for now we are just using a single search paramater, once the server supports multiple we need to use all of them
        var paramObject = {
            searchfield: searchField,
            searchvalue: searchValue,
            searchcomparator: 'LI',
            orderby: 'LastName',
            ordervector: 'ASC',
            limit: 30/*,
                 fieldlist: "FirstName,LastName,IDN,PatientStatus"*/
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/getagents`, paramObject)
            .toPromise()
            .then(this.processUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processUsers(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("SearchService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") &&  response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    getUserStatus(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:getUserStatus");

        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/agentstatus`, paramObject)
            .toPromise()
            .then(this.processUserStatus.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processUserStatus(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("SearchService:processUserStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "SearchService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    searchInstalls(): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:searchUsers");

        // Send completed install as "1" so we we only get installs back that have data
        var paramObject = {
            completedonly: 1
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("SearchService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

    activityReport(tsaid: string, agentid: string, cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:activityReport");

        // Send completed install as "0" so we we only get all installs
        var paramObject = {
            tsaid: tsaid,
            agentid: agentid,
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/useractivity`, paramObject)
            .toPromise()
            .then(this.processActivityReport.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processActivityReport(origParamObject, response): UserStatus[] {

        this.slGlobalService.saveCallStack("SearchService:processActivityReport");

        var userStatusArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json()) {
                userStatusArray = response.json();
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return userStatusArray as UserStatus[];
    }

    lbOwnerCheck(lbsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:searchUsers");

        var paramObject = {
            lbsn: lbsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/lockbox/inquiry`, paramObject)
            .toPromise()
            .then(this.processLBOwnerResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processLBOwnerResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("SearchService:processLBOwnerResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "SearchService:processLBOwnerResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    resendEmail(cardsn: string): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:searchInstalls");

        var paramObject = {
            cardsn: cardsn
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/agent/resendregistrationemail`, paramObject)
            .toPromise()
            .then(this.processResendEmail.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processResendEmail(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("SearchService:processUserStatus");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "SearchService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }


    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("SearchService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

}