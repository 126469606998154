<div class="login-page">
    <!-- Top Menu -->
    <div class="login-top" role="navigation">
        <div class="login-top-container">
        </div>
    </div>

    <!-- Top Logo -->
    <div class="login-logo">
        <img src="images/sl_logo.png" alt="SentriKey" class="sentrikey-login-img" />
    </div>

    <div class="page-not-found">
        <p class="pnf-text">Page Not Found</p>
    </div>

</div>