import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class DefaultTranslationService
{

    private defaultTranslations = {
        "COMMON_COMPONENT": {
            "genericerror": "Unable to process request please try again in a few minutes.",
            "typeaheaderror": "An error occurred, no results to display",
            "unableretrieve": "Unable to retrieve data",
            "noresults": "No results to display.",
            "yes": "Yes",
            "no": "No",
            "none": "None",
            "status": "Status",
            "active": "Active",
            "inactive": "Inactive",
            "onlyfirst": "Only the first",
            "filterresults": "results are being displayed. If you don't see the information you are looking for please filter your results.",
            "noaccess": "You do not have access to view this page.",
            "goback": "Go Back",
            "name": "Name",
            "authorized": "Authorized",
            "unauthorized": "Unauthorized",
            "select": "Select",
            "action": "Action",
            "save": "Save",
            "nomatches": "No Matches",
            "emailaddress": "Email Address",
            "search": "Search",
            "clearsearch": "Clear Search",
            "isrequired": "is required",
        },
        "LOGIN_COMPONENT": {
            "emailaddress": "Email Address",
            "password": "Password",
            "forgotpassword": "Help, I forgot my password",
            "signin": "Log In",
            "emailaddressnotblank": "Email address cannot be blank.",
            "passwordnotblank": "Password cannot be blank.",
            "trylogin": "Trying to log in",
            "unablelogin": "Unable to log in",
            "kewelogo": "kewe"
        },
        "CHECKIN_COMPONENT": {
            "accountnumber": "Account Number",
            "company": "Company",
            "firstname": "First Name",
            "lastname": "Last Name",
            "enterscan": "Enter or Scan LBSN",
            "unableretrieve": "Unable to retrieve data",
            "authorize": "Authorize",
            "usersearch": "User Search",
            "name": "Name",
            "complete": "Complete",
            "association": "Association",
            "nousersfound": "No users found",
            "noinstallsfound": "No installs found",
            "lockboxestoreceive": "Number of Lockboxes to receive",
            "modifyorder": "Modify Order",
            "lockboxestoremove": "Lockboxes to remove",
            "scanlockboxes": "Scan Lockboxes",
            "notes": "Notes",
            "scannedlockboxes": "Scanned Lockboxes",
            "clearlist": "Clear List",
            "toreceive": "Receive",
            "turninspkey": "Turn in Supra Key",
            "spkeychkbox": "YES, A SUPRA KEY IS BEING TURNED IN",
            "spboxchkbox": "YES, LOCKBOXES ARE BEING TURNED IN",
            "spcradlechkbox": "YES, CRADLES ARE BEING TURNED IN",
            "scanspkey": "Scan Supra Key",
            "scanspbox": "Scan Supra Lockbox",
            "scanspcradle": "Scan Supra Cradle",
            "step": "Step",
            "yes":"Yes",
            "no":"No",
            "clearlistmsg":"Are you sure you want to clear the lockbox list?",
            "turninspkeydetails": "This customer will be turning in Supra Keys as part of their conversion. If an agent brings in a Supra Key, select YES below and you will be prompted to scan the Supra Key. Once done scanning click NEXT. If the agent does not have a Supra Key to turn in, click NEXT.",
            "turninspboxdetails": "This customer will be turning in Supra Lockboxes as part of their conversion. If an agent brings in a Supra Lockbox, select YES below and you will be prompted to scan it. Once done scanning click NEXT. If the agent does not have a Supra Key to turn in, click NEXT.",
            "turninspcradledetails":"This customer will be turning in Supra Cradles as part of their conversion. If an agent brings in a Supra Cradle, select YES below and you will be prompted to scan it. Once done scanning click NEXT. If the agent does not have a Supra Cradle to turn in, click NEXT.",
            "scanned": "Scanned",
            "addlockbox": "Add Lockbox",
            "finishorder": "Finish Order",
            "youmustremove": "You must remove ",
            "decreaselockboxes": " lockboxes in order to decrease the Lockbox amount to receive.",
            "unabletranslation": "Unable to get translation data",
            "invaliduserpass": "Invalid username or password",
            "noboxesadded": "No boxes can be added, please click modify order if you wish to make changes to this order.",
            "selectuser": "You must select a user before scanning lockboxes.",
            "notassigned": "Lockboxes that could not be assigned: ",
            "status":"Status",
            "tsaid":"TSA ID",
            "emailmessage":"This is user has not registered please resend the invitation email."
        },
        "PROGRAMMING_COMPONENT": {
            "accountnumber": "Account Number",
            "firstname": "First Name",
            "lastname": "Last Name",
            "enterscan": "Enter or Scan LBSN",
            "unableretrieve": "Unable to retrieve data",
            "authorize": "Authorize",
            "usersearch": "User Search",
            "name": "Name",
            "association": "Association",
            "nousersfound": "No users found",
            "noinstallsfound": "No installs found",
            "lockboxestoreceive": "Number of Lockboxes to receive",
            "modifyorder": "Modify Order",
            "lockboxestoremove": "Lockboxes to remove",
            "scanlockboxes": "Scan Lockboxes",
            "notes": "Notes",
            "scannedlockboxes": "Scanned Lockboxes",
            "clearlist": "Clear List",
            "toreceive": "Receive",
            "scanned": "Scanned",
            "addlockbox": "Add Lockbox",
            "finishorder": "Finish Order",
            "youmustremove": "You must remove ",
            "decreaselockboxes": " lockboxes in order to decrease the Lockbox amount to receive.",
            "unabletranslation": "Unable to get translation data",
            "invaliduserpass": "Invalid username or password",
            "noboxesadded": "No boxes can be added, please click modify order if you wish to make changes to this order.",
            "selectuser": "You must select a user before scanning lockboxes.",
            "notassigned": "Lockboxes that could not be assigned: ",
            "status":"Status",
            "complete":"Complete",
            "yes":"Yes",
            "no":"No",
            "clearlistmsg":"Are you sure you want to clear the lockbox list?"
        },
        "ADMIN_COMPONENT": {
            "unableretrieve": "Unable to retrieve data",
            "association": "Association",
            "tsaid": "TSA ID",
            "status": "Status",
            "noinstallsfound": "No installs found",
            "installsettings": "Install Settings",
            "processeddata": "Processed Data",
            "keyagreement": "Key Agreement",
            "agentbilling": "Agent Billing",
            "installtype": "Install Type",
            "returnsaccepted": "Returns Accepted",
            "lbexchangeratio": "Locbox Exchange Ratio",
            "sendreginvite": "Send Registration Invite",
            "unabletoretrieve": "Unable to retrieve data",
            "stopprocessing": "Stop Processing",
            "startprocessing": "Start Processing",
            "lastsync":"Last Sync",
            "nodata":"No Data",
            "agent":"Agent",
            "card":"Card",
            "company":"Company",
            "lbmfg":"LBMFG",
            "lockbox":"Lockbox",
            "region":"Region",
            "user":"User",
            "userpermissions":"User Permissions",
            "installdataprocess":"Install Data Progress",
            "yes":"Yes",
            "no":"No",
            "clearlistmsg":"Are you sure you want to clear the lockbox list?",
            "lbreturn":"Lockbox Returns",
            "keyreturn":"Key Returns",
            "cradlereturn":"Cradle Returns",
        },
        "ASSOCIATION_COMPONENT": {
            "welcome": "Welcome,",
            "programming": "Programming",
            "association": "Association",
            "checkin": "Check In",
            "kewelowercase": "kewe",
            "search": "Search",
            "admin": "Settings",
            "password": "Password",
            "passwordandpin": "Password & Pin",
            "signout": "Sign Out",
            "noowner": "No Owner"
        },
        "SEARCH_COMPONENT": {
            "enterscan": "Enter or Scan LBSN",
            "unableretrieve": "Unable to retrieve data",
            "checkownership": "Check Ownership",
            "unabletranslation": "Unable to get translation data",
        },
        "MAIN_COMPONENT": {
            "welcome": "Welcome,",
            "programming": "Programming",
            "association": "Association",
            "checkin": "Check In",
            "kewelowercase": "kewe",
            "search": "Search",
            "admin": "Settings",
            "password": "Password",
            "passwordandpin": "Password & Pin",
            "signout": "Sign Out"
        }
    };

    constructor() { }

    public getAllDefaultTranslations(): any {


        return this.defaultTranslations;
    }

    public getDefaultTranslation(translationKey: string): any {

        return this.defaultTranslations[translationKey];
    }

}