
import { formatDate } from "ngx-bootstrap/chronos";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import { User } from "../user";


/*
Printing Service to build and send print jobs to a Zebra printer.

To make it easier to modify here is the zpl code not seperated out by line.
http://labelary.com/viewer.html -- view the zpl code without having to print out every time.

!! IF YOU CHANGE THE PRINT LAYOUT CHANGE THIS TOO SO YOU DON'T CONFUSE THE NEXT DEVELOPER !!

    ^XA
    ^CF0,50
    ^FO50,75^FDJohn Doe^FS
    ^FO50,205, 2^FB700,2,10,L,145^FDProxy: Test Proxy User^FS
    ^FO50,335^FB700,3,10^FDSentriLock Test Company ^FS
    ^CF0,100
    ^FO150,600^A0N,100,100^FB200,3,0,C,0^GB500,150,3,B,6^FS
    ^FO300,640^A0N,100,100^FB200,3,0,C,0^FD50\&^FS
    ^CF0,50
    ^FO50,1075^FD12/7/2021^FS
    ^FO50,1150^FD3:28:20 PM^FS
    ^FO625, 1125^FD1 of 1^FS
    ^XZ


*/
export class PrintingService {

    /**
     * Prints the programming order lable.
     * @param user 
     * @param lockboxCount 
     */
    public async printOrder(user: User, lockboxCount:number){
        var browserPrint = new ZebraBrowserPrintWrapper();

        //The laptops are specifically setup for Kewe, we can safely assume that the default printer is the zebra printer.
        try{
            var zebraPrinter = await browserPrint.getDefaultPrinter();
            browserPrint.setPrinter(zebraPrinter);
        } catch(error){
            alert("No Printer found.  Make sure the printer is turned on and set as the default printer in the Zebra Browser Utility.")
            console.error(error);
            return;
        }
        
        try {
            var printerStatus = await browserPrint.checkPrinterStatus();
        } catch (error) {
            alert("Could not get printer status.")
            console.error(error);
            return;
        }

        //Check if the printer is ready
        if(printerStatus.isReadyToPrint){
            var cmds = this.createZPLCommand(user, lockboxCount);
            browserPrint.print(cmds);
        } else {
            alert("Printer not ready to print.  Please try again.");
            //On the programming screen is where the option to reprint will happen.
            return;
        }
    }

    /**
     * Helper method that is used on the Admin page to test printing without needing an order.
     */
     public printTestOrder(labelCount: number){
        var user = new User();
        user.FirstName = "John"
        user.LastName = "Doe";
        user.Proxy = "Test Proxy User";
        user.Company = "SentriLock Test Company";

        //Labels are printed for every 10 lockboxes.  
        var lockboxCount = labelCount * 10;

        
        this.printOrder(user, lockboxCount);
    }

    /**
     * Creates the full command to be sent to the printer.
     * @param user 
     * @param lockboxCount 
     * @returns 
     */
    private createZPLCommand(user: User, lockboxCount: number){
        var numberOfLabels = this.getNumberOfLabels(lockboxCount);
        var cmds = "";
        for(var labelNumber = 1; labelNumber <= numberOfLabels; labelNumber++){
            cmds += this.createLabel(user, lockboxCount, labelNumber, numberOfLabels);
        }
        return cmds;
    }

    /**
     * Returns the number of lables that need to be printed.
     * For every 10 lockboxes we need to print another label.
     * @param lockboxCount 
     * @returns 
     */
    private getNumberOfLabels(lockboxCount: number){
        return Math.ceil(lockboxCount / 10);
    }


    /**
     * Creates the lable code 
     * 
     * !! IF YOU CHANGE THE PRINT LAYOUT BELOW, CHANGE THE COMMENT AT THE TOP OF THE CLASS SO YOU DON'T CONFUSE THE NEXT DEVELOPER !!
     * 
     * @param user 
     * @param lockboxCount 
     * @returns 
     */
    private createLabel(user: User, lockboxCount:number, labelNumber: number, numberOfLabels: number){

        var time = new Date();
        var agentName = user.LastName + ", " + user.FirstName;

        var cmds =  "^XA";
        cmds += "^CF0,50";
        cmds +="^FO50,75^FD"+agentName+"^FS";
        cmds +="^FO50,205,2^FB700,2,10,L,145^FDProxy: "+user.Proxy+"^FS";
        cmds +="^FO50,335^FB700,3,10^FD"+user.Company+"^FS";
        cmds +="^CF0,100";
        cmds +="^FO150,600^A0N,100,100^FB200,3,0,C,0^GB500,150,3,B,6^FS";
        cmds +="^FO300,640^A0N,100,100^FB200,3,0,C,0^FD"+lockboxCount+"\\&^FS";
        cmds +="^CF0,50";
        cmds +="^FO50,1075^FD"+time.toLocaleDateString()+"^FS";
        cmds +="^FO50,1150^FD"+time.toLocaleTimeString()+"^FS";
        cmds +="^FO625, 1125^FD"+labelNumber+" of "+numberOfLabels+"^FS";
        cmds +="^XZ";

        return cmds;

    }
}