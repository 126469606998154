
import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';




import { SentriLockHttpService } from './sl-http.service';
import { SentriLockGlobalService } from './sl-global.service';


@Injectable()
export class AuthService
{
    /** The message returned from the server */
    private message: string;

    constructor(private slHttpService: SentriLockHttpService,
                private slGlobalService: SentriLockGlobalService) {

        this.slGlobalService.saveCallStack("AuthService:constructor");
    }

    /**
     * Get translation data to be used in the app
     * @param controller 
     * @param translationKey 
     * @returns 
     */
    public getTranslations(controller: string, translationKey: string): Promise<any> {

        this.slGlobalService.saveCallStack("AuthService:getTranslations");

        var language = this.slGlobalService.getSupportedLanguage("");

        var paramObject = {
            module: 'kewe',
            browserlanguage: language,
            controller: controller
        };

        var headers = new Headers();
        //headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/translation/getentries`, paramObject)
            .toPromise()
            .then(this.processTranslationResponse.bind(this, translationKey, paramObject))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleError.bind(this));

    }

    private processTranslationResponse(translationKey, origParamObject, response): Boolean {

        this.slGlobalService.saveCallStack("AuthService:processTranslationResponse");

        var success = false;

        try {
            var jsonData = this.slHttpService.getJsonFromResponse(response);

            if (jsonData && jsonData.ResponseText == "Success"){

                this.slGlobalService.setDBHost(jsonData.dbhost);

                success = true;

                if (translationKey && (translationKey != "")) {
                    // we have a key telling us to save a specific translation so just save that one
                    this.slGlobalService.saveTranslation(jsonData, translationKey);
                }
                else {
                    // we don't have a key telling us to save a specific translation so save all of them
                    this.slGlobalService.saveTranslations(jsonData);
                }
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AuthService:processTranslationResponse");
            // rethrow the exception
            throw exception;
        }

        return success;
    }

    public login(username: string, password: string): Promise<Object> {

        this.slGlobalService.saveCallStack("AuthService:login");

        var paramObject = {
            username: username.trim(),
            password: password.trim()
        };

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/login/login`, paramObject)
            .toPromise()
            .then(this.processLoginResponse.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processLoginResponse(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AuthService:processLoginResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);

            if (jsonData && (<any>jsonData).ResponseText == "Success") {

                // IMPORTANT: this must get set for the auth-guard.service to let the user into the app
                this.slGlobalService.setLoggedIn(true);
            }
            else {

                // make sure the auth-guard.service keeps the user out of pages they must be logged in to access
                this.slGlobalService.setLoggedIn(false);
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AuthService:processLoginResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    public logout(): void {

        this.slGlobalService.saveCallStack("AuthService:logout");

        // this variable indicates to the property the user has logged out
        //this.slGlobalService.setLoggedIn(false);

        // clear the list of services
        //this.slGlobalService.saveServicesArray([]);

        var paramObject = {};

        // We're going to make the server call to log the user out.  We don't care about the response.
        this.slHttpService
            .getInMemPostServer(`kewe/login/logout`, paramObject)
            .toPromise()
            .then(this.processLogoutResponse.bind(this, paramObject))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.processLogoutError.bind(this));


        // clear the saved sessioin ID, make sure ths is done after we make the server call or we will get a 401 (unauthorized) because
        // the server won't have the sessionID
        //this.slGlobalService.setSessionId("");
    }

    private processLogoutResponse(origParamObject, response): void {

        this.slGlobalService.saveCallStack("AuthService:processLogoutResponse");

        try {
            // clear all the sessioin variables
            this.slGlobalService.clearSessionVariables();
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AuthService:processLogoutResponse");
            // rethrow the exception
            throw exception;
        }
    }

    private processLogoutError(response): void {

        this.slGlobalService.saveCallStack("AuthService:processLogoutError");

        // clear all the sessioin variables
        this.slGlobalService.clearSessionVariables();
    }

    public isLoggedIn(): boolean {

        this.slGlobalService.saveCallStack("AuthService:isLoggedIn");

        return this.slGlobalService.isLoggedIn();
    }

    public setLoggedIn(loggedIn: boolean): void {

        this.slGlobalService.saveCallStack("AuthService:setLoggedIn");

        this.slGlobalService.setLoggedIn(loggedIn);
    }

    public getRedirectUrl(): string {

        this.slGlobalService.saveCallStack("AuthService:getRedirectUrl");

        return this.slGlobalService.getRedirectUrl();
    }

    public setRedirectUrl(redirectUrl: string): void {

        this.slGlobalService.saveCallStack("AuthService:setRedirectUrl");

        this.slGlobalService.setRedirectUrl(redirectUrl);
    }

    public hasRedirectUrl(): boolean {

        this.slGlobalService.saveCallStack("AuthService:hasRedirectUrl");

        return this.slGlobalService.hasRedirectUrl();
    }

    /*
    * This function is so the login screen can check to see if there is a EULA so it knows if it needs to route to the EULA screen
    * */
    public hasEULA(): boolean {

        this.slGlobalService.saveCallStack("AuthService:hasEULA");

        return this.slGlobalService.hasEULA();
    }

    /*
    * This function is so the EULA screen can clear out the EULA after the user accepts.
    * */
    public setEULA(eula: string): void {

        this.slGlobalService.saveCallStack("AuthService:setEULA");

        this.slGlobalService.setEULA(eula);
    }

    /*
    * This function lets the EULA screen access the EULA text to display.
     */
    public getEULA(): string {

        this.slGlobalService.saveCallStack("AuthService:getEULA");

        return this.slGlobalService.getEULA();
    }

    public sendForgotPasswordEmail(username: string): Promise<any> {

        this.slGlobalService.saveCallStack("AuthService:sendForgotPasswordEmail");

        var paramObject = {
            username: username.trim()
        };

        var headers = new Headers();
        //headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/user/resetpassword`, paramObject)
            .toPromise()
            .then(this.processForgotPasswordResponse.bind(this, paramObject))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleError.bind(this));
    }

    private processForgotPasswordResponse(origParamObject, response): any {

        this.slGlobalService.saveCallStack("AuthService:processForgotPasswordResponse");

        return response;
    }

    public resetPassword(resetPasswordToken: string, emailAddress: string, newPassword: string): Promise<any> {

        this.slGlobalService.saveCallStack("AuthService:resetPassword");

        var paramObject = {
            token: resetPasswordToken,
            username: emailAddress.trim(),
            newpassword: newPassword.trim()
        };

        var headers = new Headers();
        //headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/user/updatepassword`, paramObject)
            .toPromise()
            .then(this.processResetPasswordResponse.bind(this, paramObject))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleError.bind(this));
    }

    private processResetPasswordResponse(origParamObject, response): any {

        this.slGlobalService.saveCallStack("AuthService:processResetPasswordResponse");

        return response;
    }

    public getUserNameFromToken(resetPasswordToken: string): Promise<any> {

        this.slGlobalService.saveCallStack("AuthService:getUserNameFromToken");

        var paramObject = {
            token: resetPasswordToken
        };

        var headers = new Headers();
        //headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/login/getusername`, paramObject)
            .toPromise()
            .then(this.processGetUserNameFromTokenResponse.bind(this, paramObject))
            //.then(response => this.isLoggedIn = response.json().data.valid)
            .catch(this.handleError.bind(this));
    }

    private processGetUserNameFromTokenResponse(origParamObject, response): any {

        this.slGlobalService.saveCallStack("AuthService:processGetUserNameFromTokenResponse");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AuthService:processGetUserNameFromTokenResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    private returnErrorResponse(response): Object {

        this.slGlobalService.saveCallStack("AuthService:returnErrorResponse");

        var jsonData = this.slHttpService.getJsonFromResponse(response);

        return Promise.reject(jsonData);
    }

    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("AuthService:handleError");

        try {
            if (error && error.message) {
                this.message = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                this.message = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                this.message = error.statusText;
            }
            else {
                this.message = "An error occurred.";
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (this.message && this.message.toUpperCase().includes("JSON")) {
                this.message = "An error occurred.";
            }
        }
        catch (err) {
            this.message = "An error occurred.";
        }


        return Promise.reject(error.message || error);
    }

}

