import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CheckinRoutingModule } from './checkin-routing.module';

import { CheckinComponent } from './checkin.component';
import { MatDialog, MatDialogModule, MatDialogRef  } from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

//import './rxjs-extensions';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        //InMemoryWebApiModule.forRoot(InMemoryDataService),
        CheckinRoutingModule
    ],
    declarations: [
        CheckinComponent
    ],
})
export class CheckinModule { }
