<div class="overlay" *ngIf="showInstallList">

    <div class="select-install-popup" *ngIf="showInstallList">
        <div class="close-x" (click)="closeInstallSearch()"></div>
        <div class="user-search-head">
            <h2>Select Install</h2>
        </div>
        <div class="select-install">
            <div class="heading-row">
                <p class="lbsn" style="width: 580px;">{{ASSOCIATION}}</p><p class="owner">{{TSAID}}</p><p class="assoc">{{STATUS}}</p>
            </div>
            <ol>
                <li (click)="selectInstall(install)" *ngFor="let install of installs; index as i" [attr.data-index]="i">
                    <p class="lb">{{install.assocname}} - {{install.title}}</p> <p class="lbown">{{install.trainingscheduleid}}</p><p class="assoc">{{COMPLETE}}</p>
                </li>
            </ol>
            <div *ngIf="displayNoInstallsFound" class="no-users">
                {{NO_INSTALLS_FOUND}}
            </div>
        </div>
    </div>

</div>

<div class="current-install" *ngIf="currentInstallAssoc">{{currentInstallAssoc}} ({{currentInstallTSAID}})</div>

<div class="prog-queue" *ngIf="displayQueue">

    <div class="message" [style.color]="messageColor">{{message}}</div>

    <div *ngIf="displayNoUserFound" class="no-users">{{NO_USERS_FOUND}}</div>

    <ol>
        <li (click)="selectUser(user)" *ngFor="let user of users; index as i" [attr.data-index]="i" class="user-click">
            <div class="card">
                <div class="head">
                    <p class="name">{{user.FirstName}} {{user.LastName}}</p>
                </div>
                <div class="body">
                    <p class="card">{{user.Company}}</p>
                    <p class="lockbox-head" *ngIf="!displaySimpleCheckIn ">{{DISPUTED}}</p>
                    <p class="lockbox-head" *ngIf="displaySimpleCheckIn ">{{BOXESOWED}}</p>
                    <p class="lockbox" *ngIf="user.BoxesDisputed && !displaySimpleCheckIn">{{user.BoxesDisputed }}</p>
                    <p class="lockbox" *ngIf="!user.BoxesDisputed  && !displaySimpleCheckIn ">0</p>
                    <p class="lockbox" *ngIf="user.BoxesOwed && displaySimpleCheckIn">{{user.BoxesOwed }}</p>
                    <p class="lockbox" *ngIf="!user.BoxesOwed  && displaySimpleCheckIn">0</p>
                    <p class="date">{{user.UTCModified}}</p>
                </div>
            </div>
        </li>
    </ol>

</div>

<div class="right-box" *ngIf="!displayQueue">
    <div class="list">
        <div class="list-box">
            <p class="heading-text">{{LIST_LOCKBOXES}}</p>
            <ol>
                <li *ngFor="let lockbox of disputedLockboxes">
                    <span>{{lockbox.LBSN}}</span>
                                -
                    <span>{{lockbox.Name}}</span>
                    <div class="add" (click)="acceptDisputedLockbox(lockbox)"><img class="svg-action" src="images/check-big.svg" /></div>
                    <div class="delete" (click)="removeLockboxFromQuarantine(lockbox)"><img class="svg-action" src="images/x-big.svg" /></div>
                </li>
                <li class="accepted-lockbox"  *ngFor="let assignedlb of assignedLockboxes">
                    <span>{{assignedlb.LBSN}}</span>
                            -
                    <span>{{assignedlb.Name}}</span>
                </li>
            </ol>
        </div>

        <div class="info-box">
            <div class="user-info">
                <p class="name">{{selectedUser?.FirstName}} {{selectedUser?.LastName}}</p>
                <p class="name-proxy" *ngIf="proxy"><span style="font-weight: bold">{{PROXY}}</span>: {{selectedUser?.Proxy}}</p>
                <p class="acctnum">{{selectedUser?.Company}}</p>
                <div class="lb-box">
                    <div class="receive">
                        <div class="lb-box-head">{{RECEIVE}}</div>
                        <div *ngIf="!selectedUser.BoxesOwed && !displaySimpleCheckIn">0</div>
                        <div *ngIf="selectedUser && !displaySimpleCheckIn">{{selectedUser?.BoxesOwed}}</div>
                        <div *ngIf="selectedUser && displaySimpleCheckIn"><input [(ngModel)]="lbsOwed" type="text" class="add-boxes" max="8" placeholder="{{selectedUser?.BoxesOwed}}"></div>
                    </div>
                    <div class="receive" *ngIf="hasLimitList">
                        <div class="lb-box-head">Limit</div>
                        <div>{{agentLimitLBCount}}</div>
                    </div>
                </div>
            </div>
            <div class="complete-message" *ngIf="displayCompleteMessage">{{ORDER_COMPLETE}}</div>
            <button *ngIf="!displaySimpleCheckIn" class="finish-checkin" (click)="finishCheckin()" [disabled]='disableFinishOrder' ng-class="{'diabled-class': disableFinishOrder }">{{FINISH_CHECK}}</button>
            <button *ngIf="displaySimpleCheckIn" class="finish-checkin" (click)="finishCheckin()" [disabled]='disableFinishOrder'>{{MODIFY_ORDER}}</button>
            <div class="cancel-checkin" (click)="cancelCheckin()">{{CANCEL}}</div>
        </div>

    </div>
</div>