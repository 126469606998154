import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, Renderer2, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckinService } from './checkin.service';

import { SentriLockGlobalService } from '../sl-global.service';
import { CommonService } from '../common.service';
import { AuthService } from '../auth.service';


import { User } from '../user';
import { Install } from '../install';
import { MatDialog, MatDialogModule, MatDialogRef  } from '@angular/material/dialog';

@Component({
    selector: 'checkin',
    templateUrl: './checkin.component.html',
    styleUrls: [ './checkin.component.css'],
    providers: [AuthService]
})
export class CheckinComponent implements OnInit, AfterViewChecked  {

    @ViewChild("infoBox", { static: false }) infoBox: ElementRef;
    @ViewChild("hideBox", { static: false }) hideBox: ElementRef;

    @ViewChild("spKey", { static: false }) public spKey: ElementRef;
    @ViewChild("spCradle", { static: false }) public spCradle: ElementRef;

    @ViewChild("sentriCardReturn", { static: false }) public sentriCardReturn: ElementRef;
    @ViewChild("lockbox", { static: false }) public turnInLBs: ElementRef;

    @ViewChild("checkInProxyTextbox", { static: false }) public checkInProxyTextbox: ElementRef;
    @ViewChild("checkInProxyCheckbox", { static: false }) public checkInProxyCheckbox: ElementRef;


    @ViewChild("key", { static: false }) public key: ElementRef;
    @ViewChild("cradle", { static: false }) public cradle: ElementRef;
    @ViewChild("box", { static: false }) public box: ElementRef;
    @ViewChild("card", { static: false }) public card: ElementRef;

    @ViewChild("sendassoc", { static: false }) public sendassoc: ElementRef;
    sendToAssoc: boolean = false;

    toAssoc = 0;


    //checkin message
    checkinMessage: string = "";
    noCheckin: string = "";

    errorMessage: string = "";

    //Wizard steps
    supraKeyStep: boolean = true;
    supraCradleStep: boolean = false;
    supraLockboxStep: boolean = false;

    noAcctNum : boolean = false;
    unknownError: boolean = false;

    displaySimpleCheckIn: boolean = false;

    //Install LB Ratio, number of boxes we give to the agent for each of their boxes
    installLBRatio: number = 1;

    //Set the color to black
    messageColor: string = '#000';

    showAjaxLoader: boolean = false;

    showInstallList: boolean = false;
    displayNoInstallsFound: boolean = false;

    displayAuthScreen: boolean = false;
    displayClearListScreen: boolean = false;
    displayUserNotRegisteredScreen: boolean = false;
    displayNoCheckInScreen: boolean = false;
    displayHoldQueueMessageScreen: boolean = false;

    notValidAgent: boolean = false;
    notValidCard: boolean = false;
    notValidTurnIn: boolean = false;

    displayUserSearch: boolean = false;
    displayNoUserFound: boolean = false;

    showHideDisplay: boolean = true;

    /**Holds messages that need to get displayed to the user */
    message: string = "";
    installMessage: string = "";

    notesColor = '#505050';

    currentInstallAssoc: string = "";
    currentInstallTSAID: string = "";

    //Install settings
    //TODO: {RW} Put these in their own class 
    currentInstallConversionType: string = "";
    currentInstallKeyReturn: string = "";
    currentInstallCradleReturn: string = "";
    currentSupraLBOwnerCheck: string = "";
    currentInstallLBReturn: string = "";
    currentInstallSCReturn: string = "";
    currentInstallLBRatio: string = "";
    currentPrintLabel: string = "";
    currentProgList: string = "";
    hasLimitList: boolean;

    scannedLBCount = 0;
    receivedLBCount: number = 0;
    creditLBCount: number = 0;

    /** Displays the limit count for the Agent.  Only displayed if the install event is useing a limit list. */
    agentLimitLBCount: number = 0;

    /** Check for if scanned LB is over Agent LB Limit.  Used on the UI */
    isOverLBLimit: boolean;
    lastScannedLB = 0;

    previousLBR = 0;

    assignedCount = 0;
    failedLockboxes = [];
    assignedLockboxes = [];
    checkinLockboxes = [];

    receipts = [];

    numberOfReceipts = 2;

    lockboxList = [];

    users;

    installs;

    selectedInstall: Install = new Install;

    selectedUser: User = new User;
    isUserSelected: boolean = false;

    //Checkbox values & input field
    supraKey: string = "";
    sentriCard: string = "";
    supraKeyRadioYes: string = "";
    supraKeyRadioNo: string = "";
    supraCradle: string = "";
    supraCradleRadioYes: string = "";
    supraCradleRadioNo: string = "";
    spkeyDisabled: boolean = false;
    spcradleDisabled: boolean = false;
    checkinProxy: string = "";

    hideWizard: boolean = false;

    //Disable buttons until they are able to be clicked
    disableFinishOrder: boolean = true;
    disableFinishWithoutOrder: boolean = true;
    disableSupraKey: boolean = true;
    disableSupraBoxScan: boolean = true;

    spKeyReturned: boolean = true;
    checkinProxyBool: boolean = true;
    sentriCardReturned: boolean = true;
    spCradleReturned: boolean = true;
    disableAddLockbox: boolean = true;

    disableClearList: boolean = true;

    // language constants from COMMON
    FIRST_NAME: string = "";                   // "First Name"
    LAST_NAME: string = "";                    // "Last Name"
    ACCOUNT_NUMBER: string = "";             // "Account Number"
    ENTER_SCAN: string = "";             // "Enter or Scan LBSN"
    ENTER_SCAN_SP_KEY: string = "";             // "Enter or Scan LBSN"
    ENTER_SCAN_SP_CRADLE: string = "";             // "Enter or Scan LBSN"
    UNABLE_RETRIEVE: string = "";             // "Enter or Scan LBSN"
    AUTHORIZE: string = "";             // "Authorize"
    USER_SEARCH: string = "";             // "User Search"
    NAME: string = "";             // "Name"
    COMPLETE: string = "";             // "Complete"
    ASSOCIATION: string = "";             // "Association"
    NO_USERS_FOUND: string = "";             // "No Users Found"
    NO_INSTALLS_FOUND: string = "";             // "No Installs Found"
    LOCKBOXES_TO_RECEIVE: string = "";             // "Number of Lockboxes to receive"
    MODIFY_ORDER: string = "";             // "Modfiy Order"
    LOCKBOXES_TO_REMOVE: string = "";             // "Lockboxes to remove"
    SCAN_LOCKBOXES: string = "";             // "Scan Lockboxes"
    NOTES: string = "";             // "Notes"
    NEXT: string = "";             // "Next"
    NO: string = "";             // "Next"
    STEP: string = "";             // "Next"
    SELECT_INSTALL: string = "";             // "Next"
    SCANNED_LOCKBOXES: string = "";             // "Scanned Lockboxes"
    TURN_IN_SUPRA_KEY: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_KEY_DETAILS: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_KEY_CHKBOX: string = "";             // "Turn in Supra Key"
    TURN_IN_SENTRICARD_CHKBOX: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_KEY_SCAN: string = "";             // "Turn in Supra Key"
    TURN_IN_SENTRICARD_SCAN: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_BOX_DETAILS: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_BOX_CHKBOX: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_BOX_SCAN: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_CRADLE_DETAILS: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_CRADLE_CHKBOX: string = "";             // "Turn in Supra Key"
    TURN_IN_SUPRA_CRADLE_SCAN: string = "";             // "Turn in Supra Key"
    CLEAR_LIST: string = "";             // "Clear List"
    RECEIVED: string = "";             // "Receive"
    LB_RECEIVE: string = "";             // "Receive"
    SCANNED: string = "";             // "Scanned"
    LIMIT: string = "";
    CREDIT: string = "";
    ADD_LOCKBOX: string = "";             // "Add Lockbox"
    FINISH_ORDER: string = "";             // "Finish Order"
    FINISH_WITHOUT_ORDER: string = ""; 
    STATUS: string = "";             // "Finish Order"
    CLEAR_LIST_MSG: string = "";             // "Finish Order"
    QUESTION_CLEAR_LIST: string = "";
    YES: string = "";             // "Finish Order"
    TSAID: string = "";             // "Finish Order"
    EMAIL_MESSAGING: string = "";             // "Finish Order"
    ASSOC_MSG: string = "";             // "Finish Order"
    AGENT_NOT: string = "";             // "Finish Order"
    ASSOC_NOT: string = "";             // "Finish Order"
    RETURNED: string = "";             // "Finish Order"
    HOLD_QUEUE: string = "";             // "Finish Order"
    AGENT_STATUS: string = "";             // "Finish Order"
    CARD_STATUS: string = "";             // "Finish Order"
    RESEND_EMAIL: string = "";
    EMAIL: string = "";
    BOXESOWED: string = "";
    NO_ACCT_NUMBER: string = "";
    CARD_RETURN_BLANK: string = "";
    EXTERNALID: string = "";
    PROXY_CHECK_IN: string = "";
    SEND_TO_ASSOC: string = "";
    UNKNOWN_ERROR: string = "";

    BOX_TYPE_SCAN: string = "";
    BOX_TYPE_CHK: string = "";
    CHECKIN_PROXY_BLANK: string = "";

    COMPANY: string = "";

    CARD_RETURN_ERROR: string = "";

    MAX_LOCK_BOX_SERIAL_NUMBER_LENGTH: number = 13;

    // first name search box
    firstNameSearchValue: string = "";
    firstNameHasFocus: boolean = false;
    lastSearchedUserFN: string = "";

    // last name search box
    lastNameSearchValue: string = "";
    lastNameHasFocus: boolean = false;
    lastSearchedUserLN: string = "";

    // account number search box
    accountNumberSearchValue: string = "";
    accountNumberHasFocus: boolean = false;
    lastSearchedAccountNumber: string = "";

    isSysAdmin: boolean = false;
    isProgrammingStation: boolean = false;
    isCheckinStation: boolean = false;
    isAssocAdmin: boolean = false;


    //Display Steps
    spkStep: boolean = true;
    sentriCardStep: boolean = true;
    spcStep: boolean = true;
    lbrStep: boolean = true;

    sentriCardBlank: boolean = false;
    chkProxyBlank: boolean = false;

    spInstall: boolean = false;

    stepOne: string = "1";
    stepTwo: string = "2";
    stepThree: string = "3";

    //Display checkbox value
    spkChecked: boolean = true;
    spcChecked: boolean = true;
    lbChecked: boolean = true;

    //Disable search fields when user is selected
    disableFirstName: boolean = false;
    disableLastName: boolean = false;
    disableAccountNumber: boolean = false;

    //Flag used in the backend to know if an order should be created for this check in.
    profileOnly: boolean = false;;



    constructor(private route: ActivatedRoute,
                private router: Router,
                private slGlobalService: SentriLockGlobalService,
                private commonService: CommonService,
                private authService: AuthService,
                private checkinService: CheckinService,
                public renderer: Renderer2) {

        this.slGlobalService.saveCallStack("CheckinComponent:constructor");

    }

    ngOnInit(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:ngOnInit");

        // we need to see if this users permissions indicate they are a System Admin
        this.isSysAdmin = this.slGlobalService.isSysAdmin();
        this.isProgrammingStation = this.slGlobalService.isProgrammingStation();
        this.isCheckinStation = this.slGlobalService.isCheckinStation();
        this.isAssocAdmin = this.slGlobalService.isAssocAdmin();

        if(this.slGlobalService.isProgrammingStation()) {
            this.router.navigate(['/programming']);
        }

        if(this.slGlobalService.isAssocAdmin()) {
            this.router.navigate(['/association']);
        }

        // get the translations
        this.authService.getTranslations('checkin', "CHECKIN_COMPONENT")
            .then(this.processTranslations.bind(this))
            .catch(this.handleError.bind(this));


        if(this.slGlobalService.getCurrentInstallAssoc() || this.slGlobalService.getCurrentInstall()) {

            this.currentInstallTSAID = this.slGlobalService.getCurrentInstall();
            this.currentInstallAssoc = this.slGlobalService.getCurrentInstallAssoc();

            //Get the install settings
            this.getInstallSettings();

            this.setWizardDisplay();

            this.showInstallList = false;

            setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
        } else {
            this.getCurrentInstalls();
        }

    }

    ngAfterViewChecked() {
        if (this.infoBox && this.hideBox) {
            const height = this.infoBox.nativeElement.offsetHeight + "px";
            this.renderer.setStyle(this.hideBox.nativeElement, "height", height);
        }
    }

    /**
     * Process the translations and assign them to the corresponding variable. 
     * @param success 
     */
    processTranslations(success) {

        this.slGlobalService.saveCallStack("CheckinComponent:processTranslations");

        if (success) {
            this.slGlobalService.saveCallStack("CheckinComponent:loadTranslations");

            // get the common translation data
            var programmingTranslationData = this.slGlobalService.getTranslations('PROGRAMMING_COMPONENT');
    
            if (programmingTranslationData) {
                this.NO_ACCT_NUMBER = programmingTranslationData.noaccountnumber;
            }
    
            // the call was successful so we should be able to get the translation data
            // get the common translation data
            var checkinTranslationData = this.slGlobalService.getTranslations('CHECKIN_COMPONENT');
    
            if (checkinTranslationData) {
    
                this.FIRST_NAME = checkinTranslationData.firstname;
                this.LAST_NAME = checkinTranslationData.lastname;
                this.COMPLETE = checkinTranslationData.complete;
                this.ACCOUNT_NUMBER = checkinTranslationData.accountnumber;
                this.ENTER_SCAN = checkinTranslationData.enterscan;
                this.SELECT_INSTALL = checkinTranslationData.selectinstall;
                this.UNABLE_RETRIEVE = checkinTranslationData.unableretrieve;
                this.AUTHORIZE = checkinTranslationData.authorize;
                this.USER_SEARCH = checkinTranslationData.usersearch;
                this.NAME = checkinTranslationData.name;
                this.ASSOCIATION = checkinTranslationData.association;
                this.NO_USERS_FOUND = checkinTranslationData.nousersfound;
                this.NO_INSTALLS_FOUND = checkinTranslationData.noinstallsfound;
                this.LOCKBOXES_TO_RECEIVE = checkinTranslationData.lockboxestoreceive;
                this.LB_RECEIVE = checkinTranslationData.lbtoreceive;
                this.TURN_IN_SUPRA_KEY = checkinTranslationData.turninspkey;
                this.ENTER_SCAN_SP_CRADLE = checkinTranslationData.enterscanspkey;
                this.ENTER_SCAN_SP_CRADLE = checkinTranslationData.enterscanspcradle;
                this.TURN_IN_SUPRA_KEY_DETAILS = checkinTranslationData.turninspkeydetails;
                this.TURN_IN_SUPRA_KEY_CHKBOX = checkinTranslationData.spkeychkbox;
                this.TURN_IN_SENTRICARD_CHKBOX = checkinTranslationData.sentricardchkbox;
                this.TURN_IN_SUPRA_KEY_SCAN = checkinTranslationData.scanspkey;
                this.TURN_IN_SENTRICARD_SCAN = checkinTranslationData.scansentricard;
                this.TURN_IN_SUPRA_BOX_DETAILS = checkinTranslationData.turninspboxdetails;
                this.TURN_IN_SUPRA_BOX_CHKBOX = checkinTranslationData.spboxchkbox;
                this.TURN_IN_SUPRA_BOX_SCAN = checkinTranslationData.scanspbox;
                this.TURN_IN_SUPRA_CRADLE_DETAILS = checkinTranslationData.turninspcradledetails;
                this.TURN_IN_SUPRA_CRADLE_CHKBOX = checkinTranslationData.spcradlechkbox;
                this.TURN_IN_SUPRA_CRADLE_SCAN = checkinTranslationData.scanspcradle;
                this.MODIFY_ORDER = checkinTranslationData.modifyorder;
                this.LOCKBOXES_TO_REMOVE = checkinTranslationData.lockboxestoremove;
                this.SCAN_LOCKBOXES = checkinTranslationData.scanlockboxes;
                this.NOTES = checkinTranslationData.notes;
                this.NEXT = checkinTranslationData.next;
                this.STEP = checkinTranslationData.step;
                this.SCANNED_LOCKBOXES = checkinTranslationData.scannedlockboxes;
                this.CLEAR_LIST = checkinTranslationData.clearlist;
                this.RECEIVED = checkinTranslationData.received;
                this.SCANNED = checkinTranslationData.scanned;
                this.CREDIT = checkinTranslationData.credit;
                this.LIMIT = checkinTranslationData.limit;
                this.ADD_LOCKBOX = checkinTranslationData.addlockbox;
                this.FINISH_ORDER = checkinTranslationData.finishorder;
                this.FINISH_WITHOUT_ORDER = checkinTranslationData.finishwithoutorder;
                this.STATUS = checkinTranslationData.status;
                this.CLEAR_LIST_MSG = checkinTranslationData.clearlistmsg;
                this.YES = checkinTranslationData.yes;
                this.NO = checkinTranslationData.no;
                this.TSAID = checkinTranslationData.tsaid;
                this.EMAIL_MESSAGING = checkinTranslationData.emailmessage;
                this.COMPANY = checkinTranslationData.company;
                this.ASSOC_MSG = checkinTranslationData.sendtoassocq;
                this.AGENT_NOT = checkinTranslationData.notagent;
                this.ASSOC_NOT = checkinTranslationData.assocnot;
                this.RETURNED = checkinTranslationData.alreadyreturned;
                this.HOLD_QUEUE = checkinTranslationData.holdqueue;
                this.AGENT_STATUS = checkinTranslationData.agentstatus;
                this.CARD_STATUS = checkinTranslationData.cardstatus;
                this.QUESTION_CLEAR_LIST = checkinTranslationData.clearlblist;
                this.RESEND_EMAIL = checkinTranslationData.resendemail;
                this.EMAIL = checkinTranslationData.email;
                this.BOXESOWED = checkinTranslationData.boxesowed;
                this.CARD_RETURN_ERROR = checkinTranslationData.cardreturnerror;
                this.CARD_RETURN_BLANK = checkinTranslationData.sentricardblank;
                this.CHECKIN_PROXY_BLANK = checkinTranslationData.chkproxyblank;
                this.EXTERNALID = checkinTranslationData.externalid;
                this.PROXY_CHECK_IN = checkinTranslationData.proxycheckin;
                this.SEND_TO_ASSOC = checkinTranslationData.sendtoassoc;
                this.UNKNOWN_ERROR = checkinTranslationData.unknownerror;
            }
    
            //Default to generic
            this.BOX_TYPE_SCAN = this.SCAN_LOCKBOXES;
            this.BOX_TYPE_CHK = this.TURN_IN_SUPRA_BOX_CHKBOX;
        }
    }

    clearMessages(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:clearMessages");

        this.errorMessage = "";
    }

    firstNameGetFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:firstNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the First Name field while it has focus
        this.firstNameHasFocus = true;
    }

    firstNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:firstNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the First Name field after we've
        // lost focus
        this.firstNameHasFocus = false;
    }


    lastNameGetFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:lastNameGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Last Name field while it has focus
        this.lastNameHasFocus = true;
    }

    lastNameLoseFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:lastNameLoseFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Last Name field after we've
        // lost focus
        this.lastNameHasFocus = false;
    }


    accountNumberGetFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:accountNumberGetFocus");

        // setting this to true will start the "takeWhile" so we get the type ahead on the Company field while it has focus
        this.accountNumberHasFocus = true;
    }

    accountNumberLoseFocus(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:accountNumberLostFocus");

        // setting this to false will end the "takeWhile" so we don't keep getting type ahead on the Company field after we've
        // lost focus
        this.accountNumberHasFocus = false;
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserFN(event): void {

        this.slGlobalService.saveCallStack("CheckinComponent:keyUpEnterUserFN");

        if (event.keyCode == 13) {
            this.lastSearchedUserFN = this.firstNameSearchValue;
            this.searchUsers();
        }
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterUserLN(event): void {

        this.slGlobalService.saveCallStack("CheckinComponent:keyUpEnterUserLN");

        this.lastSearchedUserLN = this.lastNameSearchValue;
        this.searchUsers();
    }

    /*
     * Called when the enter key is pressed. */
    keyUpEnterAccountNumber(event): void {

        this.slGlobalService.saveCallStack("CheckinSearchComponent:keyUpEnterAccountNumber");

        if (event.keyCode == 13) {
            this.lastSearchedAccountNumber = this.accountNumberSearchValue;
            this.searchUsers();
        }

    }

    updateCHKP() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateSPK");

        this.checkinProxyBool = !this.checkinProxyBool;

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.checkInProxyTextbox.nativeElement.focus();
            this.checkinProxy = "";
        },0);

        return;
    }

     updateSPK() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateSPK");

        this.spKeyReturned = !this.spKeyReturned;

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.spKey.nativeElement.focus();
            this.supraKey = "";
        },0);

        return;
    }

    updateSPC() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateSPC");

        this.spCradleReturned = !this.spCradleReturned;

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.spCradle.nativeElement.focus();
            this.supraCradle = "";
        },0);

        return;
    }

    updateSentriCard() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateSentriCard");

        this.sentriCardReturned = !this.sentriCardReturned;

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.sentriCardReturn.nativeElement.focus();
            this.sentriCard = "";
        },0);

        return;
    }

    updateSendToAssoc() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateSendToAssoc");

        this.sendToAssoc = !this.sendToAssoc;

        this.updateFinishWithoutOrderButton();

        return;
    }

    /**
     * Only allows the Finish witout order button to be clicked if there are failed lockboxes or we are not sending the order to the association.
     */
    updateFinishWithoutOrderButton() {
        //Can only send an order to the assocation without creating an order if there are failed lockboxes.
        if (this.sendToAssoc && (!this.failedLockboxes || !this.failedLockboxes.length)) {
            this.disableFinishWithoutOrder = true;
        } else {
            this.disableFinishWithoutOrder = false;
        }
    }

    updateLB() {

        this.slGlobalService.saveCallStack("CheckinComponent:updateLB");

        this.disableAddLockbox = !this.disableAddLockbox;

        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.turnInLBs.nativeElement.focus();
            this.lockboxList = [];
            this.scannedLBCount = 0;
            this.disableClearList = true;
        },0);
        return;
    }

    checkCard() {

        this.slGlobalService.saveCallStack("CheckinComponent:checkCard");

        if(this.sentriCard.length >= 10) {

            if(this.selectedUser.CardSN != this.sentriCard) {
                this.CARD_RETURN_ERROR = this.CARD_RETURN_ERROR.replace('<CARD>', this.sentriCard);
                this.CARD_RETURN_ERROR = this.CARD_RETURN_ERROR.replace('<AGENT>', this.selectedUser.FirstName + ' ' + this.selectedUser.LastName);
                this.notValidTurnIn = true;
                this.displayNoCheckInScreen = true;
            }
        }

        return;
    }

    // Modal dialogs
    //Close the clear list screen
    closeClearListScreen() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeClearListScreen");

        this.displayClearListScreen = false;
    }

    // Modal dialogs
    // Close the clear list screen
    closeRegEmailScreen() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeRegEmailScreen");

        this.displayUserNotRegisteredScreen = false;

        this.resetScreen();
    }

    // Modal dialogs
    // Close the clear list screen
    closeCheckIn() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeCheckIn");

        this.displayNoCheckInScreen = false;
        this.notValidAgent = false;
        this.notValidCard = false;

        this.resetScreen();
    }

    closeCardCheckIn() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeCardCheckIn");

        this.displayNoCheckInScreen = false;
        this.notValidAgent = false;
        this.notValidCard = false;
        this.notValidTurnIn = false;
        this.sentriCardBlank = false;
        this.chkProxyBlank = false;

        this.sentriCardReturned = true;
        this.sentriCard = "";

        if(this.sentriCardStep) {
            setTimeout(()=>{
                this.card.nativeElement.checked = false;
            });
        }
    }

    // Modal dialogs
    // Close the clear list screen
    closeHoldQueueScreen() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeHoldQueueScreen");

        this.displayHoldQueueMessageScreen = false;
    }

    next(action) {

        this.slGlobalService.saveCallStack("CheckinComponent:next");

        if(action == "cradle") {
            this.supraKeyStep = false;
            this.supraCradleStep = true;
        } else if(action == "lockbox") {
            this.supraCradleStep = false;
            this.supraLockboxStep = true;
        }
    }

    //Clear entire lockbox list
    clear() {

        this.slGlobalService.saveCallStack("CheckinComponent:clear");

        this.clearMessages();

        this.displayClearListScreen = false;

        this.lockboxList = [];
        this.failedLockboxes = [];
        this.assignedLockboxes = [];

        this.disableClearList = true;

        //Update Scanned Lockboxes count
        this.scannedLBCount = 0;
        this.receivedLBCount = 0;
        this.agentLimitLBCount = 0;
        this.creditLBCount = 0;

    }

    //Close the user search dialog
    closeSearch() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeSearch");

        this.displayUserSearch = false;
    }

    //Close the user search dialog
    closeInstallSearch() {

        this.slGlobalService.saveCallStack("CheckinComponent:closeInstallSearch");

        //Don't allow user to close the install search and stay on this page
        this.router.navigate(['/profile']);
    }

    //Select user from list
    selectInstall(install) {

        this.slGlobalService.saveCallStack("CheckinComponent:selectInstall");

        this.slGlobalService.setCurrentInstall(install.trainingscheduleid);
        this.slGlobalService.setCurrentInstallAssoc(install.assocname + ' - ' + install.title);

        //Get the install settings and set global values
        this.getInstallSettings();

        this.currentInstallAssoc = install.assocname + ' - ' + install.title;
        this.currentInstallTSAID = install.trainingscheduleid;

        this.showInstallList = false;

        setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
    }


    //Get the current installs that have data
    getCurrentInstalls() {

        this.slGlobalService.saveCallStack("CheckinComponent:getCurrentInstalls");

        this.commonService
            .searchInstalls("1")
            .then(this.setCurrentInstallList.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setCurrentInstallList(installs): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setCurrentInstallList");

        this.installMessage = "";

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var installsArray = installs;

        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!installs || (installs.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';

            this.displayNoInstallsFound = true;
            this.installMessage = this.NO_INSTALLS_FOUND;
        }
        else if (installs && (installs.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';

            this.displayNoInstallsFound = false;
        }
        else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoInstallsFound = false;
        }

        if(installs.length == 1) {
            this.selectedInstall = installsArray[0];

            this.slGlobalService.setCurrentInstall(this.selectedInstall.trainingscheduleid);
            this.slGlobalService.setCurrentInstallAssoc(this.selectedInstall.assocname + ' - ' + this.selectedInstall.title);

            //Get the install settings
            this.getInstallSettings();

            this.currentInstallAssoc = this.selectedInstall.assocname + ' - ' + this.selectedInstall.title;
            this.currentInstallTSAID = this.selectedInstall.trainingscheduleid;

            this.showInstallList = false;

            setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
        } else {
            this.showInstallList = true;

            this.installs = installsArray;
            this.selectedInstall = new Install;
        }
    }

    // runs the search when the search button is clicked
    searchUsers(): void {

        this.slGlobalService.saveCallStack("CheckinComponent:searchUsers");

        this.clearMessages();

        this.selectedUser = new User;

        this.showAjaxLoader = true;

        //Trim space around string, remove spaces, remove dashes
        var replacedString = this.accountNumberSearchValue.trim().replace(/-|\s/g,'');

        this.checkinService
            .searchUsers(this.firstNameSearchValue, this.lastNameSearchValue, replacedString)
            .then(this.setUserData.bind(this))
            .catch(this.displayError.bind(this));


    }

    setUserData(users): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setUserData");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var userArray = users;
        this.showAjaxLoader = false;

        // if there weren't any users returned then display a message letting the user there wasn't any data to display
        if (!users || (users.length == 0)) {
            //Set the color to black
            this.messageColor = '#AF2626';
            this.displayNoUserFound = true;

        } else if (users && (users.length >= 500)) {
            //Set the color to green
            this.messageColor = '#1AA544';
            this.displayNoUserFound = false;

        } else {
            // the search returned data and it is less than 500 records so clear the message and set the color back to black
            this.messageColor = '#000';
            this.displayNoUserFound = false;
        }

        if(users.length == 1) {
            this.selectUser(userArray[0]);

        } else {
            this.displayUserSearch = true;
            this.users = userArray;
            this.selectedUser = new User;
        }

    }

    /**
     * Selects a user from the search list
     * @param user
     */
    async selectUser(user) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:selectUser");

        this.isUserSelected = true;

        //Need to wait for this to finish before moving on 
        await this.getAgentStatus(user.CardSN);

        this.selectedUser = user;

        /* TODO {RSW} Temporary fix for weirdness with user class.

        BoxesOwed is returned from the server as a string, the only time we need to do any manipulation to BoxesOwed is when the value is null or 0.  Setting it to 0 will change it from "0" to 0.
        */
        if(!this.selectedUser.BoxesOwed || this.selectedUser.BoxesOwed == 0 ) {
            this.selectedUser.BoxesOwed = 0;
        }

        //We are only setting the credit amount if there is a programming list since we know exactly what the credit amount will be.
        //Otherwise just leave it 0 since it is a new check in.
        if(this.isProgrammingListSet()){
            this.creditLBCount = this.selectedUser.BoxesOwed;
        }

        if(!this.selectedUser.ReceivedLBCount || this.selectedUser.ReceivedLBCount == 0 ) {
            this.selectedUser.ReceivedLBCount = 0;
        }
        
        this.receivedLBCount =  Number(this.selectedUser.ReceivedLBCount);
        this.agentLimitLBCount = this.getSelectedUserLBLimitCount(this.selectedUser, this.hasLimitList);

        //Disable search fields
        this.disableFirstName = true;
        this.disableLastName = true;
        this.disableAccountNumber = true;

        this.displaySimpleCheckIn = this.isSimpleCheckIn();

        //hide the user search
        this.displayUserSearch = false;

        //cover the ui
        this.showHideDisplay = false;

        //Show the Finish order button
        this.disableFinishOrder = false;
        this.disableFinishWithoutOrder = false;
        this.hideWizard = true;

    }

    /**
     * Checks install setting to determine if this is a simple check in.
     * @returns 
     */
    isSimpleCheckIn(){
        return this.currentInstallLBReturn == "0" && 
        this.currentInstallCradleReturn == "0" && 
        this.currentInstallKeyReturn == "0" && 
        this.currentInstallSCReturn == "0"
    }

    /**
     * Gets the LBLimit count of the selected user.
     * 
     * If there isn't a limit list being used for this install we want to return null for the LBLimit count.  
     * @param selectedUser 
     * @param hasLimitList 
     * @returns 
     */
    getSelectedUserLBLimitCount(selectedUser, hasLimitList){
        var lBLimitCount;

        if(!hasLimitList){
            lBLimitCount = null;
        } else if(!selectedUser.LBLimitCount || selectedUser.LBLimitCount == 0 ) {
            //Changes a null value into 0 also fixes weirdness with data coming back from the server as a string, changing "0" to 0.
            lBLimitCount = 0;
        } else{
            lBLimitCount = selectedUser.LBLimitCount
        }

        return lBLimitCount;

    }

    /**
     * Gets the status of the selected user.
     * @param cardsn 
     */
    async getAgentStatus(cardsn: string) {

        this.slGlobalService.saveCallStack("ProgrammingComponent:getUserStatus");

        this.checkinService
            .getAgentStatus(cardsn)
            .then(this.displayUserStatusPopups.bind(this))
            .catch(this.userStatusError.bind(this));

    }

    /**
     * Displays popups based on the user status
     * @param agentStatusResponse 
     */
    displayUserStatusPopups(agentStatusResponse: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setUserStatus");

        if(agentStatusResponse.data.registered == false) {
            this.displayUserNotRegisteredScreen = true;
        } else if(agentStatusResponse.data.agentstatus != "Valid") {
            this.notValidAgent = true;
            this.displayNoCheckInScreen = true;
        } else if(agentStatusResponse.data.cardstatus != "Valid") {
            this.notValidCard = true;
            this.displayNoCheckInScreen = true;
        }
    }

    resendEmail() {

        this.slGlobalService.saveCallStack("CheckinComponent:resendEmail");

        this.checkinService
            .resendEmail(this.selectedUser.CardSN)
            .then(this.setResendEmail.bind(this))
            .catch(this.displayError.bind(this));

    }

    // Set the complete flag from change lockboxes to receive
    setResendEmail(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setResendEmail");

        this.resetScreen();
        this.displayUserNotRegisteredScreen = false;

    }

    //Show the dialog that allows you to clear the lockbox list
    showClearListScreen() {

        this.slGlobalService.saveCallStack("CheckinComponent:showClearListScreen");

        this.displayClearListScreen = true;
    }

    //Delete lockboxes from the list
    deleteLockbox(lbsn) {

        this.slGlobalService.saveCallStack("CheckinComponent:delete");

        const index: number = this.checkinLockboxes.indexOf(lbsn);
        if (index !== -1) {

            this.checkinLockboxes.splice(index, 1);

            const indexFL: number = this.failedLockboxes.indexOf(lbsn);
            if (indexFL !== -1) {
                this.failedLockboxes.splice(indexFL, 1);
            }

            const indexAL: number = this.assignedLockboxes.indexOf(lbsn);
            if (indexAL !== -1) {
                this.assignedLockboxes.splice(indexAL, 1);
            }

            const indexLB: number = this.lockboxList.indexOf(lbsn);
            if (indexLB !== -1) {
                this.lockboxList.splice(indexLB, 1);
            }

            //Update Scanned Lockboxes count
            this.scannedLBCount = this.checkinLockboxes.length;

            //Only decrease the recieved lockboxes if there is not a programming list.
            //If there is a programming list the agent will recieve lockboxes based on that not how many boxes were turned in.
            if(!this.isProgrammingListSet()) {
                if(this.creditLBCount > 1) {
                    this.creditLBCount =  this.creditLBCount - parseFloat(this.currentInstallLBRatio);
                } else {
                    this.creditLBCount = (this.creditLBCount - 1);
                }
            }
        }

        if(this.scannedLBCount == 0) {
            this.disableClearList = true;
        }

    }

    //Delete lockboxes from the list
    deleteFailed(lbsn) {

        this.slGlobalService.saveCallStack("CheckinComponent:delete");

        const index: number = this.checkinLockboxes.indexOf(lbsn);
        if (index !== -1) {

            //alert("Lockbox " + lbsn +" has been removed");
            this.checkinLockboxes.splice(index, 1);

            const indexFL: number = this.failedLockboxes.indexOf(lbsn);
            if (indexFL !== -1) {
                this.failedLockboxes.splice(indexFL, 1);
            }

            const indexAL: number = this.assignedLockboxes.indexOf(lbsn);
            if (indexAL !== -1) {
                this.assignedLockboxes.splice(indexAL, 1);
            }

            const indexLB: number = this.lockboxList.indexOf(lbsn);
            if (indexLB !== -1) {
                this.lockboxList.splice(indexLB, 1);
            }

            //Update Scanned Lockboxes count
            this.scannedLBCount = this.checkinLockboxes.length;
        }

        if(this.scannedLBCount == 0) {
            this.disableClearList = true;
        }
    }

    //Add lockboxes to the list
    addLockbox(lbsn) {

        this.slGlobalService.saveCallStack("CheckinComponent:add");

        lbsn = lbsn.trim();
        if (!lbsn) {
            return;
        }

        lbsn = lbsn.replace(/^[0\.]+/, '');
        lbsn = lbsn.replace(/[^0-9.]/g,'');

        this.lastScannedLB = lbsn;

        if(this.lastScannedLB != 0 && lbsn > 0 && lbsn.indexOf(".") == -1 && lbsn.length < this.MAX_LOCK_BOX_SERIAL_NUMBER_LENGTH) {

            if(this.currentInstallConversionType == "Upgrade" || this.currentInstallConversionType == "SentrilockUpgrade" || this.currentSupraLBOwnerCheck == "1") {

                if (this.failedLockboxes.indexOf(this.lastScannedLB) == -1 && this.assignedLockboxes.indexOf(this.lastScannedLB) == -1) {
                    this.checkinMessage = "";

                    this.checkinService
                        .lockboxOwnerCheck(this.selectedUser.CardSN, lbsn)
                        .then(this.processLBOwnerCheck.bind(this))
                        .catch(this.handleError.bind(this));
                } else {
                    //alert("Lockbox already in the list.");
                    this.displayHoldQueueMessageScreen = true;
                    this.checkinMessage = "Lockbox already in the list.";
                }
            } else {
                if (this.lockboxList.indexOf(this.lastScannedLB) == -1) {
                    this.lockboxList.push(lbsn);
                    this.checkinLockboxes.push(lbsn);

                    this.disableClearList = false;

                    //Update Scanned Lockboxes count
                    this.scannedLBCount = this.lockboxList.length;

                    //Only increment the recieved lockboxes if there is not a programming list.
                    //If there is a programming list the agent will recieve lockboxes based on that not how many boxes were turned in.
                    if(!this.isProgrammingListSet()) {
                        this.creditLBCount = this.creditLBCount + parseFloat(this.currentInstallLBRatio);
                    }
                }
            }
        }
    }

    processLBOwnerCheck(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:processLBOwnerCheck");

        if (jsonResponse.assochold != 0) {
            this.displayHoldQueueMessageScreen = true;
            this.checkinMessage = this.HOLD_QUEUE;
        } else {
            if (jsonResponse.prevreturn != 0) {
                this.displayHoldQueueMessageScreen = true;
                this.checkinMessage = this.RETURNED;
            } else {
                if (jsonResponse.lbassocid != jsonResponse.cardassocid && jsonResponse.validlb != true) {
                    this.displayHoldQueueMessageScreen = true;
                    this.checkinMessage = this.ASSOC_NOT.replace('<ASSOC>', this.currentInstallAssoc);
                } else {

                    if (jsonResponse.lbagentid != jsonResponse.cardagentid) {

                        if (jsonResponse.validlb == false) {
                            this.displayHoldQueueMessageScreen = true;
                            this.checkinMessage = this.AGENT_NOT;

                            this.failedLockboxes.push(this.lastScannedLB);
                            this.checkinLockboxes.push(this.lastScannedLB);

                            //Update Scanned Lockboxes count
                            this.scannedLBCount = this.checkinLockboxes.length;
                        } else if (jsonResponse.validlb == true) {
                            this.assignedLockboxes.push(this.lastScannedLB);
                            this.checkinLockboxes.push(this.lastScannedLB);

                            //Calculate what the agent should receive
                            //Update Scanned Lockboxes count
                            this.scannedLBCount = this.checkinLockboxes.length;

                            if (!this.isProgrammingListSet()) {
                                this.creditLBCount = this.creditLBCount + parseFloat(this.currentInstallLBRatio);
                            }
                        }

                        //Update Scanned Lockboxes count
                        this.scannedLBCount = this.checkinLockboxes.length;


                        this.disableClearList = false;
                    } else {
                        if (jsonResponse.validlb == false) {
                            this.displayHoldQueueMessageScreen = true;
                            this.checkinMessage = this.AGENT_NOT;

                            this.failedLockboxes.push(this.lastScannedLB);
                            this.checkinLockboxes.push(this.lastScannedLB);

                            //Update Scanned Lockboxes count
                            this.scannedLBCount = this.checkinLockboxes.length;

                        } else if (jsonResponse.validlb == true) {
                            this.assignedLockboxes.push(this.lastScannedLB);
                            this.checkinLockboxes.push(this.lastScannedLB);

                            //Calculate what the agent should receive
                            //Update Scanned Lockboxes count
                            this.scannedLBCount = this.checkinLockboxes.length;

                            if (!this.isProgrammingListSet()) {
                                this.creditLBCount = this.creditLBCount + parseFloat(this.currentInstallLBRatio);
                            }
                        }
                        //Update Scanned Lockboxes count
                        this.scannedLBCount = this.checkinLockboxes.length;
                        this.disableClearList = false;
                    }

                }
            }
        }

    }

    /**
     * Perform checks after the Finish Check-In button is clicked on the UI.
     */
    finishCheckinButtonClick(){
        this.slGlobalService.saveCallStack("CheckinComponent:finishCheckinButtonClick");
        this.checkLimitListLockboxValue();

        // If it is not over the LBLimit then continue with finishing the checkin.
        //Checking for sendToAssoc since the popup will not display if sendToAssoc is true.
        //We want to automatically send to the association.
        if(! this.isOverLBLimit || this.sendToAssoc){
            this.finishCheckin();
        }
    }

    /**
     * Sets sendToAssoc to true regardless of it being checked or not on the UI.
     * Used in the overLBLimit dialog popup. 
     */
    finishCheckInSendToAssociation(){
        this.sendToAssoc = true;
        this.finishCheckin();
    }

    /**
     * Set the profile only flag to true and finishes the checkin.
     */
    finishCheckinWitoutOrder(){
        this.profileOnly = true;
        this.finishCheckin();
    }

    /**
     * Process the check-in and creates and order to be sent to the programming queue or the asociation hold queue.
     */
    finishCheckin() {

        this.slGlobalService.saveCallStack("CheckinComponent:createTransfers");

        this.clearMessages();

        if(this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined && this.selectedUser.CardSN != undefined) {
            if(this.currentInstallSCReturn == "1") {
                if(this.sentriCard.length > 0 && this.sentriCard != this.selectedUser.CardSN) {
                    this.CARD_RETURN_ERROR = this.CARD_RETURN_ERROR.replace('<CARD>', this.sentriCard);
                    this.CARD_RETURN_ERROR = this.CARD_RETURN_ERROR.replace('<AGENT>', this.selectedUser.FirstName + ' ' + this.selectedUser.LastName);
                    this.notValidTurnIn = true;
                    this.displayNoCheckInScreen = true;
                } else if(this.sentriCard.length < 1 && !this.sentriCardReturned) {
                    this.sentriCardBlank = true;
                    this.displayNoCheckInScreen = true;
                } else if(this.checkinProxy.length < 1 && !this.checkinProxyBool) {
                    this.chkProxyBlank = true;
                    this.displayNoCheckInScreen = true;
                } else {
                    this.finishOrder();
                }
            } else {
                this.finishOrder();
            }
        }
    }

    finishOrder(){
        if(this.sendToAssoc) {
            this.toAssoc = 1;
        }
        
        // Finish Order create transfers to the agent
        this.checkinService
            .finishCheckin(this.selectedUser.CardSN, this.supraKey, this.supraCradle, 
                this.checkinLockboxes, this.toAssoc, this.sentriCard, this.checkinProxy, this.agentLimitLBCount, this.profileOnly)
            .then(this.processFinishCheckIn.bind(this))
            .catch(this.handleError.bind(this));
    }

    processFinishCheckIn(jsonResponse: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:processFinishCheckIn");

        //IF they have failed boxes send them to the hold queue
        if(this.failedLockboxes.length > 0 || this.sendToAssoc) {
            this.displayHoldQueueMessageScreen = true;
            this.checkinMessage = this.ASSOC_MSG;
        }

        this.resetScreen();
    }

    /*
     *Clear the search values
     */
    clearUserSearch() {

        this.slGlobalService.saveCallStack("CheckinComponent:clearUserSearch");

        this.sendToAssoc = false;
        this.toAssoc = 0;

        this.firstNameSearchValue = "";
        this.lastNameSearchValue = "";
        this.accountNumberSearchValue = "";
        this.sentriCard = "";
    }

    getInstallSettings() {

        this.slGlobalService.saveCallStack("CheckinComponent:getInstallSettings");

        this.commonService
            .searchInstallSettings(this.selectedInstall.trainingscheduleid)
            .then(this.setInstallSettings.bind(this))
            .catch(this.displayError.bind(this));

    }

    /**
     * Loops through all install settings on the server and sets the local and global values.
     * @param installData 
     */
    setInstallSettings(installData: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:setInstallSettings");

        // assign to this temporary variable while we make modifications to the array data so that Angular doesn't
        // have to re-render the table for each change
        var InstallDataProgress = installData;

        for (let setting of InstallDataProgress) {
            this.setInstallSettingValues(setting);
        }

        this.setWizardDisplay();

    }

    /**
     * Sets the local and global variables for the install settings
     * @param setting 
     */
    setInstallSettingValues(setting) {

        switch(setting.Name){
            case "ConversionType":
                this.slGlobalService.setCurrentInstallConversionType(setting.Value);
                this.currentInstallConversionType = setting.Value;
                break;
            case "LBReturnRatio":
                this.slGlobalService.setCurrentInstallLBRatio(setting.Value);
                this.currentInstallLBRatio = setting.Value;
                break;
            case "AcceptLockboxReturns":
                this.slGlobalService.setCurrentInstallLBReturn(setting.Value);
                this.currentInstallLBReturn = setting.Value;
                break;
            case "AcceptSentriCardReturns":
                this.slGlobalService.setCurrentInstallCardReturn(setting.Value);
                this.currentInstallSCReturn = setting.Value;
                break;
            case "AcceptCradleReturns":
                this.slGlobalService.setCurrentInstallCradleReturn(setting.Value);
                this.currentInstallCradleReturn = setting.Value;
                break;
            case "CheckSupraLBOwnership":
                this.slGlobalService.setSupraLBOwnerCheck(setting.Value);
                this.currentSupraLBOwnerCheck = setting.Value;
                break;
            case "AcceptKeyReturns":
                this.slGlobalService.setCurrentInstallKeyReturn(setting.Value);
                this.currentInstallKeyReturn = setting.Value;
                break;
            case "PrintLabels":
                this.slGlobalService.setPrintLabels(setting.Value);
                this.currentPrintLabel = setting.Value;
                break;
            case "ProgrammingList":
                this.slGlobalService.setProgList(setting.Value);
                this.currentProgList = setting.Value;
                break;
            case "LimitList":
                this.slGlobalService.setLimitList(setting.Value);
                this.hasLimitList = Boolean(Number(setting.Value));
                break;
        }

    }

    setWizardDisplay() {

        this.slGlobalService.saveCallStack("CheckinComponent:setWizardDisplay");

        if(this.currentInstallConversionType == "Supra") {
            this.BOX_TYPE_SCAN = this.TURN_IN_SUPRA_BOX_SCAN;
            this.BOX_TYPE_CHK = this.TURN_IN_SUPRA_BOX_CHKBOX;

            this.spInstall = true;
        } else {
            this.BOX_TYPE_SCAN = this.SCAN_LOCKBOXES;
            this.BOX_TYPE_CHK = this.TURN_IN_SUPRA_BOX_CHKBOX;

            this.spInstall = false;
        }

        if(this.currentInstallKeyReturn == "1") {
            this.spkStep = true;
        } else if(this.currentInstallKeyReturn == "0") {
            this.spkStep = false;
            this.stepTwo = "1";
        }

        if(this.currentInstallCradleReturn == "1") {
            this.spcStep = true;
        } else if(this.currentInstallCradleReturn == "0") {
            this.spcStep = false;

            if(this.currentInstallKeyReturn == "0") {
                this.stepThree = "1";
            } else {
                this.stepThree = "2";
            }

        }

        if(this.currentInstallLBReturn == "1") {
            this.lbrStep = true;
        } else if(this.currentInstallLBReturn == "0") {
            this.lbrStep = false;
        }

        //If not a supra install display lockboxes as step one
        if(!this.spInstall) {
            this.stepThree = "1";
        }

        if(this.currentInstallSCReturn == "1") {
            this.sentriCardStep = true;

            if(this.lbrStep == true) {
                this.stepThree = "2";
            }

        } else {
            this.sentriCardStep = false;
        }

        if(this.currentInstallLBReturn == "1" || this.currentInstallCradleReturn == "1" || this.currentInstallKeyReturn == "1" || this.currentInstallSCReturn == "1") {
            this.hideWizard = true;
            this.displaySimpleCheckIn = false;
        }
    }

    userStatusError(errorMessage: any): void {
        this.slGlobalService.saveCallStack("ProgrammingComponent:displayError");

        if(errorMessage == "Missing CardSN") {
            this.noAcctNum = true;
            this.displayNoCheckInScreen = true;
        } else if(errorMessage == 'Invalid card sn') {
            this.noAcctNum = true;
            this.displayNoCheckInScreen = true;
        } else {
            this.noAcctNum = true;
            this.displayNoCheckInScreen = true;
        }

    }

    displayError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:displayError");

        //Set the color to red
        this.messageColor = '#BF2B3F';

        this.showAjaxLoader = false;

        this.displayNoInstallsFound = true;

        if (errorMessage) {
            this.errorMessage = errorMessage;
        }
        else {
            this.errorMessage = this.UNABLE_RETRIEVE;
        }

    }

    handleError(errorMessage: any): void {

        this.slGlobalService.saveCallStack("CheckinComponent:handleError");

        this.clearUserSearch();

        if (errorMessage) {
            this.errorMessage = errorMessage;
        }
        else {
            this.errorMessage = "Unable to get translation data";
        }
    }

    /**
     * Resets the checkin component to default state.
     */
    resetScreen(): void{
        this.slGlobalService.saveCallStack("CheckinComponent:resetScreen");

        this.clearMessages();

        // Clear the search values
        this.clearUserSearch();

        //Show add lockbox and modify order ability once the user is selected
        this.disableAddLockbox = true;
        this.spCradleReturned = true;
        this.spKeyReturned = true;

        //hide the wizard layout
        this.showHideDisplay = true;
        //Enable search fields
        this.disableFirstName = !this.disableFirstName;
        this.disableLastName = !this.disableLastName;
        this.disableAccountNumber = !this.disableAccountNumber;

        this.selectedUser = new User;
        this.lockboxList = [];
        this.failedLockboxes = [];
        this.assignedLockboxes = [];
        this.checkinLockboxes = [];
        this.supraCradle = "";
        this.supraKey = "";
        this.checkinProxy = "";
        this.toAssoc = 0;
        this.receivedLBCount = 0;
        this.creditLBCount = 0;
        this.scannedLBCount = 0;
        this.isUserSelected = false;
        this.profileOnly = false;

        this.checkinProxyBool = true;

        // Disable clear list button
        this.disableClearList = false;
        this.isOverLBLimit = false;


        if(this.spkStep && this.spInstall) {
            setTimeout(()=>{
                this.key.nativeElement.checked = false;
                this.spKeyReturned = true;
            });
        }

        if(this.spcStep && this.spInstall) {
            setTimeout(()=>{
                this.cradle.nativeElement.checked = false;
                this.spCradleReturned = true;
            });
        }

        if(this.lbrStep) {
            setTimeout(()=>{
                this.box.nativeElement.checked = false;
                this.disableAddLockbox = true;
            });
        }

        if(this.sentriCardStep) {
            setTimeout(()=>{
                this.card.nativeElement.checked = false;
                this.sentriCardReturned = true;
            });
        }

        /**
         * The checkInProxyCheckbox and sendassoc are used in two different spots with different ngIf expressions.
         * Since they are ViewChild elements they must be in the DOM for them to be defined.  If the ngIf expressions is false they
         * will not be in the DOM.
         *
         * Checking to see if they are defined before reseting checkbox to false.  If they are not defined it is okay since the default
         * state of a checkbox is false.
         *
         * In an upgraded version of Angular we should be able to set the viewChilds as static which would fix the issue of them not
         * being defined in the DOM,
         *
         * https://stackoverflow.com/questions/55610047/angular6-viewchild-undefined-with-ngif
         */
        if(this.checkInProxyCheckbox){
            setTimeout(()=>{
                this.checkInProxyCheckbox.nativeElement.checked = false;
            });
        }

        if(this.sendassoc){
            setTimeout(()=>{
                this.sendassoc.nativeElement.checked = false;
            })
        }


        setTimeout(()=>{document.getElementById("firstName").focus()}, 100);
    }

    /**
     * Checks if the current install is using a limit list 
     * and if the credit + recieved lockboxes is greater than the agent limit count.
     */
    checkLimitListLockboxValue(){
        if(this.hasLimitList){
            if( (this.creditLBCount + this.receivedLBCount) > this.agentLimitLBCount){
                this.isOverLBLimit = true;
            } else {
                this.isOverLBLimit = false;
            }
        }
    }

    /**
     * Checks if there is a programming list set.
     * @returns  Boolean
     */
    isProgrammingListSet(){
        return this.currentProgList === "1";
    }
}

