import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';





import { Router, ActivatedRoute, Params } from '@angular/router';

import { SentriLockHttpService } from '../sl-http.service';
import { SentriLockGlobalService } from '../sl-global.service';
import { AuthService } from '../auth.service';

import { Install } from '../install';
import { InstallData } from '../installdata';

// import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { User } from "../user";

@Injectable()
export class AdminService {

    private headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(private route: ActivatedRoute,
        private router: Router,
        private slHttpService: SentriLockHttpService,
        public slGlobalService: SentriLockGlobalService) {

        this.slGlobalService.saveCallStack("AdminService:constructor");
    }

    getLBAssigned(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchInstalls");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/lockboxassigned`, paramObject)
            .toPromise()
            .then(this.processLBAssigned.bind(this, paramObject))
            .catch(this.handleError.bind(this));

    }

    private processLBAssigned(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processStartDataUtility");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processStartDataUtility");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    getCheckins(tsaid: string, date: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:getCheckins");

        // Send completed install as "0" so we we only get all installs
        var paramObject = {
            tsaid: tsaid,
            date: date
        };

        // TODO {RSW} fix this to use processGetCheckins and test!
        return this.slHttpService
            .getInMemPostServer(`kewe/reports/checkins`, paramObject)
            .toPromise()
            .then(this.processLBAssigned.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processGetCheckins(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processGetCheckins");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processGetCheckins");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    getProgrammingQueues(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:getProgrammingQueues");
        // Send completed install as "0" so we we only get all installs
        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/programmingqueue`, paramObject)
            .toPromise()
            .then(this.processGetProgrammingQueues.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processGetProgrammingQueues(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processGetProgrammingQueues");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processGetCheckins");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    searchInstalls(): Promise<any> {
        this.slGlobalService.saveCallStack("AdminService:searchInstalls");

        // Send completed install as "0" so we we only get all installs
        var paramObject = {
            completedonly: 0
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/candidateinstalls`, paramObject)
            .toPromise()
            .then(this.processInstalls.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstalls(origParamObject, response): Install[] {

        this.slGlobalService.saveCallStack("AdminService:processInstalls");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AssociationService:processInstalls");
            // rethrow the exception
            throw exception;
        }

        return installsArray as Install[];
    }

    stopDataUtility(tsaid: string): Promise<any> {
        this.slGlobalService.saveCallStack("AdminService:searchInstalls");

        var paramObject = {
            killdata: 1,
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installdata`, paramObject)
            .toPromise()
            .then(this.processStopDataUtility.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processStopDataUtility(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processStopDataUtility");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processStopDataUtility");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    resetUsers(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:resetUsers");

        var paramObject = {
            tsaid: tsaid,
            resetusers: 1
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/resetusers`, paramObject)
            .toPromise()
            .then(this.processResetUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processResetUsers(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processResetUsers");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processResetUsers");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    resetLockboxes(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchInstalls");
        // Send completed install as "0" so we we only get all installs
        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/resetlockboxes `, paramObject)
            .toPromise()
            .then(this.processResetLockboxes.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processResetLockboxes(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processStopDataUtility");

        var jsonData = {};

        try {
            jsonData = this.slHttpService.getJsonFromResponse(response);
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AuthService:processLBOwnerResponse");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    searchUsers(firstName: string, lastName: string, accountNumber: string, tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchUsers");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/getlockedqueues`, paramObject)
            .toPromise()
            .then(this.processUsers.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processUsers(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("AdminService:processUsers");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processUsers");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    releaseUser(accountNumber: string, tsaid: string, queueid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchUsers");


        var paramObject = {
            cardsn: accountNumber,
            tsaid: tsaid,
            queueid: queueid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/freeprogqueueentry`, paramObject)
            .toPromise()
            .then(this.processReleaseUser.bind(this, paramObject))
            .catch(this.handleError.bind(this));


    }

    private processReleaseUser(origParamObject, response): User[] {

        this.slGlobalService.saveCallStack("AdminService:processSelectUser");

        var usersArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                usersArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "UserManagementService:processSelectUser");
            // rethrow the exception
            throw exception;
        }

        return usersArray as User[];
    }

    activityReport(tsaid: string, timezone: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:activityReport");

        var paramObject = {
            tsaid: tsaid,
            timezone: timezone
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/activity`, paramObject)
            .toPromise()
            .then(this.processActivityReport.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    summaryReport(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:summaryReport");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/summary`, paramObject)
            .toPromise()
            .then(this.processSummaryReport.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    /**
     * Calls server to fetch LBQuarantine report.
     * @param tsaid 
     * @returns 
     */
    lbQuarantineReport(tsaid: string): Promise<any> {
        this.slGlobalService.saveCallStack("AdminService:lbQuarantineReport");

        var paramObject = { tsaid: tsaid };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/lbquarantinereport`, paramObject)
            .toPromise()
            .then(this.processLBQuarantineReport.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    /**
     * Calls server to fetch LBQuarantine report.
     * @param tsaid 
     * @returns 
     */
    fullProgrammingListReport(tsaid: string): Promise<any> {
        this.slGlobalService.saveCallStack("AdminService:programmingListReport");

        var paramObject = { tsaid: tsaid };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/programminglistreport`, paramObject)
            .toPromise()
            .then(this.processResponse.bind(this, paramObject, "programmingListReport"))
            .catch(this.handleError.bind(this));
    }

    /**
 * Calls server to fetch LBQuarantine report.
 * @param tsaid 
 * @returns 
 */
    fullLimitListReport(tsaid: string): Promise<any> {
        this.slGlobalService.saveCallStack("AdminService:fullLimitListReport");

        var paramObject = { tsaid: tsaid };

        return this.slHttpService
            .getInMemPostServer(`kewe/reports/limitlistreport`, paramObject)
            .toPromise()
            .then(this.processResponse.bind(this, paramObject, "limitlistreport"))
            .catch(this.handleError.bind(this));
    }

    startDataUtility(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchInstalls");

        var paramObject = {
            reinit: 1,
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installdata`, paramObject)
            .toPromise()
            .then(this.processStopDataUtility.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    saveUser(firstName: string, lastName: string, username: string, permissions: any[], password: string, confirmpassword: string): Promise<Object> {

        this.slGlobalService.saveCallStack("AdminService:saveUser");

        var paramObject = {
            firstname: firstName,
            lastname: lastName,
            username: username,
            permissions: permissions,
            password: password,
            confirmpassword: confirmpassword
        };

        var headers = new Headers();
        //headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Content-Type', 'application/json');

        // IMPORTANT - the ${} syntax only works with the back tick ` NOT quotes
        return this.slHttpService
            .getInMemPostServer(`kewe/admin/addkuser`, paramObject)
            .toPromise()
            .then(this.processSaveUser.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processSaveUser(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processSaveUser");

        var success = false;

        if (response && response.ok) {
            success = response.ok;
        }

        return response;
    }

    searchInstallData(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchInstallData");

        var paramObject = {
            statsonly: 1,
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installdata`, paramObject)
            .toPromise()
            .then(this.processInstallData.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstallData(origParamObject, response): InstallData[] {

        this.slGlobalService.saveCallStack("AdminService:processInstallData");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json();
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processInstallData");
            // rethrow the exception
            throw exception;
        }

        return installsArray as InstallData[];
    }

    searchInstallSettings(tsaid: string): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:searchInstallSettings");

        var paramObject = {
            tsaid: tsaid
        };

        return this.slHttpService
            .getInMemPostServer(`kewe/admin/installsettings`, paramObject)
            .toPromise()
            .then(this.processInstallSettings.bind(this, paramObject))
            .catch(this.handleError.bind(this));
    }

    private processInstallSettings(origParamObject, response): InstallData[] {

        this.slGlobalService.saveCallStack("AdminService:processInstallSettings");

        var installsArray = [];

        try {
            // A redirect response doesn't have a body so trying to get the JSON would result in an error.
            if (response && !response.isRedirect && (typeof response.json === "function") && response.json() && response.json().data) {
                installsArray = response.json().data;
            }
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processInstallSettings");
            // rethrow the exception
            throw exception;
        }

        return installsArray as InstallData[];
    }


    private handleError(error: any): Promise<any> {

        this.slGlobalService.saveCallStack("AdminService:handleError");

        var genericErrorMsg = "";

        var translationData = this.slGlobalService.getTranslations('COMMON_COMPONENT');

        if (translationData) {
            genericErrorMsg = translationData.genericerror;
        }

        var errorMessage;

        try {
            if (error && error.message) {
                errorMessage = error.message;
            }
            else if (error && (typeof error.json === "function") && error.json() && error.json().ResponseText) {
                errorMessage = error.json().ResponseText;
            }
            else if (error && error.statusText) {
                errorMessage = error.statusText;
            }
            else if (error && error._body && error._body.error) {
                errorMessage = error._body.error;
            }
            else {
                errorMessage = genericErrorMsg;
            }

            // make sure the error message isn't a JSON parse error message, if it use the generic error message
            if (errorMessage && errorMessage.toUpperCase().includes("JSON")) {
                errorMessage = genericErrorMsg;
            }
        }
        catch (err) {
            errorMessage = genericErrorMsg;
        }

        return Promise.reject(errorMessage);
    }

    private processActivityReport(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processActivityReport");

        let jsonData = {};

        try {
            jsonData = response.json();
        }
        catch (exception) {

            // send teh exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    private processSummaryReport(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processSummaryReport");

        var jsonData = {};

        try {
            jsonData = response.json();
        }
        catch (exception) {

            // send teh exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processUserStatus");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }


    /**
     * Process the json data, sends exception to server.
     * @param origParamObject 
     * @param response 
     * @returns 
     */
    private processLBQuarantineReport(origParamObject, response): Object {

        this.slGlobalService.saveCallStack("AdminService:processLBQuarantineReport");

        var jsonData = {};

        try {
            jsonData = response.json();
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:processLBQuarantineReport");
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

    /**
     * Process the json data, sends exception to server.
     * @param origParamObject 
     * @param response 
     * @param caller
     * @returns 
     */
    private processResponse(origParamObject, caller, response): Object {

        this.slGlobalService.saveCallStack("AdminService:" + caller);

        var jsonData = {};

        try {
            jsonData = response.json();
        }
        catch (exception) {

            // send the exception to the server to be logged
            this.slHttpService.sendErrorToServer(origParamObject, exception, response, "AdminService:" + caller);
            // rethrow the exception
            throw exception;
        }

        return jsonData;
    }

}